import React, { useCallback, useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import CalenderComp from "./CalenderComp";
import ic_hospital from "../../assets/images/ic_hospital.svg";
import dog from "../../assets/images/dog.svg";
import ic_clock from "../../assets/images/ic_clock.svg";
import ic_video from "../../assets/images/ic_video.svg";
import cat from "../../assets/images/cat.png";
import cat_1 from "../../assets/images/cat_1.png";
import cat_2 from "../../assets/images/cat_2.png";
import Modal from "react-bootstrap/Modal";
import dog_1 from "../../assets/images/dog_1.png";
import dog_2 from "../../assets/images/dog_2.png";
import dog_3 from "../../assets/images/dog_3.png";
import CreatableSelect from "react-select/creatable";
import ic_filter from "../../assets/images/ic_filter.svg";
import ic_notepad from "../../assets/images/ic_notepad.svg";
import ic_recipt from "../../assets/images/ic_recipt.svg";
import ic_clock_pending from "../../assets/images/ic_clock_pending.svg";
import ic_completed from "../../assets/images/ic_completed.svg";
import ic_rejected from "../../assets/images/ic_rejected.svg";
import ic_pending from "../../assets/images/ic_pending.svg";
import ic_play_video from "../../assets/images/ic_play_video.svg";
import ic_back_arrow from "../../assets/images/ic_back_arrow.svg";
import PaginationComp from "../../sharedComponent/PaginationComp";
import { AppContext } from "../../context/AppContext";
import PrescriptionComp from "../../sharedComponent/PrescriptionComp";
import ApiConfig from "../../api/ApiConfig";
import {
  deleteWithAuthCall,
  getWithAuthCall,
  simpleGetCall,
  simpleGetCallWithToken,
  simplePostCall,
} from "../../api/ApiServices";
import InfiniteScroll from "react-infinite-scroll-component";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import Loader from "../../sharedComponent/Loader";
import { useNavigate } from "react-router-dom";
import Axe from "../../assets/images/crossAxe.svg";
import { useRef } from "react";
import Notepad from "../../sharedComponent/Notepad";
import { formatCurrency } from "../../common/commonFunctions";

const AtClinic = () => {
  const {
    socket,
    setLoading,
    setPrescription,
    loading,
    customerData,
    setHistoryPres,
    setHistoryPresData,
    notesData,
    setNotesData,
  } = useContext(AppContext);
  // const [modalShow, setModalShow] = React.useState(false);
  const [viewCalender, setviewCalender] = useState(false);
  const [viewList, setviewList] = useState(true);
  const [reqList, setreqList] = useState(true);
  const [completedList, setCompletedList] = useState(true);
  const [Appointment, setAppointment] = useState("1");

  const navigate = useNavigate();
  const handleShowPersric = () => {
    addPrescriptionHandal();
  };

  const [viewAppointment, setviewAppointment] = useState(false);
  const [page, setPage] = useState(1);
  const [FilterName, setFilterName] = useState("");
  const [Filter2Name, setFilter2Name] = useState("");
  const [Filter3Name, setFilter3Name] = useState("");
  const [TodayDate, setTodayDate] = useState("");
  const [ClintDetailsList, setClintDetailsList] = useState({});
  const [ClintPhoto, setClintPhoto] = useState([]);
  const [ClintPrescription, setClintPrescription] = useState([]);
  const [AppointmentsCalender, setAppointmentsCalender] = useState([]);
  const [AppointmentsList, setAppointmentsList] = useState([]);
  const [AppointmentsRequest, setAppointmentsRequest] = useState([]);
  const [AppointmentsCompleted, setAppointmentsCompleted] = useState([]);
  const [InviceTotal, setInviceTotal] = useState("0");
  const [ClintVisitlist, setClintVisitlist] = useState([]);
  const [Prsiptiontlist, setPrsiptiontlist] = useState([]);
  const [vaccineListData, setVaccineListData] = useState([]);
  const [vaccineList, setVaccineList] = useState([]);
  const [ClintVisitAlllist, setClintVisitAlllist] = useState([]);
  const [ClintInvicelist, setClintInvicelist] = useState([]);
  const [TotalData, setTotalData] = useState(0);
  const [currentTab, setCurrentTab] = useState("1");
  const [VisiteTotal, setVisiteTotal] = useState("0");
  const [apid, setid] = useState("");
  // Pet Food State
  const [petFoodList, setPetFoodList] = useState([]);
  const [petFoodDropD, setPetFoodDropD] = useState([]);

  const [startdigno, setStartdigno] = useState(false);
  const [option, setoption] = useState([
    { value: "vanilla", label: "Vanilla", rating: "safe" },
    { value: "chocolate", label: "Chocolate", rating: "good" },
    { value: "strawberry", label: "Strawberry", rating: "wild" },
    { value: "salted-caramel", label: "Salted Caramel", rating: "crazy" },
  ]);
  const [selectMediVal, setselectMediVal] = useState("Select...");
  const [CliniceAdd, setCliniceAdd] = useState({
    doctor_appointment_id: "",
    product_id: "",
    prescription_frequency_id: "",
    duration: "",
    instruction: "",
    quantity: "",
    medicine_type: "",
  });
  const [petFoodAdd, setPetFoodAdd] = useState({
    doctor_appointment_id: apid,
    product_id: null,
    prescription_frequency_id: null,
    duration: null,
    instruction: "",
    quantity: null,
  });
  const [docId, setDocId] = useState("");
  const [mainDocID, setMainDocID] = useState(0);
  const [vaccineAdd, setVaccineAdd] = useState({
    doctor_appointment_id: apid,
    vaccine_id: null,
    frequency_id: null,
    duration: "",
    dose: null,
  });

  const allTab = () => {
    // setreqList(false);
    // setviewCalender(true);
    // setviewList(false);
    // setviewAppointment(false);
    // setCompletedList(false);
  };

  const SelectStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "10px",
      border: "1px solid #A3AED0",
    }),
  };

  const SelectStylesInput = {
    control: (styles) => ({
      ...styles,
      // borderRadius: "10px",
      // border: "1px solid #A3AED0",
    }),
  };
  const emailInput = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  useEffect(() => {
    socket &&
      socket.on("new_appointment", () => {
        getClinetList(page, "refresh");
        getClinetRequest(page, "refresh")
      });
  }, [socket]);

  function geAllList(status) {
    let newRequestBody = JSON.stringify({
      status: status,
      doctor_id: customerData.doctor_id,
    });
    simplePostCall(ApiConfig.APPOINTMENTS_STATUS, newRequestBody)
      .then((data) => {
        let Attendance =
          data.data &&
          data.data.map((item) => {
            return {
              start: item.start,
              end: item.end,
              title: item.title,
            };
          });
        setAppointmentsCalender(Attendance);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const getClinetDetailsByeVisit = (id) => {
    simpleGetCall(ApiConfig.CLIINET_DETAILS_BYE_VISITE_ID + id)
      .then((res) => {
        GetPRESCRIPTION_GET(id);

        setClintDetailsList(res.data);
        setClintPhoto(res.data.appointment_images);
        setClintPrescription(res.data.prescription);
      })
      .catch((err) => {
        console.error("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAppoinmentVisitDetails();
  }, []);

  const getAppoinmentVisitDetails = (id) => {
    simpleGetCall(ApiConfig.APPOINTMENTS_TODAY_LIST + customerData.doctor_id)
      .then((res) => {
        setClintVisitlist(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [FacilitiesType, setFacilitiesType] = useState([]);
  const [medicineType, setMedicineType] = useState([]);
  const [FrequencyType, setFrequencyType] = useState([]);

  function getProduct() {
    getWithAuthCall(ApiConfig.PRODUCT_DRUPDOWN)
      .then((data) => {
        let Type = data?.data ? data?.data : [];
        var grades = [];

        Type.map((grade, index) => {
          grades.push({
            label: grade.product,
            value: grade.product_id,
          });
        });
        setFacilitiesType(grades);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const getMedicineType = () => {
    simpleGetCall(ApiConfig.GET_MEDICINE_TYPE)
      .then((res) => {
        if (res.success === true) {
          setMedicineType(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getFrequency() {
    getWithAuthCall(ApiConfig.FREQUENCY_DROPDOWN)
      .then((data) => {
        // console.log(data);
        // let Type = data.data;
        // var grades = [];

        // Type.map((grade, index) => {
        //   grades.push({
        //     label: grade.prescription_frequency,
        //     value: grade.prescription_frequency_id,
        //   });
        // });
        setFrequencyType(data?.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const GetPRESCRIPTION_GET = (doctor_appointment_id) => {
    // setLoading(true);

    simpleGetCall(ApiConfig.PRESCRIPTION_GET + doctor_appointment_id)
      .then((res) => {
        setPrsiptiontlist(res.data);
        let id = res?.data?.[0].doctor_appointment_id;
        setDocId(id);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // All Vaccine Apis
  const getVaccinationDet = () => {
    simpleGetCall(ApiConfig.GET_VACCINE_DROPDOWN)
      .then((res) => {
        let Type = res.data;
        var grades = [];

        Type.map((grade, index) => {
          grades.push({
            label: grade.vaccine,
            value: grade.vaccine_id,
          });
        });
        grades && setVaccineList(grades);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllVaccineDetails = (id) => {
    simpleGetCall(
      ApiConfig.GET_ALL_VACCINE_DETAILS + `?doctor_appointment_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setVaccineListData(res?.data);
          setMainDocID(res?.data?.[0]?.doctor_appointment_id);
        } else {
          setVaccineListData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeStatus = () => {
    let payload = {
      appointment_id: apid,
      status: 5,
    };
    simplePostCall(
      ApiConfig.CHANGE_STATUS_OFAPPOINTMENT,
      JSON.stringify(payload)
    )
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          navigate("/CustomerDetails");
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function onTopicChange(selectedOption) {
    var selected_topics = [];
    console.log("selectedOption,", selectedOption);
    setCliniceAdd({
      ...CliniceAdd,
      product_id: selectedOption?.value,
    });
  }

  function onVaccineChange(selectedOption) {
    setVaccineAdd({
      ...vaccineAdd,
      vaccine_id: selectedOption?.value,
    });
  }
  const deleteVaccine = (id, docId) => {
    deleteWithAuthCall(
      ApiConfig.DELETE_VACCINE_BY_ID +
        `?vaccinator_animal_prescription_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res?.message);
          getAllVaccineDetails(docId);
        } else {
          notifyError(res?.message);
          getAllVaccineDetails(docId);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // All Pet Food APis
  const getPetFoodList = (id) => {
    simpleGetCall(
      ApiConfig.GET_PRESCRPTION_PET_FOOD + `?doctor_appointment_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setPetFoodList(res?.data);
        } else {
          setPetFoodList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deletePetFood = (id) => {
    deleteWithAuthCall(
      ApiConfig.DELETE_PET_FOOD_ID + `?animal_food_prescription_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res?.message);
          getPetFoodList(apid);
        } else {
          notifyError(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getPetFoodDD = () => {
    simpleGetCall(ApiConfig.GET_PETFOOD_DROPDOWN)
      .then((res) => {
        var grades = [];
        res.data &&
          res.data.map((grade, index) => {
            grades.push({
              label: grade.product,
              value: grade.product_id,
            });
          });
        grades && setPetFoodDropD(grades);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function onPetFoodChange(selectedOption) {
    setPetFoodAdd({
      ...petFoodAdd,
      product_id: selectedOption?.value,
    });
  }
  const addPetFood = () => {
    let payload = JSON.stringify({
      doctor_appointment_id: apid,
      product_id: petFoodAdd.product_id,
      prescription_frequency_id: petFoodAdd.prescription_frequency_id,
      duration: petFoodAdd.duration,
      instruction: petFoodAdd.instruction,
      quantity: petFoodAdd.quantity,
    });
    simplePostCall(ApiConfig.ADD_PET_FOOD, payload)
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          getPetFoodList(apid);
          setPetFoodAdd({
            ...petFoodAdd,
            product_id: "",
            prescription_frequency_id: "",
            duration: "",
            instruction: "",
            quantity: "",
          });
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProduct();
    getFrequency();
    getMedicineType();
    geAllList("");
  }, []);
  function onFrequnceChange(selectedOption) {
    var selected_topics = [];

    if (selectedOption.length > 0) {
      selectedOption.map((topic, index) => {
        selected_topics.push(topic.value);
      });
    }

    setCliniceAdd({
      ...CliniceAdd,
      prescription_frequency_id: selectedOption,
    });
  }

  function onFreqVaccChange(selectedOption) {
    var selected_topics = [];

    if (selectedOption.length > 0) {
      selectedOption.map((topic, index) => {
        selected_topics.push(topic.value);
      });
    }
    setVaccineAdd({
      ...vaccineAdd,
      frequency_id: selectedOption?.value,
    });
  }
  function onpetFoodChange(selectedOption) {
    var selected_topics = [];

    if (selectedOption.length > 0) {
      selectedOption.map((topic, index) => {
        selected_topics.push(topic.value);
      });
    }
    setPetFoodAdd({
      ...petFoodAdd,
      prescription_frequency_id: selectedOption?.value,
    });
  }
  const addVaccineDet = () => {
    let docId = apid;
    let payload = {
      doctor_appointment_id: docId,
      vaccine_id: vaccineAdd?.vaccine_id,
      frequency_id: vaccineAdd?.frequency_id,
      duration: vaccineAdd?.duration,
      dose: vaccineAdd?.dose,
    };

    simplePostCall(ApiConfig.ADD_VACCINE, JSON.stringify(payload))
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          getAllVaccineDetails(docId);
          setVaccineAdd({
            ...vaccineAdd,
            vaccine_id: "",
            frequency_id: "",
            duration: "",
            dose: "",
          });
        } else {
          notifyError(res.message);
          getAllVaccineDetails(docId);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getClinetCompleted(page);
  }, [Filter3Name]);

  useEffect(() => {
    getClinetRequest(page);
  }, [Filter2Name]);

  useEffect(() => {
    if (FilterName === "") {
      setLoading(true);
      getClinetList(page);
    } else {
      getClinetList(page);
    }
  }, [FilterName]);

  function getClinetRequest(currentPage) {
    let newRequestBody = JSON.stringify({
      status: 1,
      doctor_id: customerData.doctor_id,
      page: currentPage,
      limit: "10",
      keyword: Filter2Name,
    });
    simplePostCall(ApiConfig.APPOINTMENTS_CLINICE_LIST, newRequestBody)
      .then((data) => {
        if (data.success) {
          setLoading(false);
          let total = data.total ? data.total : 0;
          setTotalData(total);
          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              type_of_animal: eachteam.type_of_animal || "",
              appointment_desc: eachteam.appointment_desc || "",
            };
          });

          if (currentPage === 1) {
            setAppointmentsRequest(data.data);
            setLoading(false);
          } else {
            setAppointmentsRequest([...AppointmentsRequest, ...LearningData]);
            setLoading(false);
          }
        } else {
          setAppointmentsRequest([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getClinetCompleted(currentPage) {
    let newRequestBody = JSON.stringify({
      status: 5,
      doctor_id: customerData.doctor_id,
      page: currentPage,
      limit: "10",
      keyword: Filter3Name,
    });
    simplePostCall(ApiConfig.APPOINTMENTS_CLINICE_LIST, newRequestBody)
      .then((data) => {
        if (data.success) {
          setLoading(false);
          let total = data.total ? data.total : 0;
          setTotalData(total);
          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              type_of_animal: eachteam.type_of_animal || "",
              appointment_desc: eachteam.appointment_desc || "",
            };
          });

          if (currentPage === 1) {
            setAppointmentsCompleted(data.data);
            setLoading(false);
          } else {
            setAppointmentsCompleted([
              ...AppointmentsCompleted,
              ...LearningData,
            ]);
            setLoading(false);
          }
        } else {
          setAppointmentsCompleted([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getClinetList(currentPage) {
    let newRequestBody = JSON.stringify({
      doctor_id: customerData.doctor_id,
      page: currentPage,
      limit: "10",
      keyword: FilterName,
    });
    simplePostCall(ApiConfig.APPOINTMENTS_CLINICE_LIST, newRequestBody)
      .then((data) => {
        if (data.success) {
          setLoading(false);
          let total = data.total ? data.total : 0;
          setTotalData(total);
          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              type_of_animal: eachteam.type_of_animal || "",
              appointment_desc: eachteam.appointment_desc || "",
            };
          });

          if (currentPage === 1) {
            setAppointmentsList(data.data);
            setLoading(false);
          } else {
            setAppointmentsList([...AppointmentsList, ...LearningData]);
            setLoading(false);
          }
        } else {
          setAppointmentsList([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const onPageLoad = () => {
    setPage(page + 1);

    currentTab === 1
      ? getClinetList(page + 1)
      : currentTab === 2
      ? getClinetRequest(page + 1)
      : getClinetCompleted(page + 1);
  };

  /// Details All
  const getClinetDetails = (id, doctor_appointment_id) => {
    setLoading(true);
    setid(doctor_appointment_id);
    simpleGetCall(
      ApiConfig.CLIINET_DETAILS_BY_ID +
        id +
        "&" +
        "doctor_appointment_id=" +
        doctor_appointment_id
    )
      .then((res) => {
        GetPRESCRIPTION_GET(doctor_appointment_id);
        setClintDetailsList(res.data);
        setClintPhoto(res.data.appointment_images);
        setClintPrescription(res.data.prescription);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Notes api
  const getNotesData = (id) => {
    simpleGetCall(ApiConfig.GET_NOTES_DATA + `?appointment_id=${id}`)
      .then((res) => {
        if (res?.success) {
          let VAL = res?.data?.remark;
          setNotesData(VAL);
        } else {
          setNotesData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateNote = () => {
    let payload = {
      appointment_id: apid,
      remark: notesData,
    };
    simplePostCall(ApiConfig.UPDATE_NOTE_DATA, JSON.stringify(payload))
      .then((res) => {
        if (res.success) {
          notifySuccess(res?.message);
          // getNotesData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClinetVisitDetails = (id) => {
    simpleGetCall(
      ApiConfig.CLINET_VIST_BY_ID + id + "&doctor_id=" + customerData.doctor_id
    )
      .then((res) => {
        var total = res.total;
        setVisiteTotal(total);
        setClintVisitAlllist(res);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getClinetInviceDetails = (id) => {
    simpleGetCall(
      ApiConfig.CLIINET_INVICE_ID + id + "&doctor_id=" + customerData.doctor_id
    )
      .then((res) => {
        var total = res?.data?.length ? res?.data?.length : 0;
        setInviceTotal(total);

        setClintInvicelist(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function putStatusChanges(id, status) {
    let newRequestBody = JSON.stringify({
      appointment_id: id,
      status: status,
    });
    simplePostCall(ApiConfig.APPOINTMENTS_STATUS_ACCEPTED, newRequestBody)
      .then((data) => {
        if (data.success) {
          notifySuccess(data.message);
          getClinetRequest(1);
          getClinetList(1);
        } else {
          notifyError(data.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  const addPrescriptionHandal = () => {
    setStartdigno(true);
    // setModalShow(true)
    let newRequestBody = JSON.stringify({
      doctor_appointment_id: apid,
      product_id: CliniceAdd.product_id,
      prescription_frequency_id: CliniceAdd.prescription_frequency_id,
      duration: CliniceAdd.duration,
      instruction: CliniceAdd.instruction,
      quantity: CliniceAdd.quantity,
      medicine_type: CliniceAdd.medicine_type,
    });
    simplePostCall(ApiConfig.ADD_PRESRIPATION, newRequestBody)
      .then((res) => {
        if (res.success) {
          GetPRESCRIPTION_GET(apid);
          setCliniceAdd({
            ...CliniceAdd,
            product_id: "",
            prescription_frequency_id: "",
            duration: "",
            instruction: "",
            quantity: "",
            medicine_type: "",
          });
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const deleteMedicine = (id, docId) => {
    deleteWithAuthCall(
      ApiConfig.DELETE_MEDICINE_DATA + `?animal_prescription_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res?.message);
          GetPRESCRIPTION_GET(docId);
        } else {
          notifyError(res?.message);
          GetPRESCRIPTION_GET(docId);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // History prescription modal api func()
  const getHistoryPres = (id) => {
    simpleGetCall(ApiConfig.PRESCRIPTION_GET_ID + `${id}`)
      .then((res) => {
        if (res?.success) {
          setHistoryPresData(res?.data);
          setHistoryPres(true);
        } else {
          setHistoryPresData();
          setHistoryPres(false);
          notifyError(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <>
          <InfiniteScroll
            dataLength={
              currentTab === 1
                ? AppointmentsList?.length
                : currentTab === 2
                ? AppointmentsRequest?.length
                : currentTab === 3
                ? AppointmentsCompleted?.length
                : 10
            }
            next={onPageLoad}
            // hasMore={true}
            hasMore={
              currentTab === 1
                ? AppointmentsList?.length !== TotalData
                : currentTab === 2
                ? AppointmentsRequest?.length !== TotalData
                : AppointmentsCompleted?.length !== TotalData
            }
            loader={
              viewCalender === true || viewAppointment === true ? (
                ""
              ) : (
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                ></h4>
              )
            }

            // }
          >
            <div className="appointment-wrapper" id="cx-main">
              <div className="reportsTabs">
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                  <Nav
                    variant="pills"
                    id="newTabMai"
                    className="tob_nav_pills"
                    onSelect={(selectedKey) => setCurrentTab(`${selectedKey}`)}
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="1"
                        onClick={() => {
                          setPage(1);
                          setFilterName("");
                          setviewCalender(false);
                          setviewList(true);
                          geAllList("");
                          // getClinetList(1);
                          // allTab();
                        }}
                      >
                        All Appointments
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="2"
                        onClick={() => {
                          setPage(1);
                          setFilterName("");
                          setviewCalender(false);
                          setreqList(true);
                          geAllList("1");
                          // getClinetRequest(1);
                          // allTab();
                        }}
                      >
                        Requests
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="3"
                        onClick={() => {
                          setPage(1);
                          setFilterName("");
                          setviewCalender(false);
                          setCompletedList(true);
                          geAllList("5");
                          // getClinetCompleted(1);
                          // allTab();
                        }}
                      >
                        Completed
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  {viewCalender && (
                    <div className="dark-btns-wrapper search-filter">
                      <button
                        className="dark-btns"
                        onClick={() => {
                          // getClinetList()
                          setviewList(true);
                          setreqList(true);
                          setCompletedList(true);
                          setviewCalender(false);
                        }}
                      >
                        List View
                      </button>
                    </div>
                  )}
                  {viewList && (
                    <div className="search-filter-wrapper">
                      <div className="search-filter-left">
                        {currentTab == 1 ? (
                          <>
                            <input
                              type="text"
                              className="form-control serach-input"
                              placeholder="Search by Name"
                              autoFocus
                              ref={emailInput}
                              value={FilterName}
                              onChange={(e) => {
                                setFilterName(e.target.value);
                                setPage(1);
                              }}
                            />
                          </>
                        ) : currentTab == 2 ? (
                          <>
                            <input
                              type="text"
                              className="form-control serach-input"
                              placeholder="Search by Name"
                              autoFocus
                              ref={emailInput}
                              value={Filter2Name}
                              onChange={(e) => {
                                setFilter2Name(e.target.value);
                                setPage(1);
                              }}
                            />
                          </>
                        ) : (
                          <input
                            type="text"
                            className="form-control serach-input"
                            placeholder="Search by Name"
                            autoFocus
                            ref={emailInput}
                            value={Filter3Name}
                            onChange={(e) => {
                              setFilter3Name(e.target.value);
                              setPage(1);
                            }}
                          />
                        )}
                        {/* <button className="fltr-btn">
                          <img src={ic_filter} alt="" />
                         </button> 
                         */}
                      </div>
                      <div className="search-filter-right">
                        <div className="dark-btns-wrapper search-filter">
                          <button
                            className="dark-btns"
                            onClick={() => {
                              setviewCalender(true);
                              setviewList(false);
                              setreqList(false);
                              setCompletedList(false);
                            }}
                          >
                            Calendar View
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {viewAppointment && (
                    <div className="app-result-arrow-top">
                      <img
                        src={ic_back_arrow}
                        alt=""
                        onClick={() => {
                          setviewList(true);
                          setviewAppointment(false);
                          setreqList(true);
                          setCompletedList(true);
                        }}
                      />
                      <label htmlFor="" className="capital">
                        Showing result for :{" "}
                        <span>
                          {ClintDetailsList?.first_name +
                            " " +
                            ClintDetailsList?.last_name}
                        </span>
                      </label>
                    </div>
                  )}
                  {/* ======================= CALENDER COMP START ===================== */}
                  {viewCalender && (
                    <div className="all-appoimt-wrapper">
                      <div className="row gx-3">
                        <div className="col-md-8">
                          <div className="left">
                            <CalenderComp events={AppointmentsCalender} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="right">
                            <div className="top-heading">
                              <div className="left-heading">
                                <label htmlFor="">Today's Visits</label>
                              </div>
                              <div className="right-date">
                                <label htmlFor="">
                                  {TodayDate}
                                  <span></span>
                                </label>
                              </div>
                            </div>
                            {ClintVisitlist && ClintVisitlist.length > 0 ? (
                              ClintVisitlist.map((itemlist, index) => {
                                return (
                                  <div className="check-up-card">
                                    <div className="left-check-up">
                                      <div className="left-img">
                                        <img src={itemlist.image_path} alt="" />
                                      </div>
                                      <div className="right-check-up-details">
                                        <h5>Visit - {index + 1}</h5>
                                        <label htmlFor="">
                                          {itemlist.doctor_clinic_name}
                                        </label>
                                        <p>
                                          <img src={ic_clock} alt="" />
                                          <span>
                                            {itemlist?.date} - {itemlist.time}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="right-check-up">
                                      <img src={ic_hospital} alt="" />
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <p className="scheule_tody">No Schedule Today</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* ======================= CALENDER COMP END ===================== */}
                  <Tab.Content>
                    <Tab.Pane eventKey="1">
                      {viewList && (
                        <div id="app-list-page-wrapper">
                          <div className="table-wrapper scroll-table">
                            <table>
                              <thead>
                                <tr>
                                  <th>Sr. No</th>
                                  <th>Name</th>
                                  <th>Photo </th>
                                  <th>Pet Type</th>
                                  <th>Description</th>
                                  <th>Time</th>
                                  <th>Fees</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {AppointmentsList &&
                                AppointmentsList.length > 0 ? (
                                  AppointmentsList.map((itemlist, index) => {
                                    return (
                                      <tr key={"AppointmentsList" + index}>
                                        <td>{index + 1}</td>
                                        <td className="tdName">
                                          {`${itemlist?.first_name} ${itemlist?.last_name}`}
                                        </td>
                                        <td>
                                          <label htmlFor="" className="photo">
                                            <img
                                              src={itemlist.image_path}
                                              alt=""
                                            />
                                          </label>
                                        </td>
                                        <td>{itemlist.type_of_animal}</td>
                                        <td>{itemlist.appointment_desc}...</td>
                                        <td>
                                          {itemlist?.date} - {itemlist.time}
                                        </td>
                                        <td>
                                          {formatCurrency(itemlist.charges)}
                                        </td>

                                        <>
                                          {itemlist.stringStatus ===
                                          "Accepted" ? (
                                            <>
                                              <td>
                                                <img
                                                  src={ic_completed}
                                                  className="status-ic"
                                                  alt=""
                                                />
                                                <label
                                                  htmlFor=""
                                                  className="pending-txt"
                                                >
                                                  {itemlist.stringStatus}
                                                </label>
                                              </td>
                                            </>
                                          ) : itemlist.stringStatus ===
                                            "Rejected" ? (
                                            <>
                                              <td>
                                                <img
                                                  src={ic_rejected}
                                                  className="status-ic"
                                                  alt=""
                                                />
                                                <label
                                                  htmlFor=""
                                                  className="pending-txt"
                                                >
                                                  {itemlist.stringStatus}
                                                </label>
                                              </td>
                                            </>
                                          ) : itemlist.stringStatus ===
                                            "Pending" ? (
                                            <td>
                                              <img
                                                src={ic_pending}
                                                className="status-ic"
                                                alt=""
                                              />
                                              <label
                                                htmlFor=""
                                                className="pending-txt"
                                              >
                                                {itemlist.stringStatus}
                                              </label>
                                            </td>
                                          ) : itemlist.stringStatus ===
                                            "Completed" ? (
                                            <td>
                                              <img
                                                src={ic_completed}
                                                className="status-ic"
                                                alt=""
                                              />
                                              <label
                                                htmlFor=""
                                                className="pending-txt"
                                              >
                                                {itemlist.stringStatus}
                                              </label>
                                            </td>
                                          ) : itemlist.stringStatus ===
                                            "In progress" ? (
                                            <>
                                              <td>
                                                <img
                                                  src={ic_clock_pending}
                                                  className="status-ic"
                                                  alt=""
                                                />
                                                <label
                                                  htmlFor=""
                                                  className="pending-txt"
                                                >
                                                  {itemlist.stringStatus}
                                                </label>
                                              </td>
                                            </>
                                          ) : (
                                            <td>
                                              <img
                                                src={ic_clock_pending}
                                                className="status-ic"
                                                alt=""
                                              />
                                              <label
                                                htmlFor=""
                                                className="pending-txt"
                                              >
                                                {itemlist.stringStatus}
                                              </label>
                                            </td>
                                          )}
                                        </>

                                        <td>
                                          {itemlist.stringStatus ===
                                            "Pending" ||
                                          itemlist.stringStatus ===
                                            "Rejected" ? (
                                            <>
                                              <button
                                                className="view-details-btn"
                                                onClick={() => {
                                                  getClinetDetails(
                                                    itemlist.customer_animal_id,
                                                    itemlist.doctor_appointment_id
                                                  );
                                                  getClinetVisitDetails(
                                                    itemlist.customer_animal_id
                                                  );
                                                  getClinetInviceDetails(
                                                    itemlist.customer_animal_id
                                                  );
                                                  // setviewAppointment(true);
                                                  // setviewList(false);
                                                  // setviewCalender(false);
                                                }}
                                              >
                                                -
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              <button
                                                className="view-details-btn"
                                                onClick={() => {
                                                  getClinetDetails(
                                                    itemlist.customer_animal_id,
                                                    itemlist.doctor_appointment_id
                                                  );
                                                  getClinetVisitDetails(
                                                    itemlist.customer_animal_id
                                                  );
                                                  getClinetInviceDetails(
                                                    itemlist.customer_animal_id
                                                  );
                                                  getPetFoodList(
                                                    itemlist?.doctor_appointment_id
                                                  );
                                                  getAllVaccineDetails(
                                                    itemlist.doctor_appointment_id
                                                  );
                                                  getNotesData(
                                                    itemlist.doctor_appointment_id
                                                  );
                                                  getVaccinationDet();
                                                  setviewAppointment(true);
                                                  setviewList(false);
                                                  setviewCalender(false);
                                                  getPetFoodDD();
                                                }}
                                              >
                                                View Details
                                              </button>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </table>
                            {AppointmentsList.length ? (
                              ""
                            ) : (
                              <p
                                style={{
                                  fontSize: "20px",
                                  marginTop: "200px",
                                }}
                                className=" text-center justify-content-center align-items-center"
                              >
                                Oops! It doesn't look like there are any
                                Appointments?
                              </p>
                            )}
                          </div>
                          <PaginationComp
                            length={AppointmentsList.length}
                            total={TotalData}
                          />
                        </div>
                      )}

                      {viewAppointment && (
                        <div className="app-details-wrapper">
                          <div className="row gx-3">
                            <div className="col-md-9">
                              <div className="left">
                                <div className="aap-heading">
                                  {/* all */}
                                  <label htmlFor="">Appointment </label>
                                </div>
                                <div className="row">
                                  <div className="col-md-4">
                                    <div>
                                      <label htmlFor="" className="key">
                                        Client Name
                                      </label>
                                    </div>
                                    <label htmlFor="" className="value tdName">
                                      {ClintDetailsList.first_name
                                        ? `${ClintDetailsList.first_name} ${ClintDetailsList.last_name}`
                                        : ""}
                                    </label>
                                  </div>
                                  <div className="col-md-4">
                                    <div>
                                      <label htmlFor="" className="key">
                                        Appointment Date & Time
                                      </label>
                                    </div>
                                    <label htmlFor="" className="value">
                                      {ClintDetailsList?.date}{" "}
                                      {ClintDetailsList.time}
                                    </label>
                                  </div>
                                  <div className="col-md-4">
                                    <div>
                                      <label htmlFor="" className="key">
                                        Description
                                      </label>
                                    </div>
                                    <label htmlFor="" className="value">
                                      {ClintDetailsList.appointment_desc}
                                    </label>
                                  </div>

                                  <div className="col-12">
                                    <label htmlFor="" className="key">
                                      Photos
                                    </label>
                                    <div className="photo-video-wrapper">
                                      <div className="row">
                                        {ClintPhoto && ClintPhoto.length > 0 ? (
                                          ClintPhoto.map((itemlist, index) => {
                                            return (
                                              <div className="col-xl-2 col-lg-3 col-md-4 mb-3">
                                                <img
                                                  src={itemlist.image_path}
                                                  alt=""
                                                  height="70px"
                                                  width="70px"
                                                />
                                              </div>
                                            );
                                          })
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      {startdigno && (
                                        <div className="start-wrapper">
                                          {/* <div className="digno-type-select col-md-6">
                                            <label htmlFor="" className="key">
                                              Diagnosis
                                            </label>
                                            <select className="form-select formInput">
                                              <option value="">
                                                Select Gender
                                              </option>
                                              <option value="">Male</option>
                                              <option value="">Female</option>
                                              <option value="">Other</option>
                                            </select>
                                          </div> */}
                                          <Tab.Container
                                            id="left-tabs-example"
                                            defaultActiveKey="1"
                                          >
                                            <Nav
                                              variant="pills"
                                              id="newTabMai"
                                              className="tob_nav_pills"
                                            >
                                              <Nav.Item>
                                                <Nav.Link eventKey="1">
                                                  Medicine
                                                </Nav.Link>
                                              </Nav.Item>
                                              <Nav.Item>
                                                <Nav.Link eventKey="2">
                                                  Vaccine
                                                </Nav.Link>
                                              </Nav.Item>
                                              <Nav.Item>
                                                <Nav.Link eventKey="3">
                                                  Pet Food
                                                </Nav.Link>
                                              </Nav.Item>
                                              <Nav.Item>
                                                <Nav.Link eventKey="4">
                                                  Note's
                                                </Nav.Link>
                                              </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                              <Tab.Pane eventKey="1">
                                                <div className="table-start-wrapper">
                                                  <label
                                                    htmlFor=""
                                                    className="key"
                                                  >
                                                    Prescription
                                                  </label>
                                                  <div className="inner-tb">
                                                    <table>
                                                      <thead>
                                                        <tr>
                                                          <th>Sr No.</th>
                                                          <th>Medicine Name</th>
                                                          <th>Medicine Type</th>
                                                          <th>Frequency</th>
                                                          <th>
                                                            Duration (in days)
                                                          </th>
                                                          <th>Instruction</th>
                                                          <th>Quantity</th>
                                                          <th>Action</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {Prsiptiontlist &&
                                                        Prsiptiontlist.length >
                                                          0 ? (
                                                          Prsiptiontlist.map(
                                                            (
                                                              itemlist,
                                                              index
                                                            ) => {
                                                              return (
                                                                <tr>
                                                                  <td>
                                                                    {index + 1}
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      itemlist.product
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    {medicineType &&
                                                                      medicineType.find(
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          item?.medicine_type_id ==
                                                                          itemlist.medicine_type
                                                                      )
                                                                        ?.medicine_type}
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      itemlist.prescription_frequency
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      itemlist.duration
                                                                    }{" "}
                                                                    Days
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      itemlist.instruction
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      itemlist.quantity
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    <img
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        deleteMedicine(
                                                                          itemlist?.animal_prescription_id,
                                                                          itemlist?.doctor_appointment_id
                                                                        )
                                                                      }
                                                                      src={Axe}
                                                                      height="18"
                                                                      width="18"
                                                                      alt=""
                                                                    />
                                                                  </td>
                                                                  {/* <td>
                                                              <img
                                                                src={
                                                                  ic_rejected
                                                                }
                                                                alt=""
                                                              />
                                                            </td> */}
                                                                </tr>
                                                              );
                                                            }
                                                          )
                                                        ) : (
                                                          <></>
                                                        )}
                                                        <tr>
                                                          <td></td>
                                                          <td>
                                                            <CreatableSelect
                                                              isClearable
                                                              options={
                                                                FacilitiesType
                                                              }
                                                              styles={
                                                                SelectStyles
                                                              }
                                                              onChange={
                                                                onTopicChange
                                                              }
                                                              placeholder={
                                                                "Medicine..."
                                                              }
                                                            />
                                                            {/* <select
                                                              className="form-select Medicine"
                                                              value={
                                                                CliniceAdd.product_id
                                                              }
                                                              onChange={(e) =>
                                                                setCliniceAdd({
                                                                  ...CliniceAdd,
                                                                  product_id:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }
                                                            >
                                                              <option value="">
                                                                Medicine...
                                                              </option>
                                                              {FacilitiesType &&
                                                                FacilitiesType.map(
                                                                  (
                                                                    data,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <option
                                                                        value={
                                                                          data?.product_id
                                                                        }
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        {
                                                                          data?.product
                                                                        }
                                                                      </option>
                                                                    );
                                                                  }
                                                                )}
                                                            </select> */}
                                                          </td>
                                                          <td>
                                                            <select
                                                              className="form-select Medicine"
                                                              value={
                                                                CliniceAdd.medicine_type
                                                              }
                                                              onChange={(e) =>
                                                                setCliniceAdd({
                                                                  ...CliniceAdd,
                                                                  medicine_type:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }
                                                            >
                                                              <option value="">
                                                                Medicine Type
                                                              </option>
                                                              {medicineType &&
                                                                medicineType.map(
                                                                  (
                                                                    data,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <option
                                                                        value={
                                                                          data?.medicine_type_id
                                                                        }
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        {
                                                                          data?.medicine_type
                                                                        }
                                                                      </option>
                                                                    );
                                                                  }
                                                                )}
                                                            </select>
                                                          </td>
                                                          <td>
                                                            {/* <CreatableSelect
                                                              isClearable
                                                              options={FrequencyType}
                                                              styles={SelectStyles}
                                                              onChange={
                                                                onFrequnceChange
                                                              }
                                                              placeholder={
                                                                "Select..."
                                                              }
                                                            /> */}
                                                            <select
                                                              className="form-select Medicine"
                                                              value={
                                                                CliniceAdd.prescription_frequency_id
                                                              }
                                                              onChange={(e) =>
                                                                setCliniceAdd({
                                                                  ...CliniceAdd,
                                                                  prescription_frequency_id:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }
                                                            >
                                                              <option value="">
                                                                Select...
                                                              </option>
                                                              {FrequencyType &&
                                                                FrequencyType.map(
                                                                  (
                                                                    data,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <option
                                                                        value={
                                                                          data?.prescription_frequency_id
                                                                        }
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        {
                                                                          data?.prescription_frequency
                                                                        }
                                                                      </option>
                                                                    );
                                                                  }
                                                                )}
                                                            </select>
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="text"
                                                              className="form-control adjustForm"
                                                              style={{
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                              placeholder="Duration"
                                                              value={
                                                                CliniceAdd.duration
                                                              }
                                                              onChange={(e) => {
                                                                let value =
                                                                  e.target
                                                                    .value;
                                                                let valueInput =
                                                                  value.replace(
                                                                    /[^0-9 ]/gi,
                                                                    ""
                                                                  );
                                                                setCliniceAdd({
                                                                  ...CliniceAdd,
                                                                  duration:
                                                                    valueInput,
                                                                });
                                                              }}
                                                            />
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="text"
                                                              className="form-control adjustForm"
                                                              placeholder="Instruction"
                                                              value={
                                                                CliniceAdd.instruction
                                                              }
                                                              onChange={(e) => {
                                                                let value =
                                                                  e.target
                                                                    .value;
                                                                let valueInput =
                                                                  value.replace(
                                                                    /[^A-Za-z ]/gi,
                                                                    ""
                                                                  );

                                                                setCliniceAdd({
                                                                  ...CliniceAdd,
                                                                  instruction:
                                                                    valueInput,
                                                                });
                                                              }}
                                                            />
                                                          </td>

                                                          <td>
                                                            <input
                                                              type="text"
                                                              className="form-control adjustForm"
                                                              placeholder="quantity"
                                                              value={
                                                                CliniceAdd.quantity
                                                              }
                                                              onChange={(e) => {
                                                                let value =
                                                                  e.target
                                                                    .value;
                                                                let valueInput =
                                                                  value.replace(
                                                                    /[^A-Za-z ]/gi,
                                                                    ""
                                                                  );

                                                                setCliniceAdd({
                                                                  ...CliniceAdd,
                                                                  quantity:
                                                                    e.target
                                                                      .value,
                                                                });
                                                              }}
                                                            />
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                                <div className="text-end my-2">
                                                  <button
                                                    className="btn-blue-filled"
                                                    onClick={
                                                      addPrescriptionHandal
                                                    }
                                                  >
                                                    Add Medicine
                                                  </button>
                                                </div>
                                              </Tab.Pane>
                                              <Tab.Pane eventKey="2">
                                                <div className="table-start-wrapper">
                                                  <label
                                                    htmlFor=""
                                                    className="key"
                                                  >
                                                    Vaccination
                                                  </label>
                                                  <div className="inner-tb">
                                                    <table>
                                                      <thead>
                                                        <tr>
                                                          <th>Sr No.</th>
                                                          <th>Vaccine Name</th>
                                                          <th>Frequency</th>
                                                          <th>
                                                            Duration (in days)
                                                          </th>
                                                          <th>Dose (in ml)</th>
                                                          {/* <th>Instruction</th> */}
                                                          <th>Action</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {vaccineListData &&
                                                        vaccineListData.length >
                                                          0 ? (
                                                          vaccineListData.map(
                                                            (
                                                              itemlist,
                                                              index
                                                            ) => {
                                                              return (
                                                                <tr>
                                                                  <td>
                                                                    {index + 1}
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      itemlist.vaccine
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      itemlist.prescription_frequency
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      itemlist.duration
                                                                    }{" "}
                                                                    Days
                                                                  </td>

                                                                  <td>
                                                                    {
                                                                      itemlist.dose
                                                                    }
                                                                  </td>
                                                                  {/* <td>
                                                                    {
                                                                      itemlist.instruction
                                                                    }
                                                                  </td> */}
                                                                  <td>
                                                                    <img
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        deleteVaccine(
                                                                          itemlist?.vaccinator_animal_prescription_id,
                                                                          itemlist?.doctor_appointment_id
                                                                        )
                                                                      }
                                                                      src={Axe}
                                                                      height="18"
                                                                      width="18"
                                                                      alt=""
                                                                    />
                                                                  </td>
                                                                  {/* <td>
                                                              <img
                                                                src={
                                                                  ic_rejected
                                                                }
                                                                alt=""
                                                              />
                                                            </td> */}
                                                                </tr>
                                                              );
                                                            }
                                                          )
                                                        ) : (
                                                          <></>
                                                        )}
                                                        <tr>
                                                          <td></td>
                                                          <td>
                                                            <CreatableSelect
                                                              isClearable
                                                              options={
                                                                vaccineList
                                                              }
                                                              styles={
                                                                SelectStyles
                                                              }
                                                              onChange={
                                                                onVaccineChange
                                                              }
                                                              placeholder={
                                                                "vaccine..."
                                                              }
                                                            />
                                                            {/* <select
                                                              className="form-select Medicine"
                                                              value={
                                                                vaccineAdd.vaccine_id
                                                              }
                                                              onChange={(e) =>
                                                                setVaccineAdd({
                                                                  ...vaccineAdd,
                                                                  vaccine_id:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }
                                                            >
                                                              <option value="">
                                                                vaccine...
                                                              </option>
                                                              {vaccineList &&
                                                                vaccineList.map(
                                                                  (
                                                                    data,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <option
                                                                        value={
                                                                          data?.vaccine_id
                                                                        }
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        {
                                                                          data?.vaccine
                                                                        }
                                                                      </option>
                                                                    );
                                                                  }
                                                                )}
                                                            </select> */}
                                                          </td>
                                                          <td>
                                                            {/* <CreatableSelect
                                                              isClearable
                                                              options={FrequencyType}
                                                              styles={SelectStyles}
                                                              onChange={
                                                                onFreqVaccChange
                                                              }
                                                              placeholder={
                                                                "Select..."
                                                              }
                                                            /> */}
                                                            <select
                                                              className="form-select Medicine"
                                                              value={
                                                                vaccineAdd.frequency_id
                                                              }
                                                              onChange={(e) =>
                                                                setVaccineAdd({
                                                                  ...vaccineAdd,
                                                                  frequency_id:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }
                                                            >
                                                              <option value="">
                                                                Select...
                                                              </option>
                                                              {FrequencyType &&
                                                                FrequencyType.map(
                                                                  (
                                                                    data,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <option
                                                                        value={
                                                                          data?.prescription_frequency_id
                                                                        }
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        {
                                                                          data?.prescription_frequency
                                                                        }
                                                                      </option>
                                                                    );
                                                                  }
                                                                )}
                                                            </select>
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="text"
                                                              className="form-control adjustForm"
                                                              style={{
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                              placeholder="Duration"
                                                              value={
                                                                vaccineAdd.duration
                                                              }
                                                              onChange={(e) => {
                                                                let value =
                                                                  e.target
                                                                    .value;
                                                                let valueInput =
                                                                  value.replace(
                                                                    /[^0-9 ]/gi,
                                                                    ""
                                                                  );
                                                                setVaccineAdd({
                                                                  ...vaccineAdd,
                                                                  duration:
                                                                    valueInput,
                                                                });
                                                              }}
                                                            />
                                                          </td>
                                                          {/* <td>
                                                            <input
                                                              type="text"
                                                              className="form-control adjustForm"
                                                              placeholder="Instruction"
                                                              value={
                                                                CliniceAdd.instruction
                                                              }
                                                              onChange={(e) => {
                                                                let value =
                                                                  e.target.value;
                                                                let valueInput =
                                                                  value.replace(
                                                                    /[^A-Za-z ]/gi,
                                                                    ""
                                                                  );

                                                                setCliniceAdd({
                                                                  ...CliniceAdd,
                                                                  instruction:
                                                                    valueInput,
                                                                });
                                                              }}
                                                            />
                                                          </td> */}

                                                          <td>
                                                            <input
                                                              type="text"
                                                              className="form-control adjustForm"
                                                              placeholder="Dose"
                                                              value={
                                                                vaccineAdd.dose
                                                              }
                                                              onChange={(e) => {
                                                                let value =
                                                                  e.target
                                                                    .value;
                                                                let valueInput =
                                                                  value.replace(
                                                                    /[^0-9 ]/gi,
                                                                    ""
                                                                  );

                                                                setVaccineAdd({
                                                                  ...vaccineAdd,
                                                                  dose: valueInput,
                                                                });
                                                              }}
                                                            />
                                                          </td>
                                                          {/* <td>
                                                            <input
                                                              type="text"
                                                              className="form-control adjustForm"
                                                              placeholder="Instruction..."
                                                              value={
                                                                CliniceAdd.instruction
                                                              }
                                                              onChange={(e) => {
                                                                let value =
                                                                  e.target
                                                                    .value;
                                                                let valueInput =
                                                                  value.replace(
                                                                    /[^A-Za-z ]/gi,
                                                                    ""
                                                                  );

                                                                setCliniceAdd({
                                                                  ...vaccineAdd,
                                                                  instruction:
                                                                    valueInput,
                                                                });
                                                              }}
                                                            />
                                                          </td> */}
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                                <div className="text-end my-2">
                                                  <button
                                                    className="btn-blue-filled"
                                                    onClick={addVaccineDet}
                                                  >
                                                    Add Vaccine
                                                  </button>
                                                </div>
                                              </Tab.Pane>
                                              <Tab.Pane eventKey="3">
                                                <div className="table-start-wrapper">
                                                  <label
                                                    htmlFor=""
                                                    className="key"
                                                  >
                                                    Pet Food's
                                                  </label>
                                                  <div className="inner-tb">
                                                    <table>
                                                      <thead>
                                                        <tr>
                                                          <th>Sr No.</th>
                                                          <th>Food</th>
                                                          <th>Frequency</th>
                                                          <th>Duration</th>
                                                          <th>Instruction</th>
                                                          <th>Quantity</th>
                                                          <th>Action</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {petFoodList &&
                                                        petFoodList.length >
                                                          0 ? (
                                                          petFoodList.map(
                                                            (
                                                              itemlist,
                                                              index
                                                            ) => {
                                                              return (
                                                                <tr>
                                                                  <td>
                                                                    {index + 1}
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      itemlist?.product
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      itemlist?.prescription_frequency
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      itemlist?.duration
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      itemlist?.instruction
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      itemlist?.quantity
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    <img
                                                                      src={Axe}
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        deletePetFood(
                                                                          itemlist?.animal_food_prescription_id
                                                                        )
                                                                      }
                                                                      height="18"
                                                                      width="18"
                                                                      alt=""
                                                                    />
                                                                  </td>
                                                                </tr>
                                                              );
                                                            }
                                                          )
                                                        ) : (
                                                          <></>
                                                        )}
                                                        <tr>
                                                          <td></td>
                                                          <td>
                                                            <CreatableSelect
                                                              isClearable
                                                              options={
                                                                petFoodDropD
                                                              }
                                                              styles={
                                                                SelectStyles
                                                              }
                                                              onChange={
                                                                onPetFoodChange
                                                              }
                                                              placeholder={
                                                                "Food..."
                                                              }
                                                            />
                                                            {/* <select
                                                              className="form-select Medicine"
                                                              value={
                                                                petFoodAdd.product_id
                                                              }
                                                              onChange={(e) =>
                                                                setPetFoodAdd({
                                                                  ...petFoodAdd,
                                                                  product_id:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }
                                                            >
                                                              <option value="">
                                                                Pet Food...
                                                              </option>
                                                              {petFoodDropD &&
                                                                petFoodDropD.map(
                                                                  (
                                                                    data,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <option
                                                                        value={
                                                                          data?.product_id
                                                                        }
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        {
                                                                          data?.product
                                                                        }
                                                                      </option>
                                                                    );
                                                                  }
                                                                )}
                                                            </select> */}
                                                          </td>
                                                          <td>
                                                            {/* <CreatableSelect
                                                              isClearable
                                                              options={FrequencyType}
                                                              styles={SelectStyles}
                                                              onChange={
                                                                onpetFoodChange
                                                              }
                                                              placeholder={
                                                                "Select..."
                                                              }
                                                            /> */}
                                                            <select
                                                              className="form-select Medicine"
                                                              value={
                                                                petFoodAdd.prescription_frequency_id
                                                              }
                                                              onChange={(e) =>
                                                                setPetFoodAdd({
                                                                  ...petFoodAdd,
                                                                  prescription_frequency_id:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }
                                                            >
                                                              <option value="">
                                                                Select...
                                                              </option>
                                                              {FrequencyType &&
                                                                FrequencyType.map(
                                                                  (
                                                                    data,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <option
                                                                        value={
                                                                          data?.prescription_frequency_id
                                                                        }
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        {
                                                                          data?.prescription_frequency
                                                                        }
                                                                      </option>
                                                                    );
                                                                  }
                                                                )}
                                                            </select>
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="text"
                                                              className="form-control adjustForm"
                                                              style={{
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                              placeholder="Duration"
                                                              value={
                                                                petFoodAdd.duration
                                                              }
                                                              onChange={(e) => {
                                                                let value =
                                                                  e.target
                                                                    .value;
                                                                let valueInput =
                                                                  value.replace(
                                                                    /[^0-9 ]/gi,
                                                                    ""
                                                                  );
                                                                setPetFoodAdd({
                                                                  ...petFoodAdd,
                                                                  duration:
                                                                    valueInput,
                                                                });
                                                              }}
                                                            />
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="text"
                                                              className="form-control adjustForm"
                                                              placeholder="Instruction"
                                                              value={
                                                                petFoodAdd.instruction
                                                              }
                                                              onChange={(e) => {
                                                                let value =
                                                                  e.target
                                                                    .value;
                                                                let valueInput =
                                                                  value.replace(
                                                                    /[^A-Za-z ]/gi,
                                                                    ""
                                                                  );

                                                                setPetFoodAdd({
                                                                  ...petFoodAdd,
                                                                  instruction:
                                                                    valueInput,
                                                                });
                                                              }}
                                                            />
                                                          </td>

                                                          <td>
                                                            <input
                                                              type="text"
                                                              className="form-control adjustForm"
                                                              placeholder="quantity"
                                                              value={
                                                                petFoodAdd.quantity
                                                              }
                                                              onChange={(e) => {
                                                                let value =
                                                                  e.target
                                                                    .value;
                                                                let valueInput =
                                                                  value.replace(
                                                                    /[^A-Za-z ]/gi,
                                                                    ""
                                                                  );

                                                                setPetFoodAdd({
                                                                  ...petFoodAdd,
                                                                  quantity:
                                                                    e.target
                                                                      .value,
                                                                });
                                                              }}
                                                            />
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                                <div className="text-end my-2">
                                                  <button
                                                    className="btn-blue-filled"
                                                    onClick={addPetFood}
                                                  >
                                                    Add Pet Food
                                                  </button>
                                                </div>
                                              </Tab.Pane>
                                              <Tab.Pane eventKey="4">
                                                <div className="notePad">
                                                  <Notepad />
                                                </div>
                                                <div className="text-end my-2">
                                                  <button
                                                    className="btn-blue-filled"
                                                    onClick={updateNote}
                                                  >
                                                    Add Note
                                                  </button>
                                                </div>
                                              </Tab.Pane>
                                            </Tab.Content>
                                          </Tab.Container>
                                        </div>
                                      )}
                                    </div>
                                    {ClintDetailsList?.status != 5 && (
                                      <div className="btn-wrapper">
                                        {/* <button className="btn-blue-blank">
                                        Cancel
                                      </button> */}
                                        {/* <button className="btn-yellow-filled">
                                        Re-Schedule
                                      </button> */}
                                        {startdigno === false ? (
                                          <>
                                            <button
                                              className="btn-blue-filled"
                                              onClick={() => {
                                                setStartdigno(true);
                                              }}
                                            >
                                              {startdigno
                                                ? "Mark Complete"
                                                : "Start Appointment"}
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              className="btn-blue-filled"
                                              onClick={() => changeStatus()}
                                            >
                                              {startdigno
                                                ? "Mark Complete"
                                                : "Start"}
                                            </button>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* <div className="message-payment">
                                <img src={ic_pending} alt="" />
                                <label htmlFor="">
                                  15 day Payment Cycle for payment deposit to
                                  your account.
                                </label>
                              </div> */}
                            </div>
                            <div className="col-md-3">
                              <div className="right">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="1"
                                >
                                  <Nav
                                    variant="pills"
                                    id="newTabMai"
                                    className="tob_nav_pills"
                                  >
                                    <Nav.Item>
                                      <Nav.Link eventKey="1">
                                        Visits(<span>{VisiteTotal}</span>)
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="2">
                                        Invoices (<span>{InviceTotal}</span>)
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="1">
                                      <div className="visits-tab-wrapper">
                                        <div className="today-heading">
                                          <label htmlFor="" className="key">
                                            Today
                                          </label>
                                          <div className="line"></div>
                                        </div>
                                        {ClintVisitAlllist.today_appointment &&
                                        ClintVisitAlllist.today_appointment
                                          .length > 0 ? (
                                          ClintVisitAlllist.today_appointment.map(
                                            (itemlist, index) => {
                                              return (
                                                <div
                                                  className="app-time-date"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    setAppointment(index + 1);
                                                    getClinetDetailsByeVisit(
                                                      itemlist.doctor_appointment_id
                                                    );
                                                  }}
                                                >
                                                  <div className="left-app-time-date">
                                                    <label
                                                      htmlFor=""
                                                      className="value"
                                                    >
                                                      {itemlist?.date}
                                                    </label>
                                                  </div>
                                                  <div
                                                    className="right-app-time-date"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      getHistoryPres(
                                                        itemlist?.doctor_appointment_id
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={ic_notepad}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <p className="scheule_tody">
                                            No Schedule Today
                                          </p>
                                        )}
                                        <div className="today-heading">
                                          <label
                                            htmlFor=""
                                            className="key line-style"
                                          >
                                            Completed
                                          </label>
                                          <div className="line"></div>
                                        </div>
                                        {ClintVisitAlllist.history &&
                                        ClintVisitAlllist.history.length > 0 ? (
                                          ClintVisitAlllist.history.map(
                                            (itemlist, index) => {
                                              return (
                                                <div
                                                  style={{ cursor: "pointer" }}
                                                  className="app-time-date"
                                                  onClick={() => {
                                                    setAppointment(index + 1);
                                                    getClinetDetailsByeVisit(
                                                      itemlist.doctor_appointment_id
                                                    );
                                                  }}
                                                >
                                                  <div className="left-app-time-date">
                                                    <label
                                                      htmlFor=""
                                                      className="value"
                                                    >
                                                      {itemlist?.date}
                                                    </label>
                                                  </div>
                                                  <div className="right-app-time-date">
                                                    <img
                                                      src={ic_notepad}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <p className="scheule_tody">
                                            No Schedule Today
                                          </p>
                                        )}
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="2">
                                      <div className="invoices-tab-wrapper">
                                        <div className="table-wrapper ">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Sr. No</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {ClintInvicelist &&
                                              ClintInvicelist.length > 0 ? (
                                                ClintInvicelist.map(
                                                  (itemlist, index) => {
                                                    return (
                                                      <tr>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                          {itemlist?.date}
                                                        </td>
                                                        <td>
                                                          {formatCurrency(
                                                            itemlist.charges
                                                          )}
                                                        </td>
                                                        <td>
                                                          <img
                                                            src={ic_completed}
                                                            className="status-ic"
                                                            alt=""
                                                          />
                                                          <label
                                                            htmlFor=""
                                                            className="pending-txt"
                                                          >
                                                            Completed
                                                          </label>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <p className="scheule_tody">
                                                  No Schedule Completed
                                                </p>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="2">
                      <div className="requests-wrapper">
                        {reqList && (
                          <div id="app-list-page-wrapper1">
                            <div className="table-wrapper scroll-table">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Sr. No</th>
                                    <th>Name</th>
                                    <th>Photo </th>
                                    <th>Pet Type</th>
                                    <th>Description</th>
                                    <th>Time</th>
                                    <th>Fees</th>
                                    <th className="center">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {AppointmentsRequest &&
                                  AppointmentsRequest.length > 0 ? (
                                    AppointmentsRequest.map(
                                      (itemlist, index) => {
                                        return (
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td className="tdName">
                                              {itemlist.first_name}
                                            </td>
                                            <td>
                                              <label
                                                htmlFor=""
                                                className="photo"
                                              >
                                                <img
                                                  src={itemlist.image_path}
                                                  alt=""
                                                />
                                              </label>
                                            </td>
                                            <td>{itemlist.type_of_animal}</td>
                                            <td>
                                              {itemlist.appointment_desc}...
                                            </td>
                                            <td>
                                              {itemlist?.date} - {itemlist.time}
                                            </td>
                                            <td>
                                              {formatCurrency(itemlist.charges)}
                                            </td>

                                            <td className="center">
                                              <button
                                                className="reject-btn"
                                                onClick={() => {
                                                  //   setviewAppointment(true);
                                                  //   setreqList(false);
                                                  //   setviewCalender(false);
                                                  //   setviewList(false);
                                                  putStatusChanges(
                                                    itemlist.doctor_appointment_id,
                                                    "3"
                                                  );
                                                }}
                                              >
                                                Reject
                                              </button>
                                              <button
                                                className="accept-btn"
                                                onClick={() => {
                                                  //   setviewAppointment(true);
                                                  //   setreqList(false);
                                                  //   setviewCalender(false);
                                                  //   setviewList(false);
                                                  putStatusChanges(
                                                    itemlist.doctor_appointment_id,
                                                    "2"
                                                  );
                                                }}
                                              >
                                                Accept
                                              </button>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>
                              {AppointmentsRequest.length ? (
                                ""
                              ) : (
                                <p
                                  style={{
                                    fontSize: "20px",
                                    marginTop: "200px",
                                  }}
                                  className=" text-center justify-content-center align-items-center"
                                >
                                  Oops! It doesn't look like there are any
                                  Appointment?
                                </p>
                              )}
                            </div>
                            <PaginationComp
                              length={AppointmentsList.length}
                              total={TotalData}
                            />
                          </div>
                        )}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="3">
                      {completedList && (
                        <div id="app-list-page-wrapper1">
                          <div className="table-wrapper scroll-table">
                            <table>
                              <thead>
                                <tr>
                                  <th>Sr. No</th>
                                  <th>Name</th>
                                  <th>Photo </th>
                                  <th>Pet Type</th>
                                  <th>Description</th>
                                  <th>Time</th>
                                  <th>Fees</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {AppointmentsCompleted &&
                                AppointmentsCompleted.length > 0 ? (
                                  AppointmentsCompleted.map(
                                    (itemlist, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td className="tdName">
                                            {itemlist.first_name}
                                          </td>
                                          <td>
                                            <label htmlFor="" className="photo">
                                              <img
                                                src={itemlist.image_path}
                                                alt=""
                                              />
                                            </label>
                                          </td>
                                          <td>{itemlist.type_of_animal}</td>
                                          <td>
                                            {itemlist.appointment_desc}...
                                          </td>
                                          <td>
                                            {itemlist?.date} - {itemlist.time}
                                          </td>
                                          <td>
                                            {formatCurrency(itemlist.charges)}
                                          </td>
                                          <td>
                                            <img
                                              src={ic_completed}
                                              className="status-ic"
                                              alt=""
                                            />
                                            Completed
                                          </td>
                                          <td>
                                            <button
                                              className="view-details-btn"
                                              onClick={() => {
                                                getClinetDetails(
                                                  itemlist.customer_animal_id,
                                                  itemlist.doctor_appointment_id
                                                );
                                                getClinetVisitDetails(
                                                  itemlist.customer_animal_id
                                                );
                                                getClinetInviceDetails(
                                                  itemlist.customer_animal_id
                                                );
                                                setviewAppointment(true);
                                                setviewList(false);
                                                setviewCalender(false);
                                                setCompletedList(false);
                                              }}
                                            >
                                              View Details
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </table>
                            {AppointmentsCompleted.length ? (
                              ""
                            ) : (
                              <p
                                style={{
                                  fontSize: "20px",
                                  marginTop: "200px",
                                }}
                                className=" text-center justify-content-center align-items-center"
                              >
                                Oops! It doesn't look like there are any
                                Appointment?
                              </p>
                            )}
                          </div>
                          <PaginationComp
                            length={AppointmentsCompleted.length}
                            total={TotalData}
                          />
                        </div>
                      )}
                      {viewAppointment && (
                        <div className="app-details-wrapper">
                          <div className="row gx-3">
                            <div className="col-md-8 ">
                              <div className="left">
                                <div className="aap-heading">
                                  <label htmlFor="">Appointment -1</label>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div>
                                      <label htmlFor="" className="key">
                                        Client Name
                                      </label>
                                    </div>
                                    <label htmlFor="" className="value tdName">
                                      {ClintDetailsList.first_name}
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <div>
                                      <label htmlFor="" className="key">
                                        Appointment Date & Time
                                      </label>
                                    </div>
                                    <label htmlFor="" className="value">
                                      {ClintDetailsList?.date}{" "}
                                      {ClintDetailsList.time}
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <div>
                                      <label htmlFor="" className="key">
                                        Description
                                      </label>
                                    </div>
                                    <label htmlFor="" className="value">
                                      {ClintDetailsList.appointment_desc}
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label htmlFor="" className="key">
                                      Photos
                                    </label>
                                    <div className="photo-video-wrapper">
                                      <div className="row">
                                        {ClintPhoto && ClintPhoto.length > 0 ? (
                                          ClintPhoto.map((itemlist, index) => {
                                            return (
                                              <div className="col-xl-2 col-lg-3 col-md-4 ">
                                                <img
                                                  src={itemlist.image_path}
                                                  alt=""
                                                  height="50px"
                                                  width="50px"
                                                />
                                              </div>
                                            );
                                          })
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div className="start-wrapper mt-3">
                                        <label htmlFor="" className="key">
                                          Prescription
                                        </label>
                                        <div
                                          className="prescription-btn-wrapper mt-2 row"
                                          onClick={() => setPrescription(true)}
                                        >
                                          {ClintPrescription &&
                                          ClintPrescription.length > 0 ? (
                                            ClintPrescription.map(
                                              (itemlist, index) => {
                                                return (
                                                  <div className="col-md-3">
                                                    <div className="prescription-btn">
                                                      <div className="wrapper">
                                                        <div className="ic_wrapper">
                                                          <img
                                                            src={ic_recipt}
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div className="date mt-2">
                                                          02-02-2023
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="btn-wrapper">
                                      <button className="btn-blue-blank">
                                        Cancel
                                      </button>
                                      <button className="btn-yellow-filled">
                                        Re-Schedule
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 ">
                              <div className="right">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="1"
                                >
                                  <Nav
                                    variant="pills"
                                    id="newTabMai"
                                    className="tob_nav_pills"
                                  >
                                    <Nav.Item>
                                      <Nav.Link eventKey="1">
                                        Visits (<span>{VisiteTotal}</span>)
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="2">
                                        Invoices (<span>{VisiteTotal}</span>)
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="1">
                                      <div className="visits-tab-wrapper">
                                        <div className="today-heading">
                                          <label htmlFor="" className="key">
                                            Today
                                          </label>
                                          <div className="line"></div>
                                        </div>
                                        {ClintVisitAlllist.today_appointment &&
                                        ClintVisitAlllist.today_appointment
                                          .length > 0 ? (
                                          ClintVisitAlllist.today_appointment.map(
                                            (itemlist, index) => {
                                              return (
                                                <div className="app-time-date">
                                                  <div
                                                    className="left-app-time-date"
                                                    onClick={() => {
                                                      setAppointment(index + 1);

                                                      // onColorChange(index,"active")
                                                      //
                                                      getClinetDetailsByeVisit(
                                                        itemlist.doctor_appointment_id
                                                      );
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor=""
                                                      className="value"
                                                    >
                                                      {itemlist?.date}
                                                    </label>
                                                  </div>
                                                  <div className="right-app-time-date">
                                                    <img
                                                      src={ic_notepad}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <p className="scheule_tody">
                                            No Schedule Today
                                          </p>
                                        )}
                                        <div className="today-heading">
                                          <label
                                            htmlFor=""
                                            className="key line-style"
                                          >
                                            Completed
                                          </label>
                                          <div className="line"></div>
                                        </div>
                                        {ClintVisitAlllist.history &&
                                        ClintVisitAlllist.history.length > 0 ? (
                                          ClintVisitAlllist.history.map(
                                            (itemlist, index) => {
                                              return (
                                                <div className="app-time-date">
                                                  <div
                                                    className="left-app-time-date"
                                                    onClick={() => {
                                                      setAppointment(index + 1);

                                                      // onColorChange(index,"active")
                                                      //
                                                      getClinetDetailsByeVisit(
                                                        itemlist.doctor_appointment_id
                                                      );
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor=""
                                                      className="value"
                                                    >
                                                      {itemlist?.date}
                                                    </label>
                                                  </div>
                                                  <div className="right-app-time-date">
                                                    <img
                                                      src={ic_notepad}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <p className="scheule_tody">
                                            No Schedule Today
                                          </p>
                                        )}
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="2">
                                      <div className="invoices-tab-wrapper">
                                        <div className="table-wrapper">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Sr. No</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {ClintInvicelist &&
                                              ClintInvicelist.length > 0 ? (
                                                ClintInvicelist.map(
                                                  (itemlist, index) => {
                                                    return (
                                                      <tr>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                          {itemlist?.date}
                                                        </td>
                                                        <td>
                                                          {formatCurrency(
                                                            itemlist.charges
                                                          )}
                                                        </td>
                                                        <td>
                                                          <img
                                                            src={ic_completed}
                                                            className="status-ic"
                                                            alt=""
                                                          />
                                                          <label
                                                            htmlFor=""
                                                            className="pending-txt"
                                                          >
                                                            Completed
                                                          </label>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <p className="scheule_tody">
                                                  No Schedule Completed
                                                </p>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <PrescriptionComp />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>

            {/* <Modal
              show={modalShow} onHide={handleClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Modal heading
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{
                textAlign: "center",
                fontSize: "26px",
                margin: "30px"
              }}>
                <h4>Centered Modal</h4>
                <p>
                  Do you want to give a vaccine
                </p>
              </Modal.Body>
              <Modal.Footer style={{
                justifyContent: "center"
              }}>
                <Button style={{
                  width: "110px",
                  marginLeft: "50px",
                  backgroundColor: "34528a"
                }} onClick={handleShowPersric} >No</Button>
                <Button style={{
                  width: "110px",
                  backgroundColor: "34528a"
                }} onClick={handleShowVaccen}> yes</Button>

              </Modal.Footer>
            </Modal> */}
          </InfiniteScroll>
        </>
      )}
    </>
  );
};

export default AtClinic;
