import React, { useContext, useEffect, useState } from "react";
import clinic_img_1 from "../../assets/images/clinic_img_1.svg";
import clinic_img_2 from "../../assets/images/clinic_img_2.svg";
import clinic_img_3 from "../../assets/images/clinic_img_3.svg";
import clinic_img_4 from "../../assets/images/clinic_img_4.svg";
import img_preview_delete_icon from "../../assets/images/icons/img_preview_delete_icon.svg";
import upload_doc_icon from "../../assets/images/icons/upload_doc_icon.svg";
import upload_icon from "../../assets/images/icons/upload_icon.svg";
import { motion } from "framer-motion";
import { DarkButton, DonutChart } from "../commonComponents/commonComp";
import { Link } from "react-router-dom";
import { Nav, NavItem, Tab } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { getWithAuthCall, simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Select from "react-select";
import { notifyError } from "../../sharedComponent/notify";
import { AppContext } from "../../context/AppContext";

const AddTeam = () => {
  const { customerData, setPrescription, loading } = useContext(AppContext);

  const navigate = useNavigate();
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const [FacilitiesType, setFacilitiesType] = useState([]);
  const [ClinicType, setClinicType] = useState([]);
  const [CliniceAdd, setCliniceAdd] = useState({
    clinic_name: "",
    facilities: "",
  });
  console.log("CliniceAdd", CliniceAdd);

  useEffect(() => {
    getfacilities();
    getClinic();
  }, []);

  function getfacilities() {
    getWithAuthCall(ApiConfig.ADD_TEAM_DOCTOR)
      .then((data) => {
        console.log(data);
        let Type = data.data;
        var grades = [];

        Type.map((grade, index) => {
          grades.push({
            label: grade.doctor_name,
            value: grade.doctor_id,
          });
        });
        setFacilitiesType(grades);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  function getClinic() {
    getWithAuthCall(ApiConfig.ADD_TEAM_CLINIC + customerData.doctor_id)
      .then((data) => {
        console.log(data);
        let Type = data.data;
        var grades = [];

        Type.map((grade, index) => {
          grades.push({
            label: grade.doctor_clinic_name,
            value: grade.doctor_clinic_id,
          });
        });
        setClinicType(grades);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  function onTopicChange(selectedOption) {
    console.log("selectedOption", selectedOption);
    var selected_topics = [];

    // if (selectedOption.length > 0) {
    //   selectedOption.map((topic, index) => {
    //     selected_topics.push(topic.value);
    //   });
    // }
  
    setCliniceAdd({
      ...CliniceAdd,
      facilities: selectedOption.value,
    });
  }

  function onTopicChangeClinic(selectedOption) {
    var selected_topics = [];

    // if (selectedOption.length > 0) {
    //   selectedOption.map((topic, index) => {
    //     selected_topics.push(topic.value);
    //   });
    // }
    console.log("selected_topics", selected_topics);
    setCliniceAdd({
      ...CliniceAdd,
      clinic_name: selectedOption.value,
    });
  }
  const getAddDetails = () => {
    let newRequestBody = JSON.stringify({
        doctor_id: CliniceAdd.facilities,
        doctor_clinic_id:CliniceAdd.clinic_name ,
    });
    simplePostCall(ApiConfig.CREATE_TEAM, newRequestBody)
      .then((res) => {
        if (res.success) {
        //   setAllTransactionlist(res.data);
        navigate('/MyProfile')
        } else {
            notifyError(res.message);
        //   setAllTransactionlist([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  }






  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.3 }}
      className="myprofile_wrapper"
      id="cx-main"
    >
      <div className="addclinic">
        <div className="info_title_wrapper">
          <h3 className="info_title">Add Team</h3>
        </div>
        <Form>
          <div className="row">
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Dr Name</Form.Label>
                <Select
                  className=""
                  //   isMulti
                  options={FacilitiesType}
                  // onChange={handleChange}
                  onChange={onTopicChange}
                  autoFocus={true}
                  // placeholder={"Select Languages..."}
                  placeholder={
                    <div className="select-placeholder-text">
                      Select Name...
                    </div>
                  }
                />
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="mb-3">
                <Form.Label>Designation</Form.Label>
                <Select
                  className=""
                  //   isMulti
                  options={ClinicType}
                  onChange={onTopicChangeClinic}
                  autoFocus={true}
                  // placeholder={"Select Languages..."}
                  placeholder={
                    <div className="select-placeholder-text">
                      Select Designation...
                    </div>
                  }
                />
              </Form.Group>
            </div>
            <div className="col-md-6"></div>

            {/* <div className="col-md-4 col-lg-2 mt-2">
              <DarkButton
                classbtn={"cx-btn-dark  btn-width-100 font-14"}
                btn={"+ Add more"}
              />
            </div> */}
          </div>
        </Form>
      </div>
      <div className="btn_wrapper">
        <DarkButton
          classbtn={"cx-btn-light btn-width-50 font-14"}
          onClick={() => navigate("/MyProfile")}
          btn={"Cancel"}
        />
        <DarkButton 
           onClick={() => {
            //  setStatus("Daily")
     
            getAddDetails("");
            
          }}
          classbtn={"cx-btn-dark  btn-width-50 font-14"}
          btn={"Save"}
        />
      </div>
    </motion.div>
  );
};

export default AddTeam;
