import React, { useContext, useEffect, useState } from "react";
import clinic_img_1 from "../../assets/images/clinic_img_1.svg";
import clinic_img_2 from "../../assets/images/clinic_img_2.svg";
import clinic_img_3 from "../../assets/images/clinic_img_3.svg";
import clinic_img_4 from "../../assets/images/clinic_img_4.svg";
import img_preview_delete_icon from "../../assets/images/icons/img_preview_delete_icon.svg";
import clinic_info_img from "../../assets/images/clinic_info_img.svg";
import { motion } from "framer-motion";
import { DarkButton, DonutChart } from "../commonComponents/commonComp";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ApiConfig from "../../api/ApiConfig";
import { getWithAuthCall, multipartPostCall } from "../../api/ApiServices";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import Select from "react-select";

import DragandDropImages from "../../sharedComponent/DragandDropImages";
import { AppContext } from "../../context/AppContext";
import AutocompleteGoogleInput from "../../sharedComponent/AutocompleteGoogleInput";

const AddClinic = () => {
  const { customerData, setPrescription, loading } = useContext(AppContext);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [FacilitiesType, setFacilitiesType] = useState([]);
  const [signature, setSignature] = useState();
  const [deleteImg, setDeleteImg] = useState(true);
  const [manageModal, setManageModal] = useState(1);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const [CliniceAdd, setCliniceAdd] = useState({
    clinic_name: "",
    product_id: "",
    address: "",
    clinicPhotos: [],
  });

  console.log("CliniceAdd", CliniceAdd);
  const postaddClinice = () => {
    // setLoading(true);

    let formData = new FormData();
    formData.append("doctor_id", customerData.doctor_id);
    formData.append("clinic_name", CliniceAdd.clinic_name);
    formData.append("facilities", CliniceAdd.product_id);
    formData.append("address", CliniceAdd.address);
    // formData.append("clinicPhotos", CliniceAdd.clinicPhotos);

    CliniceAdd.clinicPhotos.map((img) => {
      formData.append("clinicPhotos", img);
    });

    multipartPostCall(ApiConfig.CLINICE_ADD, formData)
      .then((res) => {
        if (res.success) {
          notifySuccess(res.message);
          //   setdoctor_clinic_id(res.data.doctor_clinic_id)
          navigate("/MyProfile");
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    getfacilities();
  }, []);

  function getfacilities() {
    getWithAuthCall(ApiConfig.CLINCE_FACILITIES_DROPDOWN)
      .then((data) => {
        console.log(data);
        let Type = data.data;
        var grades = [];

        Type.map((grade, index) => {
          grades.push({
            label: grade.clinic_facility,
            value: grade.clinic_facility_id,
          });
        });
        setFacilitiesType(grades);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  function onTopicChange(selectedOption) {
    var selected_topics = [];

    if (selectedOption.length > 0) {
      selectedOption.map((topic, index) => {
        selected_topics.push(topic.value);
      });
    }

    setCliniceAdd({
      ...CliniceAdd,
      product_id: selected_topics,
    });
  }

  const handleDeleteImg = () => {
    setDeleteImg(!deleteImg);
  };

  const deleteProductImage = (selectedProdImgId, index) => {
    let images = CliniceAdd.clinicPhotos;
    images = images.filter((img) => images.indexOf(img) != index);
    setCliniceAdd({ ...CliniceAdd, clinicPhotos: images });
  };
  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.3 }}
      className="myprofile_wrapper"
      id="cx-main"
    >
      <div className="addclinic">
        <div className="info_title_wrapper">
          <h3 className="info_title">Add New Clinic</h3>
        </div>
        <Form>
          <div className="row">
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Clinic Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  value={CliniceAdd.clinic_name}
                  onChange={(e) => {
                    let value = e.target.value;
                    let valueInput = value.replace(/[^A-Za-z ]/gi, "");
                    setCliniceAdd({
                      ...CliniceAdd,
                      clinic_name: valueInput,
                    });
                  }}
                />
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="mb-3">
                <Form.Label>Facilities</Form.Label>
                <Select
                  className="formSelect"
                  isMulti
                  options={FacilitiesType}
                  // onChange={handleChange}
                  onChange={onTopicChange}
                  autoFocus={true}
                  // placeholder={"Select Languages..."}
                  placeholder={
                    <div className="select-placeholder-text">
                      Select Facilities...
                    </div>
                  }
                />
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Address</Form.Label>
                <AutocompleteGoogleInput
                  required={true}
                  className={"form-control"}
                  placeholder={"Please enter address"}
                  selectedValue={CliniceAdd?.address}
                  onPlaceSelect={({ lat, lng, address }) => {
                    const locationData = {
                      lat,
                      lng,
                      address,
                    };
                    setCliniceAdd({
                      ...CliniceAdd,
                      address: locationData?.address,
                      latitude: locationData?.lat,
                      longitude: locationData?.lng,
                    });
                  }}
                />
                {/* <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  value={CliniceAdd.address}
                  onChange={(e) => {
                    let value = e.target.value;
                    // let valueInput = value.replace(/[^A-Za-z ]/gi, "");
                    setCliniceAdd({
                      ...CliniceAdd,
                      address: value,
                    });
                  }}
                /> */}
              </Form.Group>
            </div>
            <div className="col-md-6">
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Photos
                </label>
                <div className="file-input">
                  {/* <input
                                        type="file"
                                        name="file-input"
                                        id="file-input2"
                                        className="file-input__input"
                                        // onChange={handleFileInput2}
                                        multiple
                                    /> */}
                  <label className="file-input__label2" for="file-input2">
                    {/* Drop your files here or <b>
                                            Browse
                                        </b>             
                                                               */}

                    <DragandDropImages
                      setFile={(files) => {
                        setCliniceAdd({
                          ...CliniceAdd,
                          clinicPhotos: [...CliniceAdd.clinicPhotos, ...files],
                        });
                      }}
                    />
                  </label>
                </div>
              </div>

              {deleteImg && (
                <>
                  <div className={signature ? "sign_preview" : "d-none"}>
                    <img src={signature} alt="" />
                    <span>
                      <img
                        src={img_preview_delete_icon}
                        className="delete_icon"
                        onClick={handleDeleteImg}
                        alt=""
                      />
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-6">
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Preview
                </label>
                <div className="clinic_images_wrapper">
                  {CliniceAdd.clinicPhotos.map((img, index) => {
                    console.log("U_img", img);
                    return (
                      <div className="img_item">
                        <img
                          width={72}
                          height="72"
                          className="product_img"
                          src={img.name ? URL.createObjectURL(img) : img.img}
                          alt=""
                        />
                        <span>
                          <img
                            src={img_preview_delete_icon}
                            onClick={() => deleteProductImage(img.id, index)}
                          />
                        </span>
                      </div>
                    );
                  })}

                  {/* <div className="img_item">
                                        <div className="overlay"></div>
                                        <img src={clinic_img_4} />
                                        <span className="center_text">
                                            +4
                                        </span>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className="btn_wrapper">
        <DarkButton
          classbtn={"cx-btn-light btn-width-50 font-14"}
          onClick={() => navigate("/MyProfile")}
          btn={"Cancel"}
        />
        <DarkButton
          classbtn={"cx-btn-dark  btn-width-50 font-14"}
          onClick={() => postaddClinice()}
          btn={"Save"}
        />
      </div>
    </motion.div>
  );
};

export default AddClinic;
