// const PAYMENT_URL = "http://192.168.1.14:3000/api/paymentRoute/"

// ================================== LOCAL SERVER ================================= //
// export const BASE_URL = "http://192.168.1.56:3000/"; // VINOD IP
// ================================== LOCAL SERVER ================================= //

// ================================== DEVELOPEMENT SERVER (REDBYTE_SERVER) ==========================
// export const BASE_URL = "https://animal-api.app.redbytes.in:3000/";
// ================================== DEVELOPEMENT SERVER (REDBYTE_SERVER) ==========================

// ================================== PRODUCTION SERVER ==========================
export const BASE_URL = "https://api.theanimalsapp.com:3000/";
// ================================== PRODUCTION SERVER ==========================

const VETERINARY_PATH = "api/veterinary/";
const PAYMENT_ROUTE = "api/paymentRoute/";
const ApiConfig = {

  // ====== Login With OTP =========
  SEND_OTP_FOR_LOGIN: BASE_URL + VETERINARY_PATH + `sendOtp?loginOTP=${true}`,
  LOGIN_WITH_OTP: BASE_URL + VETERINARY_PATH + `loginWithOTP`,
  // ====== Login With OTP =========

  //  ============= user API =============================
  Notification_LIST: BASE_URL + "api/pharmacy/getNotification/",
  REQUEST_OTP: BASE_URL + "api/veterinary/sendOTP",
  DASHBORD_API: BASE_URL + "api/veterinary/dashboard?doctor_id=",
  USER_LOGIN: BASE_URL + "api/veterinary/login",
  USER_SIGN_UP: BASE_URL + "api/veterinary/register",
  GENERAL_IFO: BASE_URL + "api/veterinary/generalInformationStore",
  SPECICLIZATION_DROPDOWN: BASE_URL + "api/veterinary/specializationDropDown",
  EDCATION_DROPDWN: BASE_URL + "api/veterinary/educationDropDown",
  LANGUAGE_DROPDOWN: BASE_URL + "api/veterinary/languageDropDown",
  CLINICE_ADD: BASE_URL + "api/veterinary/storeClinicInformation",
  CLINCE_FACILITIES_DROPDOWN: BASE_URL + "api/veterinary/facilitiesDropDown",
  UPLOAD_DOCUMENT: BASE_URL + "api/veterinary/uploadDocuments",
  BANK_DETALIS: BASE_URL + "api/veterinary/addBankDetails",
  ADD_CLINIC_SCHEDULE: BASE_URL + "api/veterinary/setSchedule",
  SEND_OTP: BASE_URL + "api/veterinary/forgotPassword",
  VERIYFIY_OTP: BASE_URL + "api/veterinary/verifyOTP",
  RESET_PASSWORD: BASE_URL + "api/veterinary/resetPassword",
  DOC_PROFILE_STATUS: BASE_URL + "api/veterinary/doctor_status",
  GET_DOC_PROFILE_STATUS: BASE_URL + "api/veterinary/status",
  UPDATE_CLINIC_INFO: BASE_URL + VETERINARY_PATH + "updateClinic",
  /// Clinet Details
  CLIINET_DETAILS: BASE_URL + "api/veterinary/clientList",
  CLIINET_DETAILS_BY_ID:
    BASE_URL + "api/veterinary/clientDetails?customer_animal_id=",
  CLINET_VIST_BY_ID:
    BASE_URL + "api/veterinary/clientVisit?customer_animal_id=",
  CLIINET_INVICE_ID: BASE_URL + "api/veterinary/invoice?customer_animal_id=",
  CLIINET_DETAILS_BYE_VISITE_ID:
    BASE_URL + "api/veterinary/appointmentDetails?doctor_appointment_id=",
  /// Appointments
  APPOINTMENTS_STATUS: BASE_URL + "api/veterinary/appointmentsAtClinic",
  APPOINTMENTS_TODAY_LIST:
    BASE_URL + "api/veterinary/getAtClinicTodaysAppointments?doctor_id=",
  APPOINTMENTS_CLINICE_LIST: BASE_URL + "api/veterinary/getAtClinicClientList",
  APPOINTMENTS_STATUS_ACCEPTED:
    BASE_URL + "api/veterinary/changeAppointmentStatus",
  /// Video Clinic
  APPOINTMENTS_VIDEO: BASE_URL + "api/veterinary/getAppointmentAtVideo",
  APPOINTMENTS_VIDEO_LIST: BASE_URL + "api/veterinary/getAtVideoClientList",
  APPOINTMENTS_TODAY_LIST_VIDEO:
    BASE_URL + "api/veterinary/getAtVideoTodaysAppointments?doctor_id=",

  //// Reports
  DAILY_REPORT: BASE_URL + "api/veterinary/getDailyReport?doctor_id=",
  MONTHLEY_REPORT: BASE_URL + "api/veterinary/getMonthlyReport?doctor_id=",
  FETCH_PAYMENT_ANALYTICS: BASE_URL + VETERINARY_PATH + "paymentAnalysis",
  // Transaction Details
  TRANSACTION_DAILY: BASE_URL + "api/veterinary/getRecentTransaction",
  TEANSACTION_MONTHEY: BASE_URL + "api/veterinary/getAllTransaction",

  /// Profile
  PROFILE_BASIC_INF: BASE_URL + "api/veterinary/getBasicInformation?doctor_id=",
  PROFILE_GENERAL_INF:
    BASE_URL + "api/veterinary/getGeneralInformation?doctor_id=",
  PROFILE_CLINIC_INF: BASE_URL + "api/veterinary/getClinic?doctor_id=",
  PROFILE_GET_TEAM: BASE_URL + "api/veterinary/getDoctorTeam?doctor_id=",
  HADER_PROFILE: BASE_URL + "api/veterinary/getprofileDropDown?doctor_id=",
  ADD_TEAM_DOCTOR: BASE_URL + "api/veterinary/doctorsDropDown",
  ADD_TEAM_CLINIC: BASE_URL + "api/veterinary/clinicDropDown?doctor_id=",
  CREATE_TEAM: BASE_URL + "api/veterinary/storeDoctorTeam",
  GET_DOCUMENT: BASE_URL + "api/veterinary/getDoctorDocuments?doctor_id=",
  GET_DOCUMENT_signature: BASE_URL + "api/veterinary/getDocument?doctor_id=",
  UPDATE_DOCUMENT: BASE_URL + "api/veterinary/updateDocument",
  UPDATE_DOCUMENT_SIGNICAR: BASE_URL + "api/veterinary/updateSignature",
  DOCUMENT_UPDATE: BASE_URL + "api/veterinary/updateDoctorDocuments",
  DELETE_TEAM: BASE_URL + "api/veterinary/deleteDoctorTeam?doctor_team_id=",
  LANGUAGE_GET: BASE_URL + "api/veterinary/getDoctorLanguage?doctor_id=",
  LANGUAGE_UPDATE: BASE_URL + "api/veterinary/doctorLanguageUpdate",
  BANK_DETALIS_PROFILE: BASE_URL + "api/veterinary/getDoctorBank?doctor_id=",
  PRESCRIPTION_GET:
    BASE_URL +
    "api/veterinary/getPrescriptionByAppointment?doctor_appointmet_id=",
  PRODUCT_DRUPDOWN: BASE_URL + "api/veterinary/productDropDown",
  FREQUENCY_DROPDOWN: BASE_URL + "api/veterinary/frequencyDropDown",
  ADD_PRESRIPATION: BASE_URL + "api/veterinary/addPrescription",
  PRESCRIPTION_GET_ID:
    BASE_URL + "api/veterinary/prescriptionAppointmentWise?appointment_id=",
  GET_BASE_INFO_UPDATE: BASE_URL + "api/veterinary/basicInformationUpdate",
  GET_GENRAIL_IFFO_UPDATE: BASE_URL + "api/veterinary/generalInformationUpdate",
  PROFILE_UPDATE_IMAGE: BASE_URL + "api/veterinary/profileUpdate",
  DELETE_MEDICINE_DATA: BASE_URL + "api/veterinary/deletePrescription",
  GET_VACCINE_DROPDOWN: BASE_URL + "api/veterinary/vaccineDropDown",
  DELETE_VACCINE_BY_ID: BASE_URL + "api/veterinary/deleteVaccine",
  GET_ALL_VACCINE_DETAILS: BASE_URL + "api/veterinary/getVaccine",
  ADD_VACCINE: BASE_URL + "api/veterinary/addVaccine",
  CHANGE_STATUS_OFAPPOINTMENT:
    BASE_URL + "api/veterinary/changeAppointmentStatus",
  GET_PRESCRPTION_PET_FOOD: BASE_URL + "api/veterinary/getPrescriptionPetFood",
  DELETE_PET_FOOD_ID: BASE_URL + "api/veterinary/deletePrescriptionPetFood",
  GET_PETFOOD_DROPDOWN: BASE_URL + "api/veterinary/foodPrescriptionDropDown",
  ADD_PET_FOOD: BASE_URL + "api/veterinary/addPrescriptionPetFood",

  // SUbscription apis
  GET_RECOMENDED_PLAN_SUBS: BASE_URL + VETERINARY_PATH + "subscriptions",
  REQUEST_SUBSCRIPTION: BASE_URL + VETERINARY_PATH + "requestSubscription",

  // Notes apis
  GET_NOTES_DATA: BASE_URL + VETERINARY_PATH + "getAppointmentNote",
  UPDATE_NOTE_DATA: BASE_URL + VETERINARY_PATH + "doctorAppointmentNote",

  //// Chat
  CHAT_HISTRY: BASE_URL + "api/chat/chat-conversion",
  // Payment Withdraw api
  GET_PAYMENT_DROPDOWN: BASE_URL + PAYMENT_ROUTE + "bankAccounts",
  POST_WITHDRAW_REQUEST: BASE_URL + PAYMENT_ROUTE + "withdrawRequest",
  GET_MEDICINE_TYPE: BASE_URL + VETERINARY_PATH + "getMedicingType",

  // UPDATE SCHEDULE DETAILS
  GET_CLINIC_SCHEDULE_DETAILS: BASE_URL + VETERINARY_PATH + "getClinicScheduleByClinicId",
  UPDATE_CLINIC_SCHEDULE_DETAILS: BASE_URL + VETERINARY_PATH + "updateClinicSchedule",
};

export default ApiConfig;
