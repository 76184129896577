import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import searchIcon from "../assets/images/headerIcons/SearchIcon.svg";
import roundCircle from "../assets/images/headerIcons/headerCircle.svg";
import dropdown_icon from "../assets/images/headerIcons/dropdown_icon.svg";
import moon from "../assets/images/headerIcons/moon.svg";
import bell from "../assets/images/headerIcons/bell.svg";
import dd_fix_icon from "../assets/images/headerIcons/dd_fix_icon.svg";
import profile_img from "../assets/images/profile_img.svg";
import status_available from "../assets/images/headerIcons/status_available.svg";
import status_away from "../assets/images/headerIcons/status_away.svg";
import status_busy from "../assets/images/headerIcons/status_busy.svg";
import status_offline from "../assets/images/headerIcons/status_offline.svg";
import address_icon from "../assets/images/headerIcons/address_icon.svg";
import address_icon2 from "../assets/images/headerIcons/address_icon2.svg";
import profile_setting_icon from "../assets/images/headerIcons/profile_setting_icon.svg";
import logout_icon from "../assets/images/headerIcons/logout_icon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { motion } from "framer-motion";
import { cubicBezier } from "framer-motion";
import {
  postWithAuthCall,
  simpleGetCall,
  simplePostCall,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { notifyError, notifySuccess } from "./notify";
import useImageLoader from "../customeHooks/useImageLoader";
import dummydoctor from "../assets/Header/dummydoctor.jpg";

const Header = ({ setLoggedIn }) => {
  const navigate = useNavigate();
  let docId = localStorage.getItem("doctor_id");

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const {
    customerData,
    ProfileHader,
    setProfileHader,
    setProfiDr,
    notificationsCount,
    setnoticationList,
    setNotificationsCount,
  } = useContext(AppContext);
  const [status, setStatus] = useState([]);

  const [currentStatus, setCurrentStatus] = useState({
    status: "",
    status_id: "",
  });
 
  useEffect(() => {
    getStatusList();
  }, [ProfileHader]);

  const getStatusList = () => {
    simpleGetCall(ApiConfig.GET_DOC_PROFILE_STATUS)
      .then((res) => {
        if (res.success === true) {
          setStatus(res?.data);

          const currentStatusData =
            res?.data &&
            res?.data.find((it) => it?.status_id === ProfileHader?.status_id);

          currentStatusData &&
            setCurrentStatus({
              status: currentStatusData?.status,
              status_id: currentStatusData?.status_id,
            });
        } else {
          setStatus(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(!isHovering);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setClicked(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const logout = (e) => {
    e.preventDefault();
    setLoggedIn(false);
    localStorage.clear();
  };
  const aninations = {
    initial: { opacity: 0, y: 0 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 100 },
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const [clicked, setClicked] = useState(false);
  const location = useLocation();
  const currentRoute = location.pathname;

  useEffect(() => {
    getNoticationDetails();
    getProfileHaderDetails();

    // Clean-up Function
    return () => {
      setProfileHader({});
    };
  }, []);

  // useEffect(() => {
  //   getProfileHaderDetails();
  // }, []);

  const getProfileHaderDetails = () => {
    // setLoading(true);

    simpleGetCall(ApiConfig.HADER_PROFILE + customerData.doctor_id)
      .then((res) => {
        if (res.success) {
          setProfileHader(res.data);
          setProfiDr(res.data);
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const sendData = (data) => {
    let payload = {
      doctor_id: docId,
      status: data?.status_id,
    };
    simplePostCall(ApiConfig.DOC_PROFILE_STATUS, JSON.stringify(payload))
      .then((res) => {
        if (res?.success) {
          notifySuccess(res?.message);
          const currentStatusData =
            status &&
            status.find((it) => it?.status_id === ProfileHader?.status_id);
          console.log("currentStatusData,", currentStatusData);
          setCurrentStatus({
            status: currentStatusData?.status ? currentStatusData?.status : "",
            status_id: currentStatusData?.status_id
              ? currentStatusData?.status_id
              : "",
          });
          getProfileHaderDetails();
        } else {
          notifyError(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getNoticationDetails() {
    simpleGetCall(ApiConfig.Notification_LIST + customerData.user_id)
      .then((res) => {
        setnoticationList(res?.data);
        setNotificationsCount(res?.data?.length);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const { loading, imageSrc } = useImageLoader(
    ProfileHader?.doctor_profile_image_path,
    dummydoctor
  );

  return (
    <>
      <main className="mainHeader" ref={wrapperRef}>
        <header className="top-header">
          {/* <div className="d-flex flex-column">
            <div className="breadCrumbsTop">
              <label className={sidebar ? "leftSideBread" : "leftHeadBread"}>
                {(currentRoute === "/Doctors" && "Doctors") ||
                  (currentRoute === "/DoctorDetails" && (
                    <>
                      {" "}
                      <Link to="/Doctors">Doctor /</Link> Doctor Details{" "}
                    </>
                  ))}

                {(currentRoute === "/RevenueReports" && "RevenueReports") ||
                  (currentRoute === "/RevenueReports" && (
                    <>
                      {" "}
                      <Link to="/RevenueReports">Doctor /</Link> Doctor Details{" "}
                    </>
                  ))}
              </label>
            </div>
            <div className="headingBreads">
              <label className={sidebar ? "leftSideCloseBar" : "leftHeadTxt"}>
                {(currentRoute === "/Dashboard" && "Dashboard") ||
                  (currentRoute === "/Doctors" && "Doctors") ||
                  (currentRoute === "/DoctorDetails" && "Doctor Details")}
              </label>
            </div>
          </div> */}
          <div className="left-breadcurm">
            {/* <label className="page-name-heading" htmlFor="">Dashboard<Link to="#" ></Link></label> */}
            {(currentRoute === "/Dashboard" && (
              <label className="page-name-heading" htmlFor="">
                Dashboard<Link to="#"></Link>
              </label>
            )) ||
              (currentRoute === "/CustomerDetails" && (
                <label className="page-name-heading" htmlFor="">
                  client Details<Link to="#"></Link>
                </label>
              )) ||
              (currentRoute === "/AtClinic" && (
                <label className="page-name-heading" htmlFor="">
                  Appointments/ <Link to="#">At Clinic</Link>
                </label>
              )) ||
              (currentRoute === "/Video" && (
                <label className="page-name-heading" htmlFor="">
                  Appointments/ <Link to="#">Video</Link>
                </label>
              )) ||
              (currentRoute === "/Reports" && (
                <label className="page-name-heading" htmlFor="">
                  Reports<Link to="#"></Link>
                </label>
              )) ||
              (currentRoute === "/TransactionDetails" && (
                <label className="page-name-heading" htmlFor="">
                  Transaction Details<Link to="#"></Link>
                </label>
              )) ||
              (currentRoute === "/Subscription" && (
                <label className="page-name-heading" htmlFor="">
                  Subscription<Link to="#"></Link>
                </label>
              )) ||
              (currentRoute === "/MyProfile" && (
                <label className="page-name-heading" htmlFor="">
                  My Profile<Link to="#"></Link>
                </label>
              )) ||
              (currentRoute === "/AddClinic" && (
                <label className="page-name-heading" htmlFor="">
                  <Link to="/MyProfile">My Profile</Link> / Add Clinic
                </label>
              )) ||
              (currentRoute === "/AddTeam" && (
                <label className="page-name-heading" htmlFor="">
                  <Link to="/MyProfile">My Profile</Link> / Add Team
                </label>
              ))}
          </div>
          <div
            className="searchBlog"
            onMouseOver={() => setIsHovering(true)}
            onMouseOut={handleMouseOut}
          >
            <div className={isHovering ? "innerSearch" : "smallInnerSearch"}>
              <input
                type="text"
                className="form-control innerForm"
                placeholder="search"
              />
              <img src={searchIcon} className="searchInside" alt="" />
              {/* <img src={bell} className="insideIcons" alt="" /> */}

              <div
                className="clickerNavigreen"
                onClick={() => navigate("Notification")}
              >
                <img src={bell} alt="" className="insideIcons" />
                {notificationsCount && (
                  <div className="notiBadge">
                    <span>{notificationsCount}</span>
                  </div>
                )}
              </div>
              <img src={moon} className="insideIcons" alt="" />
              {/* {ProfileHader && ProfileHader.length > 0 ? (
                ProfileHader.map((itemlist, index) => {
                  return (
                    <> */}

              {ProfileHader?.doctor_profile_image_path === "" ||
              ProfileHader?.doctor_profile_image_path ===
                ProfileHader?.doctor_profile_image_path ? (
                <>
                  <img
                    width="30px"
                    height="30px"
                    src={ProfileHader?.doctor_profile_image_path}
                    onClick={() => setClicked(!clicked)}
                    className="rounded-circle circleImg"
                  />
                </>
              ) : (
                <>
                  {!loading && (
                    <img
                      width="30px"
                      height="30px"
                      src={imageSrc}
                      onClick={() => setClicked(!clicked)}
                      className="rounded-circle circleImg"
                    />
                  )}
                </>
              )}
              {/* </> */}
              {/* );
                })
              ) : (
                <>
                  <img
                    src={roundCircle}
                    onClick={() => setClicked(!clicked)}
                    className="rounded-circle circleImg"
                  />
                </>
              )}*/}
            </div>
          </div>
          {clicked && (
            <motion.div
              variants={aninations}
              initial="initial"
              animate="animate"
              onMouseOver={() => setClicked(true)}
              onMouseOut={() => setClicked(false)}
              transition={{ duration: 0.4, delay: 0 }}
              className="profile_dropdown"
            >
              <p className="pd_title">
                {" "}
                <span>Animal App -</span> Premium Plus
              </p>
              <div className="pd_profile_description_wrapper">
                <div className="pdf_img">
                  <img
                    src={
                      ProfileHader?.doctor_profile_image_path
                    }
                    alt=""
                  />
                  <span>
                    {/* {
                      ProfileHader.status_id === 6 &&
                      <img src={status_available} alt="" /> ||
                      ProfileHader.status_id === 7 &&
                      <img src={status_away} alt="" /> ||
                      ProfileHader.status_id === 8 &&
                      <img src={status_busy} alt="" /> ||
                      ProfileHader.status_id === 9 &&
                      <img src={status_offline} alt="" />
                    } */}
                    {/* <img
                      src={ProfileHader.status_id === "6" && status_available ||
                        ProfileHader.status_id === "7" && status_away ||
                        ProfileHader.status_id === "8" && status_busy ||
                        ProfileHader.status_id === "9" && status_offline}
                      alt=""
                    /> */}
                  </span>
                </div>
                <div className="pdf_name_status">
                  {/* {ProfileHader && ProfileHader.length > 0 ? (
                    ProfileHader.map((itemlist, index) => {
                      return ( */}
                  <>
                    <p className="name">{ProfileHader?.doctor_name}</p>
                    <p className="post">{ProfileHader.specializations}</p>
                  </>
                  {/* );
                    })
                  ) : (
                    <></>
                  )} */}

                  <div className="pd_status_selection">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <div>
                          <div>
                            <img
                              src={
                                currentStatus?.status_id == 6
                                  ? status_available
                                  : currentStatus?.status_id == 7
                                  ? status_away
                                  : currentStatus?.status_id == 8
                                  ? status_busy
                                  : currentStatus?.status_id == 9
                                  ? status_offline
                                  : ""
                              }
                              alt=""
                            />
                            {currentStatus?.status ? (
                              <span className="capital"> {currentStatus?.status}</span>
                            ) : (
                              "Select Status"
                            )}
                          </div>
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {status && status.length > 0
                          ? status.map((data, index) => {
                              return (
                                <Dropdown.Item key={index}>
                                  <div onClick={() => sendData(data)}>
                                    {(data?.status_id == "6" && (
                                      <img src={status_available} alt="" />
                                    )) ||
                                      (data?.status_id == "7" && (
                                        <img src={status_away} alt="" />
                                      )) ||
                                      (data?.status_id == "8" && (
                                        <img src={status_busy} alt="" />
                                      )) ||
                                      (data?.status_id == "9" && (
                                        <img src={status_offline} alt="" />
                                      ))}
                                    <span className="capital">
                                      {" "}
                                      {data?.status}
                                    </span>
                                  </div>
                                </Dropdown.Item>
                              );
                            })
                          : null}
                        {/* <Dropdown.Item
                          onClick={() => {
                            setStatus({
                              imageName: status_away,
                              status_title: "Away",
                            });
                          }}
                        >
                          <div>
                            <img src={status_away} alt="" />
                            Away
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setStatus({
                              imageName: status_busy,
                              status_title: "Busy",
                            });
                          }}
                        >
                          <div>
                            <img src={status_busy} alt="" />
                            Busy
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setStatus({
                              imageName: status_offline,
                              status_title: "Offline",
                            });
                          }}
                        >
                          <div>
                            <img src={status_offline} alt="" />
                            Offline
                          </div>
                        </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="pd_status_selection mt-2 border_bottom">
                {/* <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <div>
                          <img src={address_icon} alt="" />
                          At, Visrantwadi Clinic, Pune </div>
                      </Dropdown.Toggle>
                   
                      {ProfileHader.clinics
 &&
                                ProfileHader.clinics
                                .length > 0 ? (
                                  ProfileHader.clinics
                                  .map(
                                    (itemlist, index) => {
                                      return (
                      <Dropdown.Menu >
                       
                        
                        <Dropdown.Item >
                          <div>
                            <img src={address_icon2} alt="" className="add_icon" />
                            Vishrantwadi, Kalas Road,
                            Pune.
                          </div>
                        </Dropdown.Item>
                        
                      </Dropdown.Menu>
                        );
                      }
                    )
                  ) : (
                    <></>
                  )}

                    </Dropdown> */}
              </div>
              <div className="pd_status_selection mt-2 border_bottom2">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <div onClick={() => navigate("/MyProfile")}>
                      <img src={profile_setting_icon} alt="" />
                      My Profile settings{" "}
                    </div>
                  </Dropdown.Toggle>
                </Dropdown>
              </div>
              <div className="logout_btn_wrapper">
                <button onClick={logout}>
                  {" "}
                  <img src={logout_icon} alt="" /> Logout
                </button>
              </div>
            </motion.div>
          )}
        </header>
      </main>
    </>
  );
};

export default Header;
