import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import teleCall from "../../assets/images/ic_call1.svg";
import email_icon from "../../assets/images/icons/email.svg";
import user_icon from "../../assets/images/icons/user_icon.svg";
import otp_icon from "../../assets/images/icons/otp_icon.svg";
import telephone from "../../assets/images/icons/telephone.svg";
import login_img from "../../assets/images/login_img_1.svg";
import { Link, useNavigate } from "react-router-dom";
import { DarkButton } from "../commonComponents/commonComp";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import ic_hide_eyes from "../../assets/images/ic_hide_eyes.svg";
import ic_show from "../../assets/images/ic_show_eyes.svg";

const SignUp = () => {
  const { setdoctorId, setLoading } = useContext(AppContext);
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const [SignUp, setSignUp] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    otp: "",
    hash: "",
  });
  const [seconds, setSeconds] = useState(60);
  const [firstClick, setFirstClick] = useState(false);

  console.log("SignUp", SignUp);
  const SignUppost = () => {
    setLoading(true);

    //  notifySuccess("Something went wrong");
    simplePostCall(ApiConfig.USER_SIGN_UP, JSON.stringify({ ...SignUp }))
      .then((res) => {
        // console.log(res);
        if (res.success) {
          navigate("/GeneralInfo");
          setdoctorId(res.data.doctor_id);
          localStorage.setItem("sign_doctor_id", res.data.doctor_id);
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        // setLoading(false);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const navigate = useNavigate();

  const startTimer = () => {
    if (seconds > 0) {
      setSeconds(60);
      let interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  };

  useEffect(() => { }, [seconds]);

  // handleOtp api
  const handleOtp = () => {
    let payload = {
      phone_number: SignUp?.phone,
    };
    if (SignUp.phone !== null) {
      simplePostCall(ApiConfig.REQUEST_OTP, JSON.stringify(payload))
        .then((res) => {
          if (res.success === true) {
            startTimer();
            notifySuccess("OTP Sent Successfully");
            setFirstClick(true);
            setSignUp({
              ...SignUp,
              hash: res.hash,
            });
          } else {
            notifyError(res.message);
            // setFirstClick(false);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      notifyError("Please Enter Mobile Number");
    }
  };
  return (
    <>
      <div className="authMain">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <img src={logo} alt="" className="login_img" />
            <div className="signinForm signUp">
              <h1 className="signInTxt">Sign up</h1>
              <p className="signInDesc">
                If you already have an account register <br /> You can
                <Link className="link_common" to="/">
                  Login here !
                </Link>
              </p>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  First Name
                </label>
                <img src={user_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your First name"
                  value={SignUp.first_name}
                  onChange={(e) => {
                    setSignUp({
                      ...SignUp,
                      first_name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Last Name
                </label>
                <img src={user_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your Last name"
                  value={SignUp.last_name}
                  onChange={(e) => {
                    setSignUp({
                      ...SignUp,
                      last_name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Email
                </label>
                <img src={email_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your Email"
                  value={SignUp.email}
                  onChange={(e) => {
                    setSignUp({
                      ...SignUp,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="inputBox">
                {firstClick ? null : (
                  <button
                    className="otpbtn"
                    onClick={() => {
                      handleOtp();
                    }}
                  >
                    Request OTP
                  </button>
                )}
                {seconds == 0 ? (
                  <button className="resendotpbtnGreen" onClick={handleOtp}>
                    Re send OTP
                  </button>
                ) : (
                  <button className="resendotpbtn" onClick={handleOtp}>
                    Re send OTP ({seconds})
                  </button>
                )}
                <label htmlFor="" className="emailLabel">
                  Phone Number
                </label>
                <img src={telephone} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your phone number"
                  value={SignUp.phone}
                  onChange={(e) => {
                    setSignUp({
                      ...SignUp,
                      phone: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  OTP
                </label>
                <img src={telephone} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter OTP here..."
                  value={SignUp.otp}
                  onChange={(e) => {
                    setSignUp({
                      ...SignUp,
                      otp: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Password
                </label>
                <img src={otp_icon} className="mailImg" alt="" />
                <input
                  type={passwordType === "password" ? "password" : "text"}
                  className="form-control formInput"
                  placeholder="Enter Password here..."
                  value={SignUp.password}
                  onChange={(e) => {
                    setSignUp({
                      ...SignUp,
                      password: e.target.value,
                    });
                  }}
                />
                <div className="eyePosition" onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <img src={ic_show} className="absolutEye" alt="" />
                  ) : (
                    <img src={ic_hide_eyes} className="absolutEye" alt="" />
                  )}
                </div>
              </div>
              <div className="inputBox">
                <div className="belowChecks">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      I agree with
                      <span className="link_common">terms and conditions</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="login btn-width-100">
                <DarkButton
                  onClick={() => SignUppost()}
                  classbtn={"cx-btn-dark btn-width-100 btn-height font-17 mb-5"}
                  btn={"Register"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="landingImg">
              <div className="phone-no">
                <img src={teleCall} alt="" />
                <label htmlFor="">+91 9509932234</label>
              </div>
              <div className="img-container">
                <div className="big-img">
                  <img src={login_img} alt="" />
                </div>
                <div>
                  <h2>Sign in to Veterianry App</h2>
                  <p>Lets get started !</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
