import React, { useEffect, useState } from "react";
import expert_plan_icon from "../../assets/images/icons/expert_plan_icon.svg";
import premium_plan_icon from "../../assets/images/icons/premium_plan_icon.svg";
import professional_plan_icon from "../../assets/images/icons/professional_plan_icon.svg";
import professional_pro_plan_icon from "../../assets/images/icons/professional_pro_plan_icon.svg";
import modal_close_btn from "../../assets/images/icons/modal_close_btn.svg";
import { motion } from "framer-motion";
import { DarkButton, DonutChart } from "../commonComponents/commonComp";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { simpleGetCallWithToken, simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { checkoutHandler } from "./Checkout";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import moment from "moment";
import Loader from "../../sharedComponent/Loader";
import { formatCurrency } from "../../common/commonFunctions";

const Subscription = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    desc: "",
    name: "",
  });
  const [vetSubsArr, setVetSubsArr] = useState([]);
  const [manageModal, setManageModal] = useState(1);
  const [loading, setLoading] = useState(false);
  const user_id = localStorage.getItem("user_id");

  const getVetSUbsPlan = () => {
    setLoading(true);
    simpleGetCallWithToken(
      ApiConfig.GET_RECOMENDED_PLAN_SUBS + `?user_id=${user_id}`
    )
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setVetSubsArr(res);
        } else {
          setVetSubsArr([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const afterPayment = () => {
    notifySuccess("You have purchased plan successfully");
  };

  const requestSubs = (data) => {
    let { subscription_price, subscription_id } = data;
    let payload = {
      subscription_id: subscription_id,
      mode_of_payment_id: 1,
      transaction_date: new Date(),
      user_id: user_id,
    };
    simplePostCall(ApiConfig.REQUEST_SUBSCRIPTION, JSON.stringify(payload))
      .then((res) => {
        if (res.success) {
          checkoutHandler(
            subscription_price,
            res?.orderDetails,
            "doc-sub",
            afterPayment
          );
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getVetSUbsPlan();
  }, []);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.3 }}
      className="Subscription_wrapper"
      id="cx-main"
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="Subscription">
            <div className="subscription_title">
              <p> Current Plan</p>
            </div>
            {vetSubsArr?.current_plan && vetSubsArr?.current_plan?.length > 0
              ? vetSubsArr?.current_plan?.map((data, index) => {
                return (
                  <>
                    <div className="subscription_item">
                      <div className="row">
                        <div className="col-lg-10">
                          <div className="sub_item_left">
                            <img src={professional_plan_icon} alt="" />
                            <div className="title_text_wrapper">
                              <p className="plan_title">
                                {data?.subscription_name}
                              </p>
                              <p className="sub_date">
                                Subscription Date :{" "}
                                <span>
                                  {moment(data?.subscription_date).format(
                                    "LL"
                                  )}
                                </span>
                              </p>
                            </div>
                            <div className="renew_date">
                              <p>
                                Renewal Date :{" "}
                                <span>
                                  {moment(
                                    data?.subscription_valid_upto
                                  ).format("LL")}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="sub_item_right">
                            <Link
                              to="#"
                              onClick={() => {
                                setManageModal(1);
                                handleShow();
                                setSubscriptionDetails({
                                  ...subscriptionDetails,
                                  desc: data?.subscription_description
                                    ? data?.subscription_description
                                    : "",
                                  name: data?.subscription_name
                                    ? data?.subscription_name
                                    : "",
                                });
                              }}
                            >
                              View Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
              : null}

            <div className="subscription_title">
              <p>Recommended Plan</p>
            </div>
            {vetSubsArr?.data && vetSubsArr?.data?.length > 0
              ? vetSubsArr?.data?.map((data, index) => {
                return (
                  <>
                    <div className="subscription_item1">
                      <div className="row">
                        <div className="col-lg-9">
                          <div className="sub_item_left">
                            <img src={professional_pro_plan_icon} alt="" />
                            <div className="title_text_wrapper">
                              <p className="plan_title">
                                {data?.subscription_name}
                              </p>
                              <p className="sub_date mb-2">
                                {data?.subscription_description}...{" "}
                                <Link
                                  onClick={() => {
                                    setManageModal(2);
                                    handleShow();
                                    setSubscriptionDetails({
                                      ...subscriptionDetails,
                                      desc: data?.subscription_description
                                        ? data?.subscription_description
                                        : "",
                                      name: data?.subscription_name
                                        ? data?.subscription_name
                                        : "",
                                    });
                                  }}
                                >
                                  Read more
                                </Link>
                              </p>
                              {data?.subscription_price ? (
                                <p className="plan_title">
                                  {formatCurrency(data?.subscription_price)}
                                </p>
                              ) : null}
                              {data?.duration_months ? (
                                <p className="plan_title">
                                  Duration : {data?.duration_months} {`Month${data?.duration_months != 1 ? 's' : ''}`}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        {data?.subscription_price ? (
                          <div className="col-lg-3">
                            <div className="sub_item_right">
                              <DarkButton
                                classbtn={
                                  "cx-btn-dark me-auto  btn-width-100 btn-height1 font-14"
                                }
                                onClick={() => requestSubs(data)}
                                btn={"Upgrade Plan"}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                );
              })
              : null}

            {/* <div className="subscription_item1">
          <div className="row">
            <div className="col-lg-9">
              <div className="sub_item_left">
                <img src={premium_plan_icon} alt="" />
                <div className="title_text_wrapper">
                  <p className="plan_title">Premium Plan</p>
                  <p className="sub_date mb-2">Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, ... <Link onClick={() => {
                    setManageModal(3)
                    handleShow()
                  }}>Read more</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sub_item_right">
                <DarkButton
                  classbtn={
                    "cx-btn-dark me-auto  btn-width-100 btn-height1 font-14"
                  }

                  btn={"Upgrade Plan"}
                />
              </div>
            </div>
          </div>


        </div>
        <div className="subscription_item1">
          <div className="row">
            <div className="col-lg-9">
              <div className="sub_item_left">
                <img src={expert_plan_icon} alt="" />
                <div className="title_text_wrapper">
                  <p className="plan_title">Expert Plan</p>
                  <p className="sub_date mb-2">Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, ... <Link onClick={() => {
                    setManageModal(4)
                    handleShow()
                  }}>Read more</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sub_item_right">
                <DarkButton
                  classbtn={
                    "cx-btn-dark me-auto  btn-width-100 btn-height1 font-14"
                  }

                  btn={"Upgrade Plan"}
                />
              </div>
            </div>
          </div>


        </div> */}
          </div>
          {/* Modal Start */}
          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="common_modal"
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                <div className="modal_header_wrapper">
                  <div className="modal_left">
                    <img
                      src={
                        manageModal == 1
                          ? professional_plan_icon
                          : manageModal == 2
                            ? professional_pro_plan_icon
                            : manageModal == 3
                              ? premium_plan_icon
                              : expert_plan_icon
                      }
                      alt=""
                    />
                    {/* <p>{manageModal == 1 ? "Professional Plan" : manageModal == 2 ? "Professional Pro Plan" : manageModal == 3 ? "Premium Plan" : "Expert Plan"}</p> */}
                    <p>{subscriptionDetails?.name}</p>
                  </div>
                  <div className="modal_close_btn">
                    <img src={modal_close_btn} alt="" onClick={handleClose} />
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modal_body_inner_wrapper">
                <p className="description">
                  {subscriptionDetails?.desc}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button onClick={handleClose}>Close</Button> 
              <div className="modal_btn_wrapper">
                <DarkButton
                type={"button"}
                  classbtn={
                    "cx-btn-light btn-width-50 btn-height1 font-14 me-3 visibility-none"
                  }
                  onClick={handleClose}
                  btn={manageModal == 1 ? "Cancel Plan" : "Cancel"}
                />{" "}
                <DarkButton
                  classbtn={
                    "cx-btn-dark me-auto  btn-width-50 btn-height1 font-14"
                  }
                  btn={"Upgrade Plan"}
                />
              </div>
              */}
            </Modal.Footer>
          </Modal>

          {/* Modal End */}
        </>
      )}
    </motion.div>
  );
};

export default Subscription;
