import React, { useContext, useState } from "react";
import logo from "../../assets/images/logo.svg";
import teleCall from "../../assets/images/ic_call1.svg";
import insiderImg from "../../assets/images/icons/landigIcon.svg";
import mailBox from "../../assets/images/icons/emailSvg.svg";
import passBox from "../../assets/images/icons/lock.svg";
import eye from "../../assets/images/icons/eye.svg";
import login_img from "../../assets/images/login_img_1.svg";
import fb from "../../assets/images/fb.svg";
import apple from "../../assets/images/apple.svg";
import google from "../../assets/images/google.svg";
import { Link, Route, useNavigate } from "react-router-dom";
import { DarkButton } from "../commonComponents/commonComp";
import Form from "react-bootstrap/Form";
import { AppContext } from "../../context/AppContext";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import Loadar from "../../sharedComponent/Loader";
import ic_hide_eyes from "../../assets/images/ic_hide_eyes.svg";
import ic_show from "../../assets/images/ic_show_eyes.svg";
import { ColorRing } from "react-loader-spinner";
import ic_left_green_arrow from "../../assets/images/ic_left_green_arrow.svg";
import playstore from "../../assets/googleimages/playstore.png"
import QRCodeVeterinary from "../../assets/googleimages/QRCodeVeterinary.png"

const Login = ({ setLoggedIn }) => {
  const {
    setCustomerData,
    userCredentials,
    setUserCredentials,
    setLoading,
    getProfileHaderDetails,
    validNumber,
    navigate,
  } = useContext(AppContext);
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const [validated, setValidated] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [isLoginWithOTP, setIsLoginWithOTP] = useState(false);
  const [loginWithOTPData, setLoginWithOTPData] = useState({
    phone_number: "",
    otp: "",
  });
  console.log("loginWithOTPData,", loginWithOTPData);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      login(event);
    }
    setValidated(true);
  };

  const login = (e) => {
    e.preventDefault();
    const loginPayLoad = isLoginWithOTP ? loginWithOTPData : userCredentials;
    const API_URL = isLoginWithOTP
      ? ApiConfig.LOGIN_WITH_OTP
      : ApiConfig.USER_LOGIN;
    setLoading(true);
    simplePostCall(API_URL, JSON.stringify(loginPayLoad))
      .then((res) => {
        console.log("res,", res);
        const { message, data } = res;
        if (res.success) {
          let data = res?.data;
          localStorage.setItem("auth_token", res?.token);
          localStorage.setItem("email", data.doctor_email);
          localStorage.setItem("full_name", data.doctor_name);
          localStorage.setItem("doctor_id", data.doctor_id);
          localStorage.setItem(
            "doctor_contact_number",
            data.doctor_contact_number
          );
          localStorage.setItem(
            "doctor_profile_image_path",
            data.doctor_profile_image_path
          );
          localStorage.setItem("user_id", data.user_id);
          localStorage.setItem("status_id", data.status_id);
          localStorage.setItem("is_subscribed", data.is_login);

          localStorage.setItem("logedIn", true);
          setCustomerData({
            doctor_id: data?.doctor_id,
            auth_token: res?.token,
            full_name: data.doctor_name,
            email: data.doctor_email,
            doctor_contact_number: data.doctor_contact_number,
            doctor_profile_image_path: data.doctor_profile_image_path,
            user_id: data.user_id,
            status_id: data.status_id,
            is_subscribed: data.is_login ? data.is_login : false,
          });

          setLoggedIn(true);
          getProfileHaderDetails();
        } else {
          notifyError(res.message);
          if (data) {
            const {
              generalInformationStore,
              storeClinicInformation,
              uploadDocuments,
              addBankDetails,
              setSchedule,
              doctor_clinic_id,
              doctor_id,
            } = data;
            doctor_clinic_id &&
              localStorage.setItem("doctor_clinic_id", doctor_clinic_id);
            localStorage.setItem("sign_doctor_id", doctor_id);
            if (!generalInformationStore) {
              navigate("/GeneralInfo");
            } else if (!storeClinicInformation) {
              navigate("/ClinicInfo");
            } else if (!uploadDocuments) {
              navigate("/UploadDocs");
            } else if (!addBankDetails) {
              navigate("/BankDetails");
            } else if (!setSchedule) {
              navigate("/ClinicSchedule");
            }
          }
        }
      })
      .catch((err) => {
        // setLoading(false);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const startTimer = () => {
    // setSeconds(60);
    if (seconds > 0) {
      let interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  };
  // SEND_OTP_FOR_LOGIN
  const requestOTP = async () => {
    if (loginWithOTPData?.phone_number?.length !== 10) {
      notifyError("Please enter valid mobile number");
      return;
    }
    try {
      const res = await simplePostCall(
        ApiConfig.SEND_OTP_FOR_LOGIN,
        JSON.stringify({ phone_number: loginWithOTPData?.phone_number })
      );
      if (res.success) {
        notifySuccess("OTP sent your mobile number");
        startTimer();
      }
    } catch (error) {
      console.log("catch error from Requst otp for Login API ,", error);
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="authMain">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="login-left">
                <img src={logo} alt="" className="login_img" />
                <div className="signinForm">
                  <h1 className="signInTxt">Sign in</h1>
                  <p className="signInDesc">
                    If you don't have an account registered <br /> You can
                    <Link className="link_common" to="/SignUp">
                      Register here !
                    </Link>
                  </p>
                  {!isLoginWithOTP ? (
                    <>
                      <Form.Group
                        className="inputBox"
                        controlId="validationCustom01"
                      >
                        <Form.Label htmlFor="" className="emailLabel">
                          Email
                        </Form.Label>
                        <img src={mailBox} className="mailImg" alt="" />
                        <Form.Control
                          autocomplete="off"
                          required
                          type="text"
                          className="form-control formInput"
                          placeholder="Enter your Email"
                          value={userCredentials.email}
                          onChange={(e) => {
                            setUserCredentials({
                              ...userCredentials,
                              email: e.target.value,
                            });
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please add email
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="inputBox"
                        controlId="validationCustom02"
                      >
                        <div className="inputBox">
                          <Form.Label htmlFor="" className="emailLabel">
                            Password
                          </Form.Label>
                          <img src={passBox} className="mailImg" alt="" />
                          {/* <img src={eye} className="eyeImg" alt="" /> */}
                          <Form.Control
                            autocomplete="off"
                            required
                            type={
                              passwordType === "password" ? "password" : "text"
                            }
                            className="form-control formInput"
                            placeholder="Enter your password"
                            value={userCredentials.password}
                            onChange={(e) => {
                              setUserCredentials({
                                ...userCredentials,
                                password: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please add password
                          </Form.Control.Feedback>
                          <div className="eyePosition" onClick={togglePassword}>
                            {passwordType === "password" ? (
                              <img
                                src={ic_show}
                                className="absolutEye"
                                alt=""
                              />
                            ) : (
                              <img
                                src={ic_hide_eyes}
                                className="absolutEye"
                                alt=""
                              />
                            )}
                          </div>
                          <div className="belowChecks">
                            <Form.Group>
                              <Form.Check
                                label=" Remember me"
                                feedback="You must agree before submitting."
                                feedbackType="invalid"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                              />
                            </Form.Group>
                            <div className="form-check"></div>
                            <div className="forgetPass">
                              <Link to="/ForgotPass" className="">
                                Forgot Password ?
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <div
                        style={{ marginBottom: "31px" }}
                        onClick={() => setIsLoginWithOTP(false)}
                      >
                        <img src={ic_left_green_arrow} />{" "}
                      </div>
                      <Form.Group
                        className="inputBox"
                        controlId="validationCustom01"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Form.Label htmlFor="" className="emailLabel">
                            Mobile Number
                          </Form.Label>
                          {seconds === 60 && (
                            <button
                              type="button"
                              className="requestBtn"
                              // disabled={!forgotData?.phone_number ? true : false}
                              onClick={requestOTP}
                            >
                              Request OTP
                            </button>
                          )}
                        </div>

                        <img src={mailBox} className="mailImg" alt="" />
                        <Form.Control
                          autocomplete="off"
                          required
                          type="text"
                          className="form-control formInput"
                          placeholder="Enter your mobile number"
                          value={loginWithOTPData.phone_number}
                          onChange={(e) => {
                            if (validNumber.test(e.target.value)) {
                              setLoginWithOTPData({
                                ...loginWithOTPData,
                                phone_number: e.target.value,
                              });
                            }
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter mobile number
                        </Form.Control.Feedback>
                        {seconds !== 60 && (
                          <button
                            type="button"
                            className="resendotpbtn"
                            onClick={() => {
                              if (seconds == 0) {
                                requestOTP();
                              }
                            }}
                          >
                            Re-send OTP ({seconds})
                          </button>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="inputBox"
                        controlId="validationCustom02"
                      >
                        <div className="inputBox">
                          <Form.Label htmlFor="" className="emailLabel">
                            OTP
                          </Form.Label>
                          <img src={passBox} className="mailImg" alt="" />
                          {/* <img src={eye} className="eyeImg" alt="" /> */}
                          <Form.Control
                            autocomplete="off"
                            required
                            type="text"
                            className="form-control formInput"
                            placeholder="Enter OTP"
                            value={loginWithOTPData.otp}
                            onChange={(e) => {
                              if (validNumber.test(e.target.value)) {
                                setLoginWithOTPData({
                                  ...loginWithOTPData,
                                  otp: e.target.value,
                                });
                              }
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter OTP
                          </Form.Control.Feedback>
                          <div className="eyePosition" onClick={togglePassword}>
                            {passwordType === "password" ? (
                              <img
                                src={ic_show}
                                className="absolutEye"
                                alt=""
                              />
                            ) : (
                              <img
                                src={ic_hide_eyes}
                                className="absolutEye"
                                alt=""
                              />
                            )}
                          </div>
                          <div className="belowChecks">
                            <Form.Group>
                              <Form.Check
                                label=" Remember me"
                                feedback="You must agree before submitting."
                                feedbackType="invalid"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                              />
                            </Form.Group>
                            <div className="form-check"></div>
                            <div className="forgetPass">
                              <Link to="/ForgotPass" className="">
                                Forgot Password ?
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                    </>
                  )}

                  {/* Login FORM With OTP */}

                  <div className="login btn-width-100">
                    <DarkButton
                      type="submit"
                      classbtn={"cx-btn-dark btn-width-100 btn-height font-17"}
                      btn={"Login"}
                    />
                  </div>
                  {!isLoginWithOTP && (
                    <div
                      style={{
                        marginTop: "30px",
                        color: "#34528a",
                        marginLeft: "38%",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsLoginWithOTP(true)}
                    >
                      or continue with OTP
                    </div>
                  )}

                  <div className="download-app-container">
                  <div style={{textAlign : "center" }} ><p>Download App </p></div>
                    <div className="download-app-img">
                      <Link
                        target="_blank"
                        to="https://play.google.com/store/apps/details?id=com.animal.doctor"
                      >
                        <img
                          className="google-play-store-img"
                          src={playstore}
                          alt="dr im"
                        />
                      </Link>

                      <div className="verticle-line"></div>
                      <Link>
                        <img
                          className="qr-img"
                          src={QRCodeVeterinary}
                          alt="dr im"
                        />
                      </Link>
                    </div>
                  </div>

                  {/* <div className="social-account-login">
                    <label htmlFor="">or continue with</label>
                    <div className="social-btns">
                      <Link className="fb">
                        <img src={fb} alt="" />
                      </Link>
                      <Link className="fb">
                        <img src={apple} alt="" />
                      </Link>
                      <Link className="fb">
                        <img src={google} alt="" />
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="landingImg">
                <div className="phone-no">
                  <img src={teleCall} alt="" />
                  <label htmlFor="">+91 9509932234</label>
                </div>
                <div className="img-container">
                  <div className="big-img">
                    <img src={login_img} alt="" />
                  </div>
                  <div>
                    <h2>Sign in to Veterianry App</h2>
                    <p>Lets get started !</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default Login;
