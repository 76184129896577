import React, { useState } from "react";
import logo from "../../assets/images/logo.svg";
import teleCall from "../../assets/images/ic_call1.svg";
import verification_process from "../../assets/images/verification_process.svg";
import verification_check from "../../assets/images/verification_check.svg";
import { DarkButton } from "../commonComponents/commonComp";
import { useNavigate } from "react-router-dom";

const VerificationUnderProcess = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [signature, setSignature] = useState();
  const [deleteImg, setDeleteImg] = useState(true);

  function handleFileInput(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  function handleFileInput2(e) {
    setSignature(URL.createObjectURL(e.target.files[0]));
    setDeleteImg(true);
  }

  const handleDeleteImg = () => {
    setDeleteImg(!deleteImg);
  };

  const options = [
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
    { value: "Marathi", label: "Marathi" },
    { value: "Urdu", label: "Urdu" },
  ];

  const [selected, setSelected] = useState(null);

  const handleChange = (e) => {
    setSelected(e.value);
  };
  // const [selectedOption, setSelectedOption] = useState("");

  // const handleChange1 = (selectedOption) => {
  //   setSelectedOption(selectedOption.value);
  // };

  return (
    <>
      <div className="authMain">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <img src={logo} alt="" className="login_img" />
            <div className="verification_wrapper">
              <div className="verification_inner">
                <h2 className="vfi_title">
                  YOUR VERIFICATION WILL BE PROCEED IN 24 HOURS
                </h2>
                <p className="vfi_desc">
                  we have successfully received your registration request.we appreciate your patience during this period. we may reach out to you via phone call or Email.
                </p>
                <div>
                  <div className="login btn-width-100 d-flex justify-content-end margin-10">
                    {/* <DarkButton
                  // onClick={()=>navigate('/ClinicSchedule')}
                    classbtn={
                      "cx-btn-dark  btn-height font-17 mb-5"
                    }
                    btn={"Cancel Request"}
                  /> */}
                    <DarkButton
                      onClick={() => navigate('/')}
                      classbtn={
                        "cx-btn-dark  btn-height font-17 mb-5 "
                      }
                      btn={"Lets Start..."}
                    />
                  </div>

                </div>

              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="landingImg">
              <div className="phone-no">
                <img src={teleCall} alt="" />
                <label htmlFor="">+91 9509932234</label>
              </div>
              <div className="img-container">
                <div className="big-img">
                  <img src={verification_process} alt="" />
                </div>
                <div>
                  <h2>Get Verified ! <img src={verification_check} alt="" /></h2>
                  <p>Share to collect fees !</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationUnderProcess;
