import React, { useContext, useEffect, useRef, useState } from "react";
import profile_img from "../../assets/images/main_profile_img.svg";
import status_available from "../../assets/images/headerIcons/status_available.svg";
import pro_info_img1 from "../../assets/images/pro_info_img1.svg";
import pro_info_img2 from "../../assets/images/pro_info_img2.svg";
import pro_info_img3 from "../../assets/images/pro_info_img3.svg";
import doc_img1 from "../../assets/images/doc_img1.svg";
import doc_img2 from "../../assets/images/doc_img2.svg";
import doc_img3 from "../../assets/images/doc_img3.svg";
import sign_icon from "../../assets/images/icons/sign_icon.svg";
import exp_icon from "../../assets/images/icons/exp_icon.svg";
import doc_img4 from "../../assets/images/doc_img4.svg";
import doc_img5 from "../../assets/images/doc_img5.svg";
import doc_img6 from "../../assets/images/doc_img6.svg";
import docs_icon from "../../assets/images/icons/docs_icon.svg";
import docs_icon_blue from "../../assets/images/icons/docs_icon_blue.svg";
import round_delete_icon from "../../assets/images/icons/round_delete_icon.svg";
import edit_icon from "../../assets/images/icons/edit_icon.svg";
import signature from "../../assets/images/signature.svg";
import { motion } from "framer-motion";
import { DarkButton, DonutChart } from "../commonComponents/commonComp";
import upload_icon from "../../assets/images/icons/upload_icon.svg";
import { useNavigate } from "react-router-dom";
import { Nav, NavItem, Tab } from "react-bootstrap";
import img_preview_delete_icon from "../../assets/images/icons/img_preview_delete_icon.svg";
import ApiConfig from "../../api/ApiConfig";
import {
  PutCallWithErrorResponse,
  deleteWithAuthCall,
  getWithAuthCall,
  multipartPostCall,
  simpleGetCall,
  simplePostCall,
} from "../../api/ApiServices";
import DragandDropImages from "../../sharedComponent/DragandDropImages";
import { AppContext } from "../../context/AppContext";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import Select from "react-select";
import { Autocomplete } from "@react-google-maps/api";
import AutocompleteGoogleInput from "../../sharedComponent/AutocompleteGoogleInput";
import TimePicker from "react-time-picker";

const MyProfile = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [generalInfo, setGeneralInfo] = useState(false);
  const handleGeneralInfo = () => {
    setGeneralInfo(!generalInfo);
  };
  const [addInput, setAddInput] = useState(false);
  const handleAddInput = () => {
    setAddInput(!addInput);
  };
  const [addInput2, setAddInput2] = useState(false);
  const handleAddInput2 = () => {
    setAddInput2(!addInput2);
  };
  const [clinicEdit, setClinicEdit] = useState(false);

  const [teamEdit, setTeamEdit] = useState(false);
  const handleTeamEdit = () => {
    setTeamEdit(!teamEdit);
  };
  const [docEdit, setDocEdit] = useState(false);
  const handleDocEdit = () => {
    setDocEdit(!docEdit);
  };
  const [docEdit2, setDocEdit2] = useState(false);
  const [bankEdit, setbankEdit] = useState(false);
  const handleDocEdit2 = () => {
    setDocEdit2(!docEdit2);
  };
  const [updateData, setUpdateData] = useState({
    doctor_name: "",
    doctor_age: "",
    doctor_gender: "",
    doctor_contact_number: "",
    doctor_email: "",
    doctor_registration_no: "",
  });
  console.log("updateData", updateData);
  const handlebank = () => {
    setbankEdit(!bankEdit);
    if (bankEdit) {
      addBAnk();
    }
  };

  const [manageModal, setManageModal] = useState(1);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const [checkedItems, setCheckedItems] = useState({});
  const {
    customerData,
    ProfileHader,
    getProfileHaderDetails,
    updateImg,
    setUpdateImg,
  } = useContext(AppContext);

  const [EducationType, setEducationType] = useState([]);

  const [selectedObj, setSelectedObj] = useState([]);
  const [ProfileBasci, setProfileBasci] = useState({});
  const [GeneralInf, setGeneralInf] = useState({
    specialization: "",
  });
  const [GeneralSpecialInf, setGeneralSpecialInf] = useState([]);
  const [SpilizationType, setSpilizationType] = useState([]);

  const [ClinicInf, setClinicInf] = useState([]);
  console.log("ClinicInf,", ClinicInf);
  const [ClinicTeam, setClinicTeam] = useState([]);
  const [Documentlist, setDocumentlist] = useState([]);
  const [Signatrelist, setSignatrelist] = useState([]);
  const [LangugeType, setLangugeType] = useState([]);
  const [LangugeID, setLangugeID] = useState([]);
  const [BankDetails, setBankDetails] = useState([]);
  const [currentTab, setCurrentTab] = useState("1");
  const [editClicked, setEditClicked] = useState(false);
  const [GeneralClicked, setGeneralClicked] = useState(false);
  const [FacilitiesType, setFacilitiesType] = useState([]);

  const [UploadDoc, setUploadDoc] = useState({
    documentsPath: [],
  });
  const [UploadDocoument, setUploadDocoument] = useState([]);
  const [updateClinicPayload, setUpdateClinicPayload] = useState({
    doctor_clinic_id: 9,
    doctor_clinic_name: "",
    clinic_facility_ids: "",
    doctor_clinic_address: "",
    longitude: "",
    latitude: "",
    clinicPhotos: [],
  });
  console.log("updateClinicPayload,", updateClinicPayload);
  const facilityIdsToFilter = updateClinicPayload?.clinic_facility_ids
    .split(",")
    .map(Number);
  // UPDATE_CLINIC_INFO
  // simplePostCall
  const convertInFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      if (Array.isArray(object[key])) {
        object[key].forEach((item, index) => {
          formData.append(`${key}`, item);
        });
      } else {
        formData.append(key, object[key]);
      }
    });
    return formData;
  };

  const updateClinicInfo = async () => {
    try {
      const clinicFormData = convertInFormData(updateClinicPayload);
      const res = await multipartPostCall(
        ApiConfig.UPDATE_CLINIC_INFO,
        clinicFormData
      );
      console.log("updateClinicInfo res,", res);
      if (res.success) {
        getProfileCliniclInfDetails();
      }
    } catch (error) {
      console.log("catch error response,", error);
    }
  };

  useEffect(() => {
    getProfileBasicDetails();
    getProfileGeneralInfDetails();
    getProfileCliniclInfDetails();
    getProfileTeamDetails();
    getDocumentDetails();
    getSignatreDetails();
    getlangugae();
    getBAnkDetails();
    getSpeciclizationlist();
    getEducation();
    getProfileHaderDetails();
    getfacilities();
  }, []);

  function getfacilities() {
    getWithAuthCall(ApiConfig.CLINCE_FACILITIES_DROPDOWN)
      .then((data) => {
        let Type = data.data;
        var grades = [];
        Type.sort((a, b) =>
          a.clinic_facility.localeCompare(b.clinic_facility)
        ).map((grade, index) => {
          grades.push({
            label: grade.clinic_facility,
            value: grade.clinic_facility_id,
          });
        });
        setFacilitiesType(grades);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  function getSpeciclizationlist() {
    getWithAuthCall(ApiConfig.SPECICLIZATION_DROPDOWN)
      .then((data) => {
        setSpilizationType(data.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const handleNewImg = (dataUrl) => {
    window.open(dataUrl, "_blank");
  };

  /// basic Api
  const getProfileBasicDetails = () => {
    simpleGetCall(ApiConfig.PROFILE_BASIC_INF + customerData.doctor_id)
      .then((res) => {
        if (res.success) {
          setProfileBasci(res.data);
          setUploadDoc({
            documentsPath: [],
          });
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  //General Information
  const getProfileGeneralInfDetails = () => {
    simpleGetCall(ApiConfig.PROFILE_GENERAL_INF + customerData.doctor_id)
      .then((res) => {
        if (res.success) {
          setGeneralInf(res.data);
          setGeneralSpecialInf(res.data?.specializations);
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  //Clinic Information
  const getProfileCliniclInfDetails = () => {
    simpleGetCall(ApiConfig.PROFILE_CLINIC_INF + customerData.doctor_id)
      .then((res) => {
        if (res.success) {
          setClinicInf(res.data);
          setClinicEdit(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const getProfileTeamDetails = () => {
    // setLoading(true);

    simpleGetCall(ApiConfig.PROFILE_GET_TEAM + customerData.doctor_id)
      .then((res) => {
        console.log(res);
        if (res.sucess) {
          setClinicTeam(res.data);
        } else {
          setTeamEdit(!teamEdit);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  /// Get Document
  const getDocumentDetails = () => {
    // setLoading(true);

    simpleGetCall(ApiConfig.GET_DOCUMENT + customerData.doctor_id)
      .then((res) => {
        if (res.sucess) {
          setDocumentlist(res.data);
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  /// Get Document

  const getSignatreDetails = () => {
    // setLoading(true);

    simpleGetCall(ApiConfig.GET_DOCUMENT_signature + customerData.doctor_id)
      .then((res) => {
        if (res.success) {
          setSignatrelist(res.data);
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  /// // Bank Details
  const getBAnkDetails = () => {
    // setLoading(true);

    simpleGetCall(ApiConfig.BANK_DETALIS_PROFILE + customerData.doctor_id)
      .then((res) => {
        if (res.success) {
          setBankDetails(res.data);
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    // if (UploadDoc) {

    postaddClinice();

    // }
  }, [UploadDoc]);

  const postaddClinice = () => {
    // setLoading(true);

    let formData = new FormData();
    formData.append("doctor_id", customerData.doctor_id);

    UploadDoc.documentsPath.map((img) => {
      formData.append("signature", img);
    });

    multipartPostCall(ApiConfig.UPDATE_DOCUMENT_SIGNICAR, formData)
      .then((res) => {
        if (res.success) {
          getSignatreDetails();
        } else {
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    // if (UploadDoc) {
    // postDocument();
    // }
  }, [UploadDocoument]);
  const postDocument = () => {
    // setLoading(true);

    let formData = new FormData();
    formData.append("doctor_id", customerData.doctor_id);

    UploadDocoument.map((img) => {
      formData.append("documents", img);
    });

    multipartPostCall(ApiConfig.DOCUMENT_UPDATE, formData)
      .then((res) => {
        if (res.success) {
          getDocumentDetails();
          setUploadDocoument([]);
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        // setLoading(false);
      });
  };
  /// Delete Doucment
  const DeleteDocument = (id) => {
    // setLoading(true);

    deleteWithAuthCall(ApiConfig.DELETE_TEAM + id)
      .then((res) => {
        if (res.success) {
          // setSignatrelist(res.data);
          getProfileTeamDetails();
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  function getlangugae() {
    getWithAuthCall(
      ApiConfig.LANGUAGE_DROPDOWN + "?doctor_id=" + customerData.doctor_id
    )
      .then((data) => {
        console.log(data);
        let Type = data.data;
        var grades = [];

        // Type.map((grade, index) => {
        //   grades.push({
        //     label: grade.language,
        //     value: grade.language_id,
        //   });
        // });
        setLangugeType(Type);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  // Function to handle checkbox toggle
  useEffect(() => {
    var selected_topics = [];
    if (selectedObj) {
      selectedObj.map((topic, index) => {
        selected_topics.push(topic.language_id);
      });
      // UpdateLanguage(selected_topics)
    }
    // console.log("selected_topics",selected_topics);
    setLangugeID(selected_topics);
  }, [selectedObj]);

  useEffect(() => {
    // UpdateLanguage()
  }, [LangugeID]);

  const handleCheckboxChange = (index, data) => {
    // setSelectedObj([...selectedObj, data]);
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [index]: !prevCheckedItems[index],
    }));
    if (selectedObj.includes(data)) {
      setSelectedObj((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== data)
      );
    } else {
      // If it's not checked, add it to the array
      setSelectedObj((prevSelectedItems) => [...prevSelectedItems, data]);
    }
  };

  const UpdateLanguage = (selected_topics) => {
    let newRequestBody = JSON.stringify({
      doctor_id: customerData.doctor_id,
      language: LangugeID,
    });
    simplePostCall(ApiConfig.LANGUAGE_UPDATE, newRequestBody)
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res?.message);
          getlangugae();
          // setLangugeID([]);
          // setSelectedObj([]);
        } else {
          //   // setLangugeID([])
          //   // setSelectedObj([])
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const [Bankdentials, setBankdentials] = useState({
    bank_name: "",
    account_number: "",
    account_holder_name: "",
    ifsc_code: "",
  });

  const addBAnk = () => {
    simplePostCall(
      ApiConfig.BANK_DETALIS,
      JSON.stringify({ ...Bankdentials, doctor_id: customerData.doctor_id })
    )
      .then((res) => {
        if (res.success) {
          // navigate('/ClinicSchedule')
          getBAnkDetails();
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changeEveVal = (e) => {
    setProfileBasci({
      ...ProfileBasci,
      [e.target.name]: e.target.value,
    });
  };

  const changeEveValBasc = (e) => {
    setGeneralInf({
      ...GeneralInf,
      [e.target.name]: e.target.value,
    });
  };

  const updateApiData = () => {
    let payload = {
      doctor_id: customerData.doctor_id,
      name: ProfileBasci?.doctor_name,
      age: ProfileBasci?.doctor_age,
      gender: ProfileBasci?.doctor_gender,
      doctor_contact_number: ProfileBasci?.doctor_contact_number,
      doctor_email: ProfileBasci?.doctor_email,
      registration: ProfileBasci?.doctor_registration_no,
    };
    simplePostCall(ApiConfig.GET_BASE_INFO_UPDATE, JSON.stringify(payload))
      .then((res) => {
        if (res.success === true) {
          // notifySuccess(res.message);
          setEditClicked(false);
          getProfileGeneralInfDetails();
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateApiDataBace = () => {
    let payload = {
      doctor_id: customerData.doctor_id,
      specialization_ids: GeneralInf?.specialization,
      education: GeneralInf?.education,
      experience: GeneralInf?.experience,
      service: GeneralInf?.services,
    };
    simplePostCall(ApiConfig.GET_GENRAIL_IFFO_UPDATE, JSON.stringify(payload))
      .then((res) => {
        if (res.success === true) {
          // notifySuccess(res.message);
          setGeneralClicked(false);
          getProfileGeneralInfDetails();
        } else {
          // notifyError(res.message)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getEducation() {
    getWithAuthCall(ApiConfig.EDCATION_DROPDWN)
      .then((data) => {
        console.log(data);

        setEducationType(data.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const changeProfile = (image) => {
    let formData = new FormData();

    formData.append("doctor_id", updateImg.doctor_id);
    formData.append("profile", image);
    multipartPostCall(ApiConfig.PROFILE_UPDATE_IMAGE, formData)
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          getProfileHaderDetails();
        }
      })
      .catch((err) => console.log(err));
  };
  const [selected, setSelected] = useState(null);
  function onTopicChange(selectedOption) {
    var selected_topics = [];

    if (selectedOption.length > 0) {
      selectedOption.map((topic, index) => {
        selected_topics.push(topic.value);
      });
    }
    console.log("sxsx selected_topics", selected_topics);
    setUpdateClinicPayload({
      ...updateClinicPayload,
      clinic_facility_ids: selected_topics.join(","),
    });
  }

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      marginTop: "10px",
      padding: "4px",
      border: "1px dashed #34528A",
      borderRadius: "10px",
      //  backgroundColor: 'white',
    }),
    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   const color = chroma(data.color);
    //   return {
    //     ...styles,
    //     backgroundColor: isDisabled ? 'red' : blue,
    //     color: '#FFF',
    //     cursor: isDisabled ? 'not-allowed' : 'default',
    //   }
    // },
  };

  const autocompleteRef = useRef(null);
  const handlePlaceSelect = () => {
    const autocompleteInstance = autocompleteRef.current;
    const place = autocompleteInstance?.getPlace();
    const lat = place?.geometry?.location.lat();
    const lng = place?.geometry?.location.lng();
    const address = place?.formatted_address;
    console.log("locationData", lat, lng, address);
    setUpdateClinicPayload({
      ...updateClinicPayload,
      doctor_clinic_address: address,
      latitude: lat,
      longitude: lng,
    });
    // actionType && setTypingValue(actionType);
    // if (onPlaceSelect) {
    //   onPlaceSelect({ lat, lng, address }, event, currentIndex, name);
    // }
  };

  //  ===================== UPDATE SCHEDULE =================
  const { setLoading } = useContext(AppContext);
  const [docClinicId, setDocClinicId] = useState("");

  const [value, onChange] = useState("08:01");
  const [value1, onChange1] = useState("11:01");

  const [value2, onChange2] = useState("13:01");
  const [value3, onChange3] = useState("20:01");

  const [value4, onChange4] = useState("08:01");
  const [value5, onChange5] = useState("11:01");

  const [value6, onChange6] = useState("13:01");
  const [value7, onChange7] = useState("20:01");
  const [Both, setBoth] = useState(false);

  const [ClinicSchedule, setClinicSchedule] = useState({
    is_weekend_mon: false,
    is_weekend_tue: false,
    is_weekend_wed: false,
    is_weekend_thu: false,
    is_weekend_fri: false,
    is_weekend_sat: false,
    is_weekend_sun: false,
    is_morning_shift: false,
    is_evening_shift: false,

    slot_morning_end_time: "",
    slot_morning_no_of_appointments: "0",
    slot_evening_no_of_appointments: "0",
    slot_morning_doctor_charge: "0",

    slot_evening_end_time: "",
    slot_evening_doctor_charge: "0",
    clinic_morning_start_time: "",
    clinic_evening_start_time: "",
    clinic_evening_end_time: "",
    is_both: false,
  });

  console.log("ClinicSchedule", ClinicSchedule);

  const getClinicScheduleDetails = async (doctorClinicId) => {
    try {
      const res = await simpleGetCall(
        `${ApiConfig.GET_CLINIC_SCHEDULE_DETAILS}/${doctorClinicId}`
      );
      const { success, data } = res;
      if (success && data) {
        setDocClinicId(doctorClinicId);
        setClinicSchedule({
          ...ClinicSchedule,
          is_weekend_mon: data?.is_weekend_mon ? data?.is_weekend_mon : false,
          is_weekend_tue: data?.is_weekend_tue ? data?.is_weekend_tue : false,
          is_weekend_wed: data?.is_weekend_wed ? data?.is_weekend_wed : false,
          is_weekend_thu: data?.is_weekend_thu ? data?.is_weekend_thu : false,
          is_weekend_fri: data?.is_weekend_fri ? data?.is_weekend_fri : false,
          is_weekend_sat: data?.is_weekend_sat ? data?.is_weekend_sat : false,
          is_weekend_sun: data?.is_weekend_sun ? data?.is_weekend_sun : false,
          is_morning_shift: data?.is_morning_shift
            ? data?.is_morning_shift
            : false,
          is_evening_shift: data?.is_evening_shift
            ? data?.is_evening_shift
            : false,

          slot_morning_no_of_appointments:
            data?.slot_morning_no_of_appointments || "0",
          slot_evening_no_of_appointments:
            data?.slot_evening_no_of_appointments || "0",
          slot_morning_doctor_charge: data?.slot_morning_doctor_charge || "0",
          slot_evening_doctor_charge: data?.slot_evening_doctor_charge || "0",
        });
      }

      onChange(data.slot_morning_start_time || "");
      onChange1(data.slot_morning_end_time || "");

      onChange2(data.slot_evening_start_time || "");
      onChange3(data.slot_evening_end_time || "");

      onChange4(data.clinic_morning_start_time || "");
      onChange5(data.clinic_morning_end_time || "");

      onChange6(data.clinic_evening_start_time || "");
      onChange7(data.clinic_evening_end_time || "");

      const isBoth =
        data.is_evening_shift && data.is_morning_shift ? true : false;
      setBoth(isBoth);
    } catch (error) {
      console.error("catch error", error);
    }
  };

  const updateScheduleDetails = async () => {
    if (!docClinicId) {
      notifyError("Please select your clinic");
      return;
    }
    try {
      setLoading(true);
      const res = await PutCallWithErrorResponse(
        ApiConfig.UPDATE_CLINIC_SCHEDULE_DETAILS,
        JSON.stringify({
          ...ClinicSchedule,
          doctor_clinic_id: docClinicId,
          slot_morning_start_time: value + " " + "ISD",
          slot_morning_end_time: value1 + " " + "ISD",
          slot_evening_start_time: value2 + " " + "ISD",
          slot_evening_end_time: value3 + " " + "ISD",
          clinic_morning_start_time: value4 + " " + "ISD",
          clinic_morning_end_time: value5 + " " + "ISD",
          clinic_evening_start_time: value6 + " " + "ISD",
          clinic_evening_end_time: value7 + " " + "ISD",
          is_both: Both,
        })
      );
      const { success, message } = res?.json;
      if (success) {
        notifySuccess(message);
        navigate("/MyProfile");
      }
    } catch (error) {
      console.log("catch error of update schedule details,", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.3 }}
      className="myprofile_wrapper"
      id="cx-main"
    >
      <div className="myprofile">
        <div className="header_wrapper">
          <div className="header_top"></div>
          <div className="header_bottom">
            <img src={ProfileHader?.doctor_profile_image_path} alt="" />
            <div className="iconHolder">
              <label htmlFor="fileTag">
                <input
                  type="file"
                  onChange={(e) => {
                    // setUpdateImg({ ...updateImg, profilePath: e.target.files[0] });
                    changeProfile(e.target.files[0]);
                    // changeProfile()
                  }}
                  id="fileTag"
                  className="d-none"
                />
                <img
                  src={edit_icon}
                  style={{ cursor: "pointer" }}
                  className="editIcon"
                />
              </label>
            </div>
            <div className="profile_name">
              <p className="name">
                {ProfileHader?.doctor_name}{" "}
                <img src={status_available} alt="" />
              </p>
              <p className="post">{ProfileHader?.specializations}</p>
            </div>
          </div>

          <div className="profile_info_wrapper">
            <div className="daw_tabs">
              <Tab.Container defaultActiveKey="first">
                <div className="row">
                  <div className="col-lg-12">
                    <Nav
                      variant="pills"
                      className=""
                      onSelect={(selectedKey) =>
                        setCurrentTab(`${selectedKey}`)
                      }
                    >
                      <div className="d-flex justify-content-around w-100">
                        <Nav.Item>
                          <Nav.Link eventKey="first">Profile</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">
                            Language Settings
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">Bank Details</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fourth">Update Schedule</Nav.Link>
                        </Nav.Item>
                      </div>
                    </Nav>
                  </div>
                  <div className="col-lg-12">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="profile_info_wrapper_inner">
                          <div className="info_title_wrapper">
                            <h3 className="info_title">Basic Information</h3>

                            {editClicked ? (
                              <img
                                src={edit_icon}
                                onClick={() => setEditClicked(false)}
                                alt=""
                              />
                            ) : (
                              <img
                                src={edit_icon}
                                onClick={() => setEditClicked(true)}
                                alt=""
                              />
                            )}
                          </div>
                          <div className="profile_info_fields">
                            <div className="row mb-4">
                              <div className="col-lg-3">
                                <label htmlFor="">Name</label>
                                {editClicked ? (
                                  <input
                                    type="text"
                                    name="doctor_name"
                                    onChange={changeEveVal}
                                    value={ProfileBasci.doctor_name}
                                    className="form-control editedControl"
                                  />
                                ) : (
                                  <p className="info_text">
                                    {ProfileBasci.doctor_name}
                                  </p>
                                )}
                              </div>
                              <div className="col-lg-3">
                                <label htmlFor="">Age</label>
                                <p className="info_text">
                                  {" "}
                                  {/* {ProfileBasci.doctor_age}yrs */}
                                  {editClicked ? (
                                    <input
                                      type="text"
                                      name="doctor_age"
                                      onChange={changeEveVal}
                                      value={ProfileBasci.doctor_age}
                                      className="form-control editedControl"
                                    />
                                  ) : (
                                    <p className="info_text">
                                      {ProfileBasci.doctor_age}
                                    </p>
                                  )}
                                </p>
                              </div>
                              <div className="col-lg-3">
                                <label htmlFor="">Gender</label>
                                <p className="info_text">
                                  {editClicked ? (
                                    <input
                                      type="text"
                                      name="doctor_gender"
                                      onChange={changeEveVal}
                                      value={ProfileBasci.doctor_gender}
                                      className="form-control editedControl"
                                    />
                                  ) : (
                                    <p className="info_text">
                                      {ProfileBasci.doctor_gender}
                                    </p>
                                  )}
                                </p>
                              </div>
                              <div className="col-lg-3">
                                <label htmlFor="">Contact Number</label>
                                <p className="info_text">
                                  {/* {ProfileBasci.doctor_contact_number} */}
                                  {editClicked ? (
                                    <input
                                      type="text"
                                      name="doctor_contact_number"
                                      onChange={changeEveVal}
                                      value={ProfileBasci.doctor_contact_number}
                                      className="form-control editedControl"
                                    />
                                  ) : (
                                    <p className="info_text">
                                      {ProfileBasci.doctor_contact_number}
                                    </p>
                                  )}
                                </p>
                              </div>
                              <div className="col-lg-3">
                                <label htmlFor="">Email</label>
                                <p className="info_text">
                                  {/* {ProfileBasci.doctor_email} */}
                                  {editClicked ? (
                                    <input
                                      type="text"
                                      name="doctor_email"
                                      onChange={changeEveVal}
                                      value={ProfileBasci.doctor_email}
                                      className="form-control editedControl"
                                    />
                                  ) : (
                                    <p className="info_text">
                                      {ProfileBasci.doctor_email}
                                    </p>
                                  )}
                                </p>
                              </div>
                              <div className="col-lg-3">
                                <label htmlFor="">Registration No.</label>
                                <p className="info_text">
                                  {/* {ProfileBasci.doctor_registration_no} */}
                                  {editClicked ? (
                                    <input
                                      disabled
                                      type="text"
                                      name="doctor_registration_no"
                                      onChange={changeEveVal}
                                      value={
                                        ProfileBasci.doctor_registration_no
                                      }
                                      className="form-control editedControl"
                                    />
                                  ) : (
                                    <p className="info_text">
                                      {ProfileBasci.doctor_registration_no}
                                    </p>
                                  )}
                                </p>
                              </div>
                              {/* <div className="col-lg-3">
                                <label htmlFor="">Known Languages</label>
                                {ProfileBasci.language &&
                                ProfileBasci.language.length > 0 ? (
                                  ProfileBasci.language.map(
                                    (itemlist, index) => {
                                      return (
                                        <p className="info_text">
                                          {itemlist.language}
                                        </p>
                                      );
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </div> */}
                            </div>
                            {editClicked ? (
                              <>
                                <div className="btn_wrapper">
                                  <DarkButton
                                    onClick={() => setEditClicked(false)}
                                    classbtn={
                                      "cx-btn-light btn-width-50 font-14"
                                    }
                                    btn={"Cancel"}
                                  />
                                  <DarkButton
                                    classbtn={
                                      "cx-btn-dark  btn-width-50 font-14"
                                    }
                                    onClick={updateApiData}
                                    btn={"Update"}
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="info_title_wrapper">
                            <h3 className="info_title">General Information</h3>
                            {/* <img
                              src={edit_icon}
                              alt=""
                              onClick={handleGeneralInfo}
                            /> */}
                            {GeneralClicked ? (
                              <img
                                src={edit_icon}
                                onClick={() => setGeneralClicked(false)}
                                alt=""
                              />
                            ) : (
                              <img
                                src={edit_icon}
                                onClick={() => setGeneralClicked(true)}
                                alt=""
                              />
                            )}
                          </div>

                          {/* {generalInfo ?
                            <div className="profile_info_fields profile_info_field_state_change mb-3">
                              <div className="row">
                                <div className="col-lg-3">
                                  <label htmlFor="">Specializationsss</label>
                                  <ul>
                                  {GeneralInf.specializations
 && GeneralInf.specializations
 .length > 0 ? (
                            GeneralInf.specializations
                            .map((itemlist, index) => {
                              return (
                                    <li>{itemlist.specialization} <img src={round_delete_icon} alt="" /></li>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                                    <li>
                                      {addInput ?
                                        <>
                                          <button className="add_new_btn" onClick={handleAddInput}>+ Add New</button>
                                          <input type="text" className="form-control" />
                                        </>
                                        :
                                        <button className="add_new_btn" onClick={handleAddInput}>+ Add New</button>
                                      }
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-lg-3">
                                  <label htmlFor="">Services</label>
                                  <ul>
                                    <li>Service-1<img src={round_delete_icon} alt="" /></li>
                                    <li>Service-2<img src={round_delete_icon} alt="" /></li>
                                    <li>Service-3<img src={round_delete_icon} alt="" /></li>
                                    <li>Service-4<img src={round_delete_icon} alt="" /></li>
                                    <li>Service-5<img src={round_delete_icon} alt="" /></li>
                                    <li>
                                      {addInput2 ?
                                        <>
                                          <button className="add_new_btn" onClick={handleAddInput2}>+ Add New</button>
                                          <input type="text" className="form-control" />
                                        </>
                                        :
                                        <button className="add_new_btn" onClick={handleAddInput2}>+ Add New</button>
                                      }
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-lg-3">
                                  <label htmlFor="">Experience</label>
                                  <p className="info_text">4+ Years</p>
                                </div>
                                <div className="col-lg-3">
                                  <label htmlFor="">Education</label>
                                  <p className="info_text">
                                    Orthopedic & Research
                                  </p>
                                </div>
                              </div>
                            </div> : */}

                          <div className="profile_info_fields mb-3">
                            <div className="row">
                              <div className="col-lg-3">
                                <label htmlFor="">Specialization</label>
                                {GeneralClicked ? (
                                  <select
                                    className="form-select editedControl"
                                    value={GeneralInf.specialization}
                                    onChange={(e) => {
                                      setGeneralInf({
                                        ...GeneralInf,
                                        specialization: e.target.value,
                                      });
                                    }}
                                  >
                                    {SpilizationType &&
                                      SpilizationType.length &&
                                      SpilizationType.map((Typelist, index) => {
                                        return (
                                          <>
                                            {/* <option value="select">Enter Your vehicle Name</option> */}
                                            <option
                                              key={"Typelist" + index}
                                              value={Typelist.specialization_id}
                                            >
                                              {Typelist.specialization}
                                            </option>
                                          </>
                                        );
                                      })}
                                  </select>
                                ) : (
                                  <ul>
                                    {GeneralSpecialInf &&
                                    GeneralSpecialInf.length > 0 ? (
                                      GeneralSpecialInf.map(
                                        (itemlist, index) => {
                                          return (
                                            <>
                                              <li>{itemlist.specialization}</li>
                                            </>
                                          );
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </ul>
                                )}
                              </div>
                              <div className="col-lg-3">
                                <label htmlFor="">Services</label>
                                <ul className="ulList">
                                  {/* <li>{GeneralInf.services}</li> */}
                                  {GeneralClicked ? (
                                    <input
                                      type="text"
                                      name="services"
                                      onChange={changeEveValBasc}
                                      value={GeneralInf.services}
                                      className="form-control editedControl"
                                    />
                                  ) : (
                                    <p className="info_text">
                                      {GeneralInf.services}
                                    </p>
                                  )}
                                </ul>
                              </div>
                              <div className="col-lg-3">
                                <label htmlFor="">Experience</label>
                                <p className="info_text">
                                  {/* {GeneralInf.experience} */}
                                  {GeneralClicked ? (
                                    <input
                                      type="text"
                                      name="experience"
                                      onChange={changeEveValBasc}
                                      value={GeneralInf.experience}
                                      className="form-control editedControl"
                                    />
                                  ) : (
                                    <p className="info_text">
                                      {GeneralInf.experience}
                                    </p>
                                  )}
                                </p>
                              </div>
                              <div className="col-lg-3">
                                <label htmlFor="">Education</label>
                                <p className="info_text">
                                  {/* {GeneralInf.education} */}
                                  {GeneralClicked ? (
                                    <select
                                      className="form-select formInput"
                                      value={GeneralInf.education}
                                      onChange={(e) => {
                                        setGeneralInf({
                                          ...GeneralInf,
                                          education: e.target.value,
                                        });
                                      }}
                                    >
                                      {EducationType &&
                                        EducationType.length &&
                                        EducationType.map((Typelist, index) => {
                                          return (
                                            <>
                                              {/* <option value="select">Enter Your vehicle Name</option> */}
                                              <option
                                                key={"Typelist" + index}
                                                value={Typelist.education_id}
                                              >
                                                {Typelist.education}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </select>
                                  ) : (
                                    <p className="info_text">
                                      {GeneralInf.education}
                                    </p>
                                  )}
                                </p>
                              </div>
                              {GeneralClicked ? (
                                <>
                                  <div className="btn_wrapper">
                                    <DarkButton
                                      classbtn={
                                        "cx-btn-light btn-width-50 font-14"
                                      }
                                      onClick={() => setGeneralClicked(false)}
                                      btn={"Cancel"}
                                    />
                                    <DarkButton
                                      classbtn={
                                        "cx-btn-dark  btn-width-50 font-14"
                                      }
                                      onClick={updateApiDataBace}
                                      btn={"Update"}
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>

                          {/* } */}
                          <div className="info_title_wrapper">
                            <h3 className="info_title">Clinic Information</h3>
                            {/* <img
                              src={edit_icon}
                              alt=""
                              onClick={() => setClinicEdit(!clinicEdit)}
                            /> */}
                          </div>
                          <div className="profile_info_fields">
                            {ClinicInf && ClinicInf.length > 0 ? (
                              ClinicInf.map((itemlist, index) => {
                                return (
                                  <div
                                    className="row"
                                    // onClick={() => {
                                    //   navigate("/ClinicSchedule");
                                    //   sessionStorage.setItem(
                                    //     "doctor_clinic_id",
                                    //     itemlist.doctor_clinic_id
                                    //   );
                                    // }}
                                  >
                                    <div className="col-lg-3">
                                      <label htmlFor="">Clinic Name</label>
                                      {clinicEdit ? (
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={
                                            updateClinicPayload?.doctor_clinic_name
                                          }
                                          onChange={(e) => {
                                            setUpdateClinicPayload({
                                              ...updateClinicPayload,
                                              doctor_clinic_name:
                                                e.target.value,
                                            });
                                          }}
                                        />
                                      ) : (
                                        <p className="info_text">
                                          {itemlist.doctor_clinic_name}
                                        </p>
                                      )}
                                    </div>
                                    <div className="col-lg-3">
                                      <label htmlFor="">Facilities</label>
                                      {clinicEdit ? (
                                        <>
                                          <Select
                                            className=""
                                            isMulti
                                            options={FacilitiesType}
                                            value={FacilitiesType.filter(
                                              (ele) =>
                                                facilityIdsToFilter?.includes(
                                                  ele?.value
                                                )
                                            )}
                                            onChange={onTopicChange}
                                            autoFocus={true}
                                            // placeholder={"Select Languages..."}
                                            placeholder={
                                              <div className="select-placeholder-text">
                                                Select Facilities...
                                              </div>
                                            }
                                            styles={colourStyles}
                                          />
                                          {/* <div className="result">
                                            {selected?.map((item, i) => {
                                              return <p></p>;
                                            })}
                                          </div> */}
                                        </>
                                      ) : (
                                        <ul>
                                          {itemlist.facilitiesString &&
                                          itemlist.facilitiesString.length >
                                            0 ? (
                                            itemlist.facilitiesString.map(
                                              (itemlistString, index) => {
                                                return (
                                                  <li>
                                                    {
                                                      itemlistString.clinic_facility
                                                    }
                                                  </li>
                                                );
                                              }
                                            )
                                          ) : (
                                            <></>
                                          )}
                                          {/* <li>
                                      {clinicEdit ?
                                        <>
                                          <button className="add_new_btn2" onClick={handleAddInput}>+ Add New</button>
                                          <input type="text" className="form-control" />
                                        </>
                                        :
                                        <button className="add_new_btn2" onClick={handleAddInput}>+ Add New</button>
                                      }</li> */}
                                        </ul>
                                      )}
                                    </div>
                                    <div className="col-lg-3">
                                      <label htmlFor="">Address</label>
                                      {clinicEdit ? (
                                        <AutocompleteGoogleInput
                                          required={true}
                                          className={"form-control"}
                                          placeholder={"Please enter address"}
                                          currentIndex={index}
                                          selectedValue={
                                            updateClinicPayload?.doctor_clinic_address
                                          }
                                          onPlaceSelect={({
                                            lat,
                                            lng,
                                            address,
                                          }) => {
                                            const locationData = {
                                              lat,
                                              lng,
                                              address,
                                            };
                                            setUpdateClinicPayload({
                                              ...updateClinicPayload,
                                              doctor_clinic_address:
                                                locationData?.address,
                                              latitude: locationData?.lat,
                                              longitude: locationData?.lng,
                                            });
                                          }}
                                        />
                                      ) : (
                                        // <Autocomplete
                                        //   onLoad={(auto) =>
                                        //     (autocompleteRef.current = auto)
                                        //   }
                                        //   onPlaceChanged={handlePlaceSelect}
                                        // >
                                        //   <input
                                        //     type="area"
                                        //     className="form-control"
                                        //     defaultValue={
                                        //       updateClinicPayload?.doctor_clinic_address
                                        //     }
                                        //   />
                                        // </Autocomplete>
                                        <p className="info_text">
                                          {itemlist.doctor_clinic_address}
                                        </p>
                                      )}
                                    </div>

                                    <div className="col-lg-3 d-flex justify-content-between">
                                      <div className="info_img_wrapper">
                                        <div className="d-flex flex-column">
                                          <label htmlFor="">Photos</label>
                                          {clinicEdit ? (
                                            <>
                                              <div className="file-input">
                                                <img
                                                  src={sign_icon}
                                                  className="mailImg2"
                                                  alt=""
                                                />
                                                <label
                                                  className="file-input__label2"
                                                  for="file-input2"
                                                >
                                                  {/* Upload clinic photos */}
                                                  <DragandDropImages
                                                    setFile={(files) => {
                                                      //   const tempArr = [...updateClinicPayload.clinicPhotos]
                                                      //  const filteredArr = tempArr.filter(image => !image.doctor_clinic_image_id)
                                                      //   console.log("filteredArr,", filteredArr)
                                                      // if(!updateClinicPayload.clinicPhotos.some(image => image.doctor_clinic_image_id)) {
                                                      setUpdateClinicPayload({
                                                        ...updateClinicPayload,
                                                        clinicPhotos: [
                                                          ...updateClinicPayload.clinicPhotos,
                                                          files[0],
                                                        ],
                                                      });
                                                      // }
                                                    }}
                                                  />
                                                </label>
                                              </div>
                                              <div className="inputBox">
                                                <div
                                                  className="clinic_images_wrapper"
                                                  style={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                  }}
                                                >
                                                  {updateClinicPayload.clinicPhotos.map(
                                                    (img, index) => {
                                                      return (
                                                        <div
                                                          style={{
                                                            position:
                                                              "relative",
                                                            marginRight: "10px",
                                                            marginBottom:
                                                              "10px",
                                                            marginTop: "10px",
                                                          }}
                                                          className="img_item"
                                                          key={"zsdvsv"}
                                                        >
                                                          <img
                                                            className="product_img"
                                                            src={
                                                              img?.name
                                                                ? URL.createObjectURL(
                                                                    img
                                                                  )
                                                                : img.doctor_clinic_image_path
                                                            }
                                                            alt="im"
                                                          />
                                                          <span
                                                            style={{
                                                              position:
                                                                "absolute",
                                                              right: "2px",
                                                            }}
                                                          >
                                                            <img
                                                              src={
                                                                img_preview_delete_icon
                                                              }
                                                              onClick={() => {
                                                                const filteredPhotos =
                                                                  updateClinicPayload.clinicPhotos.filter(
                                                                    (item) => {
                                                                      console.log(
                                                                        "im gg item",
                                                                        item
                                                                      );
                                                                      if (
                                                                        item.doctor_clinic_image_id
                                                                      ) {
                                                                        return (
                                                                          item.doctor_clinic_image_id !==
                                                                          img.doctor_clinic_image_id
                                                                        );
                                                                      } else {
                                                                        return (
                                                                          item !==
                                                                          img
                                                                        );
                                                                      }
                                                                    }
                                                                  );
                                                                filteredPhotos &&
                                                                  setUpdateClinicPayload(
                                                                    {
                                                                      ...updateClinicPayload,
                                                                      clinicPhotos:
                                                                        filteredPhotos,
                                                                    }
                                                                  );
                                                              }}
                                                            />
                                                          </span>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <ul>
                                              <li>
                                                {itemlist.images &&
                                                itemlist.images.length > 0 ? (
                                                  itemlist.images.map(
                                                    (itemlist, index) => {
                                                      return (
                                                        <>
                                                          <img
                                                            src={
                                                              itemlist.doctor_clinic_image_path
                                                            }
                                                            alt=""
                                                          />
                                                        </>
                                                      );
                                                    }
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                              </li>
                                            </ul>
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <img
                                          src={edit_icon}
                                          alt=""
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setUpdateClinicPayload({
                                              ...updateClinicPayload,
                                              doctor_clinic_id:
                                                itemlist?.doctor_clinic_id,
                                              doctor_clinic_name:
                                                itemlist?.doctor_clinic_name,
                                              clinic_facility_ids:
                                                itemlist?.clinic_facility_ids,
                                              doctor_clinic_address:
                                                itemlist?.doctor_clinic_address,
                                              longitude:
                                                itemlist
                                                  ?.doctor_clinic_location_coordinates
                                                  ?.y,
                                              latitude:
                                                itemlist
                                                  ?.doctor_clinic_location_coordinates
                                                  ?.x,
                                              clinicPhotos: itemlist?.images
                                                ? itemlist?.images
                                                : [],
                                            });
                                            setClinicEdit(!clinicEdit);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {clinicEdit && (
                                      <div
                                        className="col-lg-12"
                                        style={{ textAlign: "end" }}
                                      >
                                        <DarkButton
                                          classbtn={
                                            "cx-btn-dark shadow_none font-14 mb-5"
                                          }
                                          onClick={(e) => {
                                            // e.stopPropagation();
                                            updateClinicInfo();
                                          }}
                                          btn={"Update"}
                                        />
                                      </div>
                                    )}
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}
                            <div className="col-lg-12">
                              <DarkButton
                                classbtn={
                                  "cx-btn-dark shadow_none font-14 mb-5"
                                }
                                onClick={() => navigate("/AddClinic")}
                                btn={"+ Add Clinic"}
                              />
                            </div>
                            {/* {clinicEdit ? (
                                <>
                                  <div className="col-lg-12">
                                    <DarkButton
                                      classbtn={
                                        "cx-btn-dark shadow_none font-14 mb-5"
                                      }
                                      onClick={() => navigate("/AddClinic")}
                                      btn={"+ Add Clinic"}
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )} */}
                          </div>

                          {/* {clinicEdit ?
                            <div className="profile_info_fields clinic_edit">
                              <div className="row">
                                <div className="col-lg-3">
                                  <label htmlFor="">Clinic Name</label>
                                  <p className="info_text">David's Animal Care</p>
                                </div>
                                <div className="col-lg-3">
                                  <label htmlFor="">Facilities</label>
                                  <ul>
                                    <li>OPD</li>
                                    <li>Surgery</li>
                                    <li>24 x 7 Oxygen</li>
                                    <li>Free Wifi</li>
                                    <li>Ambulance Support</li>
                                    <li>
                                      {addInput ?
                                        <>
                                          <button className="add_new_btn2" onClick={handleAddInput}>+ Add New</button>
                                          <input type="text" className="form-control" />
                                        </>
                                        :
                                        <button className="add_new_btn2" onClick={handleAddInput}>+ Add New</button>
                                      }</li>
                                  </ul>
                                </div>
                                <div className="col-lg-3">
                                  <label htmlFor="">Address</label>
                                  <p className="info_text">
                                    125/2, Kalas Road, Vishrantwadi, Pune -
                                    411041. Maharashtra, India
                                  </p>
                                </div>
                                <div className="col-lg-3">
                                  <label htmlFor="">Photos</label>
                                  <div className="info_img_wrapper">
                                    <ul>
                                      <li>

                                        <img src={pro_info_img1} alt="" />
                                        <img src={pro_info_img2} alt="" />
                                      </li>
                                      <li>

                                        <img src={pro_info_img3} alt="" />
                                        <div className="img_with_overlay">
                                       
                                          <div className="img_overlay">
                                            <span>+3</span>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <DarkButton classbtn={
                                    "cx-btn-dark shadow_none font-14 mb-5"
                                  }
                                    onClick={() => navigate('/AddClinic')}

                                    btn={"+ Add Clinic"} />
                                </div>
                              </div>
                            </div> :
                            <div className="profile_info_fields">
                              <div className="row">
                                <div className="col-lg-3">
                                  <label htmlFor="">Clinic Name</label>
                                  <p className="info_text">David's Animal Care</p>
                                </div>
                                <div className="col-lg-3">
                                  <label htmlFor="">Facilities</label>
                                  <ul>
                                    <li>OPD</li>
                                    <li>Surgery</li>
                                    <li>24 x 7 Oxygen</li>
                                    <li>Free Wifi</li>
                                    <li>Ambulance Support</li>
                                  </ul>
                                </div>
                                <div className="col-lg-3">
                                  <label htmlFor="">Address</label>
                                  <p className="info_text">
                                    125/2, Kalas Road, Vishrantwadi, Pune -
                                    411041. Maharashtra, India
                                  </p>
                                </div>
                                <div className="col-lg-3">
                                  <label htmlFor="">Photos</label>
                                  <div className="info_img_wrapper">
                                    <ul>
                                      <li>

                                        <img src={pro_info_img1} alt="" />
                                        <img src={pro_info_img2} alt="" />
                                      </li>
                                      <li>

                                        <img src={pro_info_img3} alt="" />
                                        <div className="img_with_overlay">
                                         
                                          <div className="img_overlay">
                                            <span>+3</span>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>} */}

                          <div className="info_title_wrapper">
                            <h3 className="info_title">Team</h3>

                            <img
                              src={edit_icon}
                              alt=""
                              onClick={handleTeamEdit}
                            />
                          </div>

                          {/* {teamEdit ? ( */}
                          <div className="profile_info_fields team_edit">
                            <div className="team_wrapper">
                              {teamEdit ? (
                                <>
                                  <div
                                    className="add_team_item team_item"
                                    onClick={() => navigate("/AddTeam")}
                                  >
                                    <img src={round_delete_icon} alt="" />
                                    <p>
                                      Add <br />
                                      Team Member
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {ClinicTeam && ClinicTeam.length > 0 ? (
                                ClinicTeam.map((itemlist, index) => {
                                  return (
                                    <div className="team_item">
                                      {itemlist.doctor_profile_image_path ===
                                        null ||
                                      itemlist.doctor_profile_image_path ===
                                        "" ? (
                                        <>
                                          <img src={doc_img6} alt="" />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={
                                              itemlist.doctor_profile_image_path
                                            }
                                            alt=""
                                            height="100px"
                                            width="100px"
                                          />
                                        </>
                                      )}

                                      <p className="info_text">
                                        {itemlist.doctor_name}
                                      </p>
                                      <label htmlFor="">
                                        {itemlist.specialization}
                                      </label>
                                      <img
                                        onClick={() =>
                                          DeleteDocument(
                                            itemlist.doctor_team_id
                                          )
                                        }
                                        src={img_preview_delete_icon}
                                        className="team_delete_icon"
                                        alt=""
                                      />
                                    </div>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>

                          <div className="info_title_wrapper">
                            <h3 className="info_title">Documents</h3>
                            <img
                              src={edit_icon}
                              alt=""
                              onClick={handleDocEdit}
                            />
                          </div>
                          {/* {docEdit ? ( */}

                          <div style={{ display: "flex" }}>
                            {Documentlist && Documentlist.length > 0 ? (
                              Documentlist.map((itemlist, index) => {
                                return (
                                  <div className="profile_info_fields doc_edit">
                                    <div className="profile_docs_wrapper">
                                      <div className="doc_item">
                                        {itemlist.clinic_certificate_path.includes(
                                          ".pdf"
                                        ) ? (
                                          <>
                                            <img
                                              src={
                                                itemlist?.clinic_certificate_path
                                              }
                                              onClick={() =>
                                                handleNewImg(
                                                  itemlist?.clinic_certificate_path
                                                )
                                              }
                                              style={{
                                                objectFit: "cover",
                                                cursor: "pointer",
                                              }}
                                              height="auto"
                                              width="100%"
                                              alt=""
                                            />
                                            <a
                                              href={
                                                itemlist?.clinic_certificate_path
                                              }
                                              target="_blank"
                                              style={{ marginTop: "10px" }}
                                            >
                                              Download
                                            </a>
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              src={
                                                itemlist?.clinic_certificate_path
                                              }
                                              onClick={() =>
                                                handleNewImg(
                                                  itemlist?.clinic_certificate_path
                                                )
                                              }
                                              style={{
                                                objectFit: "cover",
                                                cursor: "pointer",
                                              }}
                                              height="auto"
                                              width="100%"
                                              alt=""
                                            />
                                            <a
                                              href={
                                                itemlist?.clinic_certificate_path
                                              }
                                              target="_blank"
                                              style={{ marginTop: "10px" }}
                                            >
                                              Download
                                            </a>
                                          </>
                                        )}
                                        {/* <p className="info_text">
                                    Registration Certificate
                                  </p> */}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </div>
                          {docEdit ? (
                            <>
                              <div className="d-flex">
                                <div className="doc_item upload_doc_item mx-3">
                                  <form
                                    className="form-container"
                                    enctype="multipart/form-data"
                                  >
                                    <div className="upload-files-container">
                                      <div className="drag-file-area">
                                        <span className="material-icons-outlined upload-icon">
                                          <img src={upload_icon} />
                                        </span>
                                        <h3 className="dynamic-message">
                                          Drop your files here. <br />
                                          or Document
                                        </h3>
                                        <label className="label">
                                          <span className="browse-files">
                                            <input
                                              type="file"
                                              className="default-file-input"
                                            />
                                            <span className="browse-files-text">
                                              {" "}
                                              <DragandDropImages
                                                setFile={(files) => {
                                                  console.log("files", files);
                                                  setUploadDocoument([
                                                    ...UploadDocoument,
                                                    ...files,
                                                  ]);
                                                }}
                                              />
                                            </span>
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                                {UploadDocoument &&
                                UploadDocoument.length > 0 ? (
                                  <div className="previewBox">
                                    <img
                                      src={
                                        UploadDocoument[0] &&
                                        URL.createObjectURL(UploadDocoument[0])
                                      }
                                      alt=""
                                    />
                                  </div>
                                ) : null}
                              </div>
                              {UploadDocoument && UploadDocoument.length > 0 ? (
                                <div className="btn_wrapper mb-3">
                                  <DarkButton
                                    classbtn={
                                      "cx-btn-light btn-width-50 font-14"
                                    }
                                    onClick={() => {
                                      setUploadDocoument([]);
                                      handleDocEdit(!docEdit);
                                    }}
                                    btn={"Cancel"}
                                  />
                                  <DarkButton
                                    classbtn={
                                      "cx-btn-dark  btn-width-50 font-14"
                                    }
                                    onClick={postDocument}
                                    btn={"Update"}
                                  />
                                </div>
                              ) : null}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* // ) : (
                          //   <div className="profile_info_fields">
                          //     <div className="profile_docs_wrapper">
                          //       <div className="doc_item">
                          //         <div className="doc_img_wrapper">
                          //           <img src={docs_icon} alt="" />
                          //         </div>
                          //         <p className="info_text">
                          //           Registration Certificate
                          //         </p>
                          //       </div>
                          //       <div className="doc_item">
                          //         <div className="doc_img_wrapper">
                          //           <img src={docs_icon_blue} alt="" />
                          //         </div>
                          //         <p className="info_text">
                          //           Degree Certificate
                          //         </p>
                          //       </div>
                          //       <div className="doc_item">
                          //         <div className="doc_img_wrapper">
                          //           <img src={docs_icon} alt="" />
                          //         </div>
                          //         <p className="info_text">
                          //           Clinic Certificate
                          //         </p>
                          //       </div>
                          //       <div className="doc_item">
                          //         <div className="doc_img_wrapper">
                          //           <img src={docs_icon} alt="" />
                          //         </div>
                          //         <p className="info_text">
                          //           School Certificate
                          //         </p>
                          //       </div>
                          //     </div>
                          //   </div>
                          // )} */}

                          <div className="info_title_wrapper">
                            <h3
                              className="info_title"
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              Signature
                            </h3>
                            <img
                              src={edit_icon}
                              alt=""
                              onClick={handleDocEdit2}
                            />
                          </div>
                          {/* {docEdit2 ? ( */}
                          {Signatrelist && Signatrelist.length > 0 ? (
                            Signatrelist.map((itemlist, index) => {
                              return (
                                <div className="profile_info_fields ">
                                  <div className="profile_docs_wrapper justify-content-start mb-0">
                                    <div className="doc_item docPadding">
                                      <img
                                        // style={{
                                        //   width: "100%",
                                        //   height: "100%",
                                        // }}
                                        src={itemlist.doctor_signature_path}
                                        alt=""
                                      />
                                    </div>
                                    {docEdit2 ? (
                                      <div className="doc_item upload_doc_item">
                                        <form
                                          className="form-container"
                                          enctype="multipart/form-data"
                                        >
                                          <div className="upload-files-container">
                                            <div className="drag-file-area">
                                              <span className="material-icons-outlined upload-icon">
                                                <img src={upload_icon} />
                                              </span>
                                              <h3 className="dynamic-message">
                                                Drop your files here. <br />
                                                or signature
                                              </h3>
                                              <label className="label">
                                                <span className="browse-files">
                                                  <input
                                                    type="file"
                                                    className="default-file-input"
                                                  />
                                                  <span className="browse-files-text">
                                                    {" "}
                                                    <DragandDropImages
                                                      setFile={(files) => {
                                                        setUploadDoc({
                                                          ...UploadDoc,
                                                          documentsPath: [
                                                            ...UploadDoc.documentsPath,
                                                            ...files,
                                                          ],
                                                        });
                                                      }}
                                                    />
                                                  </span>
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    ) : (
                                      <> </>
                                    )}
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                          {/* // ) : (
                          //   <div className="profile_info_fields ">
                          //     <div className="profile_docs_wrapper justify-content-start mb-0">
                          //       <div className="doc_item">
                          //         <img src={signature} alt="" />
                          //       </div>
                          //     </div>
                          //   </div>
                          // )} */}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="profile_info_wrapper_inner">
                          <div className="info_title_wrapper">
                            <h3 className="info_title">Select Language</h3>
                          </div>
                          <div className="profile_info_fields">
                            <div className="language_wrapper">
                              {LangugeType && LangugeType.length > 0 ? (
                                LangugeType.map((itemlist, index) => {
                                  return (
                                    <div>
                                      <input
                                        type="checkbox"
                                        name="language"
                                        id={`english-${index}`}
                                        checked={
                                          checkedItems[index] ||
                                          itemlist.checked
                                        }
                                        onChange={() => {
                                          handleCheckboxChange(index, itemlist);
                                        }}
                                      />
                                      <label htmlFor="english">
                                        {itemlist.language}
                                      </label>
                                    </div>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <div className="profile_info_wrapper_inner">
                          <div className="info_title_wrapper">
                            <h3 className="info_title">Bank Details</h3>
                            <img src={edit_icon} alt="" />
                          </div>
                          {BankDetails && BankDetails.length > 0 ? (
                            BankDetails.map((itemlist, index) => {
                              return (
                                <div className="profile_info_fields">
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <label htmlFor="">Bank Name</label>
                                      <p className="info_text">
                                        {itemlist.doctor_bank}
                                      </p>
                                    </div>
                                    <div className="col-lg-3">
                                      <label htmlFor="">Account Number</label>
                                      <p className="info_text">
                                        {itemlist.account_number}
                                      </p>
                                    </div>
                                    <div className="col-lg-3">
                                      <label htmlFor="">
                                        Account Holder Name
                                      </label>
                                      <p className="info_text">
                                        {itemlist.account_holder_name}
                                      </p>
                                    </div>
                                    <div className="col-lg-3">
                                      <label htmlFor="">
                                        IFSC / SWIFT Code
                                      </label>
                                      <p className="info_text">
                                        {itemlist.ifsc_code}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                          {bankEdit ? (
                            <>
                              <div className="profile_info_fields">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <label htmlFor="">Bank Name</label>

                                    <input
                                      type="text"
                                      placeholder="Enter Bank Name"
                                      className="form-control"
                                      value={Bankdentials.bank_name}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        let valueInput = value.replace(
                                          /[^A-Za-z ]/gi,
                                          ""
                                        );
                                        setBankdentials({
                                          ...Bankdentials,
                                          bank_name: valueInput,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-3">
                                    <label htmlFor="">Account Number</label>
                                    <input
                                      type="text"
                                      placeholder="Enter Account Number"
                                      className="form-control"
                                      value={Bankdentials.account_number}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        let valueInput = value.replace(
                                          /[^0-9 ]/gi,
                                          ""
                                        );
                                        setBankdentials({
                                          ...Bankdentials,
                                          account_number: valueInput,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-3">
                                    <label htmlFor="">
                                      Account Holder Name
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Account Holder Name"
                                      className="form-control"
                                      value={Bankdentials.account_holder_name}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        let valueInput = value.replace(
                                          /[^A-Za-z ]/gi,
                                          ""
                                        );
                                        setBankdentials({
                                          ...Bankdentials,
                                          account_holder_name: valueInput,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-3">
                                    <label htmlFor="">IFSC / SWIFT Code</label>
                                    <input
                                      type="text"
                                      placeholder="Enter IFSC/ SWIFT Code"
                                      className="form-control"
                                      value={Bankdentials.ifsc_code}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        // let valueInput = value.replace(/[^A-Za-z ]/gi, "");
                                        setBankdentials({
                                          ...Bankdentials,
                                          ifsc_code: value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <DarkButton
                            onClick={handlebank}
                            classbtn={
                              "cx-btn-dark shadow_none font-14 mb-3 mt-5"
                            }
                            btn={"+ Add Bank"}
                          />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <div className="clinic_schedule">
                          <div className="cs_header_wrapper">
                            <div className="clinic-select">
                              <label>Select Clinic</label>
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  const { value } = e.target;
                                  getClinicScheduleDetails(Number(value));
                                }}
                              >
                                <option>Select clinic</option>
                                {ClinicInf &&
                                  ClinicInf.map((item, idx) => {
                                    return (
                                      <option
                                        key={"asdasd" + idx}
                                        value={item.doctor_clinic_id}
                                      >
                                        {item.doctor_clinic_name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>

                            {docClinicId && (
                              <div className="row">
                                <div className="col-lg-4">
                                  <h2 className="cs_header">
                                    UPDATE YOUR SCHEDULE
                                  </h2>
                                </div>
                                <div className="col-lg-8">
                                  <div className="shift_and_days_selector">
                                    <div className="weekend_selecter">
                                      <label className="schedule_label">
                                        Select Weekend Days
                                      </label>
                                      <div className="weekDays-selector">
                                        <input
                                          type="checkbox"
                                          id="weekday-mon"
                                          className="weekday"
                                          checked={
                                            ClinicSchedule.is_weekend_mon
                                          }
                                          onChange={(e) => {
                                            setClinicSchedule({
                                              ...ClinicSchedule,
                                              is_weekend_mon: e.target.checked,
                                            });
                                          }}
                                        />
                                        <label htmlFor="weekday-mon">Mon</label>
                                        <input
                                          type="checkbox"
                                          id="weekday-tue"
                                          className="weekday"
                                          checked={
                                            ClinicSchedule.is_weekend_tue
                                          }
                                          onChange={(e) => {
                                            setClinicSchedule({
                                              ...ClinicSchedule,
                                              is_weekend_tue: e.target.checked,
                                            });
                                          }}
                                        />
                                        <label htmlFor="weekday-tue">Tue</label>
                                        <input
                                          type="checkbox"
                                          id="weekday-wed"
                                          className="weekday"
                                          checked={
                                            ClinicSchedule.is_weekend_wed
                                          }
                                          onChange={(e) => {
                                            setClinicSchedule({
                                              ...ClinicSchedule,
                                              is_weekend_wed: e.target.checked,
                                            });
                                          }}
                                        />
                                        <label htmlFor="weekday-wed">Wed</label>
                                        <input
                                          type="checkbox"
                                          id="weekday-thu"
                                          className="weekday"
                                          checked={
                                            ClinicSchedule.is_weekend_thu
                                          }
                                          onChange={(e) => {
                                            setClinicSchedule({
                                              ...ClinicSchedule,
                                              is_weekend_thu: e.target.checked,
                                            });
                                          }}
                                        />
                                        <label htmlFor="weekday-thu">Thu</label>
                                        <input
                                          type="checkbox"
                                          id="weekday-fri"
                                          className="weekday"
                                          checked={
                                            ClinicSchedule.is_weekend_fri
                                          }
                                          onChange={(e) => {
                                            setClinicSchedule({
                                              ...ClinicSchedule,
                                              is_weekend_fri: e.target.checked,
                                            });
                                          }}
                                        />
                                        <label htmlFor="weekday-fri">Fri</label>
                                        <input
                                          type="checkbox"
                                          id="weekday-sat"
                                          className="weekday"
                                          checked={
                                            ClinicSchedule.is_weekend_sat
                                          }
                                          onChange={(e) => {
                                            setClinicSchedule({
                                              ...ClinicSchedule,
                                              is_weekend_sat: e.target.checked,
                                            });
                                          }}
                                        />
                                        <label htmlFor="weekday-sat">Sat</label>
                                        <input
                                          type="checkbox"
                                          id="weekday-sun"
                                          className="weekday"
                                          checked={
                                            ClinicSchedule.is_weekend_sun
                                          }
                                          onChange={(e) => {
                                            setClinicSchedule({
                                              ...ClinicSchedule,
                                              is_weekend_sun: e.target.checked,
                                            });
                                          }}
                                        />
                                        <label htmlFor="weekday-sun">Sun</label>
                                      </div>
                                    </div>
                                    <div className="shift_selecter">
                                      <label className="schedule_label">
                                        Select Shift time
                                      </label>

                                      <label className="radio-button">
                                        <input
                                          type="checkbox"
                                          className="radio-button__input"
                                          id="choice1-1"
                                          name="choice1"
                                          checked={
                                            ClinicSchedule.is_morning_shift
                                          }
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setClinicSchedule({
                                                ...ClinicSchedule,
                                                is_morning_shift:
                                                  e.target.checked,
                                                is_evening_shift:
                                                  !e.target.checked,
                                              });
                                            } else {
                                              setClinicSchedule({
                                                ...ClinicSchedule,
                                                is_morning_shift:
                                                  !e.target.checked,
                                                is_evening_shift:
                                                  e.target.checked,
                                              });
                                            }
                                          }}
                                        />
                                        <span className="radio-button__control"></span>
                                        <span
                                          className="radio-button__label"
                                          htmlFor="choice1-1"
                                        >
                                          Morning
                                        </span>
                                      </label>

                                      <label className="radio-button">
                                        <input
                                          type="checkbox"
                                          className="radio-button__input"
                                          id="choice1-2"
                                          name="choice1"
                                          checked={
                                            ClinicSchedule.is_evening_shift
                                          }
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setClinicSchedule({
                                                ...ClinicSchedule,
                                                is_evening_shift:
                                                  e.target.checked,
                                                is_morning_shift:
                                                  !e.target.checked,
                                              });
                                            } else {
                                              setClinicSchedule({
                                                ...ClinicSchedule,
                                                is_evening_shift:
                                                  !e.target.checked,
                                                is_morning_shift:
                                                  e.target.checked,
                                              });
                                            }
                                          }}
                                        />
                                        <span className="radio-button__control"></span>
                                        <span className="radio-button__label">
                                          Evening
                                        </span>
                                      </label>

                                      <label className="radio-button">
                                        <input
                                          type="checkbox"
                                          className="radio-button__input"
                                          id="choice1-3"
                                          name="choice1"
                                          checked={
                                            ClinicSchedule.is_evening_shift &&
                                            ClinicSchedule.is_morning_shift
                                          }
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setClinicSchedule({
                                                ...ClinicSchedule,
                                                is_evening_shift:
                                                  e.target.checked,
                                                is_morning_shift:
                                                  e.target.checked,
                                              });
                                            } else {
                                              setClinicSchedule({
                                                ...ClinicSchedule,
                                                is_evening_shift:
                                                  e.target.checked,
                                                is_morning_shift:
                                                  !e.target.checked,
                                              });
                                            }
                                          }}
                                        />
                                        <span className="radio-button__control"></span>
                                        <span className="radio-button__label">
                                          Both
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          {docClinicId && (
                            <>
                              <div className="schedule_cards_wrapper">
                                <div className="row">
                                  <div className="col-lg-6 mb-4">
                                    {" "}
                                    <div className="schedule_card">
                                      <div className="sc_header_wrapper">
                                        <h3 className="sc_header">
                                          SLOT SELECTION
                                        </h3>
                                      </div>
                                      <div className="sc_inner">
                                        {ClinicSchedule?.is_morning_shift && (
                                          <div className="sc_inner_card sc_top">
                                            <h3 className="sct_header">
                                              Morning section
                                            </h3>
                                            <div className="date_wrapper">
                                              <label>Start Time</label>

                                              <TimePicker
                                                onChange={onChange}
                                                value={value}
                                                format="h:mm a"
                                              />
                                            </div>
                                            <div className="date_wrapper">
                                              <label>End Time</label>
                                              <TimePicker
                                                onChange={onChange1}
                                                value={value1}
                                                format="h:mm a"
                                              />
                                            </div>
                                            <div className="appointment_wrapper">
                                              <label>No. of Appointments</label>
                                              <div className="selection">
                                                <input
                                                  type="text"
                                                  className="appointmentLine"
                                                  placeholder=""
                                                  value={
                                                    ClinicSchedule.slot_morning_no_of_appointments
                                                  }
                                                  onChange={(e) => {
                                                    let regex = /^[0-9]*$/;
                                                    if (
                                                      regex.test(e.target.value)
                                                    ) {
                                                      setClinicSchedule({
                                                        ...ClinicSchedule,
                                                        slot_morning_no_of_appointments:
                                                          e.target.value,
                                                      });
                                                    }
                                                  }}
                                                />
                                                {/* <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={
                                      ClinicSchedule.slot_morning_no_of_appointments
                                    }
                                    onChange={(e) => {
                                      setClinicSchedule({
                                        ...ClinicSchedule,
                                        slot_morning_no_of_appointments:
                                          e.target.value,
                                      });
                                    }}
                                  >
                                    <option selected value="1">
                                      01
                                    </option>
                                    <option value="2">02</option>
                                    <option value="3">03</option>
                                    <option value="4">04</option>
                                    <option value="5">05</option>
                                    <option value="6">06</option>
                                    <option value="7">07</option>
                                    <option value="8">08</option>
                                    <option value="9">09</option>
                                    <option value="10">10</option>
                                  </select> */}
                                              </div>
                                            </div>
                                            <div className="doctor_charges_wrapper">
                                              <label>Doctor Charge</label>
                                              <div className="selection">
                                                <input
                                                  type="text"
                                                  className="appointmentLine"
                                                  placeholder=""
                                                  value={
                                                    ClinicSchedule.slot_morning_doctor_charge
                                                  }
                                                  onChange={(e) => {
                                                    let regex = /^[0-9]*$/;
                                                    if (
                                                      regex.test(e.target.value)
                                                    ) {
                                                      setClinicSchedule({
                                                        ...ClinicSchedule,
                                                        slot_morning_doctor_charge:
                                                          e.target.value,
                                                      });
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {ClinicSchedule?.is_evening_shift && (
                                          <div className="sc_inner_card">
                                            <h3 className="sct_header">
                                              Evening section
                                            </h3>
                                            <div className="date_wrapper">
                                              <label>Start Time</label>
                                              <TimePicker
                                                onChange={onChange2}
                                                value={value2}
                                                format="h:mm a"
                                              />
                                            </div>
                                            <div className="date_wrapper">
                                              <label>End Time</label>
                                              <TimePicker
                                                onChange={onChange3}
                                                value={value3}
                                                format="h:mm a"
                                              />
                                            </div>
                                            <div className="appointment_wrapper">
                                              <label>No. of Appointments</label>
                                              <div className="selection">
                                                <input
                                                  type="text"
                                                  className="appointmentLine"
                                                  placeholder=""
                                                  value={
                                                    ClinicSchedule.slot_evening_no_of_appointments
                                                  }
                                                  onChange={(e) => {
                                                    let regex = /^[0-9]*$/;
                                                    if (
                                                      regex.test(e.target.value)
                                                    ) {
                                                      setClinicSchedule({
                                                        ...ClinicSchedule,
                                                        slot_evening_no_of_appointments:
                                                          e.target.value,
                                                      });
                                                    }
                                                  }}
                                                />
                                                {/* <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={
                                      ClinicSchedule.slot_evening_no_of_appointments
                                    }
                                    onChange={(e) => {
                                      setClinicSchedule({
                                        ...ClinicSchedule,
                                        slot_evening_no_of_appointments:
                                          e.target.value,
                                      });
                                    }}
                                  >
                                    <option selected value="1">
                                      01
                                    </option>
                                    <option value="2">02</option>
                                    <option value="3">03</option>
                                    <option value="4">04</option>
                                  </select> */}
                                              </div>
                                            </div>
                                            <div className="doctor_charges_wrapper">
                                              <label>Doctor Charge</label>
                                              <div className="selection">
                                                <input
                                                  type="text"
                                                  className="appointmentLine"
                                                  placeholder=""
                                                  value={
                                                    ClinicSchedule.slot_evening_doctor_charge
                                                  }
                                                  onChange={(e) => {
                                                    let regex = /^[0-9]*$/;
                                                    if (
                                                      regex.test(e.target.value)
                                                    ) {
                                                      setClinicSchedule({
                                                        ...ClinicSchedule,
                                                        slot_evening_doctor_charge:
                                                          e.target.value,
                                                      });
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mb-4">
                                    {/* <div className="col-lg-6 mb-4"> */}
                                    <div className="schedule_card">
                                      <div className="sc_header_wrapper">
                                        <h3 className="sc_header">
                                          CLINIC TIME
                                        </h3>
                                        <div className="sc_switch2">
                                          <input
                                            type="checkbox"
                                            id="appoint"
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                onChange4(value);
                                                onChange5(value1);
                                                onChange6(value2);
                                                onChange7(value3);
                                              } else {
                                                onChange4("08:01");
                                                onChange5("11:01");
                                                onChange6("13:01");
                                                onChange7("20:01");
                                              }
                                            }}
                                          />
                                          <label htmlFor="appoint">
                                            As per Appointment time
                                          </label>
                                        </div>
                                      </div>
                                      <div className="sc_inner">
                                        {ClinicSchedule?.is_morning_shift && (
                                          <div className="sc_inner_card sc_top">
                                            <h3 className="sct_header">
                                              Morning section
                                            </h3>
                                            <div className="date_wrapper">
                                              <label>Start Time</label>
                                              <TimePicker
                                                onChange={onChange4}
                                                value={value4}
                                                format="h:mm a"
                                              />
                                            </div>
                                            <div className="date_wrapper">
                                              <label>End Time</label>
                                              <TimePicker
                                                onChange={onChange5}
                                                value={value5}
                                                format="h:mm a"
                                              />
                                            </div>
                                          </div>
                                        )}

                                        {ClinicSchedule?.is_evening_shift && (
                                          <div className="sc_inner_card">
                                            <h3 className="sct_header">
                                              Evening section
                                            </h3>
                                            <div className="date_wrapper">
                                              <label>Start Time</label>
                                              <TimePicker
                                                onChange={onChange6}
                                                value={value6}
                                                format="h:mm a"
                                              />
                                            </div>
                                            <div className="date_wrapper">
                                              <label>End Time</label>
                                              <TimePicker
                                                onChange={onChange7}
                                                value={value7}
                                                format="h:mm a"
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {/* </div> */}
                                    {/* </div> */}
                                    {/* </div> */}
                                  </div>
                                </div>
                              </div>

                              <div className="login btn-width-100">
                                {/* <DarkButton
                              onClick={() => navigate("/ClinicSchedule")}
                              classbtn={
                                "cx-btn-light me-3  btn-width-45 btn-height font-17 mb-5"
                              }
                              btn={"Back"}
                            /> */}
                                <DarkButton
                                  onClick={() => {
                                    updateScheduleDetails();
                                  }}
                                  classbtn={
                                    "cx-btn-dark  btn-height btn-width-50 font-17 mb-5"
                                  }
                                  btn={"Update"}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
      {currentTab === "second" ? (
        <div className="btn_wrapper">
          <DarkButton
            classbtn={"cx-btn-light btn-width-50 font-14"}
            btn={"Cancel"}
          />
          <DarkButton
            onClick={() => {
              UpdateLanguage();
            }}
            classbtn={"cx-btn-dark  btn-width-50 font-14"}
            btn={"Update"}
          />
        </div>
      ) : (
        <></>
      )}
    </motion.div>
  );
};

export default MyProfile;
