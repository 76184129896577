import React, { useRef, useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
// import Axe from "./assets/images/Axe.svg";
import Axe from "../assets/images/crossAxe.svg";

const AutocompleteGoogleInput = ({
  required,
  className,
  placeholder,
  onPlaceSelect,
  currentIndex,
  name,
  selectedValue,
}) => {
  const autocompleteRef = useRef(null);
  const handlePlaceSelect = (event) => {
    const autocompleteInstance = autocompleteRef.current;
    const place = autocompleteInstance?.getPlace();
    const lat = place?.geometry?.location.lat();
    const lng = place?.geometry?.location.lng();
    const address = place?.formatted_address;
    if (onPlaceSelect) {
      onPlaceSelect({ lat, lng, address }, event, currentIndex, name);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Autocomplete
        onLoad={(auto) => (autocompleteRef.current = auto)}
        onPlaceChanged={handlePlaceSelect}
      >
        <input
          defaultValue={selectedValue}
          required={required}
          type="area"
          className={className ? className : ""}
          placeholder={placeholder}
          onChange={(e) => handlePlaceSelect(e)}
        />
      </Autocomplete>
      <div className="invalid-feedback font-11">Please enter your address</div>
    </div>
  );
};

export default AutocompleteGoogleInput;
