import React, { useState, useContext } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { AppContext } from "../context/AppContext";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { useLocation } from "react-router-dom";

const Notepad = () => {
  const { notesData, setNotesData } = useContext(AppContext);
  const currentRoute = useLocation().pathname;

  const [editorState, setEditorState] = useState(() => {
    if (notesData) {
      let convertedToHTML = decodeURIComponent(notesData);
      const blocksFromHtml = htmlToDraft(convertedToHTML);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  });

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);

    // Extract the text content from the updated EditorState
    const contentState = newEditorState.getCurrentContent();
    const rawText = contentState.getPlainText();

    // Update notesData in the context with the raw text
    setNotesData(rawText);
  };

  let spanTxt = <span className="text-danger">No Notes Found</span>;

  return (
    <div id="editor">
      <Editor
        editorState={editorState !== "" ? editorState : spanTxt}
        onEditorStateChange={
          currentRoute !== "/CustomerDetails" && onEditorStateChange
        }
      />
    </div>
  );
};

export default Notepad;
