import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import edu_icon from "../../assets/images/icons/edu_icon.svg";
import exp_icon from "../../assets/images/icons/exp_icon.svg";
import regi_icon from "../../assets/images/icons/regi_icon.svg";
import sign_icon from "../../assets/images/icons/sign_icon.svg";
import clinic_img_1 from "../../assets/images/clinic_img_1.svg";
import clinic_img_2 from "../../assets/images/clinic_img_2.svg";
import clinic_img_3 from "../../assets/images/clinic_img_3.svg";
import clinic_img_4 from "../../assets/images/clinic_img_4.svg";
import teleCall from "../../assets/images/ic_call1.svg";
import clinic_icon from "../../assets/images/icons/clinic_icon.svg";
import img_preview_delete_icon from "../../assets/images/icons/img_preview_delete_icon.svg";
import clinic_info from "../../assets/images/clinic_info.svg";
import { DarkButton } from "../commonComponents/commonComp";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import { getWithAuthCall, multipartPostCall } from "../../api/ApiServices";
import DragandDropImages from "../../sharedComponent/DragandDropImages";
import { AppContext } from "../../context/AppContext";
import AutocompleteGoogleInput from "../../sharedComponent/AutocompleteGoogleInput";

const ClinicInfo = () => {
  const { setdoctor_clinic_id, setLoading } = useContext(AppContext);
  const doctorId = localStorage.getItem("sign_doctor_id")
    ? localStorage.getItem("sign_doctor_id")
    : null;

  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [signature, setSignature] = useState();
  const [deleteImg, setDeleteImg] = useState(true);
  const [FacilitiesType, setFacilitiesType] = useState([]);

  function handleFileInput(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  function handleFileInput2(e) {
    setSignature(URL.createObjectURL(e.target.files[0]));
    setDeleteImg(true);
  }

  const handleDeleteImg = () => {
    setDeleteImg(!deleteImg);
  };

  const options = [
    {
      value: "OPD (Allopathy & Homeopathy)",
      label: "OPD (Allopathy & Homeopathy)",
    },
    { value: "Dental facility", label: "Dental facility" },
    { value: "Ward/ Indoor", label: "Ward/ Indoor" },
    { value: "Minor OT/ Dressing Room", label: "Minor OT/ Dressing Room" },
    { value: "Laboratory services", label: "Laboratory services" },
  ];

  const [selected, setSelected] = useState(null);
  useEffect(() => {
    getfacilities();
  }, []);
  const handleChange = (e) => {
    setSelected(e.value);
  };
  // const [selectedOption, setSelectedOption] = useState("");

  // const handleChange1 = (selectedOption) => {
  //   setSelectedOption(selectedOption.value);
  // };
  const [CliniceAdd, setCliniceAdd] = useState({
    clinic_name: "",
    facilities: "",
    address: "",
    clinicPhotos: [],
    longitude: "",
    latitude: "",
  });

  const postaddClinice = () => {
    setLoading(true);

    let formData = new FormData();
    formData.append("doctor_id", doctorId);
    formData.append("clinic_name", CliniceAdd.clinic_name);
    formData.append("facilities", CliniceAdd.facilities);
    formData.append("address", CliniceAdd.address);
    formData.append("longitude", CliniceAdd.longitude);
    formData.append("latitude", CliniceAdd.latitude);

    // formData.append("clinicPhotos", CliniceAdd.clinicPhotos);

    CliniceAdd.clinicPhotos.map((img) => {
      formData.append("clinicPhotos", img);
    });

    multipartPostCall(ApiConfig.CLINICE_ADD, formData)
      .then((res) => {
        if (res.success) {
          notifySuccess(res.message);
          setdoctor_clinic_id(res.data.doctor_clinic_id);
          localStorage.setItem("doctor_clinic_id", res.data.doctor_clinic_id);
          navigate("/UploadDocs");
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  function onTopicChange(selectedOption) {
    var selected_topics = [];

    if (selectedOption.length > 0) {
      selectedOption.map((topic, index) => {
        selected_topics.push(topic.value);
      });
    }
    console.log("selected_topics", selected_topics);
    setCliniceAdd({
      ...CliniceAdd,
      facilities: selected_topics,
    });
  }

  function getfacilities() {
    getWithAuthCall(ApiConfig.CLINCE_FACILITIES_DROPDOWN)
      .then((data) => {
        let Type = data.data;
        var grades = [];

        Type.sort((a, b) =>
          a.clinic_facility.localeCompare(b.clinic_facility)
        ).map((grade, index) => {
          grades.push({
            label: grade.clinic_facility,
            value: grade.clinic_facility_id,
          });
        });
        setFacilitiesType(grades);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const deleteProductImage = (selectedProdImgId, index) => {
    let images = CliniceAdd.clinicPhotos;
    images = images.filter((img) => images.indexOf(img) != index);
    setCliniceAdd({ ...CliniceAdd, clinicPhotos: images });
  };

  return (
    <>
      <div className="authMain">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <img src={logo} alt="" className="login_img" />
            <div className="signinForm signUp general_info">
              <h1 className="signInTxt mb-5">Let's build your e-clinic</h1>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Clinic Name
                </label>
                <img src={clinic_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your clinic name"
                  value={CliniceAdd.clinic_name}
                  onChange={(e) => {
                    let value = e.target.value;
                    let valueInput = value.replace(/[^A-Za-z ]/gi, "");
                    setCliniceAdd({
                      ...CliniceAdd,
                      clinic_name: valueInput,
                    });
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Facilities
                </label>
                <Select
                  className=""
                  isMulti
                  options={FacilitiesType}
                  // onChange={handleChange}
                  onChange={onTopicChange}
                  autoFocus={true}
                  // placeholder={"Select Languages..."}
                  placeholder={
                    <div className="select-placeholder-text">
                      Select Facilities...
                    </div>
                  }
                />
                <img src={exp_icon} className="mailImg" alt="" />
                <div className="result">
                  {selected?.map((item, i) => {
                    return <p></p>;
                  })}
                </div>
              </div>

              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Address
                </label>
                <img src={clinic_icon} className="mailImg" alt="" />
                <AutocompleteGoogleInput
                  // selectedValue={CliniceAdd.address ? CliniceAdd.address  : "" }
                  required={true}
                  className={"form-control formInput"}
                  placeholder={"Enter your address"}
                  onPlaceSelect={({ lat, lng, address }) => {
                    setCliniceAdd({
                      ...CliniceAdd,
                      address: address,
                      longitude: lng,
                      latitude: lat,
                    });
                  }}
                />
                {/* <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter clinic address"
                  value={CliniceAdd.address}
                  onChange={(e) => {
                    let value = e.target.value;
                    // let valueInput = value.replace(/[^A-Za-z ]/gi, "");
                    setCliniceAdd({
                      ...CliniceAdd,
                      address: value,
                    });
                  }}
                /> */}
              </div>

              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Clinic Photos
                </label>
                <div className="file-input">
                  {/* <input
                    type="file"
                    name="file-input"
                    id="file-input2"
                    className="file-input__input"
                    onChange={handleFileInput2}
                    multiple
                  /> */}

                  <img src={sign_icon} className="mailImg2" alt="" />
                  <label className="file-input__label2" for="file-input2">
                    {/* Upload clinic photos */}
                    <DragandDropImages
                      setFile={(files) => {
                        setCliniceAdd({
                          ...CliniceAdd,
                          clinicPhotos: [...CliniceAdd.clinicPhotos, ...files],
                        });
                      }}
                    />
                  </label>
                </div>
              </div>
              {deleteImg && (
                <>
                  <div className={signature ? "sign_preview" : "d-none"}>
                    <img src={signature} alt="" />
                    <span>
                      <img
                        src={img_preview_delete_icon}
                        className="delete_icon"
                        onClick={handleDeleteImg}
                        alt=""
                      />
                    </span>
                  </div>
                </>
              )}
              <div className="inputBox">
                <div className="clinic_images_wrapper">
                  {CliniceAdd.clinicPhotos.map((img, index) => {
                    console.log("U_img", img);
                    return (
                      <div className="img_item" key={"zsdvsv"}>
                        <img
                          width={72}
                          height="72"
                          className="product_img"
                          src={img.name ? URL.createObjectURL(img) : img.img}
                          alt=""
                        />
                        <span style={{position : "absolute", left : 0}}>
                          <img
                            src={img_preview_delete_icon}
                            onClick={() => deleteProductImage(img.id, index)}
                          />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="login btn-width-100">
                {/* <DarkButton
                  onClick={() => navigate('/GeneralInfo')}
                  classbtn={
                    "cx-btn-light me-3  btn-width-45 btn-height font-17 mb-5"
                  }
                  btn={"Back"}
                /> */}
                <DarkButton
                  onClick={() => postaddClinice()}
                  classbtn={"cx-btn-dark  btn-height btn-width-50 font-17 mb-5"}
                  btn={"Save & Proceed"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="landingImg">
              <div className="phone-no">
                <img src={teleCall} alt="" />
                <label htmlFor="">+91 9509932234</label>
              </div>
              <div className="img-container">
                <div className="big-img">
                  <img src={clinic_info} alt="" />
                </div>
                <div>
                  <h2>Clinic Information</h2>
                  <p>Share your Information with us !</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClinicInfo;
