import React, { useContext, useEffect, useState } from "react";
import ic_call from "../assets/images/ic_call.svg";
import ic_location from "../assets/images/ic_location.svg";
import ic_mail from "../assets/images/ic_mail.svg";
import DoctorSymbol from "../assets/images/DoctorSymbol.svg";
import sign from "../assets/images/sign.png";

import Modal from "react-bootstrap/Modal";
import { AppContext } from "../context/AppContext";
import { simpleGetCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { notifyError } from "./notify";
const PrescriptionComp = () => {
  const {
    Prescription,
    setPrescription,
    doctorAppoinmentID,
    VaccinePrescription,
    setVaccinePrescription,
    petFoodPrescription,
    setPetFoodPrescription,
    historyPres,
    setHistoryPres,
    historyPresData,
    videoHistoryPres,
    setVideoHistoryPres,
    videohistoryPresData,
    vaccineData,
    setVaccineData,
    petFoodData,
    setPetFoodData,
    patientDet,
    setPatientDet
  } = useContext(AppContext);
  const [Tablelist, setTablelist] = useState([]);
  const [drData, setdrData] = useState([]);
  // const [patientDet, setpatientDet] = useState({});

  useEffect(() => {
    getClinetDetailsByeVisit();
    console.log("videoHistoryPres", patientDet);
  }, [videoHistoryPres]);

  const getClinetDetailsByeVisit = () => {
    simpleGetCall(ApiConfig.PRESCRIPTION_GET_ID + doctorAppoinmentID)
      .then((res) => {
        if (res.success) {
          setTablelist(res.data.prescription);
          setdrData(res.data.doctor[0]);
          // setpatientDet(res.data.customer[0]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };



  return (
    <div className="prescription-wrapper-main">
      <>
        <Modal
          show={Prescription}
          onHide={() => setPrescription(false)}
          size="lg"
        >
          <Modal.Body>
            <div className="orderModalInvoice">
              <div className="modalHeading">
                <div className="upperBlue">
                  <h1 className="docName">Dr. {drData?.doctor_name} </h1>
                  <label className="domainLab">{drData?.specialization} </label>
                  <label className="certificateLab">
                    Registration Number {drData?.doctor_registration_no}
                  </label>
                </div>
                <div className="docSymbolBox">
                  <img src={DoctorSymbol} className="docSymImg" alt="" />
                </div>
              </div>
              <div className="modalBody">
                <div className="detailsFill">
                  <div className="labelTxt">
                    <label className="widthFixed">Patient Name:</label>
                  </div>
                  <input
                    type="text"
                    className="form-control formSolution"
                    value={patientDet?.animal_name}
                    disabled
                  />
                </div>
                <div className="addressFlex">
                  <div className="labelTxt">
                    <label className="keyQuestion">Address:</label>
                  </div>
                  <p className="text-para">{patientDet?.address || ""}</p>
                  {/* <input
                    type="text"
                    className="form-control formSolution"
                    value={patientDet?.address}
                    disabled
                  /> */}
                </div>
                <div className="dateNdAgeFlex">
                  <div className="ageBox">
                    <label className="ageLab">Age:</label>
                    <input
                      type="text"
                      className="form-control formSolution"
                      value={patientDet?.animal_age}
                      disabled
                    />
                  </div>
                  <div className="dateBox">
                    <label className="ageLab">Date:</label>
                    <input
                      type="text"
                      className="form-control formSolution"
                      value={patientDet?.date}
                      disabled
                    />
                  </div>
                </div>
                <div className="addressFlex">
                  <div className="labelTxt margFiv">
                    <label className="keyQuestion">symptoms:</label>
                  </div>
                  <input
                    type="text"
                    className="form-control formSolution"
                    value={patientDet?.disease}
                    disabled
                  />
                </div>
                <div className="tableAndLandingBg">
                  <div className="backGroundPic"></div>
                  <div className="innerTable">
                    <table className="modalTable">
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Prescription</th>
                          <th>Type</th>
                          <th>instruction</th>
                          <th>Dose</th>
                          <th>Duration</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Tablelist && Tablelist.length > 0 ? (
                          Tablelist.map((itemlist, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{itemlist.product}</td>
                                <td>{itemlist.medicine_type}</td>
                                <td>{itemlist.instruction}</td>
                                <td>{itemlist.prescription_frequency}</td>
                                <td>{itemlist.duration}</td>
                                <td>{itemlist.quantity}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="signatureContent">
                  <div className="sigBox">
                    <div className="line">
                      <img
                        src={drData?.doctor_signature_path}
                        alt=""
                        height="100px"
                      />
                    </div>

                    {/* <label htmlFor="sigNatureFor" className="signaturePad">
                    <input type="file" id="sigNatureFor" className="d-none" />
                  </label> */}
                    <label className="belowTxt">SIGNATURE</label>
                  </div>
                </div>
              </div>
              <div className="belowDetails">
                <div className="imgWrapper">
                  <div className="row heightAuto">
                    <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                      <div className="txt">
                        <div className="innerTxt">Contact</div>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-12 p-0">
                      <div className="allCOntact">
                        <div className="emailAndCall">
                          {/* <div className="callNnumber">
                            <img src={ic_call} alt="" />
                            <label className="numberInn">
                              {drData?.doctor_contact_number}
                              +91 9509932234
                            </label>
                          </div> */}
                          <div className="emialInn">
                            <img src={ic_mail} alt="" />
                            <label className="numberInn">
                              {/* {drData?.doctor_email} */}
                              info@animalsapp.in
                            </label>
                          </div>
                        </div>
                        {/* <div className="locationInn">
                          <img src={ic_location} alt="" />
                          <label className="locationTxt">
                            {drData?.doctor_clinic_address}
                            Near Ravi steel, kamde, ratu Road, Ranchi,
                            Jharkhand, India 835222
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={VaccinePrescription}
          onHide={() => setVaccinePrescription(false)}
          size="lg"
        >
          <Modal.Body>
            <div className="orderModalInvoice">
              <div className="modalHeading">
                <div className="upperBlue">
                  <h1 className="docName">Dr. {drData?.doctor_name} </h1>
                  <label className="domainLab">{drData?.specialization} </label>
                  <label className="certificateLab">
                    Registration Number {drData?.doctor_registration_no}
                  </label>
                </div>
                <div className="docSymbolBox">
                  <img src={DoctorSymbol} className="docSymImg" alt="" />
                </div>
              </div>
              <div className="modalBody">
                <div className="detailsFill">
                  <div className="labelTxt">
                    <label className="widthFixed">Patient Name:</label>
                  </div>
                  <input
                    type="text"
                    className="form-control formSolution"
                    value={patientDet?.animal_name}
                    disabled
                  />
                </div>
                <div className="addressFlex">
                  <div className="labelTxt">
                    <label className="keyQuestion">Address: </label>
                  </div>
                  <p className="text-para">{patientDet?.address || ""}</p>
                  {/* <input
                    type="text"
                    className="form-control formSolution"
                    value={patientDet?.address}
                    disabled
                  /> */}
                </div>
                <div className="dateNdAgeFlex">
                  <div className="ageBox">
                    <label className="ageLab">Age:</label>
                    <input
                      type="text"
                      className="form-control formSolution"
                      value={patientDet?.animal_age}
                      disabled
                    />
                  </div>
                  <div className="dateBox">
                    <label className="ageLab">Date:</label>
                    <input
                      type="text"
                      className="form-control formSolution"
                      value={patientDet?.date}
                      disabled
                    />
                  </div>
                </div>
                <div className="addressFlex">
                  <div className="labelTxt margFiv">
                    <label className="keyQuestion">symptoms:</label>
                  </div>
                  <input
                    type="text"
                    className="form-control formSolution"
                    value={patientDet?.disease}
                    disabled
                  />
                </div>
                <div className="tableAndLandingBg">
                  <div className="backGroundPic"></div>
                  <div className="innerTable">
                    <table className="modalTable">
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Prescription</th>
                          <th>Frequency</th>
                          <th>Dose</th>
                          <th>Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vaccineData && vaccineData.length > 0 ? (
                          vaccineData.map((itemlist, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{itemlist.vaccine}</td>
                                <td>{itemlist.prescription_frequency}</td>
                                <td>{itemlist.dose}</td>
                                <td>{itemlist.duration}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="signatureContent">
                  <div className="sigBox">
                    <div className="line">
                      <img
                        src={drData?.doctor_signature_path}
                        alt=""
                        height="100px"
                      />
                    </div>

                    {/* <label htmlFor="sigNatureFor" className="signaturePad">
                    <input type="file" id="sigNatureFor" className="d-none" />
                  </label> */}
                    <label className="belowTxt">SIGNATURE</label>
                  </div>
                </div>
              </div>
              <div className="belowDetails">
                <div className="imgWrapper">
                  <div className="row heightAuto">
                    <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                      <div className="txt">
                        <div className="innerTxt">Contact</div>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-12 p-0">
                      <div className="allCOntact">
                        <div className="emailAndCall">
                          {/* <div className="callNnumber">
                            <img src={ic_call} alt="" />
                            <label className="numberInn">
                              {drData?.doctor_contact_number}
                              +91 9509932234
                            </label>
                          </div> */}
                          <div className="emialInn">
                            <img src={ic_mail} alt="" />
                            <label className="numberInn">
                              {/* {drData?.doctor_email} */}
                              info@animalsapp.in
                            </label>
                          </div>
                        </div>
                        {/* <div className="locationInn">
                          <img src={ic_location} alt="" />
                          <label className="locationTxt">
                            {drData?.doctor_clinic_address}
                            Near Ravi steel, kamde, ratu Road, Ranchi,
                            Jharkhand, India 835222
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={petFoodPrescription}
          onHide={() => setPetFoodPrescription(false)}
          size="lg"
        >
          <Modal.Body>
            <div className="orderModalInvoice">
              <div className="modalHeading">
                <div className="upperBlue">
                  <h1 className="docName">Dr. {drData?.doctor_name} </h1>
                  <label className="domainLab">{drData?.specialization} </label>
                  <label className="certificateLab">
                  Registration Number {drData?.doctor_registration_no}
                  </label>
                </div>
                <div className="docSymbolBox">
                  <img src={DoctorSymbol} className="docSymImg" alt="" />
                </div>
              </div>
              <div className="modalBody">
                <div className="detailsFill">
                  <div className="labelTxt">
                    <label className="widthFixed">Patient Name:</label>
                  </div>
                  <input
                    type="text"
                    className="form-control formSolution"
                    value={patientDet?.animal_name}
                    disabled
                  />
                </div>
                <div className="addressFlex">
                  <div className="labelTxt">
                    <label className="keyQuestion">Address:</label>
                  </div>
                  <p className="text-para">{patientDet?.address || ""}</p>
                  {/* <input
                    type="text"
                    className="form-control formSolution"
                    value={patientDet?.address}
                    disabled
                  /> */}
                </div>
                <div className="dateNdAgeFlex">
                  <div className="ageBox">
                    <label className="ageLab">Age:</label>
                    <input
                      type="text"
                      className="form-control formSolution"
                      value={patientDet?.animal_age}
                      disabled
                    />
                  </div>
                  <div className="dateBox">
                    <label className="ageLab">Date:</label>
                    <input
                      type="text"
                      className="form-control formSolution"
                      value={patientDet?.date}
                      disabled
                    />
                  </div>
                </div>
                <div className="addressFlex">
                  <div className="labelTxt margFiv">
                    <label className="keyQuestion">symptoms:</label>
                  </div>
                  <input
                    type="text"
                    className="form-control formSolution"
                    value={patientDet?.disease}
                    disabled
                  />
                </div>
                <div className="tableAndLandingBg">
                  <div className="backGroundPic"></div>
                  <div className="innerTable">
                    <table className="modalTable">
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Prescription</th>
                          <th>Frequency</th>
                          <th>Duration</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {petFoodData && petFoodData.length > 0 ? (
                          petFoodData.map((itemlist, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{itemlist.product}</td>
                                <td>{itemlist.prescription_frequency}</td>
                                <td>{itemlist.duration}</td>
                                <td>{itemlist.quantity}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="signatureContent">
                  <div className="sigBox">
                    <div className="line">
                      <img
                        src={drData?.doctor_signature_path}
                        alt=""
                        height="100px"
                      />
                    </div>

                    {/* <label htmlFor="sigNatureFor" className="signaturePad">
                    <input type="file" id="sigNatureFor" className="d-none" />
                  </label> */}
                    <label className="belowTxt">SIGNATURE</label>
                  </div>
                </div>
              </div>
              <div className="belowDetails">
                <div className="imgWrapper">
                  <div className="row heightAuto">
                    <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                      <div className="txt">
                        <div className="innerTxt">Contact</div>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-12 p-0">
                      <div className="allCOntact">
                        <div className="emailAndCall">
                          {/* <div className="callNnumber">
                            <img src={ic_call} alt="" />
                            <label className="numberInn">
                              {drData?.doctor_contact_number}
                              +91 9509932234
                            </label>
                          </div> */}
                          <div className="emialInn">
                            <img src={ic_mail} alt="" />
                            <label className="numberInn">
                              {/* {drData?.doctor_email} */}
                              info@animalsapp.in
                            </label>
                          </div>
                        </div>
                        {/* <div className="locationInn">
                          <img src={ic_location} alt="" />
                          <label className="locationTxt">
                            {drData?.doctor_clinic_address}
                            Near Ravi steel, kamde, ratu Road, Ranchi,
                            Jharkhand, India 835222
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Doctor appointment History Modal */}
        <Modal
          show={historyPres}
          onHide={() => setHistoryPres(false)}
          size="lg"
        >
          <Modal.Body>
            <div className="orderModalInvoice">
              <div className="modalHeading">
                <div className="upperBlue">
                  <h1 className="docName">
                    Dr. {historyPresData?.doctor?.[0]?.doctor_name}
                  </h1>
                  <label className="domainLab">
                    {historyPresData?.doctor?.[0]?.specialization}
                  </label>
                  <label className="certificateLab">
                  Registration Number {drData?.doctor_registration_no}
                  </label>
                </div>
                <div className="docSymbolBox">
                  <img src={DoctorSymbol} className="docSymImg" alt="" />
                </div>
              </div>
              <div className="modalBody">
                <div className="detailsFill">
                  <div className="labelTxt">
                    <label className="widthFixed">Patient Name:</label>
                  </div>
                  <input
                    type="text"
                    className="form-control formSolution"
                    value={historyPresData?.customer?.[0]?.animal_name}
                    disabled
                  />
                </div>
                <div className="addressFlex">
                  <div className="labelTxt">
                    <label className="keyQuestion">Address:</label>
                  </div>
                  <p className="text-para">{historyPresData?.address || ""}</p>
                  {/* <input
                    type="text"
                    className="form-control formSolution"
                    value={historyPresData?.customer?.[0]?.address}
                    disabled
                  /> */}
                </div>
                <div className="dateNdAgeFlex">
                  <div className="ageBox">
                    <label className="ageLab">Age:</label>
                    <input
                      type="text"
                      className="form-control formSolution"
                      value={historyPresData?.customer?.[0]?.animal_age}
                      disabled
                    />
                  </div>
                  <div className="dateBox">
                    <label className="ageLab">Date:</label>
                    <input
                      type="text"
                      className="form-control formSolution"
                      value={historyPresData?.customer?.[0]?.date}
                      disabled
                    />
                  </div>
                </div>
                <div className="addressFlex">
                  <div className="labelTxt margFiv">
                    <label className="keyQuestion">symptoms:</label>
                  </div>
                  <input
                    type="text"
                    className="form-control formSolution"
                    value={historyPresData?.customer?.[0]?.disease}
                    disabled
                  />
                </div>
                <div className="tableAndLandingBg">
                  <div className="backGroundPic"></div>
                  <div className="innerTable">
                    <table className="modalTable">
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Prescription</th>
                          <th>Frequency</th>
                          <th>Duration</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {historyPresData?.prescription &&
                          historyPresData?.prescription.length > 0 ? (
                          historyPresData?.prescription.map(
                            (itemlist, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{itemlist.product}</td>
                                  <td>{itemlist.prescription_frequency}</td>
                                  <td>{itemlist.duration}</td>
                                  <td>{itemlist.quantity}</td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="signatureContent">
                  <div className="sigBox">
                    <div className="line">
                      <img
                        src={
                          historyPresData?.doctor?.[0]?.doctor_signature_path
                        }
                        alt=""
                        height="100px"
                      />
                    </div>

                    {/* <label htmlFor="sigNatureFor" className="signaturePad">
                    <input type="file" id="sigNatureFor" className="d-none" />
                  </label> */}
                    <label className="belowTxt">SIGNATURE</label>
                  </div>
                </div>
              </div>
              <div className="belowDetails">
                <div className="imgWrapper">
                  <div className="row heightAuto">
                    <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                      <div className="txt">
                        <div className="innerTxt">Contact</div>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-12 p-0">
                      <div className="allCOntact">
                        <div className="emailAndCall">
                          <div className="callNnumber">
                            <img src={ic_call} alt="" />
                            <label className="numberInn">
                              {/* {drData?.doctor_contact_number} */}
                              +91 9509932234
                            </label>
                          </div>
                          <div className="emialInn">
                            <img src={ic_mail} alt="" />
                            <label className="numberInn">
                              {/* {drData?.doctor_email} */}
                              info@animalsapp.in
                            </label>
                          </div>
                        </div>
                        {/* <div className="locationInn">
                          <img src={ic_location} alt="" />
                          <label className="locationTxt">
                            {drData?.doctor_clinic_address}
                            Near Ravi steel, kamde, ratu Road, Ranchi,
                            Jharkhand, India 835222
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={videoHistoryPres}
          onHide={() => setVideoHistoryPres(false)}
          size="lg"
        >
          <Modal.Body>
            <div className="orderModalInvoice">
              <div className="modalHeading">
                <div className="upperBlue">
                  <h1 className="docName">
                    Dr. {videohistoryPresData?.doctor?.[0]?.doctor_name}
                  </h1>
                  <label className="domainLab">
                    {videohistoryPresData?.doctor?.[0]?.specialization}
                  </label>
                  <label className="certificateLab">
                  Registration Number {drData?.doctor_registration_no}
                  </label>
                </div>
                <div className="docSymbolBox">
                  <img src={DoctorSymbol} className="docSymImg" alt="" />
                </div>
              </div>
              <div className="modalBody">
                <div className="detailsFill">
                  <div className="labelTxt">
                    <label className="widthFixed">Patient Name:</label>
                  </div>
                  <input
                    type="text"
                    className="form-control formSolution"
                    value={videohistoryPresData?.customer?.[0]?.animal_name}
                    disabled
                  />
                </div>
                <div className="addressFlex">
                  <div className="labelTxt">
                    <label className="keyQuestion">Address:</label>
                  </div>
                  <p className="text-para">{videohistoryPresData?.address || ""}</p>
                  {/* <input
                    type="text"
                    className="form-control formSolution"
                    value={videohistoryPresData?.customer?.[0]?.address}
                    disabled
                  /> */}
                </div>
                <div className="dateNdAgeFlex">
                  <div className="ageBox">
                    <label className="ageLab">Age:</label>
                    <input
                      type="text"
                      className="form-control formSolution"
                      value={videohistoryPresData?.customer?.[0]?.animal_age}
                      disabled
                    />
                  </div>
                  <div className="dateBox">
                    <label className="ageLab">Date:</label>
                    <input
                      type="text"
                      className="form-control formSolution"
                      value={videohistoryPresData?.customer?.[0]?.date}
                      disabled
                    />
                  </div>
                </div>
                <div className="addressFlex">
                  <div className="labelTxt margFiv">
                    <label className="keyQuestion">symptoms:</label>
                  </div>
                  <input
                    type="text"
                    className="form-control formSolution"
                    value={videohistoryPresData?.customer?.[0]?.disease}
                    disabled
                  />
                </div>
                <div className="tableAndLandingBg">
                  <div className="backGroundPic"></div>
                  <div className="innerTable">
                    <table className="modalTable">
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Prescription</th>
                          <th>Frequency</th>
                          <th>Duration</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {videohistoryPresData?.prescription &&
                          videohistoryPresData?.prescription.length > 0 ? (
                          videohistoryPresData?.prescription.map(
                            (itemlist, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{itemlist.product}</td>
                                  <td>{itemlist.prescription_frequency}</td>
                                  <td>{itemlist.duration}</td>
                                  <td>{itemlist.quantity}</td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="signatureContent">
                  <div className="sigBox">
                    <div className="line">
                      <img
                        src={
                          videohistoryPresData?.doctor?.[0]
                            ?.doctor_signature_path
                        }
                        alt=""
                        height="100px"
                      />
                    </div>

                    {/* <label htmlFor="sigNatureFor" className="signaturePad">
                    <input type="file" id="sigNatureFor" className="d-none" />
                  </label> */}
                    <label className="belowTxt">SIGNATURE</label>
                  </div>
                </div>
              </div>
              <div className="belowDetails">
                <div className="imgWrapper">
                  <div className="row heightAuto">
                    <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                      <div className="txt">
                        <div className="innerTxt">Contact</div>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-12 p-0">
                      <div className="allCOntact">
                        <div className="emailAndCall">
                          <div className="callNnumber">
                            <img src={ic_call} alt="" />
                            <label className="numberInn">
                              {/* {drData?.doctor_contact_number} */}
                              +91 9509932234
                            </label>
                          </div>
                          <div className="emialInn">
                            <img src={ic_mail} alt="" />
                            <label className="numberInn">
                              {/* {drData?.doctor_email} */}
                              info@animalsapp.in
                            </label>
                          </div>
                        </div>
                        {/* <div className="locationInn">
                          <img src={ic_location} alt="" />
                          <label className="locationTxt">
                            {drData?.doctor_clinic_address}
                            Near Ravi steel, kamde, ratu Road, Ranchi,
                            Jharkhand, India 835222
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};

export default PrescriptionComp;
