import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import edu_icon from "../../assets/images/icons/edu_icon.svg";
import exp_icon from "../../assets/images/icons/exp_icon.svg";
import regi_icon from "../../assets/images/icons/regi_icon.svg";
import sign_icon from "../../assets/images/icons/sign_icon.svg";
import clinic_img_1 from "../../assets/images/clinic_img_1.svg";
import clinic_img_2 from "../../assets/images/clinic_img_2.svg";
import clinic_img_3 from "../../assets/images/clinic_img_3.svg";
import clinic_img_4 from "../../assets/images/clinic_img_4.svg";
import Service_icon from "../../assets/images/icons/Service_icon.svg";
import upload_remove from "../../assets/images/icons/upload_remove.svg";
import delete_upload from "../../assets/images/icons/delete_upload.svg";
import upload_success from "../../assets/images/icons/upload_success.svg";
import upload_doc_icon from "../../assets/images/icons/upload_doc_icon.svg";
import upload_icon from "../../assets/images/icons/upload_icon.svg";
import upload_doc_img from "../../assets/images/upload_doc_img.svg";
import { DarkButton } from "../commonComponents/commonComp";
import Select from "react-select";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { useNavigate } from "react-router-dom";
import {
  PutCallWithErrorResponse,
  simpleGetCall,
  simplePostCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";

const ClinicSchedule = () => {
  const navigate = useNavigate();
  const { setLoading } = useContext(AppContext);
  const doctor_clinic_id = localStorage.doctor_clinic_id
    ? parseInt(localStorage.doctor_clinic_id)
    : "";
  const doctorId = localStorage.getItem("sign_doctor_id")
    ? localStorage.getItem("sign_doctor_id")
    : null;
  const doctorClinicId = sessionStorage.doctor_clinic_id
    ? parseInt(sessionStorage.doctor_clinic_id)
    : "";

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("doctorClinicId");
    };
  }, []);
  const [isAsPerAppointMent, setIsAsPerAppointMent] = useState(false);
  const [value, onChange] = useState("08:01");
  const [value1, onChange1] = useState("11:01");

  const [value2, onChange2] = useState("13:01");
  const [value3, onChange3] = useState("20:01");

  const [value4, onChange4] = useState("08:01");
  const [value5, onChange5] = useState("11:01");

  const [value6, onChange6] = useState("13:01");
  const [value7, onChange7] = useState("20:01");
  const [Both, setBoth] = useState(true);

  const [ClinicSchedule, setClinicSchedule] = useState({
    is_weekend_mon: false,
    is_weekend_tue: false,
    is_weekend_wed: false,
    is_weekend_thu: false,
    is_weekend_fri: false,
    is_weekend_sat: false,
    is_weekend_sun: false,
    is_morning_shift: true,
    is_evening_shift: true,

    slot_morning_end_time: "",
    slot_morning_no_of_appointments: "0",
    slot_evening_no_of_appointments: "0",
    slot_morning_doctor_charge: "0",

    slot_evening_end_time: "",
    slot_evening_doctor_charge: "0",
    clinic_morning_start_time: "",
    clinic_evening_start_time: "",
    clinic_evening_end_time: "",
    is_both: false,
  });

  console.log("ClinicSchedule", ClinicSchedule);

  const addSchedule = () => {
    setLoading(true);
    simplePostCall(
      ApiConfig.ADD_CLINIC_SCHEDULE,
      JSON.stringify({
        ...ClinicSchedule,
        doctor_clinic_id: doctor_clinic_id,
        doctor_id: doctorId,
        slot_morning_start_time: value + " " + "ISD",
        slot_morning_end_time: value1 + " " + "ISD",
        slot_evening_start_time: value2 + " " + "ISD",
        slot_evening_end_time: value3 + " " + "ISD",
        clinic_morning_start_time: value4 + " " + "ISD",
        clinic_morning_end_time: value5 + " " + "ISD",
        clinic_evening_start_time: value6 + " " + "ISD",
        clinic_evening_end_time: value7 + " " + "ISD",
        is_both: Both,
      })
    )
      .then((res) => {
        if (res.success) {
          navigate("/VerificationUnderProcess");
        } else {
          notifyError("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getClinicScheduleDetails();
  }, []);

  const getClinicScheduleDetails = async () => {
    try {
      const res = await simpleGetCall(
        `${ApiConfig.GET_CLINIC_SCHEDULE_DETAILS}/${doctorClinicId}`
      );
      const { success, data } = res;
      if (success && data) {
        setClinicSchedule({
          ...ClinicSchedule,
          is_weekend_mon: data?.is_weekend_mon ? data?.is_weekend_mon : false,
          is_weekend_tue: data?.is_weekend_tue ? data?.is_weekend_tue : false,
          is_weekend_wed: data?.is_weekend_wed ? data?.is_weekend_wed : false,
          is_weekend_thu: data?.is_weekend_thu ? data?.is_weekend_thu : false,
          is_weekend_fri: data?.is_weekend_fri ? data?.is_weekend_fri : false,
          is_weekend_sat: data?.is_weekend_sat ? data?.is_weekend_sat : false,
          is_weekend_sun: data?.is_weekend_sun ? data?.is_weekend_sun : false,
          is_morning_shift: data?.is_morning_shift
            ? data?.is_morning_shift
            : false,
          is_evening_shift: data?.is_evening_shift
            ? data?.is_evening_shift
            : false,

          slot_morning_no_of_appointments:
            data?.slot_morning_no_of_appointments || "0",
          slot_evening_no_of_appointments:
            data?.slot_evening_no_of_appointments || "0",
          slot_morning_doctor_charge: data?.slot_morning_doctor_charge || "0",
          slot_evening_doctor_charge: data?.slot_evening_doctor_charge || "0",
        });
      }

      onChange(data.slot_morning_start_time || "");
      onChange1(data.slot_morning_end_time || "");

      onChange2(data.slot_evening_start_time || "");
      onChange3(data.slot_evening_end_time || "");

      onChange4(data.clinic_morning_start_time || "");
      onChange5(data.clinic_morning_end_time || "");

      onChange6(data.clinic_evening_start_time || "");
      onChange7(data.clinic_evening_end_time || "");

      const isBoth =
        data.is_evening_shift && data.is_morning_shift ? true : false;
      setBoth(isBoth);
    } catch (error) {
      console.error("catch error", error);
    }
  };

  const updateScheduleDetails = async () => {
    try {
      setLoading(true);
      const res = await PutCallWithErrorResponse(
        ApiConfig.UPDATE_CLINIC_SCHEDULE_DETAILS,
        JSON.stringify({
          ...ClinicSchedule,
          doctor_clinic_id: doctorClinicId,
          slot_morning_start_time: value + " " + "ISD",
          slot_morning_end_time: value1 + " " + "ISD",
          slot_evening_start_time: value2 + " " + "ISD",
          slot_evening_end_time: value3 + " " + "ISD",
          clinic_morning_start_time: value4 + " " + "ISD",
          clinic_morning_end_time: value5 + " " + "ISD",
          clinic_evening_start_time: value6 + " " + "ISD",
          clinic_evening_end_time: value7 + " " + "ISD",
          is_both: Both,
        })
      );
      const { success, message } = res?.json;
      if (success) {
        notifySuccess(message);
        navigate("/MyProfile");
      }
    } catch (error) {
      console.log("catch error of update schedule details,", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="authMain">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div style={{ display: "flex" }}>
              <img src={logo} alt="" className="login_img" />
              <div style={{ width: "100%", textAlign: "center" }}>
                <p
                  style={{
                    fontSize: "30px",
                    fontWeight: "600",
                    lineHeight: "45px",
                    color: "#34528A",
                    display: "inline-block",
                  }}
                >
                  Lets give your profile a final touch
                </p>
              </div>
            </div>
            <div className="clinic_schedule">
              <div className="cs_header_wrapper">
                <div className="row">
                  <div className="col-lg-4">
                    <h2 className="cs_header">SET YOUR SCHEDULE</h2>
                  </div>
                  <div className="col-lg-8">
                    <div className="shift_and_days_selector">
                      <div className="weekend_selecter">
                        <label className="schedule_label">
                          Select Weekend Days
                        </label>
                        <div className="weekDays-selector">
                          <input
                            type="checkbox"
                            id="weekday-mon"
                            className="weekday"
                            checked={ClinicSchedule.is_weekend_mon}
                            onChange={(e) => {
                              setClinicSchedule({
                                ...ClinicSchedule,
                                is_weekend_mon: e.target.checked,
                              });
                            }}
                          />
                          <label htmlFor="weekday-mon">Mon</label>
                          <input
                            type="checkbox"
                            id="weekday-tue"
                            className="weekday"
                            checked={ClinicSchedule.is_weekend_tue}
                            onChange={(e) => {
                              setClinicSchedule({
                                ...ClinicSchedule,
                                is_weekend_tue: e.target.checked,
                              });
                            }}
                          />
                          <label htmlFor="weekday-tue">Tue</label>
                          <input
                            type="checkbox"
                            id="weekday-wed"
                            className="weekday"
                            checked={ClinicSchedule.is_weekend_wed}
                            onChange={(e) => {
                              setClinicSchedule({
                                ...ClinicSchedule,
                                is_weekend_wed: e.target.checked,
                              });
                            }}
                          />
                          <label htmlFor="weekday-wed">Wed</label>
                          <input
                            type="checkbox"
                            id="weekday-thu"
                            className="weekday"
                            checked={ClinicSchedule.is_weekend_thu}
                            onChange={(e) => {
                              setClinicSchedule({
                                ...ClinicSchedule,
                                is_weekend_thu: e.target.checked,
                              });
                            }}
                          />
                          <label htmlFor="weekday-thu">Thu</label>
                          <input
                            type="checkbox"
                            id="weekday-fri"
                            className="weekday"
                            checked={ClinicSchedule.is_weekend_fri}
                            onChange={(e) => {
                              setClinicSchedule({
                                ...ClinicSchedule,
                                is_weekend_fri: e.target.checked,
                              });
                            }}
                          />
                          <label htmlFor="weekday-fri">Fri</label>
                          <input
                            type="checkbox"
                            id="weekday-sat"
                            className="weekday"
                            checked={ClinicSchedule.is_weekend_sat}
                            onChange={(e) => {
                              setClinicSchedule({
                                ...ClinicSchedule,
                                is_weekend_sat: e.target.checked,
                              });
                            }}
                          />
                          <label htmlFor="weekday-sat">Sat</label>
                          <input
                            type="checkbox"
                            id="weekday-sun"
                            className="weekday"
                            checked={ClinicSchedule.is_weekend_sun}
                            onChange={(e) => {
                              setClinicSchedule({
                                ...ClinicSchedule,
                                is_weekend_sun: e.target.checked,
                              });
                            }}
                          />
                          <label htmlFor="weekday-sun">Sun</label>
                        </div>
                      </div>
                      <div className="shift_selecter">
                        <label className="schedule_label">
                          Select Shift time
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            className="radio-button__input"
                            id="choice1-1"
                            name="choice1"
                            // value={ClinicSchedule.is_morning_shift}
                            // checked={ClinicSchedule?.is_morning_shift}
                            onChange={(e) => {
                              setClinicSchedule({
                                ...ClinicSchedule,
                                is_morning_shift: e.target.checked,
                                is_evening_shift: false,
                              });
                              setBoth(!e.target.checked);
                            }}
                          />
                          <span className="radio-button__control"></span>
                          <span
                            className="radio-button__label"
                            htmlFor="choice1-1"
                          >
                            Morning
                          </span>
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            className="radio-button__input"
                            id="choice1-2"
                            name="choice1"
                            // checked={ClinicSchedule.is_evening_shift}
                            onChange={(e) => {
                              setClinicSchedule({
                                ...ClinicSchedule,
                                is_evening_shift: e.target.checked,
                                is_morning_shift: false,
                              });
                              setBoth(!e.target.checked);
                            }}
                          />
                          <span className="radio-button__control"></span>
                          <span className="radio-button__label">Evening</span>
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            className="radio-button__input"
                            id="choice1-3"
                            name="choice1"
                            checked={Both}
                            onChange={(e) => {
                              setBoth(e.target.checked);
                              setClinicSchedule({
                                ...ClinicSchedule,
                                is_evening_shift: e.target.checked,
                                is_morning_shift: e.target.checked,
                              });
                            }}
                          />
                          <span className="radio-button__control"></span>
                          <span className="radio-button__label">Both</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="schedule_cards_wrapper">
                <div className="row">
                  <div className="col-lg-6 mb-4">
                    {" "}
                    <div className="schedule_card">
                      <div className="sc_header_wrapper">
                        <h3 className="sc_header">SLOT SELECTION</h3>
                      </div>
                      <div className="sc_inner">
                        {ClinicSchedule?.is_morning_shift && (
                          <div className="sc_inner_card sc_top">
                            <h3 className="sct_header">Morning section</h3>
                            <div className="date_wrapper">
                              <label>Start Time</label>

                              <TimePicker
                                onChange={onChange}
                                value={value}
                                format="h:mm a"
                              />
                            </div>
                            <div className="date_wrapper">
                              <label>End Time</label>
                              <TimePicker
                                onChange={onChange1}
                                value={value1}
                                format="h:mm a"
                              />
                            </div>
                            <div className="appointment_wrapper">
                              <label>No. of Appointments</label>
                              <div className="selection">
                                <input
                                  type="text"
                                  className="appointmentLine"
                                  placeholder=""
                                  value={
                                    ClinicSchedule.slot_morning_no_of_appointments
                                  }
                                  onChange={(e) => {
                                    let regex = /^[0-9]*$/;
                                    if (regex.test(e.target.value)) {
                                      setClinicSchedule({
                                        ...ClinicSchedule,
                                        slot_morning_no_of_appointments:
                                          e.target.value,
                                      });
                                    }
                                  }}
                                />
                                {/* <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={
                                      ClinicSchedule.slot_morning_no_of_appointments
                                    }
                                    onChange={(e) => {
                                      setClinicSchedule({
                                        ...ClinicSchedule,
                                        slot_morning_no_of_appointments:
                                          e.target.value,
                                      });
                                    }}
                                  >
                                    <option selected value="1">
                                      01
                                    </option>
                                    <option value="2">02</option>
                                    <option value="3">03</option>
                                    <option value="4">04</option>
                                    <option value="5">05</option>
                                    <option value="6">06</option>
                                    <option value="7">07</option>
                                    <option value="8">08</option>
                                    <option value="9">09</option>
                                    <option value="10">10</option>
                                  </select> */}
                              </div>
                            </div>
                            <div className="doctor_charges_wrapper">
                              <label>Doctor Charge</label>
                              <div className="selection">
                                <input
                                  type="text"
                                  className="appointmentLine"
                                  placeholder=""
                                  value={
                                    ClinicSchedule.slot_morning_doctor_charge
                                  }
                                  onChange={(e) => {
                                    let regex = /^[0-9]*$/;
                                    if (regex.test(e.target.value)) {
                                      setClinicSchedule({
                                        ...ClinicSchedule,
                                        slot_morning_doctor_charge:
                                          e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {ClinicSchedule?.is_evening_shift && (
                          <div className="sc_inner_card">
                            <h3 className="sct_header">Evening section</h3>
                            <div className="date_wrapper">
                              <label>Start Time</label>
                              <TimePicker
                                onChange={onChange2}
                                value={value2}
                                format="h:mm a"
                              />
                            </div>
                            <div className="date_wrapper">
                              <label>End Time</label>
                              <TimePicker
                                onChange={onChange3}
                                value={value3}
                                format="h:mm a"
                              />
                            </div>
                            <div className="appointment_wrapper">
                              <label>No. of Appointments</label>
                              <div className="selection">
                                <input
                                  type="text"
                                  className="appointmentLine"
                                  placeholder=""
                                  value={
                                    ClinicSchedule.slot_evening_no_of_appointments
                                  }
                                  onChange={(e) => {
                                    let regex = /^[0-9]*$/;
                                    if (regex.test(e.target.value)) {
                                      setClinicSchedule({
                                        ...ClinicSchedule,
                                        slot_evening_no_of_appointments:
                                          e.target.value,
                                      });
                                    }
                                  }}
                                />
                                {/* <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={
                                      ClinicSchedule.slot_evening_no_of_appointments
                                    }
                                    onChange={(e) => {
                                      setClinicSchedule({
                                        ...ClinicSchedule,
                                        slot_evening_no_of_appointments:
                                          e.target.value,
                                      });
                                    }}
                                  >
                                    <option selected value="1">
                                      01
                                    </option>
                                    <option value="2">02</option>
                                    <option value="3">03</option>
                                    <option value="4">04</option>
                                  </select> */}
                              </div>
                            </div>
                            <div className="doctor_charges_wrapper">
                              <label>Doctor Charge</label>
                              <div className="selection">
                                <input
                                  type="text"
                                  className="appointmentLine"
                                  placeholder=""
                                  value={
                                    ClinicSchedule.slot_evening_doctor_charge
                                  }
                                  onChange={(e) => {
                                    let regex = /^[0-9]*$/;
                                    if (regex.test(e.target.value)) {
                                      setClinicSchedule({
                                        ...ClinicSchedule,
                                        slot_evening_doctor_charge:
                                          e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    {/* <div className="col-lg-6 mb-4"> */}
                    <div className="schedule_card">
                      <div className="sc_header_wrapper">
                        <h3 className="sc_header">CLINIC TIME</h3>
                        <div className="sc_switch2">
                          <input
                            type="checkbox"
                            id="appoint"
                            onChange={(e) => {
                              if (e.target.checked) {
                                onChange4(value);
                                onChange5(value1);
                                onChange6(value2);
                                onChange7(value3);
                                setIsAsPerAppointMent(e.target.checked)
                              } else {
                                onChange4("08:01");
                                onChange5("11:01");
                                onChange6("13:01");
                                onChange7("20:01");
                                setIsAsPerAppointMent(!e.target.checked)
                              }
                            }}
                          />
                          <label htmlFor="appoint">
                            As per Appointment time
                          </label>
                        </div>
                      </div>
                      <div className="sc_inner">
                        {ClinicSchedule?.is_morning_shift && (
                          <div className="sc_inner_card sc_top">
                            <h3 className="sct_header">Morning section</h3>
                            <div className="date_wrapper">
                              <label>Start Time</label>
                              <TimePicker
                                onChange={onChange4}
                                value={value4}
                                format="h:mm a"
                              />
                            </div>
                            <div className="date_wrapper">
                              <label>End Time</label>
                              <TimePicker
                                onChange={onChange5}
                                value={value5}
                                format="h:mm a"
                              />
                            </div>
                          </div>
                        )}

                        {ClinicSchedule?.is_evening_shift && (
                          <div className="sc_inner_card">
                            <h3 className="sct_header">Evening section</h3>
                            <div className="date_wrapper">
                              <label>Start Time</label>
                              <TimePicker
                                onChange={onChange6}
                                value={value6}
                                format="h:mm a"
                              />
                            </div>
                            <div className="date_wrapper">
                              <label>End Time</label>
                              <TimePicker
                                onChange={onChange7}
                                value={value7}
                                format="h:mm a"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>

              <div className="login btn-width-100">
                {/* <DarkButton
                  onClick={() => navigate("/ClinicSchedule")}
                  classbtn={
                    "cx-btn-light me-3  btn-width-45 btn-height font-17 mb-5"
                  }
                  btn={"Back"}
                /> */}
                <DarkButton
                  onClick={() => {
                    if (doctorClinicId) {
                      updateScheduleDetails();
                    } else {
                      addSchedule();
                    }
                  }}
                  classbtn={"cx-btn-dark  btn-height btn-width-50 font-17 mb-5"}
                  btn={doctorClinicId ? "Update" : "Send Request"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClinicSchedule;
