import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import edu_icon from "../../assets/images/icons/edu_icon.svg";
import exp_icon from "../../assets/images/icons/exp_icon.svg";
import regi_icon from "../../assets/images/icons/regi_icon.svg";
import sign_icon from "../../assets/images/icons/sign_icon.svg";
import specilization_icon from "../../assets/images/icons/specilization_icon.svg";
import Service_icon from "../../assets/images/icons/Service_icon.svg";
import age_icon from "../../assets/images/icons/age_icon.svg";
import gender_icon from "../../assets/images/icons/gender_icon.svg";
import user_icon from "../../assets/images/icons/user_icon.svg";
import img_preview_delete_icon from "../../assets/images/icons/img_preview_delete_icon.svg";
import general_info from "../../assets/images/general_info.svg";
import UploadPP_icon from "../../assets/images/UploadPP_icon.svg";
import { DarkButton } from "../commonComponents/commonComp";
import Select from "react-select";
import teleCall from "../../assets/images/ic_call1.svg";
import { useNavigate } from "react-router-dom";
import { getWithAuthCall, multipartPostCall } from "../../api/ApiServices";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";

const GeneralInfo = () => {
  const { setLoading } = useContext(AppContext);
  const doctorId = localStorage.getItem("sign_doctor_id") ? localStorage.getItem("sign_doctor_id") : null;
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [signature, setSignature] = useState();
  const [deleteImg, setDeleteImg] = useState(false);
  const [addhar, setAddhar] = useState(false);
  const [addharFront, setAddharFront] = useState(false);
  const [SpilizationType, setSpilizationType] = useState([]);


  const [EducationType, setEducationType] = useState([]);
  const [LangugeType, setLangugeType] = useState([]);


  function handleFileInput(e) {
    // setFile(URL.createObjectURL(e.target.files[0]));
    if (e.target.name === "aadhar") {
      setGenerallAdd({
        ...GenerallAdd,
        [e.target.name]: e.target.files[0],
        isImageChange: true,
      });
      setAddhar(true);

    } else setGenerallAdd({ ...GenerallAdd, [e.target.name]: e.target.value });
  }
  function handleFileInputfFront(e) {
    // setFile(URL.createObjectURL(e.target.files[0]));
    if (e.target.name === "aadharFrontSide") {
      setGenerallAdd({
        ...GenerallAdd,
        [e.target.name]: e.target.files[0],
        isImageChange: true,
      });
      setAddharFront(true);

    } else setGenerallAdd({ ...GenerallAdd, [e.target.name]: e.target.value });
  }

  function handleFileInput2(e) {
    // setSignature(URL.createObjectURL(e.target.files[0]));
    if (e.target.name === "signature") {
      setGenerallAdd({
        ...GenerallAdd,
        [e.target.name]: e.target.files[0],
        isImageChange: true,

      });

      setDeleteImg(true);
    } else setGenerallAdd({ ...GenerallAdd, [e.target.name]: e.target.value });

  }

  const handleDeleteImg = () => {
    setDeleteImg(!deleteImg);
  };
  const handleAadhar = () => {
    setAddhar(!addhar);
  };
  const handleAadharFront = () => {
    setAddharFront(!addharFront);
  };


  const options = [
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
    { value: "Marathi", label: "Marathi" },
    { value: "Urdu", label: "Urdu" },
  ];

  const [selected, setSelected] = useState(null);
  console.log("selected", selected);

  const handleChange = (e) => {
    setSelected(e.value);
  };
  // const [selectedOption, setSelectedOption] = useState("");

  // const handleChange1 = (selectedOption) => {
  //   setSelectedOption(selectedOption.value);
  // };

  useEffect(() => {
    getSpeciclizationlist();
    getEducation()
    getlangugae();
  }, []);

  const [GenerallAdd, setGenerallAdd] = useState({
    first_name: "",
    age: "",
    gender: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    doctor_contact_number: "",
    registration: "",
    signature: "",
    aadhar: "",
    aadharFrontSide: "",
    service: "",
    specialization: "",
    education: "",
    experience: "",
    language: "",
    doctor_id: "",
    profile: "",
  });


  const postaddGeneral = () => {
    setLoading(true);

    let formData = new FormData();
    formData.append("first_name", GenerallAdd.first_name);
    formData.append("age", GenerallAdd.age);
    formData.append("gender", GenerallAdd.gender);
    formData.append("last_name", GenerallAdd.last_name);

    formData.append("registration", GenerallAdd.registration);
    formData.append("signature", GenerallAdd.signature);
    formData.append("aadhar", GenerallAdd.aadhar);
    formData.append("aadharfront", GenerallAdd.aadharFrontSide);
    formData.append("service", GenerallAdd.service);
    formData.append("specialization", GenerallAdd.specialization);
    formData.append("education", GenerallAdd.education);
    formData.append("experience", GenerallAdd.experience);
    formData.append("language", GenerallAdd.language);
    formData.append("doctor_id", doctorId);
    formData.append("profile", GenerallAdd.profile);
    multipartPostCall(ApiConfig.GENERAL_IFO, formData)
      .then((res) => {
        if (res.success) {
          notifySuccess(res.message);
          navigate("/ClinicInfo");
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeImg = (e) => {
    if (e.target.name === "profile") {
      setGenerallAdd({
        ...GenerallAdd,
        [e.target.name]: e.target.files[0],
        isImageChange: true,
      });
    } else setGenerallAdd({ ...GenerallAdd, [e.target.name]: e.target.value });
  };

  function onTopicChange(selectedOption) {
    var selected_topics = [];

    if (selectedOption.length > 0) {
      selectedOption.map((topic, index) => {
        selected_topics.push(topic.value);
      });
    }
    console.log("selected_topics", selected_topics);
    setGenerallAdd({
      ...GenerallAdd,
      language: selected_topics,
    });
  }

  function getSpeciclizationlist() {
    getWithAuthCall(ApiConfig.SPECICLIZATION_DROPDOWN)
      .then((data) => {
        console.log(data);
        let alphaData = data?.data;
        alphaData.sort((a, b) => a.specialization.localeCompare(b.specialization));
        setSpilizationType(alphaData);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  function getEducation() {
    getWithAuthCall(ApiConfig.EDCATION_DROPDWN)
      .then((data) => {
        console.log(data);

        setEducationType(data.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }


  function getlangugae() {
    getWithAuthCall(ApiConfig.LANGUAGE_DROPDOWN)
      .then((data) => {
        console.log(data);
        let Type = data.data;
        var grades = [];

        Type.map((grade, index) => {
          grades.push({
            label: grade.language,
            value: grade.language_id,
          });
        });
        setLangugeType(grades);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  return (
    <>
      <div className="authMain">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <img src={logo} alt="" className="login_img" />
            <div className="signinForm signUp general_info">
              <h1 className="signInTxt">Let's Furnish your profile</h1>
              <div className="profile_upload">
                <div className="file-input">
                  <input
                    type="file"
                    name="profile"
                    id="file-input"
                    className="file-input__input"
                    // onChange={handleFileInput}
                    onChange={handleChangeImg}
                  />
                  <label className="file-input__label" for="file-input">
                    <span className="file_preview">
                      <img
                        //  src={file ? file : UploadPP_icon}
                        src={
                          !GenerallAdd.profile
                            ? UploadPP_icon
                            : GenerallAdd.profile.length
                              ?
                              GenerallAdd.profile
                              :
                              GenerallAdd.profile &&
                              URL.createObjectURL(GenerallAdd.profile)
                        }
                        alt=""
                      />
                    </span>
                  </label>
                  <label className="file-input__label2" for="file-input">
                    <span>Upload Profile Picture</span>
                  </label>
                </div>
                {/* <div className={file ? "file_preview" : "file_preview2"}>
                  <img src={file} alt="" />
                </div> */}
              </div>
              {/* <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  First Name
                </label>
                <img src={user_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your First name"
                  value={GenerallAdd.first_name}
                  onChange={(e) => {
                    let value = e.target.value;
                    let valueInput = value.replace(/[^A-Za-z ]/gi, "");
                    setGenerallAdd({
                      ...GenerallAdd,
                      first_name: valueInput,
                    });
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Last Name
                </label>
                <img src={user_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your Last name"
                  value={GenerallAdd.last_name}
                  onChange={(e) => {
                    let value = e.target.value;
                    let valueInput = value.replace(/[^A-Za-z ]/gi, "");
                    setGenerallAdd({
                      ...GenerallAdd,
                      last_name: valueInput,
                    });
                  }}
                />
              </div> */}
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Age
                </label>
                <img src={age_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter Age"
                  value={GenerallAdd.age}
                  onChange={(e) => {
                    let value = e.target.value;
                    let valueInput = value.replace(/[^0-9 ]/gi, "");
                    setGenerallAdd({
                      ...GenerallAdd,
                      age: valueInput,
                    });
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Gender
                </label>
                <img src={gender_icon} className="mailImg" alt="" />
                <select
                  className="form-select formInput"
                  value={GenerallAdd.gender}
                  onChange={(e) => {
                    let value = e.target.value;
                    //  let valueInput = value.replace(/[^0-9 ]/ig, '')
                    setGenerallAdd({
                      ...GenerallAdd,
                      gender: value,
                    });
                  }}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Services
                </label>
                <img src={Service_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your services"
                  value={GenerallAdd.service}
                  onChange={(e) => {
                    // let value = e.target.value;
                    // let valueInput = value.replace(/[^A-Za-z ]/gi, ",", "");
                    setGenerallAdd({
                      ...GenerallAdd,
                      service: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Specialization
                </label>
                <img src={specilization_icon} className="mailImg" alt="" />
                <select
                  className="form-select formInput"
                  value={GenerallAdd.specialization}
                  onChange={(e) => {
                    setGenerallAdd({
                      ...GenerallAdd,
                      specialization: e.target.value,
                    });
                  }}
                >
                  <option value="">Select Specialization</option>
                  {SpilizationType &&
                    SpilizationType.length &&
                    SpilizationType.map((Typelist, index) => {
                      return (
                        <>
                          {/* <option value="select">Enter Your vehicle Name</option> */}
                          <option
                            key={"Typelist" + index}
                            value={Typelist.specialization_id}
                          >
                            {Typelist.specialization}
                          </option>
                        </>
                      );
                    })}
                </select>
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Education
                </label>
                <img src={edu_icon} className="mailImg" alt="" />
                {/* <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your education"
                  value={GenerallAdd.education}
                  onChange={(e) => {
                    setGenerallAdd({
                      ...GenerallAdd,
                      education: e.target.value,
                    });
                  }}
                /> */}
                <select
                  className="form-select formInput"
                  value={GenerallAdd.education}
                  onChange={(e) => {
                    setGenerallAdd({
                      ...GenerallAdd,
                      education: e.target.value,
                    });
                  }}
                >
                  <option value="">Select Education</option>
                  {EducationType &&
                    EducationType.length &&
                    EducationType.map((Typelist, index) => {
                      return (
                        <>
                          {/* <option value="select">Enter Your vehicle Name</option> */}
                          <option
                            key={"Typelist" + index}
                            value={Typelist.education_id}
                          >
                            {Typelist.education}
                          </option>
                        </>
                      );
                    })}
                </select>
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Experience
                </label>
                <img src={exp_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your experience"
                  value={GenerallAdd.experience}
                  onChange={(e) => {
                    setGenerallAdd({
                      ...GenerallAdd,
                      experience: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Known Languages
                </label>
                <Select
                  className=""
                  isMulti
                  options={LangugeType}
                  onChange={onTopicChange}
                  // onChange={(e) => {
                  //   setSelected(e);

                  // }}
                  autoFocus={true}
                  // placeholder={"Select Languages..."}
                  placeholder={
                    <div className="select-placeholder-text">
                      Select Languages...
                    </div>
                  }
                />
                <img src={exp_icon} className="mailImg" alt="" />
                {/* <div className="mt-4">
                  {selected?.map((item, i) => {
                    return <p></p>;
                  })}
                </div> */}
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Registration No.
                </label>
                <img src={regi_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your registration number/ certificate no."
                  value={GenerallAdd.registration}
                  onChange={(e) => {
                    setGenerallAdd({
                      ...GenerallAdd,
                      registration: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Signature
                </label>
                <div className="file-input">
                  <input
                    type="file"
                    name="signature"
                    id="file-input2"
                    className="file-input__input"
                    onChange={handleFileInput2}
                  />
                  <img src={sign_icon} className="mailImg2" alt="" />
                  <label className="file-input__label2" for="file-input2">
                    Upload Signature
                  </label>
                </div>
              </div>
              {deleteImg && (
                <>
                  <div className={"sign_preview"}>
                    <img
                      // src={signature}

                      src={
                        !GenerallAdd.signature
                          ? signature
                          : GenerallAdd.signature.length
                            ?
                            GenerallAdd.signature
                            :
                            GenerallAdd.signature &&
                            URL.createObjectURL(GenerallAdd.signature)
                      }
                      alt="" />
                    <span>
                      <img
                        src={img_preview_delete_icon}
                        className="delete_icon"
                        onClick={handleDeleteImg}
                        alt=""
                      />
                    </span>
                  </div>
                </>
              )}
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Aadhar Card Front
                </label>
                <div className="file-input adhaarBox">
                  <input
                    type="file"
                    name="aadharFrontSide"
                    id="file-input4"
                    className="file-input__input"
                    onChange={handleFileInputfFront}
                  />
                  <img src={sign_icon} className="mailImg2" alt="" />
                  <label className="file-input__label21 helloAdharaLabel" for="file-input4">
                    Upload Aadhar Card Front
                  </label>
                </div>
              </div>
              {addharFront && (
                <>
                  <div className={"sign_preview"}>
                    <img
                      // src={signature}

                      src={
                        !GenerallAdd.aadharFrontSide
                          ? signature
                          : GenerallAdd.aadharFrontSide.length
                            ?
                            GenerallAdd.aadharFrontSide
                            :
                            GenerallAdd.aadharFrontSide &&
                            URL.createObjectURL(GenerallAdd.aadharFrontSide)
                      }
                      alt="" />
                    <span>
                      <img
                        src={img_preview_delete_icon}
                        className="delete_icon"
                        onClick={handleAadharFront}
                        alt=""
                      />
                    </span>
                  </div>
                </>
              )}

              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Aadhar Card Back
                </label>
                <div className="file-input adhaarBox">
                  <input
                    type="file"
                    name="aadhar"
                    id="file-input3"
                    className="file-input__input"
                    onChange={handleFileInput}
                  />
                  <img src={sign_icon} className="mailImg2" alt="" />
                  <label className="file-input__label21 helloAdharaLabel" for="file-input3">
                    Upload Aadhar Card Back
                  </label>
                </div>
              </div>
              {addhar && (
                <>
                  <div className={"sign_preview"}>
                    <img
                      // src={signature}

                      src={
                        !GenerallAdd.aadhar
                          ? signature
                          : GenerallAdd.aadhar.length
                            ?
                            GenerallAdd.aadhar
                            :
                            GenerallAdd.aadhar &&
                            URL.createObjectURL(GenerallAdd.aadhar)
                      }
                      alt="" />
                    <span>
                      <img
                        src={img_preview_delete_icon}
                        className="delete_icon"
                        onClick={handleAadhar}
                        alt=""
                      />
                    </span>
                  </div>
                </>
              )}
              <div className="login btn-width-100">
                {/* <DarkButton
                  onClick={() => navigate("/SignUp")}
                  classbtn={
                    "cx-btn-light me-3  btn-width-45 btn-height font-17 mb-5"
                  }
                  btn={"Back"}
                /> */}
                <DarkButton
                  onClick={() => postaddGeneral()}
                  classbtn={"cx-btn-dark  btn-height btn-width-50 font-17 mb-5"}
                  btn={"Save & Proceed"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="landingImg">
              <div className="phone-no">
                <img src={teleCall} alt="" />
                <label htmlFor="">+91 9509932234</label>
              </div>
              <div className="img-container">
                <div className="big-img">
                  <img src={general_info} alt="" />
                </div>
                <div>
                  <h2>General Information</h2>
                  <p>Share your Experte with us !</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralInfo;
