import React, { useContext, useState } from "react";
import logo from "../../assets/images/logo.svg";
import teleCall from "../../assets/images/ic_call1.svg";
import upload_remove from "../../assets/images/icons/upload_remove.svg";
import delete_upload from "../../assets/images/icons/delete_upload.svg";
import upload_success from "../../assets/images/icons/upload_success.svg";
import upload_doc_icon from "../../assets/images/icons/upload_doc_icon.svg";
import upload_icon from "../../assets/images/icons/upload_icon.svg";
import upload_docs from "../../assets/images/upload_docs.svg";
import { DarkButton } from "../commonComponents/commonComp";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { multipartPostCall } from "../../api/ApiServices";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import { AppContext } from "../../context/AppContext";
import DragandDropImages from "../../sharedComponent/DragandDropImages";

const UploadDocs = () => {
  const { setLoading } = useContext(AppContext);
  const doctor_clinic_id = localStorage.doctor_clinic_id
    ? localStorage.doctor_clinic_id
    : "";
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [signature, setSignature] = useState();
  const [deleteImg, setDeleteImg] = useState(true);

  function handleFileInput(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  function handleFileInput2(e) {
    setSignature(URL.createObjectURL(e.target.files[0]));
    setDeleteImg(true);
  }

  const handleDeleteImg = () => {
    setDeleteImg(!deleteImg);
  };

  const options = [
    {
      value: "OPD (Allopathy & Homeopathy)",
      label: "OPD (Allopathy & Homeopathy)",
    },
    { value: "Dental facility", label: "Dental facility" },
    { value: "Ward/ Indoor", label: "Ward/ Indoor" },
    { value: "Minor OT/ Dressing Room", label: "Minor OT/ Dressing Room" },
    { value: "Laboratory services", label: "Laboratory services" },
  ];

  const [selected, setSelected] = useState(null);

  const handleChange = (e) => {
    setSelected(e.value);
  };
  // const [selectedOption, setSelectedOption] = useState("");

  // const handleChange1 = (selectedOption) => {
  //   setSelectedOption(selectedOption.value);
  // };

  const [UploadDoc, setUploadDoc] = useState({
    documentsPath: [],
  });
  const doctorId = localStorage.getItem("sign_doctor_id")
    ? localStorage.getItem("sign_doctor_id")
    : null;

  const postaddClinice = () => {
    setLoading(true);

    let formData = new FormData();
    formData.append("doctor_id", doctorId);
    formData.append("doctor_clinic_id", doctor_clinic_id);

    // formData.append("clinicPhotos", CliniceAdd.clinicPhotos);

    UploadDoc.documentsPath.map((img) => {
      formData.append("documentsPath", img);
    });

    multipartPostCall(ApiConfig.UPLOAD_DOCUMENT, formData)
      .then((res) => {
        if (res.success) {
          notifySuccess(res.message);
          navigate("/BankDetails");
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="authMain">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <img src={logo} alt="" className="login_img" />
            <div className="signinForm signUp general_info">
              <h1 className="signInTxt mb-5">Upload Registration Card</h1>
              <div className="docs_wrapper">
                <form className="form-container" enctype="multipart/form-data">
                  <div className="upload-files-container">
                    <div className="drag-file-area">
                      <span className="material-icons-outlined upload-icon">
                        <img src={upload_icon} />
                      </span>
                      <h3 className="dynamic-message">
                        Drop your files here. <br />
                        or
                      </h3>
                      <label className="label">
                        <span className="browse-files">
                          <input type="file" className="default-file-input" />
                          <span className="browse-files-text">
                            <DragandDropImages
                              setFile={(files) => {
                                setUploadDoc({
                                  ...UploadDoc,
                                  documentsPath: [
                                    ...UploadDoc.documentsPath,
                                    ...files,
                                  ],
                                });
                              }}
                            />
                          </span>
                        </span>
                      </label>
                    </div>
                    <span className="cannot-upload-message">
                      <span className="material-icons-outlined">error</span>{" "}
                      Please select a file first
                      <span className="material-icons-outlined cancel-alert-button">
                        cancel
                      </span>
                    </span>
                    <div className="file-block">
                      <div className="file-info">
                        <span className="material-icons-outlined file-icon">
                          description
                        </span>
                        <span className="file-name"> </span> |
                        <span className="file-size"> </span>
                      </div>
                      <span className="material-icons remove-file-icon">
                        delete
                      </span>
                      <div className="progress-bar"> </div>
                    </div>
                  </div>
                </form>

                {/* <div className="uploading_item">
                  <img src={upload_doc_icon} />
                  <div className="progress_wrapper">
                    <p className="doc_name">clinic_lisence.pdf</p>
                    <progress value="32" max="100" className="blue">32</progress>
                  </div>
                  <img src={delete_upload} />
                </div> */}
                {/* <div className="uploading_item">
                  <img src={upload_doc_icon} />
                  <div className="progress_wrapper">
                    <p className="doc_name">clinic_lisence.pdf</p>
                    <progress value="32" max="100" className="green">32</progress>
                  </div>
                  <img src={delete_upload} />
                </div> */}
                {UploadDoc.documentsPath.map((img, index) => {
                  console.log("U_img", img);
                  return (
                    <div className="uploading_item">
                      <img src={upload_doc_icon} />
                      <div className="progress_wrapper">
                        <p className="doc_name">{img.name}</p>
                        {/* <progress value="32" max="100" className="blue">32</progress> */}
                      </div>
                      <img src={upload_success} />
                      {/* <img src={upload_remove} className="remove" /> */}
                    </div>
                  );
                })}
              </div>

              <div className="login btn-width-100">
                {/* <DarkButton
                 onClick={()=>navigate('/ClinicInfo')}
                 
                  classbtn={
                    "cx-btn-light me-3  btn-width-45 btn-height font-17 mb-5"
                  }
                  btn={"Back"}
                /> */}
                <DarkButton
                  onClick={() => postaddClinice()}
                  classbtn={"cx-btn-dark  btn-height btn-width-50 font-17 mb-5"}
                  btn={"Save & Proceed"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="landingImg">
              <div className="phone-no">
                <img src={teleCall} alt="" />
                <label htmlFor="">+91 9509932234</label>
              </div>
              <div className="img-container">
                <div className="big-img">
                  <img src={upload_docs} alt="" />
                </div>
                <div>
                  <h2>Upload Documents</h2>
                  <p>Share your Experte with us !</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadDocs;
