import React, { useContext, useEffect, useRef, useState } from "react";
import btnSend from "../../assets/images/send_chat.svg";
import { AppContext } from "../../context/AppContext";
import {
  deleteWithAuthCall,
  getWithAuthCall,
  simpleGetCall,
  simplePostCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import CreatableSelect from "react-select/creatable";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import ic_notepad from "../../assets/images/ic_notepad.svg";
import AgoraUIKit from "agora-react-uikit";
import ic_completed from "../../assets/images/ic_completed.svg";

import "agora-react-uikit/dist/index.css";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import moment from "moment";
import Axe from "../../assets/images/crossAxe.svg";
import { useLocation } from "react-router-dom";
import Notepad from "../../sharedComponent/Notepad";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";

const ChattingVideoComp = () => {
  const {
    doctorAnimalID,
    roomId,
    socket,
    callId,
    customerData,
    doctorId,
    customerId,
    isCallAccepted,
    setIsCallAccepted,
    setRoomid,
    setCallId,
    historyPres,
    setVideoHistoryPres,
    setVideoHistoryPresData,
    notesData,
    setNotesData,
    VedioCallPrispation,
    setVedioCallPrispation,
  } = useContext(AppContext);
  const navigate = useNavigate();

  // STATES
  const [ClintDetailsList, setClintDetailsList] = useState({});
  const [ClintPhoto, setClintPhoto] = useState([]);
  const [startdigno, setStartdigno] = useState(false);
  const [InviceTotal, setInviceTotal] = useState("0");
  const [ClintVisitlist, setClintVisitlist] = useState([]);
  const [Prsiptiontlist, setPrsiptiontlist] = useState([]);
  const [FacilitiesType, setFacilitiesType] = useState([]);
  const [FrequencyType, setFrequencyType] = useState([]);
  // Pet Food State Data
  const [petFoodList, setPetFoodList] = useState([]);
  const [petFoodDropD, setPetFoodDropD] = useState([]);
  const [petFoodAdd, setPetFoodAdd] = useState({
    doctor_appointment_id: null,
    product_id: null,
    prescription_frequency_id: null,
    duration: null,
    instruction: "",
    quantity: null,
  });

  const [CliniceAdd, setCliniceAdd] = useState({
    doctor_appointment_id: "",
    product_id: "",
    prescription_frequency_id: "",
    duration: "",
    instruction: "",
    medicine_type: "",
    quantity: "",
  });

  const SelectStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "10px",
      border: "1px solid #A3AED0",
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: "2 !important",
    }),
  };
  const [VisiteTotal, setVisiteTotal] = useState("0");
  const [apid, setid] = useState("");
  const [ClintVisitAlllist, setClintVisitAlllist] = useState([]);
  const [ClintPrescription, setClintPrescription] = useState([]);
  const [ClintInvicelist, setClintInvicelist] = useState([]);
  const [docId, setDocId] = useState("");
  const [vaccineListData, setVaccineListData] = useState([]);
  const [mainDocID, setMainDocID] = useState(0);
  const [vaccineList, setVaccineList] = useState([]);
  const [message, setMessage] = useState("");
  const [newmessage, setnewmessage] = useState([]);
  const [medicineType, setMedicineType] = useState([]);
  const lastMessageRef = useRef(null);

  const doctorAppoinmentID = sessionStorage.doctorAppoinmentID
    ? sessionStorage.doctorAppoinmentID
    : "";
  const doctorVideoPresptionID = sessionStorage.doctorVideoPresptionID
    ? sessionStorage.doctorVideoPresptionID
    : "";
  const [recentChatdata, setRecentChatdata] = useState([]);
  const [senderId, setSenderId] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [ChatID, setChatID] = useState("");

  const [callEnded, setCallEnded] = useState(false);
  const cusId = sessionStorage.customerId ? sessionStorage.customerId : "";

  useEffect(() => {
    getMedicineType();

    return () => {
      setVedioCallPrispation(false)
    }
  }, []);

  const getMedicineType = () => {
    simpleGetCall(ApiConfig.GET_MEDICINE_TYPE)
      .then((res) => {
        if (res.success === true) {
          setMedicineType(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    socket &&
      socket.on("message created", (value) => {
        console.log("message created");
        setnewmessage((prev) => [...prev, value]);
      });
    return () => {
      socket && socket.off("message created");
    };
  }, [socket]);

  const sendMessage = async () => {
    setMessage("");
    const receiverId = sessionStorage.receiver_id
      ? sessionStorage.receiver_id
      : "";
    const messageSendPayload = {
      senderId: customerData.user_id,
      receiverId: cusId,
      chat_to_entity_type_id: 2,
      chat_from_entity_type_id: 1,
      message: message,
      chat_id: ChatID,
    };

    socket.emit("new message", messageSendPayload);
  };

  useEffect(() => {
    handleHistery();
  }, [doctorId]);

  // Chat Histery  ///
  const handleHistery = async () => {
    setnewmessage([]);
    let newRequestBody = JSON.stringify({
      doctor_id: customerData.user_id,
      customer_id: customerId,
      chat_to_entity_type_id: 2,
      chat_from_entity_type_id: 1,
      page: 1,
    });
    simplePostCall(ApiConfig.CHAT_HISTRY, newRequestBody)
      .then((data) => {
        if (data.result === true) {
          var lastmsg = data.chat_data[0].chat_id;
          setChatID(lastmsg);
          // socket.emit("message read", {
          //   senderId: Number(customerData.id),
          //   user_customer_id: customerData.customer_id,
          //   last_message_id: lastmsg,
          //   chat_id: ChatID,
          // });
          setRecentChatdata([...data.chat_data.reverse()]);
          if (data.total_pages - data.current_page === 0) {
            // sethasmore(false);
          }
        } else {
          setRecentChatdata([]);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [recentChatdata, newmessage]);

  const roomID = sessionStorage.roomID ? sessionStorage.roomID : "";
  console.log("roomId,", roomId);
  const rtcProps = {
    appId: "bc1dec31c1f1414089c89d44f29b9267",
    channel: roomId || roomID,
    token: null,
    role: "host",
    uId: customerData.user_id,
  };
  // --call created
  useEffect(() => {
    socket &&
      socket.on("callcreated", (data) => {
        setCallId(data?.call_id);
        setRoomid(data?.room_id);
        sessionStorage.setItem("roomID,", data?.room_id);
        setSenderId(data?.senderId);
        setReceiverId(data?.receiverId);
        if (data.senderId === 1) {
          // You are the sender
          // Handle sender-specific logic here
        } else if (data.receiverId === 1) {
          // You are the receiver
          // Handle receiver-specific logic here
        }
      });
    return () => {
      socket && socket.off("callcreated");
    };
  }, [socket]);

  //----reject call
  useEffect(() => {
    // Check and request permissions for microphone and camera
    socket &&
      socket.on("callrejected", (data) => {
        setIsCallAccepted(false);
        notifyError("Call rejected!");
        // Handle the response from the "usercalling" event here
      });

    return () => {
      socket && socket.off("callrejected");
    };
  }, [socket]);

  useEffect(() => {
    socket &&
      socket.on("endedcall", (data) => {
        setIsCallAccepted(false);
        // setVedioCallPrispation(true)
      });
    return () => {
      socket && socket.off("endedcall");
    };
  }, [socket]);

  const initiateCall = (actionType) => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      var constraints = {
        audio: true,
        video: true,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          const requestBody = {
            senderId: customerData.user_id,
            receiverId: cusId,
            call_from_entity_type_id: 1,
            call_to_entity_type_id: 2,
            doctor_appointment_id: doctorAppoinmentID,
          };
          console.log("requestBody,", requestBody);
          socket && socket.emit("calluser", requestBody);
          setIsCallAccepted(true);
          if (actionType === "callagain") {
            setVedioCallPrispation(false);
          }
        })
        .catch(function (err) {
          notifyError("Camera not accessible");
          setIsCallAccepted(false);
        });
    } else {
      notifyError("User Media is not supported in this browser.");
    }
  };

  const endCall = () => {
    const requestBody = {
      senderId: customerData.user_id,
      // receiverId: customerId,
      receiverId: cusId,
      call_id: callId,
      room_id: roomId,
      doctorAppoinmentID: doctorAppoinmentID,
    };
    socket.emit("callended", requestBody);
    getClinetDetailsByeVisit(doctorAppoinmentID);
    setIsCallAccepted(false);
  };

  const rtcCallbacks = {
    EndCall: () => [
      endCall(),
      setIsCallAccepted(false),
      setCallEnded(true),
      setVedioCallPrispation(true),
    ],
  };

  function onTopicChange(selectedOption) {
    var selected_topics = [];

    // if (selectedOption.length > 0) {
    //   selectedOption.map((topic, index) => {
    //     selected_topics.push(topic.value);
    //   });
    // }
    // console.log("selected_topics", selected_topics);
    setCliniceAdd({
      ...CliniceAdd,
      product_id: selectedOption,
    });
  }

  function onFrequnceChange(selectedOption) {
    var selected_topics = [];

    if (selectedOption.length > 0) {
      selectedOption.map((topic, index) => {
        selected_topics.push(topic.value);
      });
    }
    setCliniceAdd({
      ...CliniceAdd,
      prescription_frequency_id: selectedOption,
    });
  }

  useEffect(() => {
    getClinetDetails(doctorVideoPresptionID, doctorAppoinmentID);
  }, [doctorAppoinmentID, doctorVideoPresptionID]);

  const GetPRESCRIPTION_GET = (doctor_appointment_id) => {
    // setLoading(true);

    simpleGetCall(ApiConfig.PRESCRIPTION_GET + doctor_appointment_id)
      .then((res) => {
        setPrsiptiontlist(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const addPrescriptionHandal = () => {
    setStartdigno(true);
    let newRequestBody = JSON.stringify({
      doctor_appointment_id: apid,
      product_id: CliniceAdd.product_id,
      prescription_frequency_id: CliniceAdd.prescription_frequency_id,
      duration: CliniceAdd.duration,
      instruction: CliniceAdd.instruction,
      quantity: CliniceAdd.quantity,
      medicine_type: CliniceAdd.medicine_type,
    });
    simplePostCall(ApiConfig.ADD_PRESRIPATION, newRequestBody)
      .then((res) => {
        if (res.success) {
          GetPRESCRIPTION_GET(apid);

          setCliniceAdd({
            ...CliniceAdd,
            product_id: "",
            prescription_frequency_id: "",
            duration: "",
            instruction: "",
            quantity: "",
            medicine_type: "",
          });
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getClinetDetailsByeVisit = (id) => {
    simpleGetCall(ApiConfig.CLIINET_DETAILS_BYE_VISITE_ID + id)
      .then((res) => {
        GetPRESCRIPTION_GET(id);
        setClintDetailsList(res.data);
        setClintPhoto(res.data.appointment_images);
        setClintPrescription(res.data.prescription);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  // Notes api
  const getNotesData = () => {
    simpleGetCall(ApiConfig.GET_NOTES_DATA + `?appointment_id=${apid}`)
      .then((res) => {
        if (res?.success) {
          let VAL = res?.data?.remark;
          setNotesData(VAL);
        } else {
          setNotesData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateNote = () => {
    let payload = {
      appointment_id: apid,
      remark: notesData,
    };
    simplePostCall(ApiConfig.UPDATE_NOTE_DATA, JSON.stringify(payload))
      .then((res) => {
        if (res.success) {
          notifySuccess(res?.message);
          getNotesData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /// Details All
  const getClinetDetails = (id, doctor_appointment_id) => {
    setid(doctor_appointment_id);
    simpleGetCall(
      ApiConfig.CLIINET_DETAILS_BY_ID +
        id +
        "&" +
        "doctor_appointment_id=" +
        doctor_appointment_id
    )
      .then((res) => {
        setDocId(res?.data?.doctor_appointment_id);
        GetPRESCRIPTION_GET(doctor_appointment_id);
        setClintDetailsList(res.data);
        setClintPhoto(res.data.appointment_images);
        setClintPrescription(res.data.prescription);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const getAllVaccineDetails = (id) => {
    simpleGetCall(
      ApiConfig.GET_ALL_VACCINE_DETAILS + `?doctor_appointment_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setVaccineListData(res?.data);
        } else {
          setVaccineListData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getVaccinationDet = () => {
    simpleGetCall(ApiConfig.GET_VACCINE_DROPDOWN)
      .then((res) => {
        let Type = res.data;
        var grades = [];

        Type.map((grade) => {
          grades.push({
            label: grade.vaccine,
            value: grade.vaccine_id,
          });
        });
        grades && setVaccineList(grades);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // All Pet Food APis
  const getPetFoodList = (apid) => {
    simpleGetCall(
      ApiConfig.GET_PRESCRPTION_PET_FOOD + `?doctor_appointment_id=${apid}`
    )
      .then((res) => {
        if (res.success === true) {
          setPetFoodList(res?.data);
        } else {
          setPetFoodList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPetFoodDD = () => {
    simpleGetCall(ApiConfig.GET_PETFOOD_DROPDOWN)
      .then((res) => {
        let Type = res.data;
        var grades = [];
        Type.map((grade) => {
          grades.push({
            label: grade.product,
            value: grade.product_id,
          });
        });
        grades && setPetFoodDropD(grades);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function onMedicineOnChanges(selectedOption) {
    setCliniceAdd({
      ...CliniceAdd,
      product_id: selectedOption?.value,
    });
  }
  function onPetFoodChange(selectedOption) {
    setPetFoodAdd({
      ...petFoodAdd,
      product_id: selectedOption?.value,
    });
  }

  const onFreqPetChange = (selectedOption) => {
    var selected_topics = [];

    if (selectedOption.length > 0) {
      selectedOption.map((topic, index) => {
        selected_topics.push(topic.value);
      });
    }
    setPetFoodAdd({
      ...petFoodAdd,
      prescription_frequency_id: selectedOption?.value,
    });
  };
  const addPetFood = () => {
    let payload = JSON.stringify({
      doctor_appointment_id: apid,
      product_id: petFoodAdd.product_id,
      prescription_frequency_id: petFoodAdd.prescription_frequency_id,
      duration: petFoodAdd.duration,
      instruction: petFoodAdd.instruction,
      quantity: petFoodAdd.quantity,
    });
    simplePostCall(ApiConfig.ADD_PET_FOOD, payload)
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          getPetFoodList(apid);
          setPetFoodAdd({
            ...petFoodAdd,
            product_id: "",
            prescription_frequency_id: "",
            duration: "",
            instruction: "",
            quantity: "",
          });
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deletePetFood = (id) => {
    deleteWithAuthCall(
      ApiConfig.DELETE_PET_FOOD_ID + `?animal_food_prescription_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res?.message);
          getPetFoodList(apid);
        } else {
          notifyError(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // Check and request permissions for microphone and camera
    getProduct();
    getFrequency();
    if (apid !== "") {
      getAllVaccineDetails(apid);
      getVaccinationDet();
      getPetFoodList(apid);
      getPetFoodDD();
      getNotesData();
    } else {
    }
  }, [apid]);

  function getProduct() {
    getWithAuthCall(ApiConfig.PRODUCT_DRUPDOWN)
      .then((data) => {
        let Type = data.data;
        var grades = [];

        Type.map((grade) => {
          grades.push({
            label: grade.product,
            value: grade.product_id,
          });
        });
        grades && setFacilitiesType(grades);
      })
      .catch((error) => {
        console.error("api response", error);
      });
  }
  function getFrequency() {
    getWithAuthCall(ApiConfig.FREQUENCY_DROPDOWN)
      .then((data) => {
        // console.log(data);
        // let Type = data.data;
        // var grades = [];

        // Type.map((grade, index) => {
        //   grades.push({
        //     label: grade.prescription_frequency,
        //     value: grade.prescription_frequency_id,
        //   });
        // });
        setFrequencyType(data?.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  useEffect(() => {
    // Check and request permissions for microphone and camera
    getClinetVisitDetails(doctorAnimalID);
    getClinetInviceDetails(doctorAnimalID);
  }, [doctorAnimalID]);
  const [vaccineAdd, setVaccineAdd] = useState({
    doctor_appointment_id: apid,
    vaccine_id: null,
    frequency_id: null,
    duration: "",
    dose: null,
  });

  const getClinetVisitDetails = (id) => {
    // setLoading(true);

    simpleGetCall(
      ApiConfig.CLINET_VIST_BY_ID + id + "&doctor_id=" + customerData.doctor_id
    )
      .then((res) => {
        // var total = res.length ? res.length : 0;

        setVisiteTotal(res.total);
        setClintVisitAlllist(res);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const getClinetInviceDetails = (id) => {
    simpleGetCall(
      ApiConfig.CLIINET_INVICE_ID + id + "&doctor_id=" + customerData.doctor_id
    )
      .then((res) => {
        var total = res.data.length ? res.data.length : 0;
        setInviceTotal(total);

        setClintInvicelist(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  function onVaccineChange(selectedOption) {
    setVaccineAdd({
      ...vaccineAdd,
      vaccine_id: selectedOption?.value,
    });
  }
  function onFreqVaccChange(selectedOption) {
    var selected_topics = [];

    if (selectedOption.length > 0) {
      selectedOption.map((topic, index) => {
        selected_topics.push(topic.value);
      });
    }
    setVaccineAdd({
      ...vaccineAdd,
      frequency_id: selectedOption?.value,
    });
  }
  const changeStatus = () => {
    let payload = {
      appointment_id: apid,
      status: "5",
    };
    simplePostCall(
      ApiConfig.CHANGE_STATUS_OFAPPOINTMENT,
      JSON.stringify(payload)
    )
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          navigate("/CustomerDetails");
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteMedicine = (id, docId) => {
    deleteWithAuthCall(
      ApiConfig.DELETE_MEDICINE_DATA + `?animal_prescription_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res?.message);
          GetPRESCRIPTION_GET(docId);
        } else {
          notifyError(res?.message);
          GetPRESCRIPTION_GET(docId);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteVaccine = (id, docId) => {
    deleteWithAuthCall(
      ApiConfig.DELETE_VACCINE_BY_ID +
        `?vaccinator_animal_prescription_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res?.message);
          getAllVaccineDetails(docId);
        } else {
          notifyError(res?.message);
          getAllVaccineDetails(docId);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addVaccineDet = () => {
    let docId = apid;
    let payload = {
      doctor_appointment_id: docId,
      vaccine_id: vaccineAdd?.vaccine_id,
      frequency_id: vaccineAdd?.frequency_id,
      duration: vaccineAdd?.duration,
      dose: vaccineAdd?.dose,
    };
    simplePostCall(ApiConfig.ADD_VACCINE, JSON.stringify(payload))
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          getAllVaccineDetails(docId);
          setVaccineAdd({
            ...vaccineAdd,
            vaccine_id: "",
            frequency_id: "",
            duration: "",
            dose: "",
          });
        } else {
          notifyError(res.message);
          getAllVaccineDetails(docId);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // History prescription modal api func()
  const getHistoryPres = (id) => {
    simpleGetCall(ApiConfig.PRESCRIPTION_GET_ID + `${id}`)
      .then((res) => {
        if (res?.success === true) {
          setVideoHistoryPresData(res?.data);
          setVideoHistoryPres(true);
        } else {
          setVideoHistoryPresData();
          setVideoHistoryPres(false);
          notifyError(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="appointment-wrapper" id="cx-main">
      <div id="ChattingVideoComp" className="reportsTabs">
        <div className="main-vd-sec">
          <div className="video-call-wrapper row">
            {VedioCallPrispation ? (
              <div className="app-details-wrapper">
                <div className="row gx-3">
                  <div className="col-md-8 ">
                    <div className="left">
                      <div className="aap-heading">
                        {/* all */}
                        <label htmlFor="">Appointment </label>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div>
                            <label htmlFor="" className="key">
                              Client Name
                            </label>
                          </div>
                          <label htmlFor="" className="value capital">
                            {ClintDetailsList.first_name
                              ? `${ClintDetailsList.first_name} ${ClintDetailsList.last_name}`
                              : ""}
                          </label>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <label htmlFor="" className="key">
                              Appointment Date & Time
                            </label>
                          </div>
                          <label htmlFor="" className="value">
                            {ClintDetailsList.date ? ClintDetailsList.date : ""}{" "}
                            {ClintDetailsList.time ? ClintDetailsList.time : ""}
                          </label>
                        </div>
                        <div className="col-12">
                          <div>
                            <label htmlFor="" className="key">
                              Description
                            </label>
                          </div>
                          <label htmlFor="" className="value">
                            {ClintDetailsList.appointment_desc
                              ? ClintDetailsList.appointment_desc
                              : ""}
                          </label>
                        </div>
                        {ClintDetailsList.total_call_duration && (
                          <div className="col-12">
                            <div>
                              <label htmlFor="" className="key">
                                Call Duration
                              </label>
                            </div>
                            <label htmlFor="" className="value">
                              {ClintDetailsList.total_call_duration
                                ? ClintDetailsList.total_call_duration
                                : ""}
                            </label>
                          </div>
                        )}
                        <div className="col-12">
                          {ClintPhoto && ClintPhoto.length > 0 && (
                            <label htmlFor="" className="key">
                              Photos
                            </label>
                          )}
                          <div className="photo-video-wrapper">
                            {ClintPhoto && ClintPhoto.length > 0 && (
                              <div className="row">
                                {ClintPhoto.map((itemlist, index) => {
                                  return (
                                    <div className="col-xl-2 col-lg-3 col-md-4 mb-3">
                                      <img
                                        src={itemlist.image_path}
                                        alt=""
                                        height="70px"
                                        width="70px"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            {startdigno && (
                              <div className="start-wrapper">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="1"
                                >
                                  <Nav
                                    variant="pills"
                                    id="newTabMai"
                                    className="tob_nav_pills"
                                  >
                                    <Nav.Item>
                                      <Nav.Link eventKey="1">Medicine</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="2">Vaccine</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="3">Pet Food</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="4">Note's</Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="1">
                                      <div className="table-start-wrapper">
                                        <label htmlFor="" className="key">
                                          Prescription
                                        </label>
                                        <div className="inner-tb">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Sr No.</th>
                                                <th>Medicine Name</th>
                                                <th>Medicine Type</th>
                                                <th>Frequency</th>
                                                <th>Duration (in days)</th>
                                                <th>Instruction</th>
                                                <th>Quantity</th>
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {Prsiptiontlist &&
                                              Prsiptiontlist.length > 0 ? (
                                                Prsiptiontlist.map(
                                                  (itemlist, index) => {
                                                    return (
                                                      <tr>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                          {itemlist.product}
                                                        </td>
                                                        <td>
                                                          {medicineType &&
                                                            medicineType.find(
                                                              (item) =>
                                                                item?.medicine_type_id ==
                                                                itemlist.medicine_type
                                                            )?.medicine_type}
                                                        </td>
                                                        <td>
                                                          {
                                                            itemlist.prescription_frequency
                                                          }
                                                        </td>
                                                        <td>
                                                          {itemlist.duration}{" "}
                                                          Days
                                                        </td>
                                                        <td>
                                                          {itemlist.instruction}
                                                        </td>
                                                        <td>
                                                          {itemlist.quantity}
                                                        </td>
                                                        <td>
                                                          <img
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              deleteMedicine(
                                                                itemlist?.animal_prescription_id,
                                                                itemlist?.doctor_appointment_id
                                                              )
                                                            }
                                                            src={Axe}
                                                            height="18"
                                                            width="18"
                                                            alt=""
                                                          />
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <></>
                                              )}
                                              <tr>
                                                <td></td>
                                                <td>
                                                  <div style={{ zIndex: "1" }}>
                                                    {/* <ReactSelect
                                                    options={FacilitiesType}
                                                    styles={SelectStyles}
                                                    /> */}
                                                    <CreatableSelect
                                                      value={
                                                        FacilitiesType &&
                                                        FacilitiesType.filter(
                                                          (item) =>
                                                            item.value ==
                                                            CliniceAdd.product_id
                                                        )
                                                      }
                                                      isClearable
                                                      options={FacilitiesType}
                                                      styles={SelectStyles}
                                                      onChange={
                                                        onMedicineOnChanges
                                                      }
                                                      // styles={{ menu: { zIndex: '2 !important' } }}
                                                      placeholder={
                                                        "Medicine..."
                                                      }
                                                    />
                                                  </div>
                                                  {/* <select
                                                    className="form-select Medicine"
                                                    value={
                                                      CliniceAdd.product_id
                                                    }
                                                    onChange={(e) =>
                                                      setCliniceAdd({
                                                        ...CliniceAdd,
                                                        product_id:
                                                          e.target.value,
                                                      })
                                                    }
                                                  >
                                                    <option value="">
                                                      Medicine...
                                                    </option>
                                                    {FacilitiesType &&
                                                      FacilitiesType.map(
                                                        (data, index) => {
                                                          return (
                                                            <option
                                                              value={
                                                                data?.product_id
                                                              }
                                                              key={index}
                                                            >
                                                              {data?.product}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select> */}
                                                </td>
                                                <td>
                                                  <select
                                                    className="form-select Medicine"
                                                    value={
                                                      CliniceAdd.medicine_type
                                                    }
                                                    onChange={(e) =>
                                                      setCliniceAdd({
                                                        ...CliniceAdd,
                                                        medicine_type:
                                                          e.target.value,
                                                      })
                                                    }
                                                  >
                                                    <option value="">
                                                      Medicine Type
                                                    </option>
                                                    {medicineType &&
                                                      medicineType.map(
                                                        (data, index) => {
                                                          return (
                                                            <option
                                                              value={
                                                                data?.medicine_type_id
                                                              }
                                                              key={index}
                                                            >
                                                              {
                                                                data?.medicine_type
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                </td>
                                                <td>
                                                  {/* <CreatableSelect
                                                    isClearable
                                                    options={FrequencyType}
                                                    styles={SelectStyles}
                                                    onChange={onFrequnceChange}
                                                    placeholder={"Select..."}
                                                  /> */}
                                                  <select
                                                    className="form-select Medicine"
                                                    value={
                                                      CliniceAdd.prescription_frequency_id
                                                    }
                                                    onChange={(e) =>
                                                      setCliniceAdd({
                                                        ...CliniceAdd,
                                                        prescription_frequency_id:
                                                          e.target.value,
                                                      })
                                                    }
                                                  >
                                                    <option value="">
                                                      Select...
                                                    </option>
                                                    {FrequencyType &&
                                                      FrequencyType.map(
                                                        (data, index) => {
                                                          return (
                                                            <option
                                                              value={
                                                                data?.prescription_frequency_id
                                                              }
                                                              key={index}
                                                            >
                                                              {
                                                                data?.prescription_frequency
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                </td>
                                                <td>
                                                  <input
                                                    type="text"
                                                    className="form-control adjustForm"
                                                    style={{
                                                      fontSize: "14px",
                                                    }}
                                                    placeholder="Duration"
                                                    value={CliniceAdd.duration}
                                                    onChange={(e) => {
                                                      let value =
                                                        e.target.value;
                                                      let valueInput =
                                                        value.replace(
                                                          /[^0-9 ]/gi,
                                                          ""
                                                        );
                                                      setCliniceAdd({
                                                        ...CliniceAdd,
                                                        duration: valueInput,
                                                      });
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="text"
                                                    className="form-control adjustForm"
                                                    placeholder="Instruction"
                                                    value={
                                                      CliniceAdd.instruction
                                                    }
                                                    onChange={(e) => {
                                                      let value =
                                                        e.target.value;
                                                      let valueInput =
                                                        value.replace(
                                                          /[^A-Za-z ]/gi,
                                                          ""
                                                        );

                                                      setCliniceAdd({
                                                        ...CliniceAdd,
                                                        instruction: valueInput,
                                                      });
                                                    }}
                                                  />
                                                </td>

                                                <td>
                                                  <input
                                                    type="text"
                                                    className="form-control adjustForm"
                                                    placeholder="quantity"
                                                    value={CliniceAdd.quantity}
                                                    onChange={(e) => {
                                                      let value =
                                                        e.target.value;
                                                      let valueInput =
                                                        value.replace(
                                                          /[^A-Za-z ]/gi,
                                                          ""
                                                        );

                                                      setCliniceAdd({
                                                        ...CliniceAdd,
                                                        quantity:
                                                          e.target.value,
                                                      });
                                                    }}
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        <div className="text-end my-2">
                                          <button
                                            className="btn-blue-filled"
                                            onClick={addPrescriptionHandal}
                                          >
                                            Add Medicine
                                          </button>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="2">
                                      <div className="table-start-wrapper">
                                        <label htmlFor="" className="key">
                                          Vaccination
                                        </label>
                                        <div className="inner-tb">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Sr No.</th>
                                                <th>Vaccine Name</th>
                                                <th>Frequency</th>
                                                <th>Duration (in days) </th>
                                                <th>Dose (in ml)</th>
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {vaccineListData &&
                                              vaccineListData.length > 0 ? (
                                                vaccineListData.map(
                                                  (itemlist, index) => {
                                                    return (
                                                      <tr>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                          {itemlist.vaccine}
                                                        </td>
                                                        <td>
                                                          {
                                                            itemlist.prescription_frequency
                                                          }
                                                        </td>
                                                        <td>
                                                          {itemlist.duration}{" "}
                                                          Days
                                                        </td>

                                                        <td>{itemlist.dose}</td>
                                                        <td>
                                                          <img
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              deleteVaccine(
                                                                itemlist?.vaccinator_animal_prescription_id,
                                                                itemlist?.doctor_appointment_id
                                                              )
                                                            }
                                                            src={Axe}
                                                            height="18"
                                                            width="18"
                                                            alt=""
                                                          />
                                                        </td>
                                                        {/* <td>
                                                              <img
                                                                src={
                                                                  ic_rejected
                                                                }
                                                                alt=""
                                                              />
                                                            </td> */}
                                                      </tr>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <></>
                                              )}
                                              <tr>
                                                <td></td>
                                                <td>
                                                  <CreatableSelect
                                                    value={
                                                      vaccineList &&
                                                      vaccineList.filter(
                                                        (item) =>
                                                          item.value ==
                                                          vaccineAdd.vaccine_id
                                                      )
                                                    }
                                                    isClearable
                                                    options={vaccineList}
                                                    styles={SelectStyles}
                                                    onChange={onVaccineChange}
                                                    placeholder={"Vaccine..."}
                                                  />
                                                  {/* <select
                                                    className="form-select Medicine"
                                                    value={
                                                      vaccineAdd.vaccine_id
                                                    }
                                                    onChange={(e) =>
                                                      setVaccineAdd({
                                                        ...vaccineAdd,
                                                        vaccine_id:
                                                          e.target.value,
                                                      })
                                                    }
                                                  >
                                                    <option value="">
                                                      vaccine...
                                                    </option>
                                                    {vaccineList &&
                                                      vaccineList.map(
                                                        (data, index) => {
                                                          return (
                                                            <option
                                                              value={
                                                                data?.vaccine_id
                                                              }
                                                              key={index}
                                                            >
                                                              {data?.vaccine}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select> */}
                                                </td>
                                                <td>
                                                  {/* <CreatableSelect
                                                    isClearable
                                                    options={FrequencyType}
                                                    styles={SelectStyles}
                                                    onChange={onFreqVaccChange}
                                                    placeholder={"Select..."}
                                                  /> */}
                                                  <select
                                                    className="form-select Medicine"
                                                    value={
                                                      vaccineAdd.frequency_id
                                                    }
                                                    onChange={(e) =>
                                                      setVaccineAdd({
                                                        ...vaccineAdd,
                                                        frequency_id:
                                                          e.target.value,
                                                      })
                                                    }
                                                  >
                                                    <option value="">
                                                      Select...
                                                    </option>
                                                    {FrequencyType &&
                                                      FrequencyType.map(
                                                        (data, index) => {
                                                          return (
                                                            <option
                                                              value={
                                                                data?.prescription_frequency_id
                                                              }
                                                              key={index}
                                                            >
                                                              {
                                                                data?.prescription_frequency
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                </td>
                                                <td>
                                                  <input
                                                    type="text"
                                                    className="form-control adjustForm"
                                                    style={{
                                                      fontSize: "14px",
                                                    }}
                                                    placeholder="Duration"
                                                    value={vaccineAdd.duration}
                                                    onChange={(e) => {
                                                      let value =
                                                        e.target.value;
                                                      let valueInput =
                                                        value.replace(
                                                          /[^0-9 ]/gi,
                                                          ""
                                                        );
                                                      setVaccineAdd({
                                                        ...vaccineAdd,
                                                        duration: valueInput,
                                                      });
                                                    }}
                                                  />
                                                </td>
                                                {/* <td>
                                                            <input
                                                              type="text"
                                                              className="form-control adjustForm"
                                                              placeholder="Instruction"
                                                              value={
                                                                CliniceAdd.instruction
                                                              }
                                                              onChange={(e) => {
                                                                let value =
                                                                  e.target.value;
                                                                let valueInput =
                                                                  value.replace(
                                                                    /[^A-Za-z ]/gi,
                                                                    ""
                                                                  );

                                                                setCliniceAdd({
                                                                  ...CliniceAdd,
                                                                  instruction:
                                                                    valueInput,
                                                                });
                                                              }}
                                                            />
                                                          </td> */}

                                                <td>
                                                  <input
                                                    type="text"
                                                    className="form-control adjustForm"
                                                    placeholder="Dose"
                                                    value={vaccineAdd.dose}
                                                    onChange={(e) => {
                                                      let value =
                                                        e.target.value;
                                                      let valueInput =
                                                        value.replace(
                                                          /[^0-9 ]/gi,
                                                          ""
                                                        );

                                                      setVaccineAdd({
                                                        ...vaccineAdd,
                                                        dose: valueInput,
                                                      });
                                                    }}
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div className="text-end my-2">
                                        <button
                                          className="btn-blue-filled"
                                          onClick={addVaccineDet}
                                        >
                                          Add Vaccine
                                        </button>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="3">
                                      <div className="table-start-wrapper">
                                        <label htmlFor="" className="key">
                                          Pet Food
                                        </label>
                                        <div className="inner-tb">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Sr No.</th>
                                                <th>Food</th>
                                                <th>Frequency</th>
                                                <th>Duration (in days)</th>
                                                <th>Instruction</th>
                                                <th>Quantity</th>
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {petFoodList &&
                                              petFoodList.length > 0 ? (
                                                petFoodList.map(
                                                  (itemlist, index) => {
                                                    return (
                                                      <tr>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                          {itemlist.product}
                                                        </td>
                                                        <td>
                                                          {
                                                            itemlist.prescription_frequency
                                                          }
                                                        </td>
                                                        <td>
                                                          {itemlist.duration}{" "}
                                                          Days
                                                        </td>
                                                        <td>
                                                          {itemlist.instruction}
                                                        </td>
                                                        <td>
                                                          {itemlist.quantity}
                                                        </td>
                                                        <td>
                                                          <img
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              deletePetFood(
                                                                itemlist?.animal_food_prescription_id
                                                              )
                                                            }
                                                            src={Axe}
                                                            height="18"
                                                            width="18"
                                                            alt=""
                                                          />
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <></>
                                              )}
                                              <tr>
                                                <td></td>
                                                <td>
                                                  <CreatableSelect
                                                    value={
                                                      petFoodDropD &&
                                                      petFoodDropD.filter(
                                                        (item) =>
                                                          item.value ==
                                                          petFoodAdd.product_id
                                                      )
                                                    }
                                                    isClearable
                                                    options={petFoodDropD}
                                                    styles={SelectStyles}
                                                    onChange={onPetFoodChange}
                                                    placeholder={"Food..."}
                                                  />
                                                  {/* <select
                                                    className="form-select Medicine"
                                                    value={
                                                      petFoodAdd.product_id
                                                    }
                                                    onChange={(e) =>
                                                      setPetFoodAdd({
                                                        ...petFoodAdd,
                                                        product_id:
                                                          e.target.value,
                                                      })
                                                    }
                                                  >
                                                    <option value="">
                                                      Pet Food...
                                                    </option>
                                                    {petFoodDropD &&
                                                      petFoodDropD.map(
                                                        (data, index) => {
                                                          return (
                                                            <option
                                                              value={
                                                                data?.product_id
                                                              }
                                                              key={index}
                                                            >
                                                              {data?.product}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select> */}
                                                </td>
                                                <td>
                                                  {/* <CreatableSelect
                                                    isClearable
                                                    options={FrequencyType}
                                                    styles={SelectStyles}
                                                    onChange={onFreqPetChange}
                                                    placeholder={"Select..."}
                                                  /> */}
                                                  <select
                                                    className="form-select Medicine"
                                                    value={
                                                      petFoodAdd.prescription_frequency_id
                                                    }
                                                    onChange={(e) =>
                                                      setPetFoodAdd({
                                                        ...petFoodAdd,
                                                        prescription_frequency_id:
                                                          e.target.value,
                                                      })
                                                    }
                                                  >
                                                    <option value="">
                                                      Select...
                                                    </option>
                                                    {FrequencyType &&
                                                      FrequencyType.map(
                                                        (data, index) => {
                                                          return (
                                                            <option
                                                              value={
                                                                data?.prescription_frequency_id
                                                              }
                                                              key={index}
                                                            >
                                                              {
                                                                data?.prescription_frequency
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                </td>
                                                <td>
                                                  <input
                                                    type="text"
                                                    className="form-control adjustForm"
                                                    style={{
                                                      fontSize: "14px",
                                                    }}
                                                    placeholder="Duration"
                                                    value={petFoodAdd.duration}
                                                    onChange={(e) => {
                                                      let value =
                                                        e.target.value;
                                                      let valueInput =
                                                        value.replace(
                                                          /[^0-9 ]/gi,
                                                          ""
                                                        );
                                                      setPetFoodAdd({
                                                        ...petFoodAdd,
                                                        duration: valueInput,
                                                      });
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="text"
                                                    className="form-control adjustForm"
                                                    placeholder="Instruction"
                                                    value={
                                                      petFoodAdd.instruction
                                                    }
                                                    onChange={(e) => {
                                                      let value =
                                                        e.target.value;
                                                      let valueInput =
                                                        value.replace(
                                                          /[^A-Za-z ]/gi,
                                                          ""
                                                        );

                                                      setPetFoodAdd({
                                                        ...petFoodAdd,
                                                        instruction: valueInput,
                                                      });
                                                    }}
                                                  />
                                                </td>

                                                <td>
                                                  <input
                                                    type="text"
                                                    className="form-control adjustForm"
                                                    placeholder="quantity"
                                                    value={petFoodAdd.quantity}
                                                    onChange={(e) => {
                                                      let value =
                                                        e.target.value;
                                                      let valueInput =
                                                        value.replace(
                                                          /[^A-Za-z ]/gi,
                                                          ""
                                                        );

                                                      setPetFoodAdd({
                                                        ...petFoodAdd,
                                                        quantity:
                                                          e.target.value,
                                                      });
                                                    }}
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div className="text-end my-2">
                                        <button
                                          className="btn-blue-filled"
                                          onClick={addPetFood}
                                        >
                                          Add Pet Food
                                        </button>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="4">
                                      <div className="notePad">
                                        <Notepad />
                                      </div>
                                      <div className="text-end my-2">
                                        <button
                                          className="btn-blue-filled"
                                          onClick={updateNote}
                                        >
                                          Add Note
                                        </button>
                                      </div>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Tab.Container>
                              </div>
                            )}
                          </div>
                          <div className="btn-wrapper">
                            {startdigno === false ? (
                              <>
                                <button
                                  className="btn-blue-filled"
                                  style={{ marginRight: "10px" }}
                                  onClick={() => initiateCall("callagain")}
                                >
                                  Call Again
                                </button>
                                <button
                                  className="btn-blue-filled"
                                  onClick={() => setStartdigno(true)}
                                >
                                  {startdigno
                                    ? "Mark Complete"
                                    : "Add Prescription"}
                                </button>

                                {/* initiateCall */}
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn-blue-filled"
                                  onClick={() => changeStatus()}
                                >
                                  {startdigno ? "Mark Complete" : "Start"}
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 ">
                    <div className="right">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="1"
                      >
                        <Nav
                          variant="pills"
                          id="newTabMai"
                          className="tob_nav_pills"
                        >
                          <Nav.Item
                            style={{
                              color: "black",
                            }}
                          >
                            <Nav.Link eventKey="1">
                              Visits(<span>{VisiteTotal}</span>)
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="2">
                              Invoices (<span>{InviceTotal}</span>)
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="1">
                            <div className="visits-tab-wrapper">
                              <div className="today-heading">
                                <label htmlFor="" className="key">
                                  Todays
                                </label>
                                <div className="line"></div>
                              </div>
                              {ClintVisitAlllist.today_appointment &&
                              ClintVisitAlllist.today_appointment.length > 0 ? (
                                ClintVisitAlllist.today_appointment.map(
                                  (itemlist, index) => {
                                    return (
                                      <div className="app-time-date">
                                        <div
                                          className="left-app-time-date"
                                          onClick={() => {
                                            getClinetDetailsByeVisit(
                                              itemlist.doctor_appointment_id
                                            );
                                          }}
                                        >
                                          <label htmlFor="" className="value">
                                            {itemlist.date}
                                          </label>
                                        </div>
                                        <div
                                          className="right-app-time-date"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            getHistoryPres(
                                              itemlist?.doctor_appointment_id
                                            )
                                          }
                                        >
                                          <img src={ic_notepad} alt="" />
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <p className="scheule_tody">
                                  No Schedule Today
                                </p>
                              )}
                              <div className="today-heading">
                                <label htmlFor="" className="key line-style">
                                  Completed
                                </label>
                                <div className="line"></div>
                              </div>
                              {ClintVisitAlllist.history &&
                              ClintVisitAlllist.history.length > 0 ? (
                                ClintVisitAlllist.history.map(
                                  (itemlist, index) => {
                                    return (
                                      <div
                                        style={{ cursor: "pointer" }}
                                        className="app-time-date"
                                      >
                                        <div
                                          className="left-app-time-date"
                                          onClick={() => {
                                            getClinetDetailsByeVisit(
                                              itemlist.doctor_appointment_id
                                            );
                                          }}
                                        >
                                          <label htmlFor="" className="value">
                                            {itemlist.date}
                                          </label>
                                        </div>
                                        <div className="right-app-time-date">
                                          <img src={ic_notepad} alt="" />
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <p className="scheule_tody">
                                  No Schedule Today
                                </p>
                              )}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="2">
                            <div className="invoices-tab-wrapper">
                              <div className="table-wrapper ">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Sr. No</th>
                                      <th>Date</th>
                                      <th>Amount</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {ClintInvicelist &&
                                    ClintInvicelist.length > 0 ? (
                                      ClintInvicelist.map((itemlist, index) => {
                                        return (
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>{itemlist.date}</td>
                                            <td>₹{itemlist.charges}</td>
                                            <td>
                                              <img
                                                src={ic_completed}
                                                className="status-ic"
                                                alt=""
                                              />
                                              <label
                                                htmlFor=""
                                                className="pending-txt"
                                              >
                                                Completed
                                              </label>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <p className="scheule_tody">
                                        No Schedule Completed
                                      </p>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="col-lg-8">
                  <div className="left-video heightCalc">
                    <h4 className="capital">
                      Appointment with :{" "}
                      {ClintDetailsList.first_name
                        ? `${ClintDetailsList.first_name} ${ClintDetailsList.last_name}`
                        : ""}{" "}
                    </h4>
                    <div className="video-section">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "50vh",
                        }}
                      >
                        {isCallAccepted && roomId ? (
                          <AgoraUIKit
                            rtcProps={rtcProps}
                            // callbacks={{
                            //   EndCall: () => setIsCallAccepted(false),
                            // }}
                            callbacks={rtcCallbacks}
                            style={{
                              resizeMode: "cover",
                              width: "100%",
                              overflow: "hidden",
                              borderRadius: 10,
                              borderWidth: 2,
                              borderColor: "red",
                            }}
                          />
                        ) : (
                          <>
                            <div className="userCallSec mb-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="80"
                                height="80"
                                fill="#000"
                                class="bi bi-person-video3"
                                viewBox="0 0 16 16"
                              >
                                <path d="M14 9.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-6 5.7c0 .8.8.8.8.8h6.4s.8 0 .8-.8-.8-3.2-4-3.2-4 2.4-4 3.2Z" />
                                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h5.243c.122-.326.295-.668.526-1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7.81c.353.23.656.496.91.783.059-.187.09-.386.09-.593V4a2 2 0 0 0-2-2H2Z" />
                              </svg>
                            </div>
                            <p className="text-center">
                              <button
                                className="btn btn-danger"
                                onClick={() => {
                                  initiateCall("initiatecall");
                                }}
                              >
                                Start Call
                              </button>
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="right-chating">
                    <h4>Chat</h4>
                    <div className="chatting-section text-center">
                      <div className="message-main">
                        {recentChatdata.map((chatData, i) => {
                          return customerData.user_id ==
                            chatData.conversation_from ? (
                            <>
                              <div className="send-msg">
                                <div className="msg-with-img">
                                  <img
                                    src="https://api.wifiattendance.com/assets/uploads/icon-user-default.png"
                                    alt=""
                                  />
                                </div>
                                <div className="send-msg-main">
                                  <div className="msg-bg">
                                    <span className="senderName">
                                      {chatData.conversation_from_name}
                                    </span>

                                    <p className="msg-text">
                                      {chatData.conversation_message}
                                      {/* Jane wala */}
                                    </p>
                                  </div>
                                  <div className="time-date-sms-send">
                                    {moment(
                                      chatData.conversation_datetime
                                    ).fromNow()}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div key={i} className="receive-msg">
                                <div className="msg-with-img">
                                  <img
                                    src="https://api.wifiattendance.com/assets/uploads/icon-user-default.png"
                                    alt=""
                                  />
                                </div>
                                <div className="right-receive-msg">
                                  <div className="msg-bg">
                                    <span className="senderName">
                                      {chatData.conversation_from_name}
                                    </span>
                                    <div className="receive-msg-main">
                                      <p className="msg-text">
                                        {chatData.conversation_message}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="time-date-sms-recieve">
                                    {moment(
                                      chatData.conversation_datetime
                                    ).fromNow()}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}

                        {newmessage.map((chatData, i) => {
                          return customerData.user_id ==
                            chatData.conversation_from ? (
                            <>
                              <div className="send-msg">
                                <div className="msg-with-img">
                                  <img
                                    src="https://api.wifiattendance.com/assets/uploads/icon-user-default.png"
                                    alt=""
                                  />
                                </div>
                                <div className="send-msg-main">
                                  <div className="msg-bg">
                                    <span className="senderName">
                                      {chatData.conversation_from_name}
                                    </span>

                                    <p className="msg-text">
                                      {chatData.conversation_message}
                                      {/* Jane wala */}
                                    </p>
                                  </div>
                                  <div className="time-date-sms-send">
                                    {moment(
                                      chatData.conversation_datetime
                                    ).fromNow()}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div key={i} className="receive-msg">
                                <div className="msg-with-img">
                                  <img
                                    src="https://api.wifiattendance.com/assets/uploads/icon-user-default.png"
                                    alt=""
                                  />
                                </div>
                                <div className="right-receive-msg">
                                  <div className="msg-bg">
                                    <span className="senderName">
                                      {chatData.conversation_from_name}
                                    </span>
                                    <div className="receive-msg-main">
                                      <p className="msg-text">
                                        {/* {chatData.conversation_message} */}
                                        {chatData.conversation_message}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="time-date-sms-recieve">
                                    {moment(
                                      chatData.conversation_datetime
                                    ).fromNow()}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <div ref={lastMessageRef} />
                    </div>
                    <div className="send-text-sec">
                      <div className="wrap-btn-send">
                        <input
                          type="text"
                          name=""
                          id=""
                          className="form-control"
                          placeholder="Type Something..."
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          onKeyUp={(e) => {
                            if (message && e.key == "Enter") sendMessage();
                          }}
                        />
                        {/* <button>
                      <img src="" alt="" />
                      <img src={btnSend} alt="" />
                    </button> */}
                        <button>
                          <img
                            onClick={() => {
                              message && sendMessage();
                            }}
                            onKeyUp={sendMessage}
                            src={btnSend}
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChattingVideoComp;
