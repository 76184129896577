import React from "react";
import { ColorRing, Circles, FidgetSpinner } from "react-loader-spinner";

export default function Loader() {
  return (
    <div className="loader-wrapper">
 

<Circles style={{color: "red"}}
  visible={true}
  height="70"
  width="70"
  ariaLabel="dna-loading"
  wrapperStyle={{}}
  wrapperClass="dna-wrapper"
  ballColors={['red', 'red', 'red']}
  backgroundColor="red"
/>

    </div>
  );
}
