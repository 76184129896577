import React, { useContext, useState } from "react";
import logo from "../../assets/images/logo.svg";
import teleCall from "../../assets/images/ic_call1.svg";
import telephone from "../../assets/images/icons/telephone.svg";
import otp_icon from "../../assets/images/icons/otp_icon.svg";
import lock from "../../assets/images/lock.svg";
import { Link, useNavigate } from "react-router-dom";
import { DarkButton, HandleRoute } from "../commonComponents/commonComp";
import { AppContext } from "../../context/AppContext";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";

const ForgotPass = () => {

  const navigate = useNavigate("");
  const { userCredentials, setUserCredentials, setLoading } = useContext(AppContext);
  const [ResponeOtp, setResponeOtp] = useState("");
  const handleRoute = (route) => {
    navigate(route);
  };

  const startTimer = () => {
    if (seconds > 0) {
      setSeconds(60);
      let interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  };
  const sendOtp = () => {
    simplePostCall(ApiConfig.SEND_OTP, JSON.stringify({
      phone_number: userCredentials.email
    }))
      .then((res) => {
        if (res.success) {
          startTimer();
          setFirstClick(true);
          // setResponeOtp(res.user.otp)
          // navigate('/ClinicSchedule')
          notifySuccess(res.message);
        } else {
          notifyError(res.message);

        }
      })
      .catch((err) => {

        console.log(err);
      });
  };

  const handalverifyOt = () => {

    setLoading(true)

    simplePostCall(ApiConfig.VERIYFIY_OTP, JSON.stringify({
      phone_number: userCredentials.email,
      otp: ResponeOtp
    }))
      .then((res) => {

        if (res.success) {
          notifySuccess(res.otp)
          handleRoute("/ResetPass")
          notifySuccess(res.message);
        } else {
          notifyError(res.message);

        }
      })
      .catch((err) => {

        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const [seconds, setSeconds] = useState(60);
  const [firstClick, setFirstClick] = useState(false);


  return (
    <>
      <div className="authMain">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <img src={logo} alt="" className="login_img" />
            <div className="signinForm">
              <h1 className="signInTxt">Forgot your password ?</h1>
              <p className="signInDesc">
                If you forgot your password, Don't Worry ! <br />You can reset your
                <Link className="link_common" to="#"> password Here !</Link>
              </p>
              <div className="inputBox">
                {/* <button className="otpbtn" onClick={() => sendOtp()}>Request OTP</button> */}
                {firstClick ? null : (
                  <button
                    className="otpbtn"
                    onClick={() => sendOtp()}
                  >
                    Request OTP
                  </button>
                )}
                {seconds == 0 ? (
                  <button className="resendotpbtnGreen" onClick={sendOtp}>
                    Re send OTP
                  </button>
                ) : (
                  <button className="resendotpbtn" onClick={sendOtp}>
                    Re send OTP ({seconds})
                  </button>
                )}
                {/* <button className="resendotpbtn">Re send OTP (26)</button> */}
                <label htmlFor="" className="emailLabel">
                  Phone Number
                </label>
                <img src={telephone} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your phone number"
                  value={userCredentials.email}
                  onChange={(e) => {
                    setUserCredentials({
                      ...userCredentials,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  OTP
                </label>
                <img src={otp_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter OTP here..."
                  value={ResponeOtp}
                  onChange={(e) => {
                    let value = e.target.value;
                    let valueInput = value.replace(/[^0-9 ]/gi, "");
                    setResponeOtp(valueInput,);
                  }}
                />

              </div>
              <div className="login btn-width-100">
                <DarkButton

                  onClick={() => handalverifyOt()}

                  classbtn={"cx-btn-dark btn-width-100 btn-height font-17 mb-5"}
                  btn={"Reset Password"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="landingImg">
              <div className="phone-no">
                <img src={teleCall} alt="" />
                <label htmlFor="">+91 9509932234</label>
              </div>
              <div className="img-container">
                <div className="big-img">
                  <img src={lock} alt="" />
                </div>
                <div>
                  <h2>Forgot Password ?</h2>
                  <p>No Worries ! We got your back.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default ForgotPass;
