import { useNavigate } from "react-router-dom";
import ApiConfig, { BASE_URL } from "../api/ApiConfig";
import { simpleGetCall } from "../api/ApiServices";
import { AppContext } from "./AppContext";
import io from "socket.io-client";
import React, { useEffect, useMemo, useState } from "react";
import appointment_received from "../assets/sound/appointment_received.mp3";
import callingRingtone from "../assets/sound/night-owl-26908.mp3";
import useSound from "use-sound";
import { notificationMsg } from "../sharedComponent/notify";
import PopupOverlay from "../pages/Appointments/PopupOverlay";

const AppState = (props) => {
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [noticationList, setnoticationList] = useState([]);
  const [customerData, setCustomerData] = useState({
    auth_token: localStorage.getItem("auth_token")
      ? localStorage.getItem("auth_token")
      : null,
    full_name: localStorage.getItem("full_name")
      ? localStorage.getItem("full_name")
      : null,
    email: localStorage.getItem("email") ? localStorage.getItem("email") : null,
    doctor_id: localStorage.getItem("doctor_id")
      ? localStorage.getItem("doctor_id")
      : null,
    user_id: localStorage.getItem("user_id")
      ? localStorage.getItem("user_id")
      : null,
    doctor_contact_number: localStorage.getItem("doctor_contact_number")
      ? localStorage.getItem("doctor_contact_number")
      : null,
    doctor_profile_image_path: localStorage.getItem("doctor_profile_image_path")
      ? localStorage.getItem("doctor_profile_image_path")
      : null,
    is_subscribed: localStorage.logedIn ? localStorage.logedIn : "",
  });

  const [backdrop, setBackdrop] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [Dark, setDark] = useState("lightMode");
  const [Prescription, setPrescription] = useState(false);
  const [VaccinePrescription, setVaccinePrescription] = useState(false);
  const [petFoodPrescription, setPetFoodPrescription] = useState(false);
  const [doctorId, setdoctorId] = useState("");
  const [customerId, setcustomerId] = useState("");
  const [doctor_clinic_id, setdoctor_clinic_id] = useState("");
  const [doctorAppoinmentID, setdoctorAppoinmentID] = useState("");
  const [doctorAnimalID, setdoctorAnimalID] = useState("");
  const [doctorVideoPresptionID, setdoctorVideoPresptionID] = useState("");
  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [ProfileHader, setProfileHader] = useState([]);
  const [ProfiDr, setProfiDr] = useState({});
  const [VedioCallPrispation, setVedioCallPrispation] = useState(false);
  const [roomId, setRoomid] = useState("");
  const [callId, setCallId] = useState("");
  const [senderId, setSenderId] = useState("");
  const [senderName, setSenderName] = useState("");
  const [senderNameImg, setSenderImg] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [drocterCallOpen, setdrocterCallOpen] = useState(false);
  const [VideoChatting, setVideoChatting] = useState(true);
  const [CallAccepted, setCallAccepted] = useState(false);
  const [isCallAccepted, setIsCallAccepted] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [historyPres, setHistoryPres] = useState(false);
  const [historyPresData, setHistoryPresData] = useState({});
  const [videoHistoryPres, setVideoHistoryPres] = useState(false);
  const [videohistoryPresData, setVideoHistoryPresData] = useState({});
  const [notesData, setNotesData] = useState("");
  const [vaccineData, setVaccineData] = useState([]);
  const [petFoodData, setPetFoodData] = useState([]);
  const [patientDet, setPatientDet] = useState({});

  const [play, { stop }] = useSound(callingRingtone);
  const navigate = useNavigate();
  const [audio, setAudio] = useState(null);
  // appointment_received
  useEffect(() => {
    setAudio(new Audio(appointment_received));

    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, []);

  const playAudio = useMemo(() => () => {
      try {
        if (audio) {
          audio.currentTime = 0;
          // audio.removeEventListener("ended", handleAudioEnded);
          audio.play();
          // audio.addEventListener("ended", handleAudioEnded);
        }
      } catch (error) {
        console.log("Audio catch error");
      }
    },
    [audio]
  );

  const handleAudioEnded = () => {
    audio.removeEventListener("ended", handleAudioEnded);
    playAudio();
  };

  const stopAudio = useMemo(
    () => () => {
      try {
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
        }
      } catch (error) {
        console.log("Audio catch error");
      }
    },
    [audio]
  );

  useEffect(() => {
    getProfileHaderDetails();
  }, [notesData]);

  const getProfileHaderDetails = () => {
    // setLoading(true);

    simpleGetCall(ApiConfig.HADER_PROFILE + customerData.doctor_id)
      .then((res) => {
        if (res.success) {
          setProfileHader(res.data);
          setProfiDr(res.data);
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const [updateImg, setUpdateImg] = useState({
    doctor_id: customerData.doctor_id,
    profilePath: "",
  });
  let option = {
    timeout: 20000,
    query: {
      UID: localStorage.getItem("doctor_id"),
    },
  };
  const [socket, setSocket] = useState();

  useEffect(() => {
    if (customerData.user_id) {
      setSocket(io(`${BASE_URL}?UID=${customerData.user_id}`, option));
    }
  }, [customerData]);

  const handleMouseEnter = () => {
    play();
  };

  const handleMouseLeave = () => {
    stop();
  };

  useEffect(() => {
    // Check and request permissions for microphone and camera

    socket &&
      socket.on("usercalling", (data) => {
        handleMouseEnter();
        setdrocterCallOpen(true);
        setModalOpen(true);
        setCallId(data.call_id);
        setSenderId(data.senderId);
        setcustomerId(data.senderId);
        setReceiverId(data.receiverId);
        setRoomid(data.room_id);
        sessionStorage.setItem("roomID", data.room_id);
        sessionStorage.setItem("receiver_id", data.senderId);
        setSenderName(data.SenderName);
        setSenderImg(data.SenderProfile);
        // onDisplayNotification();
        // Handle the response from the "usercalling" event here
      });

    return () => {
      socket && socket.off("usercalling");
    };
  }, [socket]);

  useEffect(() => {
    socket &&
      socket.on("endedcall", (data) => {
        setVedioCallPrispation(false);
        setIsCallAccepted(false);
      });

    return () => {
      socket && socket.off("endedcall");
    };
  }, [socket]);

  const rejectCall = () => {
    handleMouseLeave();
    const requestBody = {
      senderId: senderId,
      receiverId: receiverId,
      call_id: callId,
      room_id: roomId,
      rejectedBy: customerData.user_id ? customerData.user_id : "",
    };

    socket.emit("rejectcall", requestBody);

    setModalOpen(false);
    setdrocterCallOpen(false);
    stop();
  };

  const answerCall = () => {
    console.log("answering call to sender...");
    handleMouseLeave();
    const requestBody = {
      senderId: receiverId,
      receiverId: senderId,
      call_id: callId,
      room_id: roomId,
    };

    socket.emit("answercall", requestBody);
    navigate("/ChattingVideoComp");
    setdrocterCallOpen(false);
    setModalOpen(false);
    setIsCallAccepted(true);
    setCallAccepted(true);
    stop();

    // setVideoChatting(false)
  };

  useEffect(() => {
    // Check and request permissions for microphone and camera
    socket &&
      socket.on("notification", (data) => {
        console.log("data", data);
        // handleMouseEnter();
        notificationMsg(data.text);
        getNoticationDetails()
        // setAudio(new Audio(appointment_received));
        setTimeout(() => {
          data.text?.toLowerCase()?.includes("new appointment") && playAudio()
        });
        return () => {
          socket && socket.off("notification");
        };
      });
    return () => {
      socket && socket.off("notification");
    };
  }, [socket]);

  function getNoticationDetails() {
    simpleGetCall(ApiConfig.Notification_LIST + customerData.user_id)
      .then((res) => {
        setnoticationList(res?.data);
        setNotificationsCount(res?.data?.length);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  var validNumber = new RegExp(/^\d*\.?\d*$/);
  return (
    <div>
      <AppContext.Provider
        value={{
          backdrop,
          setBackdrop,
          VedioCallPrispation,
          setVedioCallPrispation,
          validNumber,
          sidebar,
          setSidebar,
          Dark,
          setDark,
          Prescription,
          setPrescription,
          historyPres,
          setHistoryPres,
          historyPresData,
          setHistoryPresData,
          videoHistoryPres,
          setVideoHistoryPres,
          videohistoryPresData,
          setVideoHistoryPresData,

          VaccinePrescription,
          setVaccinePrescription,
          petFoodPrescription,
          setPetFoodPrescription,
          doctorId,
          setdoctorId,
          doctor_clinic_id,
          setdoctor_clinic_id,
          userCredentials,
          setUserCredentials,
          customerData,
          setCustomerData,
          loading,
          setLoading,
          doctorAppoinmentID,
          setdoctorAppoinmentID,
          ProfileHader,
          setProfileHader,
          ProfiDr,
          setProfiDr,
          getProfileHaderDetails,
          updateImg,
          setUpdateImg,
          socket,
          setSocket,
          customerId,
          setcustomerId,
          roomId,
          setRoomid,
          senderName,
          setSenderName,
          senderNameImg,
          setSenderImg,
          receiverId,
          setReceiverId,
          drocterCallOpen,
          setdrocterCallOpen,
          VideoChatting,
          setVideoChatting,
          CallAccepted,
          setCallAccepted,
          modalOpen,
          setModalOpen,
          rejectCall,
          answerCall,
          isCallAccepted,
          setIsCallAccepted,
          callId,
          setCallId,
          doctorVideoPresptionID,
          setdoctorVideoPresptionID,
          doctorAnimalID,
          setdoctorAnimalID,
          notificationsCount,
          setNotificationsCount,
          noticationList,
          setnoticationList,
          notesData,
          setNotesData,
          vaccineData,
          setVaccineData,
          petFoodData,
          setPetFoodData,
          patientDet,
          setPatientDet,
          navigate,
        }}
      >
        {props.children}
        {drocterCallOpen === true ? <PopupOverlay /> : <></>}
      </AppContext.Provider>
    </div>
  );
};

export default AppState;
