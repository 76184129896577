import React from 'react'

const PaginationComp = ({length,total}) => {
  return (
    <div className='pagination-wrapper'>
        <label htmlFor="">Showing  1 - {length} of {total}</label>
    </div>
  )
}

export default PaginationComp

