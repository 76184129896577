import React, { useContext, useState } from "react";
import logo from "../../assets/images/logo.svg";
import bank_icon from "../../assets/images/icons/bank_icon.svg";
import wallet_img from "../../assets/images/wallet_img.svg";
import { DarkButton } from "../commonComponents/commonComp";
import teleCall from "../../assets/images/ic_call1.svg";
import { useNavigate } from "react-router-dom";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import { notifyError } from "../../sharedComponent/notify";

const BankDetails = () => {
  const { setLoading } = useContext(AppContext);
  const doctorId = localStorage.getItem("sign_doctor_id")
    ? localStorage.getItem("sign_doctor_id")
    : null;

  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [signature, setSignature] = useState();
  const [deleteImg, setDeleteImg] = useState(true);

  function handleFileInput(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  function handleFileInput2(e) {
    setSignature(URL.createObjectURL(e.target.files[0]));
    setDeleteImg(true);
  }

  const handleDeleteImg = () => {
    setDeleteImg(!deleteImg);
  };

  const options = [
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
    { value: "Marathi", label: "Marathi" },
    { value: "Urdu", label: "Urdu" },
  ];

  const [selected, setSelected] = useState(null);

  const handleChange = (e) => {
    setSelected(e.value);
  };
  // const [selectedOption, setSelectedOption] = useState("");

  // const handleChange1 = (selectedOption) => {
  //   setSelectedOption(selectedOption.value);
  // };
  const [Bankdentials, setBankdentials] = useState({
    bank_name: "",
    account_number: "",
    account_holder_name: "",
    ifsc_code: "",
  });

  const addBAnk = () => {
    if (
      !Bankdentials.account_holder_name ||
      !Bankdentials.bank_name ||
      !Bankdentials.account_number ||
      !Bankdentials.ifsc_code
    ) {
      notifyError("All fields are required");
      return
    }
    setLoading(true);
    simplePostCall(
      ApiConfig.BANK_DETALIS,
      JSON.stringify({ ...Bankdentials, doctor_id: doctorId })
    )
      .then((res) => {
        if (res.success) {
          navigate("/ClinicSchedule");
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="authMain">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <img src={logo} alt="" className="login_img" />
            <div className="signinForm signUp general_info">
              <h1 className="signInTxt">Bank Details</h1>

              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Bank
                </label>
                <img src={bank_icon} className="mailImg" alt="" />
                {/* <img src={bank_icon} className="mailImg" alt="" />
                <select className="form-select formInput">
                  <option value="">Select Bank</option>
                  <option value="">Bank 1</option>
                  <option value="">Bank 2</option>
                  <option value="">Bank 3</option>
                </select> */}
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your Bank"
                  value={Bankdentials.bank_name}
                  onChange={(e) => {
                    let value = e.target.value;
                    let valueInput = value.replace(/[^A-Za-z ]/gi, "");
                    setBankdentials({
                      ...Bankdentials,
                      bank_name: valueInput,
                    });
                  }}
                />
              </div>

              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Account Number
                </label>
                <img src={bank_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your  account number"
                  value={Bankdentials.account_number}
                  onChange={(e) => {
                    let value = e.target.value;
                    let valueInput = value.replace(/[^0-9 ]/gi, "");
                    setBankdentials({
                      ...Bankdentials,
                      account_number: valueInput,
                    });
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Account Holder Name
                </label>
                <img src={bank_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your acoount holder's name"
                  value={Bankdentials.account_holder_name}
                  onChange={(e) => {
                    let value = e.target.value;
                    let valueInput = value.replace(/[^A-Za-z ]/gi, "");
                    setBankdentials({
                      ...Bankdentials,
                      account_holder_name: valueInput,
                    });
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  IFSC / SWIFT Code
                </label>
                <img src={bank_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter IFSC / SWIFT Code"
                  value={Bankdentials.ifsc_code}
                  onChange={(e) => {
                    let value = e.target.value;
                    // let valueInput = value.replace(/[^A-Za-z ]/gi, "");
                    setBankdentials({
                      ...Bankdentials,
                      ifsc_code: value,
                    });
                  }}
                />
              </div>

              <div className="login btn-width-100">
                {/* <DarkButton
                 onClick={()=>navigate('/UploadDocs')}
                  classbtn={
                    "cx-btn-light me-3  btn-width-45 btn-height font-17 mb-5"
                  }
                  btn={"Back"}
                /> */}
                <DarkButton
                  onClick={() => addBAnk()}
                  classbtn={"cx-btn-dark  btn-height btn-width-50 font-17 mb-5"}
                  btn={"Save & Proceed"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="landingImg">
              <div className="phone-no">
                <img src={teleCall} alt="" />
                <label htmlFor="">+91 9509932234</label>
              </div>
              <div className="img-container">
                <div className="big-img">
                  <img src={wallet_img} alt="" />
                </div>
                <div>
                  <h2>Banking Details</h2>
                  <p>Share to collect fees !</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankDetails;
