import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import earn_icon from "../../assets/images/icons/earn_icon.svg";
import patient_icon from "../../assets/images/icons/patient_icon.svg";
import total_appoint_icon from "../../assets/images/icons/total_appoint_icon.svg";
import complete_icon from "../../assets/images/icons/complete_icon.svg";
import cancelled_icon from "../../assets/images/icons/cancelled_icon.svg";
import wallet_icon from "../../assets/images/icons/wallet_icon.svg";
import export_icon from "../../assets/images/icons/export_icon.svg";
import ic_completed from "../../assets/images/ic_completed.svg";
import ic_rejected from "../../assets/images/ic_rejected.svg";
import cat from "../../assets/images/cat.png";
import ic_filter from "../../assets/images/ic_filter.svg";
import { motion } from "framer-motion";
import { DarkButton, DonutChart } from "../commonComponents/commonComp";
import { Link } from "react-router-dom";
import { simpleGetCall, simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import InfiniteScroll from "react-infinite-scroll-component";
import PaginationComp from "../../sharedComponent/PaginationComp";
import PopupOverlay from "../Appointments/PopupOverlay";
import Modal from "react-bootstrap/Modal";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import moment from "moment";
import Chart from "react-apexcharts";
import { formatCurrency } from "../../common/commonFunctions";

const TransactionDetails = () => {
  const todaysDate = new Date();
  const { setLoading, customerData, drocterCallOpen } = useContext(AppContext);
  const { user_id } = customerData;
  const [AllTransactionlist, setAllTransactionlist] = useState([]);
  const [AllTransactionHistorylist, setAllTransactionHistorylist] = useState(
    []
  );
  const [currentTab, setCurrentTab] = useState("1");

  const [FilterName, setFilterName] = useState("");
  const [Filter1Name, setFilter1Name] = useState("");
  const [TotalCharges, setTotalCharges] = useState("");
  const [page, setPage] = useState(1);
  const [TotalData, setTotalData] = useState(0);
  const pha_id = localStorage.getItem("user_id");
  const [dropdownKey, setDropdownKey] = useState([]);
  const [withdraw, setWithdraw] = useState(false);
  const [withdrawDaat, setWithdrawDaat] = useState({
    user_id: pha_id,
    request_reason: "",
    request_amount: null,
    bank_id: null,
  });

  const getBankListDrop = () => {
    setWithdraw(!withdraw);
    simpleGetCall(
      ApiConfig.GET_PAYMENT_DROPDOWN + `?user_id=${pha_id}&type=${"doc"}`
    )
      .then((res) => {
        const extractedData = res?.data?.map((item) => ({
          bankName: item.doctor_bank,
          bankId: item.doctor_bank_id,
        }));
        setDropdownKey(extractedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postWithdrawAmnt = () => {
    if (withdrawDaat.request_amount <= parseInt(TotalCharges?.earning)) {
      if (withdrawDaat.request_amount !== null) {
        simplePostCall(
          ApiConfig.POST_WITHDRAW_REQUEST,
          JSON.stringify(withdrawDaat)
        )
          .then((res) => {
            notifySuccess(res?.message);
            setWithdraw(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        notifyError("All fields are required");
      }
    } else {
      notifyError("Please select a valid amount");
    }
  };

  const [viewChange, setviewChange] = useState(true);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  useEffect(() => {
    getTransactionDetails();
  }, [FilterName]);
  useEffect(() => {
    getMontheDetails(page);
  }, [Filter1Name]);

  useEffect(() => {
    fetchPaymentAnalytics(todaysDate, todaysDate);
  }, []);

  const getTransactionDetails = () => {
    let newRequestBody = JSON.stringify({
      doctor_id: customerData.doctor_id,
      keyword: FilterName,
    });
    simplePostCall(ApiConfig.TRANSACTION_DAILY, newRequestBody)
      .then((res) => {
        if (res.success) {
          setAllTransactionlist(res.data);
        } else {
          setAllTransactionlist([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getMontheDetails = (currentPage) => {
    // setLoading(true);
    let newRequestBody = JSON.stringify({
      doctor_id: customerData.doctor_id,
      keyword: Filter1Name,
      // page: currentPage,
      // limit: 10,
    });
    simplePostCall(ApiConfig.TEANSACTION_MONTHEY, newRequestBody)
      .then((data) => {
        if (data.success) {
          setTotalCharges(data?.total_amount);
          setLoading(false);
          let total = data.total ? data.total : 0;
          setTotalData(total);
          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              first_name: eachteam.first_name || "",
            };
          });

          if (currentPage === 1) {
            setAllTransactionHistorylist(data.data);
            setLoading(false);
          } else {
            setAllTransactionHistorylist([
              ...AllTransactionHistorylist,
              ...LearningData,
            ]);
            setLoading(false);
          }
        } else {
          setAllTransactionHistorylist([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPageLoad = () => {
    setPage(page + 1);

    if (currentTab == 2) {
      getMontheDetails(page + 1);
    }
  };

  const [paymentAnalytics, setpaymentAnalytics] = useState({
    withdrawPercentage: 0,
    receivedPercentyage: 0,
  });

  const tempArr = Object.keys(paymentAnalytics).map((key) =>
    Number(paymentAnalytics[key])
  );
  const arr = tempArr && tempArr.map((ele) => parseFloat(ele.toFixed(2)));

  const [donut, setDonut] = useState({
    options: {
      dataLabels: {
        enabled: false,
      },
      colors: ["#01B529", "#FFB200"],
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
      labels: ["Withdrawl", "Received"],
    },
    series: arr,
    style: {
      // colors: ["#FFB200", "#01B529", "#5500DF"],
    },
  });

  const fetchPaymentAnalytics = async (fromData, toData) => {
    const userId = user_id && user_id?.split("_")[1];
    const payLoad = {
      user_id: userId,
      from_date: moment(fromData).format("YYYY-MM-DD"),
      to_date: moment(toData).format("YYYY-MM-DD"),
    };
    try {
      const res = await simplePostCall(
        `${ApiConfig.FETCH_PAYMENT_ANALYTICS}`,
        JSON.stringify(payLoad)
      );
      const { success, data } = res;
      if (success && data) {
        setpaymentAnalytics({
          withdrawPercentage: data?.WithdrawPercentage,
          receivedPercentyage: data?.ReceviedPercentage,
        });
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.3 }}
        className="appointment-wrapper reports_wrapper transaction_details_wrapper"
        id="cx-main"
      >
        <InfiniteScroll
          dataLength={
            currentTab == 1
              ? ""
              : currentTab == 2
              ? AllTransactionHistorylist?.length
              : ""
          }
          next={onPageLoad}
          // hasMore={true}
          hasMore={
            currentTab == 1
              ? ""
              : currentTab == 2
              ? AllTransactionHistorylist.length !== TotalData
              : ""
          }
          loader={
            <h4
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginLeft: "20px",
              }}
            ></h4>
          }
        >
          <div className="transaction_details" id="TransactionDetails">
            {/* {drocterCallOpen === true ? <PopupOverlay /> : <></>} */}
            <div className="search-filter-wrapper mb-4">
              <div className="search-filter-left">
                {currentTab == 1 ? (
                  <>
                    <input
                      type="text"
                      className="form-control serach-input"
                      placeholder="Search by Name..."
                      value={FilterName}
                      autoFocus
                      onChange={(e) => {
                        setFilterName(e.target.value);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      className="form-control serach-input"
                      placeholder="Search by Name..."
                      value={Filter1Name}
                      autoFocus
                      onChange={(e) => {
                        setFilter1Name(e.target.value);
                      }}
                    />
                  </>
                )}
                {/* <button className="fltr-btn">
                  <img src={ic_filter} alt="" />
                </button> */}
              </div>
            </div>
            <div className="wrapper-main">
              <div className="row">
                <div className="col-lg-8">
                  <div className="table-wrapper table_wrapper_pagination py-4">
                    <div className="reportsTabs">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="1"
                      >
                        <Nav
                          variant="pills"
                          id="newTabMai"
                          className="tob_nav_pills d-flex"
                          onSelect={(selectedKey) =>
                            setCurrentTab(`${selectedKey}`)
                          }
                        >
                          <Nav.Item>
                            <Nav.Link
                              eventKey="1"
                              onClick={() => {
                                //  setStatus("Daily")

                                getTransactionDetails("");
                                setFilterName("");
                              }}
                            >
                              Recent Transactions
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="2"
                              onClick={() => {
                                // setStatus("Monthly")
                                setFilter1Name("");

                                getMontheDetails(1);
                              }}
                            >
                              Withdraw History
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        {/* <Link to="#" className="export_button custom_border">
                          <img src={export_icon} alt="" />
                        </Link> */}
                        <Tab.Content>
                          <Tab.Pane eventKey="1">
                            <div className="dashboard_wrapper trans-wrapper">
                              <div
                                className="table-wrapper table_wrapper_pagination p-0 dasboard-cust-tb-wrapper"
                                id="tran-table"
                              >
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Sr. No</th>
                                      <th>Name</th>
                                      <th>Date</th>
                                      <th>Transaction ID</th>
                                      <th>Description</th>
                                      <th>Amount</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {AllTransactionlist &&
                                    AllTransactionlist.length > 0 ? (
                                      AllTransactionlist.map(
                                        (itemlist, index) => {
                                          return (
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>
                                                <label
                                                  htmlFor=""
                                                  className="photo tdName"
                                                >
                                                  <img
                                                    src={itemlist.image_path}
                                                    alt=""
                                                  />
                                                  {`${itemlist?.first_name} ${itemlist?.last_name}`}
                                                </label>
                                              </td>
                                              <td>{itemlist.date}</td>
                                              <td>{itemlist.transaction_id}</td>
                                              <td>
                                                {itemlist?.appointment_desc ? itemlist?.appointment_desc : ""}
                                                {/* {itemlist.appointment_type == 2 ? "Video" : itemlist.appointment_type == 1 ? "Clinic" : ""} */}
                                              </td>
                                              <td>₹{itemlist.charges}</td>
                                              <td>
                                                <img
                                                  src={ic_completed}
                                                  className="status-ic"
                                                  alt=""
                                                />
                                                Completed
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </tbody>
                                </table>
                                {AllTransactionlist.length === 0 ? (
                                  <p
                                    style={{
                                      fontSize: "20px",
                                      marginTop: "200px",
                                    }}
                                    className=" text-center justify-content-center align-items-center"
                                  >
                                    Oops! It doesn't look like there are any
                                    Transaction?
                                  </p>
                                ) : (
                                  <></>
                                )}

                                {/* <div className="pagination_wrapper">
                            <p>Showing 1-10 of 100</p>
                          </div> */}
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="2">
                            <div className="dashboard_wrapper trans-wrapper">
                              <div
                                className="table-wrapper table_wrapper_pagination p-0 dasboard-cust-tb-wrapper"
                                id="tran-table2"
                              >
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Sr. No</th>
                                      <th>Name</th>
                                      <th>Date</th>
                                      <th>Description</th>
                                      <th>Amount</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {AllTransactionHistorylist &&
                                    AllTransactionHistorylist.length > 0 ? (
                                      AllTransactionHistorylist.map(
                                        (itemlist, index) => {
                                          return (
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>
                                                <label
                                                  htmlFor=""
                                                  className="photo tdName"
                                                >
                                                  <img
                                                    src={
                                                      itemlist.doctor_profile_image_path
                                                    }
                                                    alt=""
                                                  />
                                                  {itemlist.doctor_name}
                                                </label>
                                              </td>
                                              <td>
                                                {moment(
                                                  itemlist.request_datetime
                                                ).format("L")}
                                              </td>
                                              {/* <td>12345678900</td> */}
                                              <td>{itemlist.request_reason}</td>
                                              <td>
                                                ₹{itemlist.request_amount}
                                              </td>
                                              <td>
                                                <img
                                                  src={itemlist?.status == "Accepted" ? ic_completed : ic_rejected}
                                                  className="status-ic"
                                                  alt=""
                                                />
                                                {itemlist?.status}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </tbody>
                                </table>
                                {AllTransactionHistorylist.length === 0 ? (
                                  <p
                                    style={{
                                      fontSize: "20px",
                                      marginTop: "200px",
                                    }}
                                    className=" text-center justify-content-center align-items-center"
                                  >
                                    Oops! It doesn't look like there are any
                                    Transaction?
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="pagination_wrapper">
                                {/* <PaginationComp
                              length={AllTransactionHistorylist.length}
                              total={TotalData}
                            /> */}
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="patient_stat_wrapper">
                    <div className="lc_top">
                      <div className="lct_header_wrapper">
                        <p>My Earnings</p>
                      </div>
                      <div className="earning_amount">
                        <p>
                          {TotalCharges?.earning
                            ? formatCurrency(TotalCharges?.earning)
                            : "0"}
                        </p>
                        <p>{TotalCharges.bank_name}</p>
                        {/* <p>*Early withdrawal charges may apply.</p> */}
                        <DarkButton
                          classbtn={
                            "cx-btn-dark me-auto  btn-width-100 btn-height font-17"
                          }
                          onClick={getBankListDrop}
                          btn={"Withdraw"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="donut_chart_wrapper">
                    <div className="dc_header">
                      <p>Payment Analytics</p>
                      <div className="selection">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          // value={"1"}
                          onChange={(e) => {
                            const { value } = e.target;
                            const firstDayOfMonth = new Date(
                              todaysDate.getFullYear(),
                              todaysDate.getMonth(),
                              1
                            );
                            const lastDayOfMonth = new Date(
                              todaysDate.getFullYear(),
                              todaysDate.getMonth() + 1,
                              0
                            );
                            if (value == "1") {
                              fetchPaymentAnalytics(todaysDate, todaysDate);
                            } else if (value == "2") {
                              fetchPaymentAnalytics(
                                firstDayOfMonth,
                                lastDayOfMonth
                              );
                            }
                          }}
                        >
                          <option value="1">Day</option>
                          <option value="2">Month</option>
                        </select>
                      </div>
                    </div>
                    {arr.some((element) => element !== 0) && (
                      <Chart
                        options={donut.options}
                        series={arr}
                        s
                        type="donut"
                        // width="280"
                        height="250"
                      />
                    )}
                    <div className="Chart_labels">
                      <div className="cl_item">
                        <p>Withdrawl</p>
                        <p>
                          {paymentAnalytics.withdrawPercentage?.toFixed(2)}%
                        </p>
                      </div>
                      {/* <div className="cl_item">
                              <p>Subscriptions</p>
                              <p>46%</p>
                            </div> */}
                      <div className="cl_item">
                        <p>Received</p>
                        <p>
                          {paymentAnalytics.receivedPercentyage?.toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </InfiniteScroll>
        {/* Modal Of withdraw */}
        <div id="withdrawModal">
          {withdraw && (
            <Modal show={withdraw} centered onHide={() => setWithdraw(false)}>
              <Modal.Header closeButton className="tittleHead">
                <Modal.Title className="tittleModal">
                  Withdraw Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="formBox">
                  <div className="formBody">
                    <label htmlFor="" className="labelForm">
                      Bank Id
                    </label>
                    <select
                      onChange={(e) =>
                        setWithdrawDaat({
                          ...withdrawDaat,
                          bank_id: e.target.value,
                        })
                      }
                      className="form-select"
                    >
                      <option value="">Select Bank</option>
                      {dropdownKey && dropdownKey?.length > 0
                        ? dropdownKey.map((data, index) => {
                            return (
                              <option key={index} value={data?.bankId}>
                                {data?.bankName}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  <div className="formBody">
                    <label htmlFor="" className="labelForm">
                      Request Reason
                    </label>
                    <textarea
                      rows="2"
                      className="form-control"
                      onChange={(e) =>
                        setWithdrawDaat({
                          ...withdrawDaat,
                          request_reason: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                  <div className="formBody">
                    <label htmlFor="" className="labelForm">
                      Request Amount
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={(e) =>
                        setWithdrawDaat({
                          ...withdrawDaat,
                          request_amount: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="buttonBox">
                    <button className="submitBtn" onClick={postWithdrawAmnt}>
                      Submit
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default TransactionDetails;
