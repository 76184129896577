import React, { useContext, useState } from "react";
import logo from "../../assets/images/logo.svg";
import teleCall from "../../assets/images/icons/telephone.svg";
import insiderImg from "../../assets/images/icons/landigIcon.svg";
import lock from "../../assets/images/icons/lock.svg";
import otp_icon from "../../assets/images/icons/otp_icon.svg";
import ic_hide_eyes from "../../assets/images/ic_hide_eyes.svg";
import ic_show from "../../assets/images/ic_show_eyes.svg";
import reset_pass from "../../assets/images/reset_pass.svg";
import fb from "../../assets/images/fb.svg";
import apple from "../../assets/images/apple.svg";
import google from "../../assets/images/google.svg";
import { Link, useNavigate } from "react-router-dom";
import { DarkButton } from "../commonComponents/commonComp";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import { simplePostCall } from "../../api/ApiServices";
import { AppContext } from "../../context/AppContext";
import ApiConfig from "../../api/ApiConfig";

const ResetPass = () => {
  const navigate = useNavigate("");

  const { userCredentials, setLoading } = useContext(AppContext);

  const [new_password, setnew_password] = useState("");
  const [confirm_password, setconfirm_password] = useState("");

  const handalverifyOt = () => {
    setLoading(true)


    simplePostCall(ApiConfig.RESET_PASSWORD, JSON.stringify({
      email: userCredentials.email,
      new_password: new_password,
      confirm_password: confirm_password
    }))
      .then((res) => {

        if (res.success) {
          // setResponeOtp(res.user.otp)
          navigate("/")

          notifySuccess(res.message);
        } else {
          notifyError(res.message);

        }
      })
      .catch((err) => {

        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const [passwordTypeC, setPasswordTypeC] = useState("password");
  const togglePasswordC = () => {
    if (passwordTypeC === "password") {
      setPasswordTypeC("text");
      return;
    }
    setPasswordTypeC("password");
  };

  return (
    <>
      <div className="authMain">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <img src={logo} alt="" className="login_img" />
            <div className="signinForm">
              <h1 className="signInTxt">Reset your password ?</h1>
              <p className="signInDesc">
                If you forgot your password, Don't Worry ! <br /> You can
                request
                <Link className="link_common" to="/ForgotPass">
                  {" "}
                  New here !
                </Link>
              </p>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Create New Password
                </label>
                <img src={lock} className="mailImg" alt="" />{" "}
                {
                  passwordType === "password" ? (
                    <img src={ic_show} className="eyeImg" onClick={togglePassword} alt="" />
                  ) : (
                    <img src={ic_hide_eyes} className="eyeImg" onClick={togglePassword} alt="" />
                  )
                }
                <input
                  type={passwordType === "password" ? "password" : "text"}
                  className="form-control formInput"
                  placeholder="Enter your new password"
                  value={new_password}
                  onChange={(e) => {
                    let value = e.target.value;
                    let valueInput = value.replace(
                      /[^0-9 A-Za-z @]/gi,
                      ""
                    );
                    setnew_password(valueInput,);
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Re-enter Password
                </label>
                <img src={lock} className="mailImg" alt="" />
                {
                  passwordTypeC === "password" ? (
                    <img src={ic_show} className="eyeImg" onClick={togglePasswordC} alt="" />
                  ) : (
                    <img src={ic_hide_eyes} className="eyeImg" onClick={togglePasswordC} alt="" />
                  )
                }
                <input
                  type={passwordTypeC === "password" ? "password" : "text"}
                  className="form-control formInput"
                  placeholder="Re-enter your Password"
                  value={confirm_password}
                  onChange={(e) => {
                    let value = e.target.value;
                    let valueInput = value.replace(
                      /[^0-9 A-Za-z @]/gi,
                      ""
                    );
                    setconfirm_password(valueInput,);
                  }}
                />
              </div>
              <div className="login btn-width-100">
                <DarkButton
                  onClick={() => handalverifyOt()}
                  classbtn={"cx-btn-dark btn-width-100 btn-height font-17"}
                  btn={"Reset Password"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="landingImg">
              <div className="phone-no">
                <img src={teleCall} alt="" />
                <label htmlFor="">+91 9509932234</label>
              </div>
              <div className="img-container">
                <div className="big-img">
                  <img src={reset_pass} alt="" />
                </div>
                <div>
                  <h2>Create New Password !</h2>
                  <p>Get the unique password.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPass;
