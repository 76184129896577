import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import earn_icon from "../../assets/images/icons/earn_icon.svg";
import patient_icon from "../../assets/images/icons/patient_icon.svg";
import subs_icon from "../../assets/images/icons/subs_icon.svg";
import clock_icon from "../../assets/images/icons/clock_icon.svg";
import active_icon from "../../assets/images/icons/active_icon.svg";
import appoint_img1 from "../../assets/images/appoint_img1.svg";
import appoint_img2 from "../../assets/images/appoint_img2.svg";
import appoint_img3 from "../../assets/images/appoint_img3.svg";
import appoint_img4 from "../../assets/images/appoint_img4.svg";
import tc_patient_img1 from "../../assets/images/tc_patient_img1.svg";
import tc_patient_img2 from "../../assets/images/tc_patient_img2.svg";
import tc_patient_img3 from "../../assets/images/tc_patient_img3.svg";
import tc_patient_img4 from "../../assets/images/tc_patient_img4.svg";
import tc_patient_img5 from "../../assets/images/tc_patient_img5.svg";
import tc_patient_img6 from "../../assets/images/tc_patient_img6.svg";
import { motion } from "framer-motion";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { Nav, NavItem, Tab } from "react-bootstrap";
import { DonutChart } from "../commonComponents/commonComp";
import ApiConfig from "../../api/ApiConfig";
import { simpleGetCall, simplePostCall } from "../../api/ApiServices";
import Loader from "../../sharedComponent/Loader";
import PopupOverlay from "../Appointments/PopupOverlay";
import Notepad from "../../sharedComponent/Notepad";
import moment from "moment";

const Dashboard = () => {
  const todaysDate = new Date();
  const { setLoading, customerData, drocterCallOpen, loading } =
    useContext(AppContext);
  const { user_id, is_subscribed } = customerData;
  const [revenueBar, setRevenueBar] = useState({
    options: {
      chart: {
        type: "line",
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
      },
      yaxis: {
        min: 0,
        max: 800,
      },
      colors: ["#6AD2FF", "#4318FF"],
    },
    series: [
      {
        // name: "New Patient",
        name: " ",
        data: [80, 140, 30, 80, 50, 120, 200],
      },
      {
        // name: "Old Patient",
        name: "",
        data: [300, 340, 280, 460, 180, 520, 510],
      },
    ],
  });
  const [currentTab, setCurrentTab] = useState("1");

  const [Dashbordsubmenu, setDashbordsubmenu] = useState([]);
  const [DoshbordAppointments, setDoshbordAppointments] = useState([]);
  const [Dashbordtop_customer, setDashbordtop_customer] = useState([]);
  const [DashbordChart, setDashbordChart] = useState([]);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  useEffect(() => {
    setLoading(true);
    getClinetDetails(1);
    fetchPaymentAnalytics(todaysDate, todaysDate);
  }, []);

  const getClinetDetails = (type) => {
    simpleGetCall(
      ApiConfig.DASHBORD_API +
        customerData.doctor_id +
        "&" +
        "appointment_type=" +
        type
    )
      .then((res) => {
        if (res.success) {
          setDashbordsubmenu(res.submenu);
          setDoshbordAppointments(res.appointments);
          setDashbordtop_customer(res.top_customer);
          setDashbordChart(res.data);
        } else {
          setDashbordsubmenu([]);
          setDoshbordAppointments([]);
          setDashbordtop_customer([]);
          setDashbordChart([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [paymentAnalytics, setpaymentAnalytics] = useState({
    withdrawPercentage: 0,
    receivedPercentyage: 0,
  });

  const tempArr = Object.keys(paymentAnalytics).map((key) =>
    Number(paymentAnalytics[key])
  );
  const arr = tempArr && tempArr.map((ele) => parseFloat(ele.toFixed(2)));

  const [donut, setDonut] = useState({
    options: {
      dataLabels: {
        enabled: false,
      },
      colors: ["#01B529", "#FFB200"],
      // "#5500DF"
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
      labels: ["Withdrawl", "Received"],
    },
    series: arr,
    style: {
      // colors: ["#FFB200", "#01B529", "#5500DF"],
    },
  });

  const fetchPaymentAnalytics = async (fromData, toData) => {
    const userId = user_id && user_id?.split("_")[1];
    const payLoad = {
      user_id: userId,
      from_date: moment(fromData).format("YYYY-MM-DD"),
      to_date: moment(toData).format("YYYY-MM-DD"),
    };
    try {
      const res = await simplePostCall(
        `${ApiConfig.FETCH_PAYMENT_ANALYTICS}`,
        JSON.stringify(payLoad)
      );
      const { success, data } = res;
      if (success && data) {
        setpaymentAnalytics({
          withdrawPercentage: data?.WithdrawPercentage,
          receivedPercentyage: data?.ReceviedPercentage,
        });
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <motion.div
          variants={aninations}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.3 }}
          id="cx-main"
        >
          <div className="dashboard_wrapper">
            {/* {drocterCallOpen === true ? <PopupOverlay /> : <></>} */}
            <div className="row">
              <div className="col-lg-8 mb-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="dashboard_statistics_wrapper">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="stat_item">
                            <img src={earn_icon} />
                            <div className="stat_text stat_1">
                              <label>Earnings</label>
                              <p>{Dashbordsubmenu.Total_Amount}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="stat_item">
                            <img src={patient_icon} />
                            <div className="stat_text stat_2">
                              <label>Patients</label>
                              <p>{Dashbordsubmenu.patients}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="stat_item stat_item_status">
                            <div className="stat_item_inner">
                              <img src={subs_icon} />
                              <div className="stat_text stat_3">
                                <label>Subscription</label>
                                {is_subscribed && (
                                  <p>
                                    <img src={active_icon} />
                                    Active
                                  </p>
                                )}
                              </div>
                            </div>
                            {/* <div className="stat_note">
                              <p>*Your next renewal is on 15th June 2023</p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="line_chart_wrapper">
                      <div className="lc_top">
                        <div className="lct_header_wrapper">
                          <p>Patient Overview</p>
                          <div className="chart_labels">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="chart_label label_old">
                                  {/* <p>Old Patient</p> */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="chart_label label_new">
                                  <p>Appointment's</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="lc_bottom">
                        <div className="selection">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                          >
                            <option selected disabled>
                              Month
                            </option>
                            <option value="1">JAN</option>
                            <option value="2">FEB</option>
                            <option value="3">MAR</option>
                            <option value="3">APR</option>
                            <option value="3">MAY</option>
                            <option value="3">JUN</option>
                            <option value="3">JUL</option>
                            <option value="3">AUG</option>
                            <option value="3">SEP</option>
                            <option value="3">OCT</option>
                            <option value="3">NOV</option>
                            <option value="3">DEC</option>
                          </select>
                        </div>
                      </div>
                      <Chart
                        options={revenueBar.options}
                        series={DashbordChart}
                        type="line"
                        width="90%"
                        height="260"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="dashboard_appointments_wrapper">
                  <div className="lc_top">
                    <div className="lct_header_wrapper">
                      <p>Today's Appointments</p>
                      <div className="appoint_link">
                        <Link to="/AtClinic">View All</Link>
                      </div>
                    </div>
                  </div>
                  <div className="daw_tabs">
                    <Tab.Container defaultActiveKey="first">
                      <div className="row">
                        <div className="col-lg-12">
                          <Nav
                            variant="pills"
                            className=""
                            onSelect={(selectedKey) =>
                              setCurrentTab(`${selectedKey}`)
                            }
                          >
                            <Nav.Item>
                              <Nav.Link
                                eventKey="first"
                                onClick={() => {
                                  getClinetDetails(1);
                                }}
                              >
                                At Clinic
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="second"
                                onClick={() => {
                                  getClinetDetails(2);
                                }}
                              >
                                Video
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                        <div className="col-lg-12">
                          <Tab.Content>
                            <Tab.Pane eventKey="first">
                              <div className="dash_appoint_list">
                                {DoshbordAppointments &&
                                DoshbordAppointments.length > 0 ? (
                                  DoshbordAppointments.map(
                                    (itemlist, index) => {
                                      return (
                                        <div className="dash_list_item">
                                          <div className="dli_left">
                                            {itemlist.image_path === null ||
                                            itemlist.image_path === "" ? (
                                              <>
                                                <img
                                                  src={appoint_img1}
                                                  alt=""
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  height="30px"
                                                  width="30px"
                                                  src={itemlist.image_path}
                                                  alt=""
                                                />
                                              </>
                                            )}
                                            <div className="dli_text">
                                              <p className="dli_appointment_title">
                                                Check Up - {index + 1}
                                              </p>
                                              <p className="patient_name">
                                                {itemlist.first_name}
                                              </p>
                                              <div className="time_wrapper">
                                                <img src={clock_icon} alt="" />
                                                <p className="time">
                                                  {itemlist.time}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="dli_right">
                                            {/* <Link to="#">View Details</Link> */}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <p
                                    className="scheule_tody"
                                    style={{
                                      marginTop: "20px",
                                    }}
                                  >
                                    No Schedule Today
                                  </p>
                                )}
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div className="dash_appoint_list">
                                {DoshbordAppointments &&
                                DoshbordAppointments.length > 0 ? (
                                  DoshbordAppointments.map(
                                    (itemlist, index) => {
                                      return (
                                        <div className="dash_list_item">
                                          <div className="dli_left">
                                            {itemlist.image_path === null ||
                                            itemlist.image_path === "" ? (
                                              <>
                                                <img
                                                  src={appoint_img1}
                                                  alt=""
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  height="30px"
                                                  width="30px"
                                                  src={itemlist.image_path}
                                                  alt=""
                                                />
                                              </>
                                            )}

                                            <div className="dli_text">
                                              <p className="dli_appointment_title">
                                                Check Up - {index + 1}
                                              </p>
                                              <p className="patient_name">
                                                {itemlist.first_name}
                                              </p>
                                              <div className="time_wrapper">
                                                <img src={clock_icon} alt="" />
                                                <p className="time">
                                                  {itemlist.time}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div className="dli_right">
                                            <Link to="#">View Details</Link>
                                          </div> */}
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <p className="scheule_tody"
                                  style={{
                                    marginTop: "20px",
                                  }}
                                  >
                                    No Schedule Today
                                  </p>
                                )}
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </div>
                    </Tab.Container>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 mb-4">
                <div className="top_customer_table_wrapper">
                  <div className="lc_top">
                    <div className="lct_header_wrapper">
                      <p>Appointments</p>
                      <div className="appoint_link">
                        <Link to="/CustomerDetails">View All</Link>
                      </div>
                    </div>
                  </div>
                  {/* <div className="tct_header_wrapper">
                <h3 className="tct_header">Top Customer's</h3>
                <Link to="/CustomerDetails" className="tct_view">
                  View All
                </Link>
              </div> */}
                  <div className="dasboard-cust-tb-wrapper">
                    <table className="top_customer_table">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Owner</th>
                          <th>Patient</th>
                          <th>Pet Type</th>
                          <th>Case</th>
                          {/* <th>Vaterinarian</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {Dashbordtop_customer &&
                        Dashbordtop_customer.length > 0 ? (
                          Dashbordtop_customer.map((itemlist, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{itemlist.first_name}</td>
                                <td>
                                  <div className="tct_patient_wrapper">
                                    <img
                                      height="30px"
                                      width="30px"
                                      src={itemlist.image_path}
                                      alt=""
                                    />
                                    <p className="tct_patient_name">
                                      {itemlist.animal_name}
                                    </p>
                                  </div>
                                </td>
                                <td> {itemlist.type_of_animal}</td>
                                <td>
                                  <div className="case_type case1">
                                    {itemlist.disease}
                                  </div>
                                </td>
                                {/* <td>{itemlist.type_of_animal}</td> */}
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                    {Dashbordtop_customer.length === 0 ? (
                      <p
                        style={{
                          fontSize: "16px",
                          marginTop: "100px",
                        }}
                        className=" text-center justify-content-center align-items-center"
                      >
                        Oops! It doesn't look like there are any Appointment?
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="donut_chart_wrapper">
                  <div className="dc_header">
                    <p>Payment Analytics</p>
                    <div className="selection">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        // value={"1"}
                        onChange={(e) => {
                          const { value } = e.target;
                          const firstDayOfMonth = new Date(
                            todaysDate.getFullYear(),
                            todaysDate.getMonth(),
                            1
                          );
                          const lastDayOfMonth = new Date(
                            todaysDate.getFullYear(),
                            todaysDate.getMonth() + 1,
                            0
                          );
                          if (value == "1") {
                            fetchPaymentAnalytics(todaysDate, todaysDate);
                          } else if (value == "2") {
                            fetchPaymentAnalytics(
                              firstDayOfMonth,
                              lastDayOfMonth
                            );
                          }
                        }}
                      >
                        <option value="1">Day</option>
                        <option value="2">Month</option>
                      </select>
                    </div>
                  </div>
                  {arr.some((element) => element !== 0) && (
                    <Chart
                      options={donut.options}
                      series={arr}
                      type="donut"
                      // width="280"
                      height="250"
                    />
                  )}
                  <div className="Chart_labels">
                    <div className="cl_item">
                      <p>Withdrawl</p>
                      <p>{paymentAnalytics.withdrawPercentage?.toFixed(2)}%</p>
                    </div>
                    {/* <div className="cl_item">
                              <p>Subscriptions</p>
                              <p>46%</p>
                            </div> */}
                    <div className="cl_item">
                      <p>Received</p>
                      <p>{paymentAnalytics.receivedPercentyage?.toFixed(2)}%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default Dashboard;
