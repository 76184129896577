import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import earn_icon from "../../assets/images/icons/earn_icon.svg";
import patient_icon from "../../assets/images/icons/patient_icon.svg";
import total_appoint_icon from "../../assets/images/icons/total_appoint_icon.svg";
import complete_icon from "../../assets/images/icons/complete_icon.svg";
import cancelled_icon from "../../assets/images/icons/cancelled_icon.svg";
import revenue_icon from "../../assets/images/icons/revenue_icon.svg";
import export_icon from "../../assets/images/icons/export_icon.svg";
import ic_completed from "../../assets/images/ic_completed.svg";
import ic_rejected from "../../assets/images/ic_rejected.svg";
import cat from "../../assets/images/cat.png";
import Chart from "react-apexcharts";
import { motion } from "framer-motion";
import { DonutChart } from "../commonComponents/commonComp";
import { Link } from "react-router-dom";
import { simpleGetCall, simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import Loader from "../../sharedComponent/Loader";
import ic_clock_pending from "../../assets/images/ic_clock_pending.svg";
import ic_pending from "../../assets/images/ic_pending.svg";
import PopupOverlay from "../Appointments/PopupOverlay";
import moment from "moment";

const Reports = () => {
  const { setLoading, loading, customerData, drocterCallOpen } =
    useContext(AppContext);
  const { user_id } = customerData;
  const [Reportlist, setReportlist] = useState([]);
  const [monthlyPatientDetails, setMonthlyPatientDetails] = useState({});
  const [dailyPatientDetails, setDailyPatientDetails] = useState({});
  const [newpation, setnewpation] = useState("");
  const todaysDate = new Date();
  const [viewChange, setviewChange] = useState(true);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const [paymentAnalytics, setpaymentAnalytics] = useState({
    withdrawPercentage: 0,
    receivedPercentyage: 0,
  });

  const tempArr = Object.keys(paymentAnalytics).map((key) =>
    Number(paymentAnalytics[key])
  );

  const arr = tempArr && tempArr.map((ele) => parseFloat(ele.toFixed(2)));

  const [donut, setDonut] = useState({
    options: {
      dataLabels: {
        enabled: false,
      },
      colors: ["#01B529", "#FFB200"],
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
      labels: ["Withdrawl", "Received"],
    },
    series: arr,
    style: {
      // colors: ["#FFB200", "#01B529", "#5500DF"],
    },
  });

  useEffect(() => {
    getReportDetails();
    fetchPaymentAnalytics(todaysDate, todaysDate);
  }, []);

  const fetchPaymentAnalytics = async (fromDate, toDate) => {
    const userId = user_id?.split?.("_")[1];
    const payLoad = {
      user_id: userId,
      from_date: moment(fromDate).format("YYYY-MM-DD"),
      to_date: moment(toDate).format("YYYY-MM-DD"),
    };
    try {
      const res = await simplePostCall(
        `${ApiConfig.FETCH_PAYMENT_ANALYTICS}`,
        JSON.stringify(payLoad)
      );
      const { success, data } = res;
      if (success) {
        setpaymentAnalytics({
          withdrawPercentage: data?.WithdrawPercentage,
          receivedPercentyage: data?.ReceviedPercentage,
        });
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  const getReportDetails = () => {
    setLoading(true);

    simpleGetCall(ApiConfig.DAILY_REPORT + customerData.doctor_id)
      .then((res) => {
        if (res.success) {
          const {
            data: { patients },
          } = res;
          setReportlist(res?.data);
          setnewpation(res?.data.patients);
          patients && setDailyPatientDetails(patients);
        } else {
          setReportlist([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getMontheDetails = () => {
    simpleGetCall(ApiConfig.MONTHLEY_REPORT + customerData.doctor_id)
      .then((res) => {
        const {
          data: { patients },
        } = res;
        if (res.success) {
          setReportlist(res?.data);
          patients && setMonthlyPatientDetails(patients);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <motion.div
          variants={aninations}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.3 }}
          className="appointment-wrapper reports_wrapper"
          id="cx-main"
        >
          <div className="reportsTabs">
            {/* {drocterCallOpen === true ? <PopupOverlay /> : <></>} */}

            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
              <Nav
                variant="pills"
                id="newTabMai"
                className="tob_nav_pills d-flex"
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey="1"
                    onClick={() => {
                      getReportDetails("");
                      fetchPaymentAnalytics(todaysDate, todaysDate);
                    }}
                  >
                    Daily
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="2"
                    onClick={() => {
                      getMontheDetails("");
                      const firstDayOfMonth = new Date(
                        todaysDate.getFullYear(),
                        todaysDate.getMonth(),
                        1
                      );
                      const lastDayOfMonth = new Date(
                        todaysDate.getFullYear(),
                        todaysDate.getMonth() + 1,
                        0
                      );
                      fetchPaymentAnalytics(firstDayOfMonth, lastDayOfMonth);
                    }}
                  >
                    Monthly
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {/* <Link to="#" className="export_button">
                <img src={export_icon} alt="" />
              </Link> */}
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <div className="dashboard_wrapper" id="reports-wrapper">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="dashboard_statistics_wrapper">
                              <div className="row">
                                <div className="col-lg-3">
                                  <div className="stat_item">
                                    <img src={total_appoint_icon} />
                                    <div className="stat_text stat_4">
                                      <label>Total Appointments</label>
                                      {/* <p>{Reportlist.appointmentReport}</p> */}
                                      <p>
                                        {Reportlist.appointmentReport &&
                                        Reportlist.appointmentReport
                                          .TOTAL_APPOINTMENTS
                                          ? Reportlist.appointmentReport
                                              .TOTAL_APPOINTMENTS
                                          : 0}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="stat_item">
                                    <img src={complete_icon} />
                                    <div className="stat_text stat_1">
                                      <label>Completed</label>
                                      <p>
                                        {Reportlist.appointmentReport?.Completed
                                          ? Reportlist.appointmentReport
                                              ?.Completed
                                          : 0}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="stat_item">
                                    <img src={cancelled_icon} />
                                    <div className="stat_text stat_5">
                                      <label>Pending</label>
                                      <p>
                                        {Reportlist.appointmentReport?.Pending
                                          ? Reportlist.appointmentReport
                                              ?.Pending
                                          : 0}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="stat_item">
                                    <img src={revenue_icon} />
                                    <div className="stat_text stat_6">
                                      <label>Total Revenue</label>
                                      <p>
                                        ₹
                                        {Reportlist.appointmentReport
                                          ?.Total_Amount
                                          ? Reportlist.appointmentReport
                                              ?.Total_Amount
                                          : 0}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 mb-3">
                        <div className="dashboard_wrapper2">
                          <div className="table-wrapper table_wrapper_pagination dasboard-cust-tb-wrapper">
                            <table>
                              <thead>
                                <tr>
                                  <th>Sr. No</th>
                                  <th>Name</th>
                                  <th>Transaction ID</th>
                                  <th>Date</th>
                                  <th>Amount</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Reportlist?.clientList &&
                                Reportlist?.clientList.length > 0 ? (
                                  Reportlist?.clientList.map(
                                    (itemlist, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>
                                            <label
                                              htmlFor=""
                                              className="photo tdName"
                                            >
                                              <img
                                                src={itemlist.image_path}
                                                alt=""
                                              />
                                              {itemlist.first_name}
                                            </label>
                                          </td>
                                          <td>{itemlist.transaction_id}</td>
                                          <td>{itemlist.date}</td>
                                          <td>₹{itemlist.charges}</td>
                                          <>
                                            {itemlist.stringStatus ===
                                            "Accepted" ? (
                                              <>
                                                <td>
                                                  <img
                                                    src={ic_completed}
                                                    className="status-ic"
                                                    alt=""
                                                  />
                                                  <label
                                                    htmlFor=""
                                                    className="pending-txt"
                                                  >
                                                    {itemlist.stringStatus}
                                                  </label>
                                                </td>
                                              </>
                                            ) : itemlist.stringStatus ===
                                              "Rejected" ? (
                                              <>
                                                <td>
                                                  <img
                                                    src={ic_rejected}
                                                    className="status-ic"
                                                    alt=""
                                                  />
                                                  <label
                                                    htmlFor=""
                                                    className="pending-txt"
                                                  >
                                                    {itemlist.stringStatus}
                                                  </label>
                                                </td>
                                              </>
                                            ) : itemlist.stringStatus ===
                                              "Pending" ? (
                                              <td>
                                                <img
                                                  src={ic_pending}
                                                  className="status-ic"
                                                  alt=""
                                                />
                                                <label
                                                  htmlFor=""
                                                  className="pending-txt"
                                                >
                                                  {itemlist.stringStatus}
                                                </label>
                                              </td>
                                            ) : itemlist.stringStatus ===
                                              "Completed" ? (
                                              <td>
                                                <img
                                                  src={ic_completed}
                                                  className="status-ic"
                                                  alt=""
                                                />
                                                <label
                                                  htmlFor=""
                                                  className="pending-txt"
                                                >
                                                  {itemlist.stringStatus}
                                                </label>
                                              </td>
                                            ) : itemlist.stringStatus ===
                                              "In progress" ? (
                                              <>
                                                <td>
                                                  <img
                                                    src={ic_clock_pending}
                                                    className="status-ic"
                                                    alt=""
                                                  />
                                                  <label
                                                    htmlFor=""
                                                    className="pending-txt"
                                                  >
                                                    {itemlist.stringStatus}
                                                  </label>
                                                </td>
                                              </>
                                            ) : (
                                              <td>
                                                <img
                                                  src={ic_clock_pending}
                                                  className="status-ic"
                                                  alt=""
                                                />
                                                <label
                                                  htmlFor=""
                                                  className="pending-txt"
                                                >
                                                  {itemlist.stringStatus}
                                                </label>
                                              </td>
                                            )}
                                          </>
                                          {/* <td>
                                            <img
                                              src={ic_completed}
                                              className="status-ic"
                                              alt=""
                                            />
                                            {itemlist.stringStatus}
                                          </td> */}
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <p className="scheule_tody"></p>
                                )}
                              </tbody>
                            </table>
                            {Reportlist && Reportlist.length === 0 ? (
                              <p
                                className="scheule_tody"
                                style={{
                                  marginTop: "80px",
                                }}
                              >
                                No Reports Today
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          {/* <div className="pagination_wrapper">
                        <p>Showing 1-10 of 100</p>
                      </div> */}
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="patient_stat_wrapper">
                          <div className="lc_top">
                            <div className="lct_header_wrapper">
                              <p>Patients</p>
                            </div>
                            <div className="pt_stat_item pts_1">
                              <div className="row">
                                <div
                                //  className="col-md-4 col-sm-4"
                                >
                                  <div
                                    className="left"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <label htmlFor="">New Joined :</label>
                                    <p style={{ marginLeft: "10px" }}>
                                      {dailyPatientDetails?.new_patient_count
                                        ? dailyPatientDetails?.new_patient_count
                                        : "0"}
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="col-md-8 col-sm-8">
                                  <div className="right">
                                    <div class="progress">
                                      <div
                                        class="progress-bar"
                                        role="progressbar"
                                        style={{ width: "100%" }}
                                        aria-valuenow={
                                          dailyPatientDetails?.new_patient_count
                                            ? dailyPatientDetails?.new_patient_count
                                            : ""
                                        }
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      >
                                        {dailyPatientDetails?.new_patient_count} 
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <div className="pt_stat_item pts_2">
                              <div className="row">
                                <div
                                // className="col-md-4 col-sm-4"
                                >
                                  <div
                                    className="left"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      // justifyContent : "space-between"
                                    }}
                                  >
                                    <label htmlFor="">Old Patient : </label>
                                    <p style={{ marginLeft: "10px" }}>
                                      {dailyPatientDetails?.new_patient_count
                                        ? dailyPatientDetails?.new_patient_count
                                        : "0"}
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="col-md-8 col-sm-8">
                                  <div className="right">
                                    <div class="progress">
                                      <div
                                        class="progress-bar"
                                        role="progressbar"
                                        style={{ width: "100%" }}
                                        aria-valuenow={
                                          dailyPatientDetails?.new_patient_count
                                            ? dailyPatientDetails?.new_patient_count
                                            : ""
                                        }
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      >
                                        {dailyPatientDetails?.new_patient_count}
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="donut_chart_wrapper">
                          <div className="dc_header">
                            <p>Payment Analytics</p>
                          </div>
                          {/* <DonutChart /> */}
                          <div>
                            {arr.some((element) => element !== 0) && (
                              <Chart
                                options={donut.options}
                                series={arr}
                                s
                                type="donut"
                                // width="280"
                                height="250"
                              />
                            )}
                          </div>
                          <div className="Chart_labels">
                            <div className="cl_item">
                              <p>Withdrawl</p>
                              <p>
                                {paymentAnalytics.withdrawPercentage
                                  ? paymentAnalytics.withdrawPercentage?.toFixed(
                                      2
                                    ) + " " + "%"
                                  : ""}
                                
                              </p>
                            </div>
                            <div className="cl_item">
                              <p>Received</p>
                              <p>
                                {paymentAnalytics?.receivedPercentyage
                                  ? paymentAnalytics?.receivedPercentyage?.toFixed(
                                      2
                                    ) + " " + "%"
                                  : ""}
                              </p>
                            </div>
                            {/* <div className="cl_item">
                                <p>Subscriptions</p>
                                <p>50%</p>
                              </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <div className="dashboard_wrapper">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="dashboard_statistics_wrapper">
                              <div className="row">
                                <div className="col-lg-3">
                                  <div className="stat_item">
                                    <img src={total_appoint_icon} />
                                    <div className="stat_text stat_4">
                                      <label>Total Appointments</label>
                                      <p>
                                        {
                                          Reportlist.appointmentReport
                                            ?.TOTAL_APPOINTMENTS
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="stat_item">
                                    <img src={complete_icon} />
                                    <div className="stat_text stat_1">
                                      <label>Completed</label>
                                      <p>
                                        {Reportlist.appointmentReport?.Completed
                                          ? Reportlist.appointmentReport
                                              ?.Completed
                                          : 0}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="stat_item">
                                    <img src={cancelled_icon} />
                                    <div className="stat_text stat_5">
                                      <label>Pending</label>
                                      <p>
                                        {Reportlist.appointmentReport?.Pending
                                          ? Reportlist.appointmentReport
                                              ?.Pending
                                          : 0}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="stat_item">
                                    <img src={revenue_icon} />
                                    <div className="stat_text stat_6">
                                      <label>Total Revenue</label>
                                      <p>
                                        ₹
                                        {Reportlist.appointmentReport
                                          ?.Total_Amount
                                          ? Reportlist.appointmentReport
                                              ?.Total_Amount
                                          : 0}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 mb-3">
                        <div className="dashboard_wrapper2">
                          <div className="table-wrapper table_wrapper_pagination dasboard-cust-tb-wrapper">
                            <table>
                              <thead>
                                <tr>
                                  <th>Sr. No</th>
                                  <th>Name</th>
                                  <th>Transaction ID</th>
                                  <th>Date</th>
                                  <th>Amount</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Reportlist.clientList &&
                                Reportlist.clientList.length > 0 ? (
                                  Reportlist.clientList.map(
                                    (itemlist, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>
                                            <label htmlFor="" className="photo">
                                              <img
                                                src={itemlist.image_path}
                                                alt=""
                                              />
                                              {itemlist.first_name}
                                            </label>
                                          </td>
                                          <td>{itemlist.transaction_id}</td>
                                          <td>{itemlist.date}</td>
                                          <td>₹{itemlist.charges}</td>
                                          <>
                                            {itemlist.stringStatus ===
                                            "Accepted" ? (
                                              <>
                                                <td>
                                                  <img
                                                    src={ic_completed}
                                                    className="status-ic"
                                                    alt=""
                                                  />
                                                  <label
                                                    htmlFor=""
                                                    className="pending-txt"
                                                  >
                                                    {itemlist.stringStatus}
                                                  </label>
                                                </td>
                                              </>
                                            ) : itemlist.stringStatus ===
                                              "Rejected" ? (
                                              <>
                                                <td>
                                                  <img
                                                    src={ic_rejected}
                                                    className="status-ic"
                                                    alt=""
                                                  />
                                                  <label
                                                    htmlFor=""
                                                    className="pending-txt"
                                                  >
                                                    {itemlist.stringStatus}
                                                  </label>
                                                </td>
                                              </>
                                            ) : itemlist.stringStatus ===
                                              "Pending" ? (
                                              <td>
                                                <img
                                                  src={ic_pending}
                                                  className="status-ic"
                                                  alt=""
                                                />
                                                <label
                                                  htmlFor=""
                                                  className="pending-txt"
                                                >
                                                  {itemlist.stringStatus}
                                                </label>
                                              </td>
                                            ) : itemlist.stringStatus ===
                                              "Completed" ? (
                                              <td>
                                                <img
                                                  src={ic_completed}
                                                  className="status-ic"
                                                  alt=""
                                                />
                                                <label
                                                  htmlFor=""
                                                  className="pending-txt"
                                                >
                                                  {itemlist.stringStatus}
                                                </label>
                                              </td>
                                            ) : itemlist.stringStatus ===
                                              "In progress" ? (
                                              <>
                                                <td>
                                                  <img
                                                    src={ic_clock_pending}
                                                    className="status-ic"
                                                    alt=""
                                                  />
                                                  <label
                                                    htmlFor=""
                                                    className="pending-txt"
                                                  >
                                                    {itemlist.stringStatus}
                                                  </label>
                                                </td>
                                              </>
                                            ) : (
                                              <td>
                                                <img
                                                  src={ic_clock_pending}
                                                  className="status-ic"
                                                  alt=""
                                                />
                                                <label
                                                  htmlFor=""
                                                  className="pending-txt"
                                                >
                                                  {itemlist.stringStatus}
                                                </label>
                                              </td>
                                            )}
                                          </>

                                          {/* <td>
                                            <img
                                              src={ic_completed}
                                              className="status-ic"
                                              alt=""
                                            />
                                            {itemlist.stringStatus}
                                          </td> */}
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <p className="scheule_tody"></p>
                                )}
                              </tbody>
                            </table>

                            {Reportlist && Reportlist.length === 0 ? (
                              <p className="scheule_tody">No Reports Monthly</p>
                            ) : (
                              ""
                            )}
                          </div>
                          {/* <div className="pagination_wrapper">
                            <p>Showing 1-10 of 100</p>
                          </div> */}
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="patient_stat_wrapper">
                          <div className="lc_top">
                            <div className="lct_header_wrapper">
                              <p>Patients</p>
                            </div>
                            <div className="pt_stat_item pts_1">
                              <div className="row">
                                <div
                                // className="col-md-4 col-sm-4"
                                >
                                  <div
                                    className="left"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <label htmlFor="">New Joined : </label>
                                    <p style={{ marginLeft: "10px" }}>
                                      {monthlyPatientDetails?.new_patient_count
                                        ? monthlyPatientDetails?.new_patient_count
                                        : "0"}
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="col-md-8 col-sm-8">
                                  <div className="right">
                                    <div class="progress">
                                      <div
                                        style={{  
                                          marginTop : "10px",
                                          color : "#042486",
                                          fontWeight : "700" 
                                        }}
                                        // class="progress-bar"
                                        role="progressbar"
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="50"
                                      >
                                        {
                                          monthlyPatientDetails?.new_patient_count
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <div className="pt_stat_item pts_2">
                              <div className="row">
                                <div
                                // className="col-md-4 col-sm-4"
                                >
                                  <div
                                    className="left"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <label htmlFor="">Old Patient :</label>
                                    <p style={{ marginLeft: "10px" }}>
                                      {monthlyPatientDetails?.old_patient_count
                                        ? monthlyPatientDetails?.old_patient_count
                                        : "0"}
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="col-md-8 col-sm-8">
                                  <div className="right">
                                    <div class="progress">
                                      <div
                                        style={{ marginTop: "10px" }}
                                        // class="progress-bar"
                                        role="progressbar"
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      >
                                        {
                                          monthlyPatientDetails?.old_patient_count
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        {arr.some((element) => element !== 0) && (
                          <div className="donut_chart_wrapper">
                            <div className="dc_header">
                              <p>Payment Analytics</p>
                            </div>
                            {/* <DonutChart /> */}
                            <div>
                              <Chart
                                options={donut.options}
                                series={arr}
                                s
                                type="donut"
                                // width="280"
                                height="250"
                              />
                            </div>
                            <div className="Chart_labels">
                              <div className="cl_item">
                                <p>Withdrawl</p>
                                <p>
                                  {paymentAnalytics?.withdrawPercentage
                                    ? paymentAnalytics?.withdrawPercentage?.toFixed(
                                        2
                                      )
                                    : ""}
                                  %
                                </p>
                              </div>
                              <div className="cl_item">
                                <p>Received</p>
                                <p>
                                  {paymentAnalytics?.receivedPercentyage
                                    ? paymentAnalytics?.receivedPercentyage?.toFixed(
                                        2
                                      )
                                    : ""}
                                  %
                                </p>
                              </div>
                              {/* <div className="cl_item">
                                <p>Subscriptions</p>
                                <p>50%</p>
                              </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default Reports;
