import React, { useContext, useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { AppContext } from '../../context/AppContext'
// import callerImg from '../../assets/images/dummyprofile.jpeg'
import { useNavigate } from "react-router-dom";
import ApiConfig from '../../api/ApiConfig';

// import useSound from 'use-sound';
// import fanfareSfx from '../../assets/sound/mixkit-waiting-ringtone-1354.wav';
const PopupOverlay = () => {
  const {senderNameImg,senderName,handleMouseLeave, modalOpen, setModalOpen,socket,roomId,callId,setdrocterCallOpen,setEventKey,rejectCall,senderId,receiverId ,answerCall, setCallAccepted } = useContext(AppContext)
  const navigate = useNavigate();


  // const [play, { stop }] = useSound(fanfareSfx);

  // const rejectCall = () => {
  //   console.log('rejectCall call to sender...',senderId,receiverId)
  //   stop()
  //   const requestBody = {
  //     senderId: receiverId,
  //     receiverId: senderId,
  //     call_id: callId,
  //     room_id: roomId,
  //   }

  //   socket.emit('rejectcall', requestBody)
   
  //   setModalOpen(false)
  //   setdrocterCallOpen(false)
  // }


  // const answerCall = () => {
  //   console.log("answering call to sender...");
  //   stop()
  //   const requestBody = {
  //     senderId: receiverId,
  //     receiverId: senderId,
  //     call_id: callId,
  //     room_id: roomId,
  //   };

  //   socket.emit("answercall", requestBody);
  //   navigate("/ProfileSetting");
  //   setdrocterCallOpen(false)
  //   setModalOpen(false)
  //   setEventKey(33)
  //   setCallAccepted(true)
  //   stop()

  //   // setVideoChatting(false)



  // };

  useEffect(() => {
    // Check and request permissions for microphone and camera
    socket && socket.on("callrejected", (data) => {
      console.log("Received user calling callrejected callrejected:", data);

      // Handle the response from the "usercalling" event here
    });

    return () => {
      socket && socket.off("callrejected");
    };
  }, [socket])

  return (
    <div id='popupPrime'>
      {modalOpen ? (
        <Dialog
          header='Incoming Call'
          visible={modalOpen}
          position={'top-right'}
          // style={{ width: '15vw' }}
          onHide={() => setModalOpen(false)}
          // footer={footerContent}
          draggable={false}
          resizable={false}
        >
          <div className='insideCallSec'>
            <img src={senderNameImg} alt='' />
            <div className='detailsSec'>
              <p style={{
                marginTop:"30px",
                color:'black',
                fontSize:"15px",

              }}
              >
                Name : <span>{senderName ? senderName : "Dr name"}</span>
              </p>
              <span style={{
                marginTop:"10px",
                marginLeft:"25px",
                fontSize:"15px",
                color:'black'
              }}>
              incoming call
                {/* Date : <span>09/10/2023</span> */}

              </span>
            </div>
            <div className='buttonsFlex'>
              <div className='acceptBox'  onClick={() => answerCall()}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='22'
                  height='22'
                  fill='#fff'
                  class='bi bi-telephone-fill'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z'
                  />
                </svg>
              </div>
              <div className='rejectBox' onClick={() => rejectCall()} onMouseLeave={handleMouseLeave} >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='22'
                  height='22'
                  fill='#fff'
                  class='bi bi-telephone-fill'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z'
                  />
                </svg>
              </div>
            </div>
          </div>
        </Dialog>
      ) : null}
    </div>
  )
}

export default PopupOverlay
