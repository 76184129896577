import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { AppContext } from "../../context/AppContext";

export const DarkButton = ({ btn, type, classbtn, onClick }) => {
  const { loading } = useContext(AppContext);

  return (
    <>
      <button
        onClick={onClick}
        type={type}
        className={classbtn}
        disabled={loading ? true : false}
      >
        {btn}

        {loading && (
          <ColorRing
            visible={true}
            height="30"
            width="30"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
          />
        )}
      </button>
    </>
  );
};

export const DonutChart = () => {
  const [donut, setDonut] = useState({
    options: {
      dataLabels: {
        enabled: false,
      },
      colors: ["#FFB200", "#01B529", "#5500DF"],
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
    },
    series: [40, 40, 80],
    style: {
      // colors: ["#FFB200", "#01B529", "#5500DF"],
    },
  });
  return (
    <>
      <div>
        <Chart
          options={donut.options}
          series={donut.series}
          s
          type="donut"
          // width="280"
          height="250"
        />
      </div>
    </>
  );
};
