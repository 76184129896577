import React, { useCallback, useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import CalenderComp from "./CalenderComp";
import ic_hospital from "../../assets/images/ic_hospital.svg";
import dog from "../../assets/images/dog.svg";
import ic_clock from "../../assets/images/ic_clock.svg";
import ic_video from "../../assets/images/ic_video.svg";
import cat from "../../assets/images/cat.png";
import cat_1 from "../../assets/images/cat_1.png";
import cat_2 from "../../assets/images/cat_2.png";
import Modal from "react-bootstrap/Modal";
import dog_1 from "../../assets/images/dog_1.png";
import dog_2 from "../../assets/images/dog_2.png";
import dog_3 from "../../assets/images/dog_3.png";
import CreatableSelect from "react-select/creatable";
import ic_filter from "../../assets/images/ic_filter.svg";
import ic_notepad from "../../assets/images/ic_notepad.svg";
import ic_recipt from "../../assets/images/ic_recipt.svg";
import ic_clock_pending from "../../assets/images/ic_clock_pending.svg";
import ic_completed from "../../assets/images/ic_completed.svg";
import ic_rejected from "../../assets/images/ic_rejected.svg";
import ic_pending from "../../assets/images/ic_pending.svg";
import ic_play_video from "../../assets/images/ic_play_video.svg";
import ic_back_arrow from "../../assets/images/ic_back_arrow.svg";
import PaginationComp from "../../sharedComponent/PaginationComp";
import { AppContext } from "../../context/AppContext";
import PrescriptionComp from "../../sharedComponent/PrescriptionComp";
import ApiConfig from "../../api/ApiConfig";
import { simpleGetCall, simplePostCall } from "../../api/ApiServices";
import InfiniteScroll from "react-infinite-scroll-component";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import { useNavigate } from "react-router-dom";
import chat_bg from "../../assets/images/chat_bg.png";
import video_bg from "../../assets/images/video_bg.png";
import Loader from "../../sharedComponent/Loader";
import { formatCurrency } from "../../common/commonFunctions";

const Video = () => {
  const {
    setLoading,
    setPrescription,
    loading,
    customerData,
    setcustomerId,
    setdoctorId,
    doctorAppoinmentID,
    setdoctorAppoinmentID,
    doctorVideoPresptionID,
    setdoctorVideoPresptionID,
    setdoctorAnimalID,
    socket
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [viewCalender, setviewCalender] = useState(false);
  const [viewList, setviewList] = useState(true);
  const [reqList, setreqList] = useState(true);
  const [completedList, setCompletedList] = useState(true);

  const [viewAppointment, setviewAppointment] = useState(false);
  const [page, setPage] = useState(1);
  const [FilterName, setFilterName] = useState("");
  const [Filter2Name, setFilter2Name] = useState("");
  const [Filter3Name, setFilter3Name] = useState("");
  const [TodayDate, setTodayDate] = useState("");
  const [ClintDetailsList, setClintDetailsList] = useState({});
  const [ClintPhoto, setClintPhoto] = useState([]);
  const [ClintPrescription, setClintPrescription] = useState([]);
  const [AppointmentsCalender, setAppointmentsCalender] = useState([]);
  const [AppointmentsList, setAppointmentsList] = useState([]);
  const [AppointmentsRequest, setAppointmentsRequest] = useState([]);
  const [AppointmentsCompleted, setAppointmentsCompleted] = useState([]);
  const [InviceTotal, setInviceTotal] = useState("0");
  const [ClintVisitlist, setClintVisitlist] = useState([]);
  const [ClintVisitAlllist, setClintVisitAlllist] = useState([]);

  const [ClintInvicelist, setClintInvicelist] = useState([]);
  const [TotalData, setTotalData] = useState(0);
  const [currentTab, setCurrentTab] = useState("1");
  const [VisiteTotal, setVisiteTotal] = useState("0");
  const [Appointment, setAppointment] = useState("1");

  const [startdigno, setStartdigno] = useState(false);
  const [StartVideoCall, setStartVideoCall] = useState(false);
  const [option, setoption] = useState([
    { value: "vanilla", label: "Vanilla", rating: "safe" },
    { value: "chocolate", label: "Chocolate", rating: "good" },
    { value: "strawberry", label: "Strawberry", rating: "wild" },
    { value: "salted-caramel", label: "Salted Caramel", rating: "crazy" },
  ]);
  const allTab = () => {
    setreqList(false);
    setviewCalender(true);
    setviewList(false);
    setviewAppointment(false);
    setCompletedList(false);
  };

  const SelectStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "10px",
      border: "1px solid #A3AED0",
    }),
  };

  useEffect(() => {
    socket &&
      socket.on("new_appointment", () => {
        getClinetList(page, "refresh");
        getClinetRequest(page, "refresh")
      });
  }, [socket]);

  useEffect(() => {
    setLoading(true);
    geAllList("");
  }, []);

  function geAllList(status) {
    let newRequestBody = JSON.stringify({
      status: status,
      doctor_id: customerData.doctor_id,
    });
    simplePostCall(ApiConfig.APPOINTMENTS_VIDEO, newRequestBody)
      .then((data) => {
        let Attendance =
          data.data &&
          data.data.map((item) => {
            return {
              // start: new Date(item.start?.replace("T", " ")),
              // end: new Date(item.end?.replace("T", " ")),
              start: item.start,
              end: item.end,
              title: item.title,
            };
          });
        setAppointmentsCalender(Attendance);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const getClinetDetailsByeVisit = (id) => {
    simpleGetCall(ApiConfig.CLIINET_DETAILS_BYE_VISITE_ID + id)
      .then((res) => {
        setClintDetailsList(res.data);
        setClintPhoto(res.data.appointment_images);
        setClintPrescription(res.data.prescription);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAppoinmentVisitDetails();
  }, []);

  const getAppoinmentVisitDetails = (id) => {
    simpleGetCall(
      ApiConfig.APPOINTMENTS_TODAY_LIST_VIDEO + customerData.doctor_id
    )
      .then((res) => {
        setClintVisitlist(res.data);
        setTodayDate(res.todayDate);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getClinetCompleted(page);
  }, [Filter3Name]);

  useEffect(() => {
    getClinetRequest(page);
  }, [Filter2Name]);

  useEffect(() => {
    getClinetList(page);
  }, [FilterName]);

  function getClinetRequest(currentPage) {
    let newRequestBody = JSON.stringify({
      status: 1,
      doctor_id: customerData.doctor_id,
      page: currentPage,
      limit: "5",
      keyword: Filter2Name,
    });
    simplePostCall(ApiConfig.APPOINTMENTS_VIDEO_LIST, newRequestBody)
      .then((data) => {
        if (data.success) {
          setLoading(false);
          let total = data.total ? data.total : 0;
          setTotalData(total);
          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              type_of_animal: eachteam.type_of_animal || "",
              appointment_desc: eachteam.appointment_desc || "",
            };
          });

          if (currentPage === 1) {
            setAppointmentsRequest(data.data);
            setLoading(false);
          } else {
            setAppointmentsRequest([...AppointmentsRequest, ...LearningData]);
            setLoading(false);
          }
        } else {
          setAppointmentsRequest([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getClinetCompleted(currentPage) {
    let newRequestBody = JSON.stringify({
      status: 5,
      doctor_id: customerData.doctor_id,
      page: currentPage,
      limit: "5",
      keyword: Filter3Name,
    });
    simplePostCall(ApiConfig.APPOINTMENTS_VIDEO_LIST, newRequestBody)
      .then((data) => {
        if (data.success) {
          setLoading(false);
          let total = data.total ? data.total : 0;
          setTotalData(total);
          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              type_of_animal: eachteam.type_of_animal || "",
              appointment_desc: eachteam.appointment_desc || "",
            };
          });

          if (currentPage === 1) {
            setAppointmentsCompleted(data.data);
            setLoading(false);
          } else {
            setAppointmentsCompleted([
              ...AppointmentsCompleted,
              ...LearningData,
            ]);
            setLoading(false);
          }
        } else {
          setAppointmentsCompleted([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getClinetList(currentPage) {
    let newRequestBody = JSON.stringify({
      doctor_id: customerData.doctor_id,
      page: currentPage,
      limit: "5",
      keyword: FilterName,
    });
    simplePostCall(ApiConfig.APPOINTMENTS_VIDEO_LIST, newRequestBody)
      .then((data) => {
        if (data.success) {
          let total = data.total ? data.total : 0;
          setTotalData(total);
          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              type_of_animal: eachteam.type_of_animal || "",
              appointment_desc: eachteam.appointment_desc || "",
            };
          });

          if (currentPage === 1) {
            setAppointmentsList(data.data);
            setLoading(false);
          } else {
            setAppointmentsList([...AppointmentsList, ...LearningData]);
            setLoading(false);
          }
        } else {
          setAppointmentsList([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onPageLoad = () => {
    setPage(page + 1);

    currentTab === 1
      ? getClinetList(page + 1)
      : currentTab === 2
      ? getClinetRequest(page + 1)
      : getClinetCompleted(page + 1);
  };

  /// Details All
  const getClinetDetails = (id, doctor_appointment_id) => {
    setLoading(true);
    setdoctorAppoinmentID(doctor_appointment_id);
    setdoctorVideoPresptionID(id);
    simpleGetCall(
      ApiConfig.CLIINET_DETAILS_BY_ID +
        id +
        "&" +
        "doctor_appointment_id=" +
        doctor_appointment_id
    )
      .then((res) => {
        setClintDetailsList(res.data);
        setClintPhoto(res.data.appointment_images);
        setClintPrescription(res.data.prescription);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getClinetVisitDetails = (id) => {
    // setLoading(true);
    setdoctorAnimalID(id);

    simpleGetCall(
      ApiConfig.CLINET_VIST_BY_ID + id + "&doctor_id=" + customerData.doctor_id
    )
      .then((res) => {
        // var total = res.length ? res.length : 0;

        setVisiteTotal(res.total);
        setClintVisitAlllist(res);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getClinetInviceDetails = (id) => {
    // setLoading(true);
    setdoctorAnimalID(id);
    simpleGetCall(
      ApiConfig.CLIINET_INVICE_ID + id + "&doctor_id=" + customerData.doctor_id
    )
      .then((res) => {
        var total = res.data.length ? res.data.length : 0;
        setInviceTotal(total);

        setClintInvicelist(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function putStatusChanges(id, status) {
    let newRequestBody = JSON.stringify({
      appointment_id: id,
      status: status,
    });
    simplePostCall(ApiConfig.APPOINTMENTS_STATUS_ACCEPTED, newRequestBody)
      .then((data) => {
        if (data.success) {
          notifySuccess(data.message);
          getClinetRequest(1);
        } else {
          notifyError(data.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const emailInput = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const handalVideo = () => {
    function getDateTime() {
      const today = new Date();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const year = today.getFullYear();
      const date = String(today.getDate()).padStart(2, "0");
      // const hours = String(today.getHours()).padStart(2, '0');
      // const minutes = String(today.getMinutes()).padStart(2, '0');
      // const seconds = String(today.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${date}`;
    }
    let currentDate = getDateTime();
    let currentApiDate = ClintDetailsList.date;

    setStartdigno(true);
    sessionStorage.setItem("doctorAppoinmentID", doctorAppoinmentID);
    sessionStorage.setItem("doctorVideoPresptionID", doctorVideoPresptionID);
    navigate("/ChattingVideoComp");
  };

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <>
          <InfiniteScroll
            dataLength={
              currentTab === 1
                ? AppointmentsList?.length
                : currentTab === 2
                ? AppointmentsRequest?.length
                : AppointmentsCompleted?.length
            }
            next={onPageLoad}
            // hasMore={true}
            hasMore={
              currentTab === 1
                ? AppointmentsList?.length !== TotalData
                : currentTab === 2
                ? AppointmentsRequest?.length !== TotalData
                : AppointmentsCompleted?.length !== TotalData
            }
            loader={
              viewCalender === true || viewAppointment === true ? (
                ""
              ) : (
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                ></h4>
              )
            }
          >
            <div className="appointment-wrapper" id="cx-main">
              <div className="reportsTabs">
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                  <Nav
                    variant="pills"
                    id="newTabMai"
                    className="tob_nav_pills"
                    onSelect={(selectedKey) => setCurrentTab(`${selectedKey}`)}
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="1"
                        onClick={() => {
                          setPage(1);
                          setFilterName("");
                          setviewCalender(false)
                          setviewList(true)
                          geAllList("");
                          getClinetList(1);
                          // allTab();
                        }}
                      >
                        All Appointments
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="2"
                        onClick={() => {
                          setPage(1);
                          setFilterName("");
                          setviewCalender(false)
                          setreqList(true)
                          geAllList("1");
                          getClinetRequest(1);
                          // allTab();
                        }}
                      >
                        Requests
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="3"
                        onClick={() => {
                          setPage(1);
                          setFilterName("");
                          setviewCalender(false)
                          setCompletedList(true)
                          geAllList("5");
                          getClinetCompleted(1);
                          // allTab();
                        }}
                      >
                        Completed
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  {
                  viewCalender 
                  // false
                  && (
                    <div className="dark-btns-wrapper search-filter">
                      <button
                        className="dark-btns"
                        onClick={() => {
                          // getClinetList()
                          setviewList(true);
                          setreqList(true);
                          setCompletedList(true);
                          setviewCalender(false);
                        }}
                      >
                        List View
                      </button>
                    </div>
                  )}
                  {viewList && (
                    <div className="search-filter-wrapper">
                      <div className="search-filter-left">
                        {currentTab == 1 ? (
                          <>
                            <input
                              type="text"
                              className="form-control serach-input"
                              placeholder="Search by Name"
                              autoFocus
                              ref={emailInput}
                              value={FilterName}
                              onChange={(e) => {
                                setFilterName(e.target.value);
                                setPage(1);
                              }}
                            />
                          </>
                        ) : currentTab == 2 ? (
                          <>
                            <input
                              type="text"
                              className="form-control serach-input"
                              placeholder="Search by Name"
                              autoFocus
                              ref={emailInput}
                              value={Filter2Name}
                              onChange={(e) => {
                                setFilter2Name(e.target.value);
                                setPage(1);
                              }}
                            />
                          </>
                        ) : (
                          <input
                            type="text"
                            className="form-control serach-input"
                            placeholder="Search by Name"
                            autoFocus
                            ref={emailInput}
                            value={Filter3Name}
                            onChange={(e) => {
                              setFilter3Name(e.target.value);
                              setPage(1);
                            }}
                          />
                        )}
                        {/* <button className="fltr-btn">
                      <img src={ic_filter} alt="" />
                    </button> */}
                      </div>
                      <div className="search-filter-right">
                        <div className="dark-btns-wrapper search-filter">
                          <button
                            className="dark-btns"
                            onClick={() => {
                              setviewCalender(true);
                              setviewList(false);
                              setreqList(false);
                            }}
                          >
                            Calendar View
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {viewAppointment && (
                    <div className="app-result-arrow-top">
                      <img
                        src={ic_back_arrow}
                        alt=""
                        onClick={() => {
                          setviewList(true);
                          setviewAppointment(false);
                          setreqList(true);
                          setCompletedList(true);
                        }}
                      />
                      <label htmlFor="">
                        Showing result for :{" "}
                        <span className="capital">
                          {ClintDetailsList?.first_name +
                            " " +
                            ClintDetailsList.last_name}
                        </span>
                      </label>
                    </div>
                  )}
                  {/* ======================= CALENDER COMP START ===================== */}
                  {viewCalender && (
                    <div className="all-appoimt-wrapper">
                      <div className="row gx-3">
                        <div className="col-md-8">
                          <div className="left">
                            <CalenderComp events={AppointmentsCalender} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="right">
                            <div className="top-heading">
                              <div className="left-heading">
                                <label htmlFor="">Today's Visits</label>
                              </div>
                              <div className="right-date">
                                <label htmlFor="">
                                  {TodayDate}
                                  <span></span>
                                </label>
                              </div>
                            </div>
                            {ClintVisitlist && ClintVisitlist.length > 0 ? (
                              ClintVisitlist.map((itemlist, index) => {
                                return (
                                  <div className="check-up-card">
                                    <div className="left-check-up">
                                      <div className="left-img">
                                        <img src={itemlist.image_path} alt="" />
                                      </div>
                                      <div className="right-check-up-details">
                                        <h5>Visit - {index + 1}</h5>
                                        <label htmlFor="">
                                          {itemlist.doctor_clinic_name}
                                        </label>
                                        <p>
                                          <img src={ic_clock} alt="" />
                                          <span>
                                            {itemlist.date} - {itemlist.time}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="right-check-up">
                                      <img src={ic_hospital} alt="" />
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <p className="scheule_tody">No Schedule Today</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* ======================= CALENDER COMP END ===================== */}
                  <Tab.Content>
                    <Tab.Pane eventKey="1">
                      {viewList && (
                        <div id="app-list-page-wrapper">
                          <div className="table-wrapper scroll-table">
                            <table>
                              <thead>
                                <tr>
                                  <th>Sr. No</th>
                                  <th>Name</th>
                                  <th>Photo</th>
                                  <th>Pet Type</th>
                                  <th>Description</th>
                                  <th>Time</th>
                                  <th>Fees</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {AppointmentsList &&
                                AppointmentsList.length > 0 ? (
                                  AppointmentsList.map((itemlist, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td className="tdName">
                                          {`${itemlist?.first_name} ${itemlist?.last_name}`}
                                        </td>
                                        <td>
                                          <label htmlFor="" className="photo">
                                            <img
                                              src={itemlist.image_path}
                                              alt=""
                                            />
                                          </label>
                                        </td>
                                        <td>{itemlist.type_of_animal}</td>
                                        <td>{itemlist.appointment_desc}...</td>
                                        <td>
                                          {itemlist.date} - {itemlist.time}
                                        </td>
                                        <td>
                                          {formatCurrency(itemlist.charges)}
                                        </td>

                                        <>
                                          {itemlist.stringStatus ===
                                          "Accepted" ? (
                                            <>
                                              <td>
                                                <img
                                                  src={ic_completed}
                                                  className="status-ic"
                                                  alt=""
                                                />
                                                <label
                                                  htmlFor=""
                                                  className="pending-txt"
                                                >
                                                  {itemlist.stringStatus}
                                                </label>
                                              </td>
                                            </>
                                          ) : itemlist.stringStatus ===
                                            "Rejected" ? (
                                            <>
                                              <td>
                                                <img
                                                  src={ic_rejected}
                                                  className="status-ic"
                                                  alt=""
                                                />
                                                <label
                                                  htmlFor=""
                                                  className="pending-txt"
                                                >
                                                  {itemlist.stringStatus}
                                                </label>
                                              </td>
                                            </>
                                          ) : itemlist.stringStatus ===
                                            "Pending" ? (
                                            <td>
                                              <img
                                                src={ic_pending}
                                                className="status-ic"
                                                alt=""
                                              />
                                              <label
                                                htmlFor=""
                                                className="pending-txt"
                                              >
                                                {itemlist.stringStatus}
                                              </label>
                                            </td>
                                          ) : itemlist.stringStatus ===
                                            "Completed" ? (
                                            <td>
                                              <img
                                                src={ic_completed}
                                                className="status-ic"
                                                alt=""
                                              />
                                              <label
                                                htmlFor=""
                                                className="pending-txt"
                                              >
                                                {itemlist.stringStatus}
                                              </label>
                                            </td>
                                          ) : itemlist.stringStatus ===
                                            "In progress" ? (
                                            <>
                                              <td>
                                                <img
                                                  src={ic_clock_pending}
                                                  className="status-ic"
                                                  alt=""
                                                />
                                                <label
                                                  htmlFor=""
                                                  className="pending-txt"
                                                >
                                                  {itemlist.stringStatus}
                                                </label>
                                              </td>
                                            </>
                                          ) : (
                                            <td>
                                              <img
                                                src={ic_clock_pending}
                                                className="status-ic"
                                                alt=""
                                              />
                                              <label
                                                htmlFor=""
                                                className="pending-txt"
                                              >
                                                {itemlist.stringStatus}
                                              </label>
                                            </td>
                                          )}
                                        </>

                                        <td>
                                          {itemlist.stringStatus ===
                                            "Pending" ||
                                          itemlist.stringStatus ===
                                            "Rejected" ? (
                                            <>
                                              <button
                                                className="view-details-btn"
                                                onClick={() => {
                                                  getClinetDetails(
                                                    itemlist.customer_animal_id,
                                                    itemlist.doctor_appointment_id
                                                  );
                                                  getClinetVisitDetails(
                                                    itemlist.customer_animal_id
                                                  );
                                                  getClinetInviceDetails(
                                                    itemlist.customer_animal_id
                                                  );
                                                  // setviewAppointment(true);
                                                  // setviewList(false);
                                                  // setviewCalender(false);
                                                }}
                                              >
                                                -
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              <button
                                                className="view-details-btn"
                                                onClick={() => {
                                                  setdoctorId(
                                                    itemlist.doctor_id
                                                  );
                                                  // setcustomerId(
                                                  //   itemlist.user_id
                                                  // );
                                                  sessionStorage.setItem(
                                                    "customerId",
                                                    itemlist.user_id
                                                  );
                                                  getClinetDetails(
                                                    itemlist.customer_animal_id,
                                                    itemlist.doctor_appointment_id
                                                  );
                                                  getClinetVisitDetails(
                                                    itemlist.customer_animal_id
                                                  );
                                                  getClinetInviceDetails(
                                                    itemlist.customer_animal_id
                                                  );
                                                  setviewAppointment(true);
                                                  setviewList(false);
                                                  setviewCalender(false);
                                                }}
                                              >
                                                View Details
                                              </button>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </table>
                            {AppointmentsList.length ? (
                              ""
                            ) : (
                              <p
                                style={{
                                  fontSize: "20px",
                                  marginTop: "200px",
                                }}
                                className=" text-center justify-content-center align-items-center"
                              >
                                Oops! It doesn't look like there are any
                                Appointment?
                              </p>
                            )}
                          </div>

                          <PaginationComp
                            length={AppointmentsList.length}
                            total={TotalData}
                          />
                        </div>
                      )}
                      {viewAppointment && (
                        <div className="app-details-wrapper">
                          <div className="row gx-3">
                            <div className="col-md-8 ">
                              <div className="left">
                                <div className="aap-heading">
                                  {/* all */}
                                  <label htmlFor="">Appointment </label>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div>
                                      <label htmlFor="" className="key">
                                        Client Name
                                      </label>
                                    </div>
                                    <label htmlFor="" className="value tdName">
                                      {ClintDetailsList.first_name
                                        ? `${ClintDetailsList.first_name} ${ClintDetailsList.last_name}`
                                        : ""}
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <div>
                                      <label htmlFor="" className="key">
                                        Appointment Date & Time
                                      </label>
                                    </div>
                                    <label htmlFor="" className="value">
                                      {ClintDetailsList.date}{" "}
                                      {ClintDetailsList.time}
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <div>
                                      <label htmlFor="" className="key">
                                        Description
                                      </label>
                                    </div>
                                    <label htmlFor="" className="value">
                                      {ClintDetailsList.appointment_desc}
                                    </label>
                                  </div>
                                  {ClintDetailsList.total_call_duration && (
                                    <div className="col-12">
                                      <div>
                                        <label htmlFor="" className="key">
                                          Call Duration
                                        </label>
                                      </div>
                                      <label htmlFor="" className="value">
                                        {ClintDetailsList.total_call_duration
                                          ? ClintDetailsList.total_call_duration
                                          : ""}
                                      </label>
                                    </div>
                                  )}
                                  <div className="col-12">
                                    {ClintPhoto && ClintPhoto.length > 0 && (
                                      <label htmlFor="" className="key">
                                        Photos
                                      </label>
                                    )}
                                    <div className="photo-video-wrapper">
                                      <div className="row">
                                        {ClintPhoto && ClintPhoto.length > 0 ? (
                                          ClintPhoto.map((itemlist, index) => {
                                            return (
                                              <div className="col-xl-2 col-lg-3 col-md-4 ">
                                                <img
                                                  src={itemlist.image_path}
                                                  alt=""
                                                  height="70px"
                                                  width="70px"
                                                />
                                              </div>
                                            );
                                          })
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      {/* {startdigno && (
                                        <ChattingVideoComp/>
                                      )} */}
                                    </div>
                                    {ClintDetailsList.status != 5 && (
                                      <div className="btn-wrapper">
                                        {/* <button className="btn-blue-blank">
                                          Cancel
                                        </button> */}
                                        {/* <button className="btn-yellow-filled">
                                          Re-Schedule
                                        </button> */}
                                        <button
                                          className="btn-blue-filled"
                                          onClick={() => {
                                            handalVideo();
                                          }}
                                        >
                                          {startdigno
                                            ? "Mark Complete"
                                            : "Start Appointment"}
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* <div className="message-payment">
                                <img src={ic_pending} alt="" />
                                <label htmlFor="">
                                  15 day Payment Cycle for payment deposit to
                                  your account.
                                </label>
                              </div> */}
                            </div>
                            <div className="col-md-4 ">
                              <div className="right">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="1"
                                >
                                  <Nav
                                    variant="pills"
                                    id="newTabMai"
                                    className="tob_nav_pills"
                                  >
                                    <Nav.Item>
                                      <Nav.Link eventKey="1">
                                        Visits(<span>{VisiteTotal}</span>)
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="2">
                                        Invoices (<span>{InviceTotal}</span>)
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="1">
                                      <div className="visits-tab-wrapper">
                                        <div className="today-heading">
                                          <label htmlFor="" className="key">
                                            Today's
                                          </label>
                                          <div className="line"></div>
                                        </div>
                                        {ClintVisitAlllist.today_appointment &&
                                        ClintVisitAlllist.today_appointment
                                          .length > 0 ? (
                                          ClintVisitAlllist.today_appointment.map(
                                            (itemlist, index) => {
                                              return (
                                                <div
                                                  className="app-time-date"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    setAppointment(index + 1);
                                                    getClinetDetailsByeVisit(
                                                      itemlist.doctor_appointment_id
                                                    );
                                                  }}
                                                >
                                                  <div className="left-app-time-date">
                                                    <label
                                                      htmlFor=""
                                                      className="value"
                                                    >
                                                      {itemlist.date}
                                                    </label>
                                                  </div>
                                                  <div className="right-app-time-date">
                                                    <img
                                                      src={ic_notepad}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <p className="scheule_tody">
                                            No Schedule Today
                                          </p>
                                        )}
                                        <div className="today-heading">
                                          <label
                                            htmlFor=""
                                            className="key line-style"
                                          >
                                            Completed
                                          </label>
                                          <div className="line"></div>
                                        </div>
                                        {ClintVisitAlllist.history &&
                                        ClintVisitAlllist.history.length > 0 ? (
                                          ClintVisitAlllist.history.map(
                                            (itemlist, index) => {
                                              return (
                                                <div
                                                  style={{ cursor: "pointer" }}
                                                  className="app-time-date"
                                                  onClick={() => {
                                                    setAppointment(index + 1);
                                                    getClinetDetailsByeVisit(
                                                      itemlist.doctor_appointment_id
                                                    );
                                                  }}
                                                >
                                                  <div className="left-app-time-date">
                                                    <label
                                                      htmlFor=""
                                                      className="value"
                                                    >
                                                      {itemlist.date}
                                                    </label>
                                                  </div>
                                                  <div className="right-app-time-date">
                                                    <img
                                                      src={ic_notepad}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <p className="scheule_tody">
                                            No Schedule Today
                                          </p>
                                        )}
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="2">
                                      <div className="invoices-tab-wrapper">
                                        <div className="table-wrapper ">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Sr. No</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {ClintInvicelist &&
                                              ClintInvicelist.length > 0 ? (
                                                ClintInvicelist.map(
                                                  (itemlist, index) => {
                                                    return (
                                                      <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{itemlist.date}</td>
                                                        <td>
                                                          {formatCurrency(
                                                            itemlist.charges
                                                          )}
                                                        </td>
                                                        <td>
                                                          <img
                                                            src={ic_completed}
                                                            className="status-ic"
                                                            alt=""
                                                          />
                                                          <label
                                                            htmlFor=""
                                                            className="pending-txt"
                                                          >
                                                            Completed
                                                          </label>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <p className="scheule_tody">
                                                  No Schedule Completed
                                                </p>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="2">
                      <div className="requests-wrapper">
                        {reqList && (
                          <div id="app-list-page-wrapper1">
                            <div className="table-wrapper scroll-table">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Sr. No sdf</th>
                                    <th>Name</th>
                                    <th>Photo / Video</th>
                                    <th>Pet Type</th>
                                    <th>Description</th>
                                    <th>Time</th>
                                    <th>Fees</th>
                                    <th className="center">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {AppointmentsRequest &&
                                  AppointmentsRequest.length > 0 ? (
                                    AppointmentsRequest.map(
                                      (itemlist, index) => {
                                        return (
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>
                                              {" "}
                                              {`${itemlist?.first_name} ${itemlist?.last_name}`}
                                            </td>
                                            <td>
                                              <label
                                                htmlFor=""
                                                className="photo"
                                              >
                                                <img
                                                  src={itemlist.image_path}
                                                  alt=""
                                                />
                                              </label>
                                            </td>
                                            <td>{itemlist.type_of_animal}</td>
                                            <td>
                                              {itemlist.appointment_desc}...
                                            </td>
                                            <td>
                                              {itemlist.date} - {itemlist.time}
                                            </td>
                                            <td>
                                              {formatCurrency(itemlist.charges)}
                                            </td>

                                            <td className="center">
                                              <button
                                                className="reject-btn"
                                                onClick={() => {
                                                  //   setviewAppointment(true);
                                                  //   setreqList(false);
                                                  //   setviewCalender(false);
                                                  //   setviewList(false);
                                                  putStatusChanges(
                                                    itemlist.doctor_appointment_id,
                                                    "3"
                                                  );
                                                }}
                                              >
                                                Reject
                                              </button>
                                              <button
                                                className="accept-btn"
                                                onClick={() => {
                                                  //   setviewAppointment(true);
                                                  //   setreqList(false);
                                                  //   setviewCalender(false);
                                                  //   setviewList(false);
                                                  putStatusChanges(
                                                    itemlist.doctor_appointment_id,
                                                    "2"
                                                  );
                                                }}
                                              >
                                                Accept
                                              </button>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>
                              {AppointmentsRequest.length ? (
                                ""
                              ) : (
                                <p
                                  style={{
                                    fontSize: "20px",
                                    marginTop: "200px",
                                  }}
                                  className=" text-center justify-content-center align-items-center"
                                >
                                  Oops! It doesn't look like there are any
                                  Appointment?
                                </p>
                              )}
                            </div>
                            <PaginationComp
                              length={AppointmentsList.length}
                              total={TotalData}
                            />
                          </div>
                        )}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="3">
                      {completedList && (
                        <div id="app-list-page-wrapper2">
                          <div className="table-wrapper scroll-table">
                            <table>
                              <thead>
                                <tr>
                                  <th>Sr. No</th>
                                  <th>Name</th>
                                  <th>Photo / Video</th>
                                  <th>Pet Type</th>
                                  <th>Description</th>
                                  <th>Time</th>
                                  <th>Fees</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {AppointmentsCompleted &&
                                AppointmentsCompleted.length > 0 ? (
                                  AppointmentsCompleted.map(
                                    (itemlist, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>{`${itemlist?.first_name} ${itemlist?.last_name}`}</td>
                                          <td>
                                            <label htmlFor="" className="photo">
                                              <img
                                                src={itemlist.image_path}
                                                alt=""
                                              />
                                            </label>
                                          </td>
                                          <td>{itemlist.type_of_animal}</td>
                                          <td>
                                            {itemlist.appointment_desc}...
                                          </td>
                                          <td>
                                            {itemlist.date} - {itemlist.time}
                                          </td>
                                          <td>
                                            {formatCurrency(itemlist.charges)}
                                          </td>
                                          <td>
                                            <img
                                              src={ic_completed}
                                              className="status-ic"
                                              alt=""
                                            />
                                            Completed
                                          </td>
                                          <td>
                                            <button
                                              className="view-details-btn"
                                              onClick={() => {
                                                getClinetDetails(
                                                  itemlist.customer_animal_id,
                                                  itemlist.doctor_appointment_id
                                                );
                                                getClinetVisitDetails(
                                                  itemlist.customer_animal_id
                                                );
                                                getClinetInviceDetails(
                                                  itemlist.customer_animal_id
                                                );
                                                setviewAppointment(true);
                                                setviewList(false);
                                                setviewCalender(false);
                                                setCompletedList(false);
                                              }}
                                            >
                                              View Details
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </table>
                            {AppointmentsCompleted.length ? (
                              ""
                            ) : (
                              <p
                                style={{
                                  fontSize: "20px",
                                  marginTop: "200px",
                                }}
                                className=" text-center justify-content-center align-items-center"
                              >
                                Oops! It doesn't look like there are any
                                Appointment?
                              </p>
                            )}
                          </div>
                          <PaginationComp
                            length={AppointmentsCompleted.length}
                            total={TotalData}
                          />
                        </div>
                      )}
                      {viewAppointment && (
                        <div className="app-details-wrapper">
                          <div className="row gx-3">
                            <div className="col-md-8 ">
                              <div className="left">
                                <div className="aap-heading">
                                  <label htmlFor="">Appointment -1</label>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div>
                                      <label htmlFor="" className="key">
                                        Name
                                      </label>
                                    </div>
                                    <label htmlFor="" className="value">
                                      {ClintDetailsList.first_name}
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <div>
                                      <label htmlFor="" className="key">
                                        Appointment Date & Time
                                      </label>
                                    </div>
                                    <label htmlFor="" className="value">
                                      {ClintDetailsList.date}{" "}
                                      {ClintDetailsList.time}
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <div>
                                      <label htmlFor="" className="key">
                                        Description
                                      </label>
                                    </div>
                                    <label htmlFor="" className="value">
                                      {ClintDetailsList.appointment_desc}
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label htmlFor="" className="key">
                                      Photos
                                    </label>
                                    <div className="photo-video-wrapper">
                                      <div className="row">
                                        {ClintPhoto && ClintPhoto.length > 0 ? (
                                          ClintPhoto.map((itemlist, index) => {
                                            return (
                                              <div className="col-xl-2 col-lg-3 col-md-4 ">
                                                <img
                                                  src={itemlist.image_path}
                                                  alt=""
                                                  height="70px"
                                                  width="70px"
                                                />
                                              </div>
                                            );
                                          })
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div className="start-wrapper mt-3">
                                        <label htmlFor="" className="key">
                                          Prescription
                                        </label>
                                        <div
                                          className="prescription-btn-wrapper mt-2 row"
                                          onClick={() => setPrescription(true)}
                                        >
                                          {ClintPrescription &&
                                          ClintPrescription.length > 0 ? (
                                            ClintPrescription.map(
                                              (itemlist, index) => {
                                                return (
                                                  <div className="col-md-3">
                                                    <div className="prescription-btn">
                                                      <div className="wrapper">
                                                        <div className="ic_wrapper">
                                                          <img
                                                            src={ic_recipt}
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div className="date mt-2">
                                                          02-02-2023
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="btn-wrapper">
                                      <button className="btn-blue-blank">
                                        Cancel
                                      </button>
                                      <button className="btn-yellow-filled">
                                        Re-Schedule
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 ">
                              <div className="right">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="1"
                                >
                                  <Nav
                                    variant="pills"
                                    id="newTabMai"
                                    className="tob_nav_pills"
                                  >
                                    <Nav.Item>
                                      <Nav.Link eventKey="1">
                                        Visits (<span>{VisiteTotal}</span>)
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="2">
                                        Invoices (<span>{VisiteTotal}</span>)
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="1">
                                      <div className="visits-tab-wrapper">
                                        <div className="today-heading">
                                          <label htmlFor="" className="key">
                                            Today
                                          </label>
                                          <div className="line"></div>
                                        </div>
                                        {ClintVisitAlllist.today_appointment &&
                                        ClintVisitAlllist.today_appointment
                                          .length > 0 ? (
                                          ClintVisitAlllist.today_appointment.map(
                                            (itemlist, index) => {
                                              return (
                                                <div className="app-time-date">
                                                  <div
                                                    className="left-app-time-date"
                                                    onClick={() => {
                                                      setAppointment(index + 1);

                                                      // onColorChange(index,"active")
                                                      //
                                                      getClinetDetailsByeVisit(
                                                        itemlist.doctor_appointment_id
                                                      );
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor=""
                                                      className="value"
                                                    >
                                                      {itemlist.date}
                                                    </label>
                                                  </div>
                                                  <div className="right-app-time-date">
                                                    <img
                                                      src={ic_notepad}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <p className="scheule_tody">
                                            No Schedule Today
                                          </p>
                                        )}
                                        <div className="today-heading">
                                          <label
                                            htmlFor=""
                                            className="key line-style"
                                          >
                                            Completed
                                          </label>
                                          <div className="line"></div>
                                        </div>
                                        {ClintVisitAlllist.history &&
                                        ClintVisitAlllist.history.length > 0 ? (
                                          ClintVisitAlllist.history.map(
                                            (itemlist, index) => {
                                              return (
                                                <div className="app-time-date">
                                                  <div
                                                    className="left-app-time-date"
                                                    onClick={() => {
                                                      setAppointment(index + 1);

                                                      // onColorChange(index,"active")
                                                      //
                                                      getClinetDetailsByeVisit(
                                                        itemlist.doctor_appointment_id
                                                      );
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor=""
                                                      className="value"
                                                    >
                                                      {itemlist.date}
                                                    </label>
                                                  </div>
                                                  <div className="right-app-time-date">
                                                    <img
                                                      src={ic_notepad}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <p className="scheule_tody">
                                            No Schedule Today
                                          </p>
                                        )}
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="2">
                                      <div className="invoices-tab-wrapper">
                                        <div className="table-wrapper">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Sr. No</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {ClintInvicelist &&
                                              ClintInvicelist.length > 0 ? (
                                                ClintInvicelist.map(
                                                  (itemlist, index) => {
                                                    return (
                                                      <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{itemlist.date}</td>
                                                        <td>
                                                          {formatCurrency(
                                                            itemlist.charges
                                                          )}
                                                        </td>
                                                        <td>
                                                          <img
                                                            src={ic_completed}
                                                            className="status-ic"
                                                            alt=""
                                                          />
                                                          <label
                                                            htmlFor=""
                                                            className="pending-txt"
                                                          >
                                                            Completed
                                                          </label>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <p className="scheule_tody">
                                                  No Schedule Completed
                                                </p>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <PrescriptionComp />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </InfiniteScroll>
        </>
      )}
    </>
  );
};

export default Video;
