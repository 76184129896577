import React from "react";
import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

// import FullCalendar from "@fullcalendar/react"; // must go before plugins
// import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!

const CalenderComp = ({ events }) => {
  console.log(events);
  const localizer = momentLocalizer(moment);

  // Array of events to be displayed on the calendar
  // const events = [event1];
  // console.log("events",events);

  function getDate(dayString) {
    const today = new Date();
    const year = today.getFullYear().toString();
    let month = (today.getMonth() + 1).toString();

    if (month.length === 1) {
      month = "0" + month;
    }

    return dayString.replace("YEAR", year).replace("MONTH", month);
  }
  const event1 = {
    title: "Today",
    start: "Thu Sep 07 2023 10:0:00 GMT-6:-30 (India Standard Time)", // Start date (today + 3 days)
    end: "Thu Sep 14 2023 22:20:37 GMT+0530 (India Standard Time)", // End date (today + 4 days)

         
  };



  // Array of events to be displayed on the calendar
  const eventsDetails = [event1];
  console.log("eventsDetails",eventsDetails);
  return (
    <>
      <div className="calendar-section ">
        <Calendar
         min={new Date("2023-07-18T10:00:00")}
         max={new Date("2023-07-18T20:00:00")}
          views={["month", ""]}
          style={{ height: "90vh" }}
          // endAccessor="end"
          // showMultiDayTimes={true}
          step={30}
          localizer={localizer}
          
          startAccessor="start"
          events={events}
          eventPropGetter={(event, start, end, isSelected) => {
            switch (event.title) {
              case "Completed":
                return { className: "event1" };
              case "Visit":
                return { className: "event2" };
      

              case "Weekends":
                return { className: "event5" };
              default:
                return { className: "event1" };
            }
          }}
     
        />
      </div>
    </>
  );
};

export default CalenderComp;
