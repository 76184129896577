import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import ic_hospital from "../../assets/images/ic_hospital.svg";
import dog from "../../assets/images/dog.svg";
import ic_clock from "../../assets/images/ic_clock.svg";
import ic_right_arrow from "../../assets/images/ic_right_arrow.svg";
import cat from "../../assets/images/cat.png";
import cat_1 from "../../assets/images/cat_1.png";
import cat_2 from "../../assets/images/cat_2.png";
import Modal from "react-bootstrap/Modal";
import dog_1 from "../../assets/images/dog_1.png";
import dog_2 from "../../assets/images/dog_2.png";
import dog_3 from "../../assets/images/dog_3.png";
import CreatableSelect from "react-select/creatable";
import ic_filter from "../../assets/images/ic_filter.svg";
import ic_notepad from "../../assets/images/ic_notepad.svg";
import ic_recipt from "../../assets/images/ic_recipt.svg";
import ic_rating from "../../assets/images/ic_rating.svg";
import ic_completed from "../../assets/images/ic_completed.svg";
import ic_pending from "../../assets/images/ic_pending.svg";
import ic_play_video from "../../assets/images/ic_play_video.svg";
import ic_back_arrow from "../../assets/images/ic_back_arrow.svg";
import PaginationComp from "../../sharedComponent/PaginationComp";
import { AppContext } from "../../context/AppContext";
import PrescriptionComp from "../../sharedComponent/PrescriptionComp";
import InfiniteScroll from "react-infinite-scroll-component";
import { simpleGetCall, simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import Notepad from "../../sharedComponent/Notepad";
const CustomerDetails = () => {
  const {
    setLoading,
    customerData,
    setPrescription,
    loading,
    doctorAppoinmentID,
    setdoctorAppoinmentID,
    VaccinePrescription,
    setVaccinePrescription,
    petFoodPrescription,
    setPetFoodPrescription,
    notesData,
    setNotesData,
    vaccineData,
    setVaccineData,
    petFoodData,
    setPetFoodData,
    patientDet,
    setPatientDet,
  } = useContext(AppContext);

  const [viewList, setviewList] = useState(false);
  const [reqList, setreqList] = useState(true);
  const [completedList, setCompletedList] = useState(true);
  const [TotalData, setTotalData] = useState(0);
  const [FilterName, setFilterName] = useState("");
  const [DoctorColor, setDoctorColor] = useState("active");
  const [Appointment, setAppointment] = useState("1");
  const [VisiteTotal, setVisiteTotal] = useState("0");
  const [InviceTotal, setInviceTotal] = useState("0");
  const [ClintVisitID, setClintVisitID] = useState("");

  const [viewAppointment, setviewAppointment] = useState(false);
  const [ClintDetails, setClintDetails] = useState([]);

  const [ClintDetailsList, setClintDetailsList] = useState({});
  console.log("ClintDetailsList,", ClintDetailsList);
  const [ClintPhoto, setClintPhoto] = useState([]);
  const [ClintPrescription, setClintPrescription] = useState([]);
  const [ClintVisitlist, setClintVisitlist] = useState([]);
  const [ClintInvicelist, setClintInvicelist] = useState([]);
  const [last_page, setlast_page] = useState(false);

  const onColorChange = (index) => {
    setDoctorColor("");
    setClintVisitID(index);
  };

  const [page, setPage] = useState(1);
  useEffect(() => {
    if (FilterName === "") {
      setLoading(true);
      getDetails(page);
    } else {
      getDetails(page);
    }
  }, [FilterName]);

  // Modals APi
  const getVaccinationDet = (id) => {
    simpleGetCall(
      ApiConfig.GET_ALL_VACCINE_DETAILS + `?doctor_appointment_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setVaccineData(res?.data);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPetfoodPrescription = (id) => {
    simpleGetCall(
      ApiConfig.GET_PRESCRPTION_PET_FOOD + `?doctor_appointment_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setPetFoodData(res?.data);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDetails = (currentPage) => {
    let newRequestBody = JSON.stringify({
      doctor_id: customerData.doctor_id,
      page: currentPage,
      limit: 10,
      keyword: FilterName,
    });
    simplePostCall(ApiConfig.CLIINET_DETAILS, newRequestBody)
      .then((data) => {
        if (data.success) {
          setLoading(false);
          let total = data.total ? data.total : 0;
          setTotalData(total);
          setlast_page(data.last_page);

          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              animal_name: eachteam.animal_name || "",
              phone_number: eachteam.phone_number || "",
            };
          });

          if (currentPage === 1) {
            setClintDetails(data.data);
            setLoading(false);
          } else {
            setClintDetails([...ClintDetails, ...LearningData]);
            setLoading(false);
          }
        } else {
          setClintDetails([]);
          setlast_page(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // ======================================= pagination starts ======================
  //  const [pageCount, setPageCount] = useState(0);
  const onPageLoad = () => {
    // debugger
    setPage(page + 1);
    if (last_page) {
    } else {
      getDetails(page + 1);
    }
  };

  // ======================================= PAGINATION ENDS ========================

  const getClinetDetails = (id, doctor_appointment_id) => {
    setdoctorAppoinmentID(doctor_appointment_id);
    setLoading(true);

    simpleGetCall(
      ApiConfig.CLIINET_DETAILS_BY_ID +
        id +
        "&" +
        "doctor_appointment_id=" +
        doctor_appointment_id
    )
      .then((res) => {
        setClintDetailsList(res.data);
        setPatientDet(res.data);
        setClintPhoto(res.data.appointment_images);
        setClintPrescription(res.data.prescription);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getClinetVisitDetails = (id) => {
    setLoading(true);
    simpleGetCall(
      ApiConfig.CLINET_VIST_BY_ID + id + "&doctor_id=" + customerData.doctor_id
    )
      .then((res) => {
        setVisiteTotal(res.total);
        setClintVisitlist(res);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getClinetInviceDetails = (id) => {
    setLoading(true);

    simpleGetCall(
      ApiConfig.CLIINET_INVICE_ID + id + "&doctor_id=" + customerData.doctor_id
    )
      .then((res) => {
        var total = res.data.length ? res.data.length : 0;
        setInviceTotal(total);

        setClintInvicelist(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Notes api
  const getNotesData = (id) => {
    simpleGetCall(ApiConfig.GET_NOTES_DATA + `?appointment_id=${id}`)
      .then((res) => {
        if (res?.success) {
          let VAL = res?.data?.remark;
          setNotesData(VAL);
        } else {
          setNotesData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getClinetDetailsByeVisit = (id) => {
    setdoctorAppoinmentID(id);
    setLoading(true);

    simpleGetCall(ApiConfig.CLIINET_DETAILS_BYE_VISITE_ID + id)
      .then((res) => {
        setClintDetailsList(res.data);
        setClintPhoto(res.data.appointment_images);
        setClintPrescription(res.data.prescription);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <>
          <InfiniteScroll
            dataLength={ClintDetails?.length}
            next={onPageLoad}
            // hasMore={true}
            hasMore={
              viewAppointment === true
                ? false
                : ClintDetails.length !== TotalData
            }
            scrollableTarget="scroll-table"
            loader={
              viewAppointment === true ? (
                ""
              ) : (
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                ></h4>
              )
            }
          >
            <div
              className="appointment-wrapper CustomerDetails-wrapper"
              id="cx-main"
            >
              <div className="reportsTabs">
                {viewAppointment === true ? (
                  <></>
                ) : (
                  <>
                    <div className="search-filter-wrapper">
                      <div className="search-filter-left">
                        <input
                          type="text"
                          className="form-control serach-input"
                          placeholder="Search by Name..."
                          value={FilterName}
                          autoFocus
                          onChange={(e) => {
                            setFilterName(e.target.value);
                            setPage(1);
                          }}
                        />
                        {/* <button className="fltr-btn">
              <img src={ic_filter} alt="" />
            </button> */}
                      </div>
                    </div>
                  </>
                )}

                {viewAppointment && (
                  <div
                    className="app-result-arrow-top"
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <img
                      src={ic_back_arrow}
                      alt=""
                      onClick={() => {
                        setviewList(true);
                        setviewAppointment(false);
                        setreqList(true);
                        setCompletedList(true);
                      }}
                    />
                    <label htmlFor="">
                      Showing result for :{" "}
                      <span className="capital">
                        {ClintDetailsList.first_name +
                          " " +
                          ClintDetailsList.last_name}
                      </span>
                    </label>
                  </div>
                )}

                <div id="app-list-page-wrapper">
                  {completedList && (
                    <div className="table-wrapper scroll-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Name</th>
                            <th>Pet Name & Photo</th>
                            <th>Pet Type</th>
                            <th>Contact No.</th>
                            <th>Email</th>

                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {ClintDetails && ClintDetails.length > 0 ? (
                            ClintDetails.map((itemlist, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td className="tdName">
                                    {`${itemlist?.first_name} ${itemlist?.last_name}`}
                                  </td>
                                  <td>
                                    <label htmlFor="" className="photo me-2">
                                      <img src={itemlist.image_path} alt="" />
                                    </label>
                                    {itemlist?.animal_name}
                                  </td>
                                  <td>{itemlist.type_of_animal}</td>
                                  <td>{itemlist.phone_number}</td>
                                  <td>{itemlist.email}</td>
                                  <td>
                                    <button
                                      className="view-details-btn"
                                      onClick={() => {
                                        setviewAppointment(true);
                                        setviewList(false);
                                        setCompletedList(false);
                                        getClinetDetails(
                                          itemlist.customer_animal_id,
                                          itemlist.doctor_appointment_id
                                        );
                                        getClinetVisitDetails(
                                          itemlist.customer_animal_id
                                        );
                                        getClinetInviceDetails(
                                          itemlist.customer_animal_id
                                        );
                                        getNotesData(
                                          itemlist.doctor_appointment_id
                                        );
                                        getVaccinationDet(
                                          itemlist.doctor_appointment_id
                                        );
                                        getPetfoodPrescription(
                                          itemlist.doctor_appointment_id
                                        );
                                      }}
                                    >
                                      View Details
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                      {ClintDetails.length ? (
                        ""
                      ) : (
                        <p
                          style={{
                            fontSize: "20px",
                            marginTop: "200px",
                          }}
                          className=" text-center justify-content-center align-items-center"
                        >
                          Oops! It doesn't look like there are any Customer?
                        </p>
                      )}
                    </div>
                  )}
                  {viewAppointment === true ? (
                    <></>
                  ) : (
                    <>
                      <PaginationComp
                        length={ClintDetails.length}
                        total={TotalData}
                      />
                    </>
                  )}
                  {viewAppointment && (
                    <div className="app-details-wrapper">
                      <div className="row gx-3">
                        <div className="col-md-8 ">
                          <div className="left">
                            {/* <div className="top-btn-heading-wrapper">
                              <div className="aap-heading ">
                                <label className="capital" htmlFor="">{ClintDetailsList.first_name + " " + ClintDetailsList.last_name}</label>
                              </div>
                              <div className="btn-wrapper">
                                <button className="btn-yellow-filled">
                                  Re-Schedule
                                </button>
                              </div>
                            </div> */}

                            <div className="row" id="appointment-customer-left">
                              <div className="col-md-6">
                                <div>
                                  <label htmlFor="" className="key">
                                    Client Name
                                  </label>
                                </div>
                                <label htmlFor="" className="value">
                                  {ClintDetailsList?.first_name +
                                    " " +
                                    ClintDetailsList?.last_name}
                                </label>
                              </div>
                              <div className="col-md-6">
                                <div>
                                  <label htmlFor="" className="key">
                                    Appointment Date & Time
                                  </label>
                                </div>
                                <label htmlFor="" className="value">
                                  {ClintDetailsList?.date}{" "}
                                  {ClintDetailsList?.time}
                                </label>
                              </div>
                              <div className="col-12">
                                <div>
                                  <label htmlFor="" className="key">
                                    Description
                                  </label>
                                </div>
                                <label htmlFor="" className="value">
                                  {ClintDetailsList?.appointment_desc}
                                </label>
                              </div>
                              <div className="col-12">
                                {ClintPhoto && ClintPhoto.length > 0 && (
                                  <label htmlFor="" className="key">
                                    Photos
                                  </label>
                                )}

                                <div className="photo-video-wrapper">
                                  <div className="row">
                                    {ClintPhoto &&
                                      ClintPhoto.length > 0 &&
                                      ClintPhoto.map((itemlist, index) => {
                                        return (
                                          <div className="col-xl-2 col-lg-3 col-md-4 ">
                                            <img
                                              src={itemlist.image_path}
                                              alt=""
                                              className="photo"
                                            />
                                          </div>
                                        );
                                      })}
                                    {/* <div className="col-xl-2 col-lg-3 col-md-4 photo-ic_video">
                                <img src={dog_1} alt="" className="photo" />
                                <img
                                  src={ic_play_video}
                                  alt=""
                                  className="ic-video"
                                />
                              </div> */}
                                  </div>
                                  <div className="start-wrapper mt-3">
                                    <label htmlFor="" className="key">
                                      {/* {ClintPrescription?.length === 0 ? (
                                        <></>
                                      ) : ( */}
                                      Prescriptions
                                      {/* )} */}
                                    </label>
                                    {ClintPrescription.length !== 0 ||
                                    vaccineData.length !== 0 ||
                                    petFoodData.length !== 0 ? (
                                      <div className="insideFlex">
                                        {ClintPrescription?.length ===
                                        0 ? null : (
                                          <div className="col-md-3 colThreeMg">
                                            <div
                                              className="prescription-btn-wrapper mt-2 row"
                                              onClick={() =>
                                                setPrescription(true)
                                              }
                                            >
                                              <div className="prescription-btn">
                                                <div className="wrapper">
                                                  <div className="ic_wrapper">
                                                    <img
                                                      src={ic_recipt}
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="date my-2 text-center">
                                                    <span>
                                                      Medicine Prescription
                                                    </span>
                                                    <br />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {/* Vaccine Prescription */}
                                        {vaccineData &&
                                        vaccineData.length > 0 ? (
                                          <div className="col-md-3 colThreeMg">
                                            <div
                                              className="prescription-btn-wrapper mt-2 row"
                                              onClick={() =>
                                                setVaccinePrescription(true)
                                              }
                                            >
                                              <div className="prescription-btn">
                                                <div className="wrapper">
                                                  <div className="ic_wrapper">
                                                    <img
                                                      src={ic_recipt}
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="date my-2 text-center">
                                                    <span>
                                                      Vaccine Prescription
                                                    </span>
                                                    <br />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {/* Petshop Prescription */}
                                        {petFoodData &&
                                        petFoodData.length > 0 ? (
                                          <div className="col-md-3 colThreeMg">
                                            <div
                                              className="prescription-btn-wrapper mt-2 row"
                                              onClick={() =>
                                                setPetFoodPrescription(true)
                                              }
                                            >
                                              <div className="prescription-btn">
                                                <div className="wrapper">
                                                  <div className="ic_wrapper">
                                                    <img
                                                      src={ic_recipt}
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="date my-2 text-center">
                                                    <span>
                                                      Pet Food Prescription
                                                    </span>
                                                    <br />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    ) : (
                                      <p className="text-danger">
                                        No prescription found !!!
                                      </p>
                                    )}
                                  </div>

                                  {ClintDetailsList?.review_title &&
                                    ClintDetailsList?.appointment_customer_rating && (
                                      <div className="rating-wrapper">
                                        <label htmlFor="" className="key">
                                          Ratings & Review
                                        </label>
                                        <div className="rating-card">
                                          <div className="header-section">
                                            <div className="left-rating">
                                              <p className="value">
                                                {ClintDetailsList?.review_title}
                                              </p>
                                              <label
                                                className="capital"
                                                htmlFor=""
                                              >
                                                {/* Dr. {ClintDetailsList?.doctor_name} */}
                                                {`${ClintDetailsList.first_name} ${ClintDetailsList.last_name}`}
                                              </label>
                                            </div>
                                            <div className="right-rating">
                                              {ClintDetailsList?.appointment_customer_rating && (
                                                <img src={ic_rating} alt="" />
                                              )}
                                              <span>
                                                {
                                                  ClintDetailsList?.appointment_customer_rating
                                                }
                                              </span>
                                            </div>
                                          </div>
                                          <div className="paragraph">
                                            <p>
                                              {
                                                ClintDetailsList?.appointment_customer_review
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  {ClintDetailsList?.remark && (
                                    <div className="rating-wrapper">
                                      <label htmlFor="" className="key">
                                        <span style={{ fontWeight: "700" }}>
                                          Note :
                                        </span>{" "}
                                        {ClintDetailsList?.remark}
                                      </label>
                                      {/* <Notepad /> */}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 ">
                          <div className="right">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="1"
                            >
                              <Nav
                                variant="pills"
                                id="newTabMai"
                                className="tob_nav_pills"
                              >
                                <Nav.Item>
                                  <Nav.Link eventKey="1">
                                    Visits (<span>{VisiteTotal}</span>)
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="2">
                                    Invoices (<span>{InviceTotal}</span>)
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                              <Tab.Content>
                                <Tab.Pane eventKey="1">
                                  <div className="visits-tab-wrapper">
                                    <div className="today-heading">
                                      <label htmlFor="" className="key">
                                        Today
                                      </label>
                                      <div className="line"></div>
                                    </div>
                                    {ClintVisitlist.today_appointment &&
                                    ClintVisitlist.today_appointment.length >
                                      0 ? (
                                      ClintVisitlist.today_appointment.map(
                                        (itemlist, index) => {
                                          return (
                                            <div
                                              style={{ cursor: "pointer" }}
                                              className={`app-time-date ${""}`}
                                              onClick={() => {
                                                setAppointment(index + 1);
                                                setClintVisitID("");
                                                setDoctorColor("active");
                                                getClinetDetailsByeVisit(
                                                  itemlist.doctor_appointment_id
                                                );
                                              }}
                                            >
                                              <div className="left-app-time-date">
                                                <label
                                                  htmlFor=""
                                                  className="value"
                                                >
                                                  Appointment- {index + 1}
                                                </label>
                                                <div>
                                                  <label
                                                    htmlFor=""
                                                    className="time"
                                                  >
                                                    {itemlist.date}
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="right-app-time-date">
                                                <img
                                                  src={ic_right_arrow}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          );
                                        }
                                      )
                                    ) : (
                                      <p className="scheule_tody">
                                        No Schedule Today
                                      </p>
                                    )}
                                    <div className="today-heading">
                                      <label
                                        htmlFor=""
                                        className="key line-style"
                                      >
                                        Previous
                                      </label>
                                      <div className="line"></div>
                                    </div>
                                    {ClintVisitlist.history &&
                                    ClintVisitlist.history.length > 0 ? (
                                      ClintVisitlist.history.map(
                                        (itemlist, index) => {
                                          return (
                                            <div
                                              style={{ cursor: "pointer" }}
                                              className={`app-time-date ${
                                                ClintVisitID === index
                                                  ? "active"
                                                  : ""
                                              }`}
                                              onClick={() => {
                                                setAppointment(index + 1);
                                                onColorChange(index, "active");
                                                getClinetDetailsByeVisit(
                                                  itemlist.doctor_appointment_id
                                                );
                                              }}
                                            >
                                              <div className="left-app-time-date">
                                                <label
                                                  htmlFor=""
                                                  className="value"
                                                >
                                                  Appointment- {index + 1}
                                                </label>
                                                <div>
                                                  <label
                                                    htmlFor=""
                                                    className="time"
                                                  >
                                                    {itemlist.date}
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="right-app-time-date">
                                                <img
                                                  src={ic_right_arrow}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          );
                                        }
                                      )
                                    ) : (
                                      <p className="scheule_tody">
                                        No Previous Schedule Found
                                      </p>
                                    )}
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="2">
                                  <div className="invoices-tab-wrapper">
                                    <div className="table-wrapper">
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>Sr. No</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {ClintInvicelist &&
                                          ClintInvicelist.length > 0 ? (
                                            ClintInvicelist.map(
                                              (itemlist, index) => {
                                                return (
                                                  <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{itemlist.date}</td>
                                                    <td>₹{itemlist.charges}</td>
                                                    <td>
                                                      <img
                                                        src={ic_completed}
                                                        className="status-ic"
                                                        alt=""
                                                      />
                                                      <label
                                                        htmlFor=""
                                                        className="pending-txt"
                                                      >
                                                        Completed
                                                      </label>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )
                                          ) : (
                                            <p className="scheule_tody">
                                              No Schedule Completed
                                            </p>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <PrescriptionComp />
                </div>
              </div>
            </div>
          </InfiniteScroll>
        </>
      )}
    </>
  );
};

export default CustomerDetails;
